import btnLoading from '../img/icons/loader.svg';
import openSea from '../img/opensea-w.svg';

export function Leaderboard({data, loading, playerClass, contractAddress}) {
    function loader() {
        return(<>
            <span className='mx-auto'>
                <img src={btnLoading} className="w-12 animate-spin mx-auto" />
            </span>
        </>)
    }

    function renderRecords() {
        let tmpData = [...data.topRpsPlayers];

        tmpData.sort((a, b) => {
            // First, compare by wins
            if (a.wins < b.wins) {
              return 1;
            } else if (a.wins > b.wins) {
              return -1;
            } else {
              // If wins are equal, compare by kills
              if (a.kills < b.kills) {
                return 1;
              } else if (a.kills > b.kills) {
                return -1;
              } else {
                return 0;
              }
            }});

            console.log(tmpData)

        const records = tmpData.map((player, index) => {
            console.log(player)
            return(
                <tr key={`leaderboard_entry_${index}`} className="px-4 h-14 border-b border-white odd:bg-blue-700 even:bg-blue-600 transition-colors">
                    <td><h3>{index + 1}</h3></td>
                    <td><h3>{playerClass[player.playerClass].symbol}</h3></td>
                    <td><h5>#{player.tokenId}</h5></td>
                    <td><h5 className='text-[1.6rem]'>{ player.username ? player.username : player.wallet}</h5></td>
                    <td><h4><span className='mr-4' title="Wins">🏆</span>{player.wins}</h4></td>
                    <td><h4><span className='mr-4' title="Kills">🔪</span>{player.kills}</h4></td>
                    <td><h4><span className='mr-4' title="Total Matches Played">🎮</span>{player.losses + player.wins}</h4></td>
                    <td className=''>
                        {/* <div className='flex'>
                            <a
                            className="mx-auto hover:opacity-75 hover:scale-110 transition-all"
                            href={`https://testnets.opensea.io/assets/mumbai/${contractAddress}/${player.tokenId}`}
                            target="_blank">
                            <img src={openSea} alt="View on OpenSea" className="w-[20px] " />
                            </a>
                        </div> */}
                    </td>
                </tr>
            )
        })

        return records
    }

    return(<>
        <div className='flex flex-row place-content-between mt-12 align-bottom'>
            <h3 className='text-white text-left'>Leaderboard</h3>
        </div>
        <hr className='border-2 border-white' />
        { loading && loader() }
        { data && 
        <table className='bg-blue-600 w-full '>
            <tr className='font-teko uppercase text-[1.1rem] border-b-[1px] border-white'>
                <th>Rank</th>
                <th>Class</th>
                <th>Combatant ID</th>
                <th>Token Holder</th>
                <th>Wins</th>
                <th>Kills</th>
                <th>Games Played</th>
                <th>Marketplace</th>
            </tr>
            {renderRecords()}
        </table>
        }
        <p className='mt-4 text-[0.9rem] italic'>Note: Token Holder reflects the owner of the token during the last match the combatant participated in.</p>
    </>)
}

