import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

// import ExileLogo from './img/exiled/ax-test-logo.svg';

import w_arrow from './img/exiled/arrow-w.svg';
import exiled_lockup from './img/exiled/exiled_lockup.svg';
import alpha_logo from './img/exiled/alpha_lockup.svg';
import animatedHead from './img/exiled/Logo_Animation_blackBG.gif';

export default function Brand (props) {

    return (
        <>
        <div className='bg-exiled_red w-screen min-h-screen px-8'>
            <div className='container'>
                <div id="intro">
                    <div className='py-6 flex flex-row space-x-10'>
                        <img src={animatedHead} className="w-[60px] mix-blend-screen" />
                        <h4 className='exiled_h4 my-auto'>Alpha Academy Brand</h4>
                    </div>
                </div>
                <div className='py-20'>
                    <h3 className='exiled_h3'>Breakpoint test</h3>
                    <h1 className='exiled_h1 hidden 2xl:block'>2XL</h1>
                    <h1 className='exiled_h1 hidden xl:block 2xl:hidden'>XL</h1>
                    <h1 className='exiled_h1 hidden lg:block xl:hidden'>LG</h1>
                    <h1 className='exiled_h1 hidden md:block lg:hidden'>MD</h1>
                    <h1 className='exiled_h1 block md:hidden'>SM</h1>
                </div>

                <div id="headings" className='flex flex-col space-y-24'>
                    <h1 className='exiled_h1'><span className="text-black/40" >H1</span> Alpha Academy Hero Heading</h1>
                    <h2 className='exiled_h2'><span className="text-black/40" >H2</span> Alpha Academy Hero Heading<br />the degens drove the dog to the docks</h2>
                    <h3 className='exiled_h3'><span className="text-black/40" >H3</span> Alpha Academy Hero Heading<br />the degens drove the dog to the docks</h3>
                    <h4 className='exiled_h4'><span className="text-black/40" >H4</span> Alpha Academy Hero Heading<br />the degens drove the dog to the docks</h4>
                    <h5 className='exiled_h5'><span className="text-black/40" >H5</span> Alpha Academy Hero Heading<br />the degens drove the dog to the docks</h5>
                </div>

            {/* Tone Of Voice */}
            {/* Fonts */}
            {/* Colours */}
            {/* Logos */}
            </div>
        </div>
        </>
    )
}
