import React, {useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sketch from 'react-p5';

import AEDark from '../img/AE_logo_V2.svg';
import ae_to_sat_tokens from './collection_metadata_consolidated.json';
import ae_allocation from './allocation_list.json';
import { useMediaQuery } from 'react-responsive';


// import bg_top from 'https://www.alphaelementary.io/comic/bg-top.png';
// import bg_middle from '../img/show_and_tell/comic/bg-centre-img.png';
// import bg_bottom from '../img/show_and_tell/comic/bg-bottom.png';
// import bubble from '../img/show_and_tell/comic/bubble.png';
// import ae_kid from '../img/show_and_tell/comic/AE-kid.png';
// import backpack from '../img/show_and_tell/comic/Backpack.png';
// import rev_bk from '../img/show_and_tell/comic/revealed.png';

// let ae_sat_pairs = aeSatPairs.map( test => ({showAlloc: test.showAllocations}));

let Panel01 = null;
let Panel01_text = null;
let Panel02 = null;
let Panel02_text = null;
let Panel03 = null;
let Panel03_text = null;
let backpack = null;
let sat_item_img = null;
let ae_student = null;
let teacher = null;
let ae_site = null;
let nft;
let revBtn;
let imageURL = ''
let bSaveImg = false;
let scale = 1; // default is 0.3
let myFont;

export default function SATReveal (props) {
    const isMobile = useMediaQuery({query: `(max-width: 700px)`});
    const [getResult, setResult] = useState(false);
    const location = useLocation()
    let history = useNavigate();


    ////////////
    // p5 VARIABLES
    ///////////

    // let bg = "bg-white" // controls div bg colour
    let phase = 0;
    let count = 0;
    let keyframeCounter = 0;
    let comic = {}
    let rev = false;

    let caption;
    let cap_input;
    let label;
    let submit;
    let saveImg;
    let input_div;
    let osLink;
    let backBtn;

    // comic.bg_top = bg_top
    // comic.bg_middle = bg_middle
    // comic.bg_bottom = bg_bottom
    // comic.bubble = bubble
    // comic.ae_kid = ae_kid
    // comic.backpack = backpack
    // comic.rev_bk = rev_bk

    // automatically check tokens once wallet is connected
    // useEffect(() => {
    //     if(props.isConnected) {
    //         // checkWinner()
    //         props.fetchUserTokens()
    //     }
    //   }, [props.isConnected])

    //   console.log("look:" + location.state.stolen)

    //   const connectBtn = (<>
    //     <button onClick={props.connectFunc}
    //         className="
    //         flex tracking-wider mx-auto invert blk_btn
    //         ">
    //             Connect Wallet
    //         </button>
    //     </>);

    async function revealArtefacts(event) {
        // event.preventDefault();
        // alert(props.tokenIDs);
        // console.log(`https://cw6tu3q794.execute-api.ap-southeast-2.amazonaws.com/revealArtefact?satTokenId=${location.state.sat_id}&artefactId=${location.state.artefact_id}`)
        fetch(`https://cw6tu3q794.execute-api.ap-southeast-2.amazonaws.com/revealArtefact?satTokenId=${location.state.sat_id}&artefactId=${location.state.artefact_id}`, {method: "POST"})
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    console.log("SUCCESS!")
                    props.notification("Artefact revealed! Please allow a few minutes for updates to display on OpenSea.", "success")
                    fetch(`https://api.opensea.io/api/v1/asset/0xb6b4ca6fe80008c429b713c26d6dcebc4ac32893/${location.state.sat_id}?force_update=true`, {method: "GET"})
                        .then(res => res.json())
                        .then(
                            (result) => {
                                console.log("refresh sent")
                            });
                });
    }
    
    // function displayRevealButton() {
    //     return (<>
    //         <form onSubmit={revealArtefacts}>
    //         <div className="flex space-x-5">
    //             <h3>Submitting with AE Artwork #{props.tokenIDs && props.tokenIDs[0]}</h3>
    //             <button type={"submit"} className="tracking-wider mx-auto invert blk_btn block mt-10 w-[250px]">Create record</button>
    //         </div>
    //     </form>
    //         </>); 
    // }
    console.log(`Backpack: ${location.state.image_name}`)
    console.log(`Rev Image: ${location.state.rev_img}`)
    

    // phase 0 – top panel show
    // phase 1 - middle pannel show
    // phase 2 - bottom panel show
    // phase 3 - AE and backpack show
    // phase 4 - reveal

    let phase_0 = {phase: 0, duration: 8, delay: 10}; // top panel
    let phase_1 = {phase: 1, duration: 15, delay: 15}; // middle panel
    let phase_2 = {phase: 2, duration: 15, delay: 15}; // middle pannel text
    let phase_3 = {phase: 3, duration: 12, delay: 15}; // bottom pannel
    let phase_4 = {phase: 4, duration: 10, delay: 15}; // backpack
    let phase_5 = {phase: 5, duration: 30, delay: 80}; // 
    let phase_6 = {phase: 6, duration: 15, delay: 2}; // NFT reveal
    let phase_7 = {phase: 7, duration: 15, delay: 80}; // Fade back to comic
    // let phase_4 = {phase: 5, duration: 15, count: 0, opacity: 0};
    // let phase_5 = {phase: 4, duration: 60, delay: 190, count: 0, opacity: 255};

    function mySketch() {
        // const preload = (p5) => {
        //     if(imageURL) {
        //         Panel01 = p5.loadImage('https://alphaelem.s3.amazonaws.com/Panel01.png', (img) => Panel01 = img)
        //         Panel01_text = p5.loadImage('https://alphaelem.s3.amazonaws.com/Panel01_Text.png', (img2) => Panel01_text = img2)
        //         Panel02 = p5.loadImage('https://alphaelem.s3.amazonaws.com/Panel02.png', (img2) => Panel02 = img2)
        //         Panel02_text = p5.loadImage('https://alphaelem.s3.amazonaws.com/Panel02_Speech.png', (img3) => Panel02_text = img3)
        //         Panel03 = p5.loadImage('https://alphaelem.s3.amazonaws.com/Panel03.png', (img4) => Panel03 = img4)
        //         Panel03_text = p5.loadImage('https://alphaelem.s3.amazonaws.com/Panel03_Text.png', (img5) => Panel03_text = img5)
        //         // backpack = p5.loadImage(`https://www.alphaelementary.io/backpacks/Backpack_Neue.jpg`, (bp) => backpack = bp)
        //         backpack = p5.loadImage(`${location.state.image_name}`, (bp) => backpack = bp)
        //         // sat_item_img = p5.loadImage(`https://alphaelem.s3.amazonaws.com/artefacts/dddd_retrogroove.png`, (sat) => sat_item_img = sat)
        //         // UNCOMMENT THE BELOW AND DELETE THE ABOVE AS IT IS TESTING
        //         sat_item_img = p5.loadImage(`https://www.alphaelementary.io/artifacts/500/${location.state.rev_img}`, (sat) => sat_item_img = sat)
        //         ae_site = p5.loadImage(`https://alphaelem.s3.amazonaws.com/Website_Graphic.png`, (aew) => ae_site = aew)
        //         location.state.ae_student && (ae_student = p5.loadImage(`https://alphaelem.s3.amazonaws.com/ae_students/${location.state.ae_student}.png`, (stu) => ae_student = stu))
        //         location.state.stolen == true && (teacher = p5.loadImage(`https://alphaelem.s3.amazonaws.com/Teacher_02.png`, (tchr) => teacher = tchr))
        //     }
        // }
        const setup = (p5, canvasParentRef) => {
            var canvasDiv = document.getElementById('myCanvas');
            var width = canvasDiv.offsetWidth;
            var height = width;
            console.log(width)
            if(width > 1400) {
                width = p5.map(width, 1400, 3000, 1350, 1650)
                height = width*0.8;
            } else if (width <= 1400 && width >= 700) {
                width = p5.map(width, 1400, 400, 1400, 450)
                height = width*0.8;
            } else if (width > 400) {
                width = 700
                height = p5.map(width, 700, 300, 1000, 600, true);
            } else if (width <= 400) {
                width = 450
                height = p5.map(width, 500, 300, 1000, 500, true);
            }
            let sketchCanvas = p5.createCanvas(width, height).parent(canvasParentRef);
            var masterCnv = document.getElementById('defaultCanvas0');
            masterCnv.classList.add("mx-auto")

            // IMAGES
            p5.loadImage('https://alphaelem.s3.amazonaws.com/Panel01.png', img => {Panel01 = img})
            p5.loadImage('https://alphaelem.s3.amazonaws.com/Panel01_Text.png', img2 => {Panel01_text = img2})
            p5.loadImage('https://alphaelem.s3.amazonaws.com/Panel02.png', img2 => {Panel02 = img2})
            p5.loadImage('https://alphaelem.s3.amazonaws.com/Panel02_Speech.png', img3 => {Panel02_text = img3})
            p5.loadImage('https://alphaelem.s3.amazonaws.com/Panel03.png', img4 => {Panel03 = img4})
            p5.loadImage('https://alphaelem.s3.amazonaws.com/Panel03_Text.png', img5 => {Panel03_text = img5})
            // backpack = p5.loadImage(`https://www.alphaelementary.io/backpacks/Backpack_Neue.jpg`, (bp) => {backpack = bp)
            p5.loadImage(`${location.state.image_name}`, bp => {backpack = bp})
            // sat_item_img = p5.loadImage(`https://alphaelem.s3.amazonaws.com/artefacts/dddd_retrogroove.png`, (sat) => {sat_item_img = sat)
            // UNCOMMENT THE BELOW AND DELETE THE ABOVE AS IT IS TESTING
            p5.loadImage(`https://www.alphaelementary.io/artifacts/500/${location.state.rev_img}`, sat => {sat_item_img = sat})
            p5.loadImage(`https://alphaelem.s3.amazonaws.com/Website_Graphic.png`, aew => {ae_site = aew})
            location.state.ae_student && (ae_student = p5.loadImage(`https://alphaelem.s3.amazonaws.com/ae_students/${location.state.ae_student}.png`, stu => {ae_student = stu}))
            location.state.stolen == true && (teacher = p5.loadImage(`https://alphaelem.s3.amazonaws.com/Teacher_02.png`, tchr => {teacher = tchr}))

            p5.imageMode(p5.CENTER);
            p5.frameRate(40)
            p5.rectMode(p5.CENTER)
            
            if(p5.width <= 1024) {
                scale = p5.map(p5.width, 300, 1024, 0.4, 0.5)
                // scale = 0.25;
            } else if (p5.width >= 1025) {
                scale = p5.map(p5.width, 1024, 1600, 0.5, 1)
            }

            
            phase_0.length = phase_0.duration + phase_0.delay;
            phase_1.start = phase_0.length + phase_1.delay;
            phase_1.length = phase_1.duration;
            phase_1.end = phase_1.start + phase_1.length;
            // middle panel
            phase_2.start = phase_1.end + phase_2.delay;
            phase_2.length = phase_2.duration;
            phase_2.end = phase_2.start + phase_2.length;
            // middle pannel text
            phase_3.start = phase_2.end + phase_3.delay;
            phase_3.length = phase_3.duration;
            phase_3.end = phase_3.start + phase_3.length;
            // bottom pannel
            phase_4.start = phase_3.end + phase_4.delay;
            phase_4.length = phase_4.duration;
            phase_4.end = phase_4.start + phase_4.length;
            
            // backpack
            phase_5.start = phase_4.end + phase_5.delay;
            phase_5.length = phase_5.duration;
            phase_5.end = phase_5.start + phase_5.length;

            phase_6.start = phase_5.end + phase_6.delay;
            phase_6.length = phase_6.duration;
            phase_6.end = phase_6.start + phase_6.length;

            phase_7.start = phase_6.end + phase_7.delay;
            phase_7.length = phase_7.duration + phase_7.delay;
            phase_7.end = phase_7.start + phase_7.length;
        }

        const draw = (p5) => {
            if(keyframeCounter >= phase_5.start ) {
                // let col = p5.map(keyframeCounter, phase_5.start, phase_5.end, 255, 0);
                // p5.background(col);
            } else {
                p5.clear();
            }
            let mouseX = p5.map(p5.mouseX, 0, p5.width, -10, 10)
            let mouseY = p5.map(p5.mouseY, 0, p5.height, -2, 2)

            if(Panel01) {
                // top
                p5.push()
                    p5.push()
                    if(keyframeCounter < phase_1.start) {
                        let opac = p5.map(keyframeCounter, 0, 18, 0, 255);
                        p5.tint(255, opac);
                        p5.image(Panel01_text, p5.width/4, 120*scale, Panel01_text.width*scale, Panel01_text.height*scale)
                    }
                    p5.pop()
                if(keyframeCounter >= phase_1.start && keyframeCounter < phase_1.end) {
                    let animY = p5.map(keyframeCounter, phase_1.start, phase_1.end-1, 280*scale, 300*scale);
                    p5.image(Panel01, p5.width/2+mouseX, animY+(mouseY), Panel01.width*scale, Panel01.height*scale);
                    p5.image(Panel01_text, p5.width/4, 120*scale, Panel01_text.width*scale, Panel01_text.height*scale)
                } else if(keyframeCounter >= phase_1.end) {
                    // image(comic.bg_middle, width/2, 1167*scale, comic.bg_middle.width*scale, comic.bg_middle.height*scale);
                    p5.image(Panel01, p5.width/2+mouseX, 300*scale+(mouseY), Panel01.width*scale, Panel01.height*scale);
                    p5.image(Panel01_text, p5.width/4, 120*scale, Panel01_text.width*scale, Panel01_text.height*scale)
                }
                p5.pop()
                // middle
                p5.push()
                if(keyframeCounter >= phase_2.start && keyframeCounter < phase_2.end) {
                    let opac = p5.map(keyframeCounter, phase_2.start, phase_2.end-1, 0, 255);
                    p5.tint(255, opac);
                    let animY = p5.map(keyframeCounter, phase_2.start, phase_2.end-1, 700*scale, 720*scale);
                    p5.image(Panel02, p5.width/2+(-mouseX), animY+(mouseY), Panel02.width*scale, Panel02.height*scale);
                } else if(keyframeCounter >= phase_2.end) {
                    p5.image(Panel02, p5.width/2+(-mouseX), 720*scale+(mouseY), Panel02.width*scale, Panel02.height*scale);
                }
                p5.pop()

                p5.push()
                if(keyframeCounter >= phase_3.start && keyframeCounter < phase_3.end) {
                    let opac = p5.map(keyframeCounter, 0, phase_3.end-1, 0, 255);
                    p5.tint(255, opac);
                    p5.image(Panel02_text, p5.width/3, 690*scale, Panel02_text.width*scale, Panel02_text.height*scale);
                } else if(keyframeCounter >= phase_3.end) {
                    if(keyframeCounter < phase_6.start) {  
                        p5.image(Panel02_text, p5.width/3, 690*scale, Panel02_text.width*scale, Panel02_text.height*scale);
                    }
                }
                p5.pop()

                p5.push()
                    if(keyframeCounter >= phase_4.start && keyframeCounter <= phase_4.end) {
                        // let opac = p5.map(keyframeCounter, phase_4.delay, phase_4.length-1, 0, 255);
                        // tint(255, opac);
                        let animY = p5.map(keyframeCounter, phase_4.start, phase_4.end, 1210*scale, 1200*scale);
                        p5.image(Panel03, p5.width/2+(mouseX), animY+(mouseY), Panel03.width*scale, Panel03.height*scale);
                    } else if (keyframeCounter > phase_4.end) {
                        p5.image(Panel03, p5.width/2+(mouseX), 1200*scale+(mouseY), Panel03.width*scale, Panel03.height*scale);
                        p5.push()
                        // hide speech bubble after reveal
                        if(keyframeCounter < phase_4.end+40) {
                            let opac = p5.map(keyframeCounter, phase_4.end, phase_4.end+40, 0, 255);
                            p5.tint(255, opac);
                        }
                        p5.image(Panel03_text, p5.width*0.8, 1320*scale, Panel03_text.width*scale, Panel03_text.height*scale)
                        p5.pop()
                    }
                p5.pop()

                if(keyframeCounter >= phase_5.start + 30) {
                    p5.push()
                    let opac = p5.map(keyframeCounter, phase_5.start, phase_5.end, 0, 125, true);
                    p5.background(0, opac);
                    p5.pop();
                }
                
                p5.push()
                p5.strokeWeight(5)
                let padd = 200;
                    if(keyframeCounter >= phase_5.start && keyframeCounter <= phase_5.end) {
                        let animY = p5.map(keyframeCounter, phase_5.start, phase_5.end, 740*scale, 720*scale);
                        // bac
                        p5.rect(p5.width/2, animY+(20*scale), (backpack.width+padd)*scale, (backpack.height+padd)*scale)
                        p5.image(backpack, p5.width/2, animY, backpack.width*scale, backpack.height*scale);
                    } else if (keyframeCounter > phase_5.end && keyframeCounter < phase_6.start) {
                        p5.rect(p5.width/2, (720+20)*scale, (backpack.width+padd)*scale, (backpack.height+padd)*scale)
                        p5.image(backpack, p5.width/2, 720*scale, backpack.width*scale, backpack.height*scale);
                        p5.fill(240, 105, 105);
                        p5.noStroke();
                        // BUTTON
                        p5.rect(p5.width/2, 1315*scale, (backpack.width-60)*scale, 60*scale, 9*scale)
                        p5.fill(255)
                        p5.textFont("Bebas Neue")
                        p5.textSize(36*scale)
                        p5.textAlign(p5.CENTER)
                        p5.text("UNZIP BACKPACK", p5.width/2, 1323*scale)
                    }
                p5.pop()
                    
                    if(keyframeCounter > phase_5.end && rev == false) {
                        p5.cursor('pointer');
                        p5.noLoop();
                    } else {
                        p5.cursor(p5.ARROW);
                    }
                    
                    // REVEAL ITEM   
                    p5.push()
                    p5.strokeWeight(5)
                    if(keyframeCounter >= phase_6.start && keyframeCounter <= phase_6.end) {
                        // let opac = p5.map(keyframeCounter, phase_5.end, phase_5.length-1, 0, 255);
                        // p5.background(opac)
                        p5.background(0)
                        p5.push();
                            let opac = p5.map(keyframeCounter, phase_6.start+5, phase_6.end, 0, 255);
                            p5.tint(255, opac);
                            p5.image(sat_item_img, p5.width/2, p5.height/2, p5.width*0.7, p5.width*0.7);
                        p5.pop()
                    } else if (keyframeCounter > phase_6.end && keyframeCounter < phase_6.end + phase_7.delay) {
                        p5.background(0)
                        p5.image(sat_item_img, p5.width/2, p5.height/2, p5.width*0.7, p5.width*0.7);
                        // p5.fill(255)
                        // p5.textFont("Nanum Pen Script")
                        // p5.textSize(66*scale)
                        // p5.textAlign(p5.CENTER)
                        // p5.text(location.state.artefact_name, p5.width/2, p5.height/2-(450*scale))
                    }
                p5.pop()
                
                p5.push()
                    p5.strokeWeight(5)
                    if(keyframeCounter >= phase_7.start && keyframeCounter <= phase_7.end) {

                        p5.image(sat_item_img, p5.width*0.68, p5.height/2, (sat_item_img.width*scale), (sat_item_img.height*scale));
                        // AE STUDENT
                        ae_student && (p5.image(ae_student, p5.width/2-(380*scale), p5.height/3, (ae_student.width*scale)*0.9, (ae_student.height*scale)*0.9));
                        p5.strokeWeight(5)
                        p5.noFill()
                        p5.rect(p5.width/2-(380*scale), p5.height/3, (ae_student.width*scale)*0.9, (ae_student.height*scale)*0.9); // AE box
                        p5.rect(p5.width*0.68, p5.height/2, (sat_item_img.width*scale), (sat_item_img.height*scale)); // artefact box
                        let opac = p5.map(keyframeCounter, phase_7.start+5, phase_7.end, 255, 0);
                        p5.background(0, opac)
                    } else if (keyframeCounter > phase_7.end) {
                        // AE STUDENT
                        ae_student && (p5.image(ae_student, p5.width/2-(380*scale), p5.height/3, (ae_student.width*scale)*0.9, (ae_student.height*scale)*0.9));
                        p5.image(sat_item_img, p5.width*0.68, p5.height/2, (sat_item_img.width*scale), (sat_item_img.height*scale));
                        p5.strokeWeight(5)
                        p5.noFill()
                        p5.rect(p5.width/2-(380*scale), p5.height/3, (ae_student.width*scale)*0.9, (ae_student.height*scale)*0.9); // AE box
                        p5.rect(p5.width*0.68, p5.height/2, (sat_item_img.width*scale), (sat_item_img.height*scale)); // artefact box
                    }
                p5.pop()

                
                if(keyframeCounter > phase_7.end) {
                // if(keyframeCounter > 2) {
                    if(cap_input.value()) {

                        p5.push()
                        // box
                        p5.strokeWeight(5)
                        p5.stroke(0)
                        p5.fill(255)
                        p5.rect(p5.width/2-(380*scale), p5.height/3+(350*scale), 450*scale, 150*scale, 15*scale)

                        // bubble point
                        p5.strokeCap(p5.SQUARE);
                        p5.beginShape();
                        let triW = p5.width/2-(215*scale);
                        let triH = p5.height/3+(258*scale);
                        let triS = 20*scale
                        p5.vertex(triW-triS, triH+triS);
                        p5.vertex(triW, triH-triS);
                        p5.vertex(triW+triS, triH+triS);
                        p5.endShape();
                        // text
                        p5.fill(0)
                        p5.noStroke()
                        p5.textAlign(p5.CENTER);
                        p5.textFont("Nanum Pen Script")
                        let textS;
                        console.log(cap_input.value().length)
                        if(cap_input.value().length >= 40 ) {
                            textS = p5.map(cap_input.value().length, 40, 48, 56*scale, 30*scale, true)
                        } else {
                            textS = 60*scale;
                        }
                        p5.textSize(textS*scale)
                        p5.textLeading((textS-5)*scale);
                        p5.text(cap_input.value(), p5.width/2-(380*scale), p5.height/3+(370*scale), 420*scale, 100*scale);
                        p5.pop()

                    }
                    // AE TEACHER 
                    if(teacher) {
                        p5.image(teacher, p5.width/2-(380*scale), p5.height*0.86, (teacher.width*0.85)*scale, (teacher.height*0.85)*scale);
                    }
                }

                if(keyframeCounter == phase_5.start) {
                    document.getElementById('comic').style.backgroundColor = 'black'; 
                }

                if(bSaveImg) {
                    p5.push()
                    p5.imageMode(p5.CORNER)
                    let badgeH = ae_site.height/2
                    let badgeW = ae_site.width/2
                    p5.image(ae_site, p5.width-badgeW, 0, badgeW, badgeH);
                    p5.pop()
                    p5.save('AE_Show_and_tell_reveal.png')
                    bSaveImg = false;
                }

                if(keyframeCounter == phase_7.end) {

                // if(keyframeCounter == 1) {
                    input_div = p5.createDiv();
                    input_div.id('inputdiv')
                    input_div.class('bg-black w-400 lg:w-700 mx-auto')
                    let caption_container = document.getElementById('comiczone');
                    caption_container.style.display = 'flex';
                    caption_container.appendChild(document.getElementById('inputdiv'))
                    // label = p5.createP("Enter a caption for Show N Tell:");
                    
                    cap_input = p5.createInput('', 'text').attribute('maxlength', 65);
                    cap_input.size(300, 30);
                    if(teacher) {
                        cap_input.attribute('')
                        cap_input.value("Hold up...This isn't my backpack!")
                    } else {
                        cap_input.attribute('placeholder', "Enter a caption for Show and Tell")
                    }
                    cap_input.class('ml-3 rounded-sm')
                    cap_input.style('color: #000000; padding: 3px 5px 3px 5px;');
                    
                    submit = p5.createButton('Clear');
                    submit.mousePressed(updateMessage);
                    submit.class('font-bebas text-lg mx-3')
                    submit.style('color: #ffffff; background: #646464; border-radius: 4px; cursor: pointer')
                    submit.size(100, 35)
                    
                    saveImg = p5.createButton('Save Image');
                    saveImg.class('font-bebas text-lg')
                    saveImg.style('color: #ffffff; background: #F06969; border-radius: 4px; cursor: pointer')
                    saveImg.mousePressed(saveFile);
                    saveImg.size(100, 35)
                    
                    osLink = p5.createButton('View on OpenSea');
                    osLink.class('font-bebas text-lg ml-3')
                    osLink.style('color: #000000; background: #ffffff; border-radius: 4px; cursor: pointer')
                    osLink.mousePressed(openSea);
                    osLink.size(140, 35)
                    
                    // backBtn = p5.createButton('Back');
                    // backBtn.class('font-bebas text-lg')
                    // backBtn.style('color: #ffffff; background: #000000; border-radius: 4px; cursor: pointer')
                    // backBtn.mousePressed(backLink);
                    // backBtn.size(100, 35)

                    input_div.child(label);
                    input_div.child(cap_input);
                    input_div.child(submit);
                    input_div.child(saveImg);
                    input_div.child(osLink);
                }
                keyframeCounter++;
            } else {
                p5.background(240)
                p5.fill(0)
                p5.textSize(40)
                p5.textAlign(p5.CENTER)
                p5.text("LOADING...", p5.width/2, p5.height*0.4)
                // if(p5.frameCount > 80) {
                //     window.location.reload()
                // }
            }
        }

        function saveFile(p5) {
            // p5.save('show_and_tell_reveal.png');
            bSaveImg = true;
          }
        
          function updateMessage() {
            cap_input.value('');
          }
          
          function openSea() {
                window.location.href = `${props.sat_openSeaURL}${location.state.sat_id}`;
          }
          
          function backLink() {
            history.back();
          }

          function mousePressed(p5) {
            if(keyframeCounter > phase_5.end) {
                if(rev == false) {
                    revealArtefacts();
                }
                rev = true;
                p5.loop();
            }
          }

        return <Sketch  setup={setup} draw={draw} mousePressed={mousePressed} />
    }

    
    return(<>
            <div className={`relative overflow-x-hidden pt-10 min-h-screen max-h-auto flex flex-col place-content-around transition-colors duration-[1000ms]`} id="comic" >
                <div className="lg:container mx-auto pt-14 pb-20">

                    <div className='flex flex-col w-full lg:space-y-12' id="myCanvas" >
                        {/* <h2 className='text-white text-center'>Reveal your Show and Tell Artifact</h2> */}
                        <div className='flex flex-row w-full justify-center'><p className=' text-black'>Can't see anything? If you can't see the images, try refreshing the page.</p></div>
                        { !isMobile ? mySketch() : <div><p className='text-black text-xl text-center px-5'>Please use Quick reveal on a mobile device for best experience.</p></div>} 
                        {/* <div>{thisImage && mySketch()}</div> */}
                        
                        {/* <div className='lg:w-1/2 mx-auto' id="students">
                            { props.isConnected ? displayRevealButton() : connectBtn }
                        </div> */}
                        {/* { props.isConnected && <PrimaryButtonNarrow click={props.fetchUserTokens} target="_blank" >Refresh</PrimaryButtonNarrow> } */}
                        <img src={AEDark} className='w-20 mx-auto' />
                        {/* <img src={bg_top}/> */}
                    </div>

                </div>
            </div>
            <div id="comiczone" className="hidden flex-row w-[100%] mx-auto py-2 bg-black transition-none fixed top-14">
            </div>
        </>);
}