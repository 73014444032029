import React from 'react';
import { motion } from 'framer-motion'

import { PageHeader, Connect } from  './components';

// elements
import organica_img_lg from './img/frame_organica.png';
import neue_img_lg from './img/frame_neue.png';
import psychedelia_img_lg from './img/frame_psychedelia.png';
import retrogroove_img_lg from './img/frame_retrogroove.png';

export default function Houses (props) {
    const houses = [
        {
            name: 'Organica',
            image: organica_img_lg,
            // symbol: dopiumSymbol,
            description: <>The children of mother earth, 100% organic with nature's beauty and tones as their preferred look.</>
        },
        {
            name: 'Neue',
            image: neue_img_lg,
            // symbol: vibiumSymbol,
            description: <>The trendsetters in class. The cool kids, the futuristic fashionistas. Stylin' on chic minimal colourways.</>
        },
        {
            name: 'Psychedelia',
            image: psychedelia_img_lg,
            // symbol: delinquiumSymbol,
            description: <>These brain-melting bambinos bring the full mind-expanding spectrum with their kaleidoscopic steez...</>
        },
        {
            name: 'Retrogroove',
            image: retrogroove_img_lg,
            // symbol: corruptiumSymbol,
            description: <>No school like the old-school. Doused in classic nostalgia style, these superfly scraps are all about the O.G. retro vibes of yesteryear.</>
        }
    ]
    
    function getElements() {
        let result = (houses.map(function(house, index) {
            return(
                <div className='mb-14 scroll-mt-20 flex flex-col items-center md:items-start' key={index} id={house.name}>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                        <motion.img
                         initial={{opacity: 0, y: 80}}
                         whileInView={{opacity: 1, y: 0}}
                         transition={{duration: 1}}
                         viewport={{once: true, amount: 0.5}}
                         src={house.image} alt={`Some mysterious house called ${house.name}`} className={`w-full rounded-lg border-white/20 border-2`} />
                        <motion.div
                        initial={{y: 10}}
                        whileInView={{y: 0}}
                        viewport={{once: true, amount: "some"}}
                        className='text-center md:mx-10 md:w-[80%] lg:w-[65%] flex flex-col md:place-content-center'>
                            <h3 className='mb-4 text-black'>{house.name}</h3>
                            <p className='text-black'>{house.description}</p>
                        </motion.div>
                    </div>
                </div>
            )
        }))

        return (result);
    }

    return(<>
            <PageHeader header="The Houses" subheader="Some believe your house is selected by the sorting rat." />
            <div className={`w-full transition-colors bg-white text-black`}>
                <div className="container pt-14 pb-20 px-4 md:px-0 mx-auto">
                        <div className='' id="">
                            <>
                                <div className='flex flex-col-reverse mb-4 md:mb-0 lg:max-w-[880px] mx-auto'>
                                    <div className='mx-4 md:mx-8 lg:mx-20 md:mb-8' id="intro">
                                        <p className='text-lg text-center leading-6 2xl:w-[80%] mx-auto'>
                                        Alpha Elementary is home to 4 distinct school houses. Each house has its own aesthetic and theme. Some Alpha's are happy to rep the house colours whilst others really embody what the house is about. The 4 houses are Organica, Neue, Psychedelia &amp; Retrogroove. 
                                        </p>
                                    </div>
                                    <div className='text-center w-[90%] md:w-3/4 lg:w-1/2 mx-auto'>
                                        <h4>The Four Houses of Alpha Elementary</h4>
                                        <div className='mx-auto grid grid-cols-4 space-x-2 md:space-x-4 text-center items-center mt-4 mb-8 group'>
                                            <a href={`#${houses[0].name}`} className="hover:no-underline text-center flex flex-col items-center no-underline">
                                                <img src={houses[0].image} className="w-20 opacity-75 transition-opacity duration-300 hover:opacity-100" alt="Click to jump to element" />
                                                <h5 className='text-black text-[1rem] md:text-[1.3rem]'>{houses[0].name}</h5>
                                            </a>    
                                            <a href={`#${houses[1].name}`} className="hover:no-underline text-center flex flex-col items-center no-underline">
                                                <img src={houses[1].image} className="w-20 opacity-75 transition-opacity duration-300 hover:opacity-100" alt="Click to jump to element" />
                                                <h5 className='text-black text-[1rem] md:text-[1.3rem]'>{houses[1].name}</h5>
                                            </a>
                                            <a href={`#${houses[2].name}`} className="hover:no-underline text-center flex flex-col items-center no-underline">
                                                <img src={houses[2].image} className="w-20 opacity-75 transition-opacity duration-300 hover:opacity-100" alt="Click to jump to element" />
                                                <h5 className='text-black text-[1rem] md:text-[1.3rem]'>{houses[2].name}</h5>
                                            </a>
                                            <a href={`#${houses[3].name}`} className="hover:no-underline text-center flex flex-col items-center no-underline">
                                                <img src={houses[3].image} className="w-20 opacity-75 transition-opacity duration-300 hover:opacity-100" alt="Click to jump to element" />
                                                <h5 className='text-black text-[1rem] md:text-[1.3rem]'>{houses[3].name}</h5>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </>

                                { getElements() } 
                            
                        </div>
                </div>
            </div>
        </>);
}