import React, {useState, useEffect} from 'react';
import { useMutation, useLazyQuery, gql } from '@apollo/client';
import { saveToLocalStorage, loadFromLocalStorage } from '../components/localStorageUtils';
import { HashLink as Link } from 'react-router-hash-link';

import btnLoading from '../img/icons/loader.svg';
import { PageHeader, Connect } from  '../components';

const GET_USER = gql`
    query GetUser($input: String!) {
        getUser(wallet: $input) {
            discord_username
            discord_id
            approved
            status
            elementary_token_ids
            elementary_frens
            reg_experiment
        }
    }
`

const ADD_APPLICANT = gql`
    mutation AddApplicant($input: CreateUserInput!) {
        createUser(input: $input) {
            wallet
            discord_id
            discord_username
            discord_in_ours
            reg_experiment
            pfp
        }
    }
`;

const GET_DISCORD_USER = gql`
    mutation AuthenticateDiscord($code: String!) {
        authenticateDiscord(code: $code) {
            id 
            username
            inDiscord
        }
    }
`

export default function Experiments (props) {
    // const [userStatus, setUserStatus] = useState(null);
    const redirect = 'https://alphaacademy.app/experiments'
    const [ code, setCode ] = useState(null)
    const [ userData, setUserData ] = useState(null)
    const [getDiscordUser, {loading: discordLoading, error: discordError, data: discordUserData}] = useMutation(GET_DISCORD_USER);
    const [mutateFunction, { data: creAccRes, loading, error }] = useMutation(ADD_APPLICANT, 
        {
            notifyOnNetworkStatusChange: true
        }
    );

    const [checkUserStatus, {loading: userStatusLoading, error: userStatusError, data: userStatusResult}] = useLazyQuery(GET_USER, {
        notifyOnNetworkStatusChange:true
    });
  

  // check if user has an account
  useEffect(()=> {
    if(!userStatusResult) {
        checkUserStatus({variables: {"input": props.userAddress}});
    }
  }, [props.userAddress])

//   // IF new data is downloaded from DB, save it to local storage
  useEffect(() => {
    if(userStatusResult) {
      console.log(userStatusResult)
      setUserData(userStatusResult.getUser)
      let tmpLocalStorage = {...userStatusResult, wallet: props.userAddress}
      // save to local storage
      saveToLocalStorage("getUser", tmpLocalStorage, 15);
    }
  }, [userStatusResult])
    
    useEffect(()=> {
        if(code) {
            getDiscordUser({variables: {"code": code}});
        }
    }, [code])

    // useEffect(()=> {
    //     if(userData) {
    //         // console.log("got the userData: ")
    //         // console.log(userData)
    //     }
    // }, [userData])
    
    function submitForm(event) {
        event.preventDefault();
        // let tmpData = userData;
        let tmpData = {...userData, wallet: props.userAddress ? props.userAddress : null, reg_experiment: true};
        mutateFunction({variables: { input: tmpData }})
        let tmpLocalStorage = {getUser: tmpData, wallet: props.userAddress}
        // save to local storage
        saveToLocalStorage("getUser", tmpLocalStorage, 15);
    }

    // Discord auth
    const handleLoginClick = () => {
        const authWindow = window.open(`https://discord.com/api/oauth2/authorize?client_id=1087943597959172138&redirect_uri=${redirect}&response_type=code&scope=identify%20guilds`, 'Discord Authorization', 'width=500,height=800');
        const checkAuthInterval = setInterval(() => {
        if (authWindow.closed) {
            clearInterval(checkAuthInterval);
            return;
        }

        try {
            const urlParams = new URLSearchParams(authWindow.location.search);
            const authCode = urlParams.get('code');

            if (authCode) {
            authWindow.close();
            setCode(authCode);
            }
        } catch (e) {
            // Ignore exception caused by cross-origin access
        }
        }, 1000);
    };


    useEffect(() => {
        if(discordUserData) {
            setUserData(userData => ({...userData, discord_id: discordUserData.authenticateDiscord.id, discord_username: discordUserData.authenticateDiscord.username, discord_in_ours: discordUserData.authenticateDiscord.inDiscord}));
        }
    }, [discordUserData])

    // check A.E. tokens, if none exist, set pfp element to '0'
    // this way, we know to render a generic PFP
    useEffect(()=>{
        if(props?.tokenIDs == null) {
            props.fetchUserTokens();
        }
    }, [props.isConnected])

    useEffect(()=>{
        if(props?.tokenIDs == null || props?.tokenIDs.length == 0) {
            setUserData(userData => ({...userData, pfp: 0})) // pfp ID is 0
        }
    }, [props.tokenIDs])


    function loader() {

        const loaderAnimation = (<>
            <span className='mx-auto'>
                <img src={btnLoading} className="w-6 animate-spin mx-auto" />
            </span>
        </>)

        return loaderAnimation
    }

    function setPfp() {
        if(props?.tokenIDs == null || props?.tokenIDs.length == 0) { 
            return
        }

        return(<>
            <div className=''>
                <h4 className='mt-6 mb-3'>Select your PFP</h4>
                <div className={`flex flex-row flex-wrap justify-center w-3/4 mx-auto`}>
                    { props.tokenIDs.map((token) =>
                        { return(
                            <img key={`select_ae_${token}`}
                            onClick={()=>{setUserData(userData => ({...userData, pfp: token}))}}
                            src={require(`../img/ae_students/${token}.png`) } className={`rounded-full transition-all w-1/6 mx-2 my-2 hover:opacity-100 hover:cursor-pointer ${userData?.pfp === token ? "opacity-100 border-2 border-opacity-100 border-white" : "opacity-60 border-2 border-white/0"}`} />
                        )}
                        )
                    }
                </div>
            </div>
        </>)
    }

    function renderMainConcent() {
        return(
            <>
            { props.isHolder ?
                <>
                            <h3 className='mb-6'>Welcome to the lab</h3>
                            <p className='text-white mb-14 w-full xl:w-1/2 mx-auto text-[1.1rem] leading-[1.7rem]'>
                                You'll be helping to test a new gamified experience. 
                            <br /><br />
                                The goal here is to road test ideas and experiences quickly, get feedback to determine the viability of these ideas. And hopefully have some fun along the way.
                                <br /><br />
                                The graphics will be messy, the code; buggy, but the goal is to take a rough prototype and grow it with the community.
                                <br /><br />
                                The ultimate goal? If they prove viable, we will launch it into the wider market. If the experiments don't show promise, they'll be switched off and taken out back...
                            </p>
                            <div className='border border-slate-500 bg-slate-900 w-1/2 mx-auto py-6 px-6 rounded-2xl'>
                                <h3 className='mb-6'>The Experiment: <br />Rock, Paper Scissors 🪨 📄 ✂️</h3>
                                <p className='text-white mx-auto text-[1.1rem] leading-[1.7rem]'>
                                We're in the playground, afterall. The concept is a blockchain based game of Rock Paper Scissors. Each participant will mint a token which will be their player. This player will have its own on-chain stats. They can choose to register their player for matches, which will automatically play out at a set time, and you can tune in to watch. 
                                <br /><br />
                                As the prototype develops, the idea is to experiment with the new ERC-6551 standard - token bound accounts. This means the token itself can have a wallet with its own NFTs (e.g. lives). This functionality will be integrated in a later build.
                                <br /><br />
                                <strong>So, are you keen to be part of the experiment?</strong> It's only open to A.E. members and you can register below. This will give you access a discord channel where the experiment is discussed and updates will be shared.
                                </p>

                                { userData?.reg_experiment !== true && 
                                // only show if user has not yet opted-in
                                    <div>
                                        { !userData?.discord_username && <button onClick={handleLoginClick} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-white px-3 pt-2 py-1 hover:bg-white hover:text-black disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50" disabled={userStatusLoading}>{ !userStatusLoading ? "Connect with Discord" : <>{loader()}</>}</button> }
                                        { discordLoading && loader() }
                                        { (userData?.discord_username && creAccRes == null) && <h4 className='py-4'>GM, { userData.discord_username }</h4>}
                                        { (userData?.discord_username && creAccRes == null) && setPfp() }
                                        { (userData?.discord_username && creAccRes == null) && <button onClick={submitForm} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-white px-3 pt-2 py-1 hover:bg-white hover:text-black disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">{ !loading ? "Register" : <img src={btnLoading} className="w-6 animate-spin mx-auto" />}</button> }
                                        {/* <p className='text-white mx-auto text-[1.1rem] leading-[1.7rem] mt-4'>
                                            Registering will give you access to the Discord channel and register your wallet. More information to come.
                                        </p> */}

                                        {/* If successfuly */}
                                        { creAccRes && <h5 className='py-4 text-[1.7rem]'>Registration successful. <br /><br />You're now an official A.E. lab rat 🐀</h5>}
                                    </div>
                                }

                                {/* Show link to enter main page */}
                                { (creAccRes || userData?.reg_experiment) && 
                                    <>
                                        <Link onClick={()=>{window.scrollTo(0,0)}} to="/experiments/rps" className={` rounded-md font-teko text-[1.1rem] tracking-wider uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 hover:bg-blue-600 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`}>            
                                            <span className='mr-2'>🥽</span>
                                            Enter the Lab
                                        </Link> 
                                    </>
                                }

                            </div>
                </>
            :
                <>
                    <div className='bg-black'>
                        <div className='py-12 container mx-auto text-center px-6 lg:px-0'>
                            <h3 className='mb-6'>Welcome to the lab</h3>
                            <p className='text-white mb-14 w-full xl:w-1/2 mx-auto text-[1.1rem] leading-[1.7rem]'>
                                This experience is for Alpha Elementary holders only.
                            <br /><br />
                               If you'd like to participate, consider grabbing your first A.E. kid today!
                            </p>
                        </div>
                    </div>
                </>
            }
            </>
        )
    }

    return(<>
        <PageHeader header="Experiments" subheader="Alpha Experimentary" />
            <div className='bg-black'>
                <div className='py-12 container mx-auto text-center px-6 lg:px-0'>
                    {!props.isConnected ?
                        <Connect connect={props.connectFunc} />
                    : // if wallet is connected
                        renderMainConcent() 
                    }
            </div>
        </div>
        </>);
}
