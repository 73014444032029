import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState, useRef } from 'react';
// import useSound from 'use-sound'

// import ExileLogo from './img/exiled/ax-test-logo.svg';
// import ExileLogo from './img/exiled/alpha_academy_head_w.svg';
import vibiumImg from './img/exiled/exiled-hero-vibium.png'
import dopiumImg from './img/exiled/exiled-hero-dopium.png'
import delinquiumImg from './img/exiled/exiled-hero-delinquium.png'
import corruptiumImg from './img/exiled/exiled-hero-corruptium.png'

import w_arrow from './img/exiled/arrow-w.svg';
import exiled_lockup from './img/exiled/exiled_lockup.svg';
import alpha_logo from './img/exiled/alpha_lockup.svg';
import animatedHead from './img/exiled/Logo_Animation_blackBG.gif';
import alphaExiledLogotype from './img/exiled/AlphaExiled_logotype.svg';
import twitter from './img/exiled/twitter.svg';
import discord from './img/exiled/discord.svg';

// import hogchop_pic from './img/hogchop_profile.jpg';
// import sajo_pic from './img/sajo_profile.png';


export default function Home (props) {
    const [ introState, setIntroState ] = useState(0);
    const [ timerStarted, setTimerStarted] = useState(false);
    const [ element, setElement ] = useState(["vibium", "delinquium", "dopium", "corruptium"][returnRandomNumber(0, 3)]);
    const [ exiledHead, setExiledHead ] = useState(false);
    const [ exiledLogo, setExiledLogo ] = useState(false);
    const [ heroImage, setHeroImage ] = useState(false);
    const heroImages = {vibium: vibiumImg, delinquium: delinquiumImg, dopium: dopiumImg, corruptium: corruptiumImg};
    const exileLogoAnim = new Image();
    const exileLogoAtype = new Image();
    const firstHeroImg = new Image();

    useEffect(() => {
        exileLogoAnim.src = animatedHead;
        exileLogoAnim.onload = () => {
            setExiledHead(true);
        }
    }, [])

    useEffect(() => {
        exileLogoAtype.src = alphaExiledLogotype;
        exileLogoAtype.onload = () => {
            setExiledLogo(true);
        }
    }, [])

    useEffect(() => {
        firstHeroImg.src = heroImages[element]
        firstHeroImg.onload = () => {
            setHeroImage(true);
            console.log("hero image loaded")
        }
    }, [])
    
    // const teamMembers = [
    //     {
    //         image: sajo_pic,
    //         name: 'SaJo',
    //         role: 'founder, artist, creative director',
    //         link: 'https://twitter.com/SaJoCreate',
    //         link_label: '@SaJoCreate',
    //         icon: twitter
    //     },
    //     {
    //         image: hogchop_pic,
    //         name: 'hogchop',
    //         role: 'Founder, lead dev, technologist',
    //         link: 'https://twitter.com/hogchop1',
    //         link_label: '@HogChop1',
    //         icon: twitter
    //     },
    //     {
    //         image: sajo_pic,
    //         name: 'SaJo',
    //         role: 'founder, artist, creative director',
    //         link: 'https://twitter.com/SaJoCreate',
    //         link_label: '@SaJoCreate',
    //         icon: twitter
    //     },
    //     {
    //         image: hogchop_pic,
    //         name: 'hogchop',
    //         role: 'Founder, lead dev, technologist',
    //         link: 'https://twitter.com/hogchop1',
    //         link_label: '@HogChop1',
    //         icon: twitter
    //     },
    //     {
    //         image: sajo_pic,
    //         name: 'SaJo',
    //         role: 'founder, artist, creative director',
    //         link: 'https://twitter.com/SaJoCreate',
    //         link_label: '@SaJoCreate',
    //         icon: twitter
    //     },
    //     {
    //         image: hogchop_pic,
    //         name: 'hogchop',
    //         role: 'Founder, lead dev, technologist',
    //         link: 'https://twitter.com/hogchop1',
    //         link_label: '@HogChop1',
    //         icon: twitter
    //     },
    //     {
    //         image: sajo_pic,
    //         name: 'SaJo',
    //         role: 'founder, artist, creative director',
    //         link: 'https://twitter.com/SaJoCreate',
    //         link_label: '@SaJoCreate',
    //         icon: twitter
    //     },
    //     {
    //         image: hogchop_pic,
    //         name: 'hogchop',
    //         role: 'Founder, lead dev, technologist',
    //         link: 'https://twitter.com/hogchop1',
    //         link_label: '@HogChop1',
    //         icon: twitter
    //     }
    // ]

    // function cycleElements(dir) {
    //     let currElem = ["vibium", "dopium", "delinquium", "corruptium"].indexOf(element)
    //     currElem + dir == -1 ? currElem = 3 : currElem = (currElem + dir) % 4
    //     setElement(["vibium", "dopium", "delinquium", "corruptium"][currElem])
    // }

    const elementThemes = {
        vibium: {
            heroImage: vibiumImg,
            colour: 'bg-exiled_gold',
            colourDark: 'bg-exiled_gold/50'
        },
        dopium: {
            heroImage: dopiumImg,
            colour: 'bg-exiled_purple',
            colourDark: 'bg-exiled_purple/50',
        },
        delinquium: {
            heroImage: delinquiumImg,
            colour: 'bg-[#D8C6C4]',
            colourDark: 'bg-[#D8C6C4]/50',
        },
        corruptium: {
            heroImage: corruptiumImg,
            colour: 'bg-exiled_grey',
            colourDark: 'bg-exiled_grey/50',
        },
    }

    function returnRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {
        if(timerStarted) {
            const changeElement = setInterval(() => {
                if (element === "vibium") {
                    setElement("delinquium");
                } else if (element === "delinquium") {
                    setElement("dopium");
                } else if (element === "dopium") {
                    setElement("corruptium");
                } else if (element === "corruptium") {
                    setElement("vibium");
                }
            }, 6000);
            return () => clearInterval(changeElement);
        }
    }, [element, timerStarted]);

    useEffect(() => {
        if(timerStarted == true && document?.getElementById('title_anim')) {
            // document.getElementById('title_anim').classList.remove('bg-exiled_red');
            // document.getElementById('title_anim').classList.add(elementThemes[element].colour);
        }
    }, [timerStarted]);

    function progressIntro(num) {
        let timer;

        if (num == 1) {
            timer = 1500;
        } else {
            timer = 500;
        }
        const intervalId = setInterval(() => {
            if(heroImage === false && num === 2) {
                console.log("hero image not loaded")
                // setTimeout(() => {

                // })
            } else {
                console.log("Hero image loaded!!!")
                setIntroState(num);
                clearInterval(intervalId);
                if(num === 2) {
                    setTimerStarted(true)
                    document.getElementById('title_anim').classList.remove('bg-exiled_red');
                    document.getElementById('title_anim').classList.add(elementThemes[element].colour);
                }
            }
        }, timer);
        return () => clearInterval(intervalId);

    }

    const introAnim = {
        hidden: {
            x: 0
        },
        show: {
            x: 0,
        },
        exit: {
            opacity: 0,
            transition: {
                delay: 1,
                duration: 2,
            }
        }
    }

    
    const headIntro = {
        hidden: {
            opacity: 0
        },
        show: {
            opacity: 1,
            transition: {
                delay: 1,
                duration: 5,
                type: "spring",
            }
        },
        exit: {
            opacity: 0,
            transition: {
                delay: 1,
                duration: 0.5,
            }
        }
    }

    const ctaText = {
        hidden: {
            x: -50,
            opacity: 0
        },
        show: {
            x: 0,
            opacity: 1,
            transition: {
                ease: "easeOut",
                delay: 3.2
            }
        },
        exit: {
            x: 0,
            opacity: 0,
        }
    }

    const uiAnimateIn = {
        hidden: {
            opacity: 0,
        },
        show: {
            opacity: 1,
            transition: {
                delay: 3.2,
                duration: 1,
                ease: "easeOut"
            }
        }
    }

    const alphaAnim = {
        hidden: {
            opacity: 0,
            x: "-65%",
        },
        show: {
            opacity: 1,
            x: 0,
            transition: {
                delay: 2,
                ease: "easeOut",
                duration: 0.9,
                opacity: {
                    duration: 0.6
                }
            }
        }
    }

    const exiledAnim = {
        hidden: {
            opacity: 0,
            x: "-45%",
        },
        show: {
            opacity: 1,
            x: 0,
            transition: {
                delay: 2,
                ease: "easeOut",
                duration: 0.9,
                opacity: {
                    duration: 0.6
                }
            }
        }
    }

    const exiledFigAnim = {
        hidden: {
            opacity: 0,
            x: "-60%",
        },
        show: {
            opacity: 1,
            x: 0,
            transition: {
                delay: 2,
                ease: "easeOut",
                duration: 1,
                opacity: {
                    duration: 0.6
                }
            }
        }
    }

    return (
        <>
        {/* <audio ref={mainRef} src={mainLoop} preload="true" />
        <audio ref={introRef} src={introLoop} preload="true" autoPlay={true} /> */}
        <AnimatePresence exitBeforeEnter>
        { (introState === 0 || introState === 1) &&
            <motion.div id="title_anim" className="h-screen w-screen bg-exiled_red transition-colors duration-[1000ms] flex flex-col justify-center absolute top-0 z-[999]" initial="hidden" animate="show" exit="exit" variants={introAnim}>
               {/* Ensure Exiled Head has loaded */}
               { (exiledHead === true && introState === 0) &&
                <>
                    <motion.h3 className='exiled_h3 text-center' initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0, y: -30}} transition={{delay: 1}} >Alpha Academy</motion.h3>
                    <motion.h4 className='font-acumin_bold font-bold tracking-[0.1rem] text-[1rem] text-center' initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0, y: -30}} transition={{delay: 2}} onAnimationComplete={()=>{progressIntro(1)}}>Presents</motion.h4>
                </> }
                { (exiledHead === true && exiledLogo === true && introState === 1) &&
                <>
                    <motion.div variants={headIntro} className="mix-blend-screen -mt-[6vh] lg:mt-0" initial="hidden" animate="show" exit="exit" onAnimationComplete={()=>{progressIntro(2)}} >
                    {/* <motion.div variants={headIntro} className="mix-blend-screen -mt-[6vh] lg:mt-0" initial="hidden" animate="show" exit="exit" > */}
                        <img src={animatedHead} className="w-40 mx-auto md:mt-0" />
                        <img src={alphaExiledLogotype} className="w-40 mx-auto md:mt-0" />
                    </motion.div>
                </> }
            </motion.div>
        }
        </AnimatePresence>
        { introState === 2 &&
            <motion.div className={`h-[94vh] md:h-screen m-screen ${elementThemes[element].colour} transition-colors duration-[1000ms] z-20 relative`}>
                <motion.div className='pl-2 pr-4 py-3 lg:px-8 lg:py-6 flex flex-row place-content-between absolute w-screen' variants={uiAnimateIn} initial="hidden" animate="show">

                    <motion.div className={`${elementThemes[element].colour} transition-colors duration-[1000ms]`}>
                        <motion.img src={animatedHead} className={`w-[44px] md:w-[60px] mix-blend-screen`} />
                    </motion.div>

                    {/* Hide AE link on mobile */}
                    <div className='hidden lg:block mt-2 z-[900]'>
                        <a href="https://alphaacademy.app/elementary/" className='flex flex-row space-x-4 items-center h-[38px] pl-3 pr-1 border-2 border-white/0 hover:border-white/100 hover:no-underline transition-all duration-200 group active:bg-white/50'>
                            <h4 className='exiled_h4 my-auto pt-0.5 mr-2 group-hover:mr-0 transition-all duration-200 text-white '>Alpha Elementary</h4>
                            <img src={w_arrow} className={`w-6 mr-0 group-hover:mr-3 transition-all duration-200`} />
                        </a>
                    </div>

                    {/* Show the CTA on mobile up here */}
                    <motion.div className='flex flex-row justify-end space-x-4 items-center pt-2 lg:hidden z-30'>
                         {/* <h3 className='exiled_h3 pt-1'>Coming Soon </h3> */}
                        <a href="https://twitter.com/AlphaAcademyInc" target={"_blank"} alt="snoop our twitter" className='' ><img src={twitter} className="h-5" alt="Follow us fixed" /></a>
                        <a href="https://discord.gg/WBsxBgRvdZ" target="_blank" alt="break into our discord" className=''><img src={discord} className="h-5" alt="Join the discord" /></a>
                    </motion.div>
                </motion.div>
                {/* copyright */}
                <motion.div className='hidden lg:block lg:w-screen mb-4 absolute bottom-0 left-0 pr-6' variants={uiAnimateIn} initial="hidden" animate="show">
                    <h5 className='font-teko text-[1.1rem] font-light my-auto text-white text-right'>COPYRIGHT @ ALPHA ACADEMY 2023</h5>
                </motion.div>
                <div className='relative min-h-[94vh] lg:h-screen w-screen lg:w-11/12 2xl:w-3/4 lg:mx-auto overflow-hidden lg:overflow-visible'>
                    <div className='pt-[22%] lg:pt-0 w-11/12 mx-auto lg:right-8 lg:w-7/12 2xl:w-7/12 lg:h-full flex flex-col lg:mr-0'>

                        <div className='my-auto'>
                            {/* ALPHA LOCKUP LARGE */}
                            <motion.img src={alpha_logo} className="w-[40%] ml-2 mb-2 lg:w-[32%] lg:ml-12 lg:mb-4" variants={alphaAnim} initial="hidden" animate="show" />
                            <motion.img src={exiled_lockup} className="fix-image w-full" variants={exiledAnim} initial="hidden" animate="show"/>

                            <motion.div className='mr-[1%] mt-4 hidden lg:flex flex-row justify-end space-x-4 items-center z-90' variants={uiAnimateIn} initial="hidden" animate="show">
                                <AnimatePresence exitBeforeEnter>
                                    <motion.h3 variants={ctaText} initial="hidden" animate="show" exit="exit" key="vibium" className='exiled_h3 font-[400] pt-1'>Coming Soon </motion.h3>
                                    {/* { element == 'dopium' && <motion.h3 variants={ctaText} initial="hidden" animate="show" exit="exit" key="dopium" className='exiled_h3 pt-1'>ROLL WITH US </motion.h3> }
                                    { element == 'delinquium' && <motion.h3 variants={ctaText} initial="hidden" animate="show" exit="exit" key="delinquium" className='exiled_h3 pt-1'>CHILL WITH US </motion.h3> }
                                    { element == 'corruptium' && <motion.h3 variants={ctaText} initial="hidden" animate="show" exit="exit" key="corruptium" className='exiled_h3 pt-1'>FUCK WITH US </motion.h3> } */}
                                </AnimatePresence>
                                <a href="https://twitter.com/AlphaAcademyInc" target={"_blank"} alt="snoop our twitter" className='hover:-translate-y-1 transition-transform hover:cursor-pointer' ><img src={twitter} className="h-6" alt="Follow us" /></a>
                                <a href="https://discord.gg/WBsxBgRvdZ" target="_blank" alt="break into our discord" className='hover:-translate-y-1 transition-transform hover:cursor-pointe'><img src={discord} className="h-6" alt="Join the discord" /></a>
                            </motion.div>
                        </div>
                        
                    </div>

                    <div className={`mx-auto absolute w-[125vw] -ml-[12.5%] md:w-auto lg:h-screen bottom-0 lg:bottom-0 aspect-square lg:-ml-[20%] 2xl:-ml-[20%] z-10 overflow-hidden pt-4 lg:pt-0`}>
                        <motion.img src={elementThemes[element].heroImage} className="aspect-square lg:mt-0 mx-auto" variants={exiledFigAnim} initial="hidden" animate="show" />
                    </div>
                </div>

                {/* Show the CTA on mobile up here */}
                <div className='bg-black lg:hidden'>
                    <div className={`min-h-[7vh] pt-2 pb-3 ${elementThemes[element].colourDark} duration-[1000ms]`}>
                        <div className='flex flex-row mt-2 z-[900] mx-auto place-content-center'>
                            <a href="https://alphaacademy.app/elementary/" className='flex flex-row space-x-4 items-center h-[38px] pl-3 pr-1 border-2 border-white/0 hover:border-white/100 hover:no-underline transition-all duration-200 group active:bg-white/50'>
                                <h4 className='exiled_h4 my-auto pt-0.5 mr-2 group-hover:mr-0 transition-all duration-200 text-white '>Alpha Elementary</h4>
                                <img src={w_arrow} className={`w-6 mr-0 group-hover:mr-3 transition-all duration-200`} />
                            </a>
                        </div>
                    </div>
                </div>
            </motion.div>
        }
        </>
    )
}

