import React, {useState, useEffect} from 'react';
import { useMutation, useLazyQuery, gql } from '@apollo/client';
import { saveToLocalStorage, loadFromLocalStorage } from '../components/localStorageUtils';
import { HashLink as Link } from 'react-router-hash-link';
import { motion, AnimatePresence } from 'framer-motion';

import btnLoading from '../img/icons/loader.svg';
import { Connect } from  '../components';
import AddMumbaiButton from './add_mumbai';

import gridPaper from '../img/icons/gridpaper-thin.svg';
import mumbaiButton from '../img/v2_assets/mumbai-network.png';
import polygonNetwork from '../img/v2_assets/polygon-network.png';

// const GET_USER = gql`
//     query GetUser($input: String!) {
//         getUser(wallet: $input) {
//             discord_username
//             discord_id
//             elementary_token_ids
//             elementary_frens
//             reg_experiment
//         }
//     }
// `

export default function RockPaperScissors (props) {
    // const [ userData, setUserData ] = useState(null)

    // const [checkUserStatus, {loading: userStatusLoading, error: userStatusError, data: userStatusResult}] = useLazyQuery(GET_USER, {
    //     notifyOnNetworkStatusChange:true
    // });

//     useEffect(()=> {
//         // get local user (if exists)
//         const localUser = loadFromLocalStorage("getUser");
//         if(props.userAddress.length > 0 && localUser && localUser.wallet === props.userAddress) {
//           setUserData(localUser)
//         } else if(props.userAddress.length > 0 && !userStatusLoading) {
//           checkUserStatus({variables: {"input": props.userAddress}});
//         }
//       }, [props.userAddress])

// // if user's wallet is connected, check to see if they've registered for experiments
//   useEffect(()=> {
//     // get local user (if exists)
//     const localUser = loadFromLocalStorage("getUser");
//     if(props.userAddress.length > 0 && localUser && localUser.wallet === props.userAddress) {
//       setUserData(localUser)
//     } 
//   }, [props.userAddress])

    const rockAnim = {
        hidden: {
            scale: 1.5,
            opacity: 0
        },
        visible: {
            scale: 1,
            opacity: 1,
            transition: {
                delay: 0.5,
                duration: 0.2
            }
        }
    }
    const paperAnim = {
        hidden: {
            y: -150,
            opacity: 0
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                delay: 0.9,
                duration: 0.2,
                type: "ease out"
            }
        }
    }
    const scissorsAnim = {
        hidden: {
            rotate: 180,
            opacity: 0
        },
        visible: {
            rotate: 0,
            opacity: 1,
            transition: {
                delay: 1.3,
                duration: 0.2
            }
        }
    }

    function loader() {

        const loaderAnimation = (<>
            <span className='mx-auto'>
                <img src={btnLoading} className="w-6 animate-spin mx-auto" />
            </span>
        </>)

        return loaderAnimation
    }

    function renderExperiment() {
        return(
            <>
                <motion.div className=' min-h-[92vh]'>
                    {/* INTRO */}
                    <div className='w-full flex felx-row mx-auto justify-center'>
                        <motion.div key="rock" variants={rockAnim} initial="hidden" animate="visible" className='text-[9rem] px-4'>🪨</motion.div>
                        <motion.div key="paper" variants={paperAnim} initial="hidden" animate="visible" className='text-[9rem] px-4'>📄</motion.div>
                        <motion.div key="scissors" variants={scissorsAnim} initial="hidden" animate="visible" className='text-[9rem] px-4'>✂️</motion.div>
                    </div>
                    {/* HEADER */}
                    <div className=''>
                        <h4 className=''>Rock, Paper, Scissors!</h4>
                        <h2 className='mb-6'>Welcome to the lab.</h2>
                        <Link to="#updates" className={` rounded-md font-teko text-[1.1rem] tracking-wider uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-600 hover:bg-blue-700 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`}>            
                            <span className='mr-2'>🥽</span>
                            Jump to updates
                        </Link>

                        {/* OUTLINE */}
                        <div className='w-full 2xl:w-1/2 2xl:mx-auto bg-blue-800 border-2 border-white rounded-2xl text-left mx-0 my-4 px-6 py-6 shadow-lg'>
                            <h4 className='text-white pb-2'><strong>The outline</strong></h4>
                            <ul className='text-white text-[1.2rem] list-disc list-outside leading-6 ml-4'>
                                <li className='pb-2'>We're playing a big, online version of Rock, Paper, Scissors</li>
                                <li className='pb-2'>Each A.E. holder will be able to mint their own player token</li>
                                <li className='pb-2'>They will randomly be allocated either a Rock, Paper or Scissors player token</li>
                                <li className='pb-2'>This experience will utilise ERC-721 tokens on the Polygon network, recording player's wins and losses on-chain</li>
                                <li className='pb-2'>A series of matches will be played. Players can choose to enter as many as they like. If their token loses all it's lives, it will be destroyed.</li>
                                <li className='pb-2'>Testing will take place on the Mumbai Polygon Testnet</li>
                                <li className='pb-2'>This will NOT turn into a real-time, player controlled battle game. The infrastructure required for real time battle is vastly different</li>
                            </ul>
                        </div>

                        {/* REQUIREMENTS */}
                        <div className='w-full 2xl:w-1/2 2xl:mx-auto bg-blue-800 border-2 border-white rounded-2xl text-left mx-0 my-4 px-6 py-6 shadow-lg'>
                            <h4 className='text-white pb-2'><strong>The requirements</strong></h4>
                            <ul className='text-white text-[1.2rem] list-disc list-outside leading-6 ml-4'>
                                <li className='pb-2'>Each player must hold at least 1 Alpha Elementary Token</li>
                                <li className='pb-2'>Willing to test on the Polygon Mumbai test net, which will require using a faucet to get free test MATIC</li>
                                <li className='pb-2'>Share any opinions you have after testing, both good and band</li>
                                <li className='pb-2'>Participate where possible in the matches</li>
                                <li className='pb-2'>Note: feedback is encouraged but not all feedback can be taken onboard</li>
                            </ul>
                        </div>

                        {/* DEV JOURNAL */}
                        <hr id="updates" className='border-0' />
                        <h2 className='mb-6 mt-8'>Dev journal</h2>

                        {/* OUTLINE */}
                        <div className='w-full 2xl:w-1/2 2xl:mx-auto bg-blue-800 border-2 border-white rounded-2xl text-left mx-0 my-4 px-6 py-6 shadow-lg'>
                            <h4 className='text-white pb-2'><strong>Mon, 29 May, 2023</strong></h4>
                            <p className='text-white text-[1.2rem] pb-3 leading-6'>
                                Today the registration has officially opened for anyone in the A.E. community to opt-in to participate. 
                            </p>
                            <p className='text-white text-[1.2rem] pb-3 leading-6'>
                                The game itself is still currently in development, as is the smart contract. Early versions of both exist. The game is currently a pure simulation with hard-coded players and values. The first test deployment of the smart contract has <a className='text-yellow-500 underline' href="https://mumbai.polygonscan.com/address/0xD7641Ecea03d3364a2b2B782C3f16ea77EE6a615" target="_blank">been deployed here</a>.
                            </p>
                            <p className='text-white text-[1.2rem] pb-3 leading-6'>
                                Note that new versions will be deployed and this version will not be connected to the experience.
                            </p>
                            <br />
                            <p className='text-white text-[1.2rem] pb-3 leading-6'>
                                Below you can see a video of one of the simulations of the games. The highlighted player denotes your token. 🔴 Red does are lives, 🟢 green dots are kills:
                            </p>
                            <video loop autoPlay controls>
                                <source src="https://alphaelem.s3.amazonaws.com/rps-preview_29_may_1.mp4" type="video/mp4" />
                                Your browser does not support the video tag. Please use a newer browser.
                            </video>
                            <p className='text-white text-[1.2rem] pb-3 mt-6 leading-6'><strong>Details of current build</strong></p>
                            <ul className='text-white text-[1.2rem] list-disc list-outside leading-6 ml-4'>
                                <li className='pb-2'>60 agents (faux players) enter the arena</li>
                                <li className='pb-2'>Each agent spawns with a slightly different speed based upon chance</li>
                                <li className='pb-2'>Each agent has between 3 - 5 lives</li>
                                <li className='pb-2'>Each time an agent is defeated, it will respawn unless it has 0 lives left. In which case it will be out</li>
                                <li className='pb-2'>As more agents are destroyed, the arena shrinks to ensure the match doesn't become boring</li>
                                <li className='pb-2'>A winner is declared only 1 team remains. All remaining agents will earn a point for winning</li>
                                <li className='pb-2'>Currently agents may accelerate too quickly near the perimeter and become stuck outside. In this instance they will respawn within the arena again.</li>
                            </ul>
                            <p className='text-white text-[1.2rem] pb-3 mt-6 leading-6'><strong>Planned next updates</strong> (est 1 week...or longer)</p>
                            <ul className='text-white text-[1.2rem] list-disc list-outside leading-6 ml-4'>
                                <li className='pb-2'>✅ Deploy a new test Smart Contract</li>
                                <li className='pb-2'>✅ Allow Smart Contract to generate a random seed to ensure on-chain random outcome of matches, while making them easy to replay at viewers own leisure</li>
                                <li className='pb-2'>Record results of matches on-chain</li>
                                <li className='pb-2'>✅ Launch testing site where Lab Rats can get their player token and enter them into matches</li>
                            </ul>
                            <p className='text-white text-[1.2rem] pb-3 mt-6 leading-6'><strong>Future updates</strong></p>
                            <ul className='text-white text-[1.2rem] list-disc list-outside leading-6 ml-4'>
                                <li className='pb-2'>Ability to get extra lives</li>
                                <li className='pb-2'>Integration of ERC-6551 Token wallets to allow for lives and other pickups to be managed as NFTs</li>
                                <li className='pb-2'>Experiment with how winners can unlock benefits without getting an unfair advantage</li>
                                <li className='pb-2'>Include the ability to add friend's wallets and see their player if it is in a watch</li>
                                <li className='pb-2'>Implement a burn mechanic on the tokens so that any that have lost all lives will be destroyed</li>
                            </ul>
                        </div>

                        {/* OUTLINE */}
                        <div id="get_started" className='w-full 2xl:w-1/2 2xl:mx-auto bg-blue-800 border-2 border-white rounded-2xl text-left mx-0 my-4 px-6 py-6 shadow-lg'>
                            <h4 className='text-white pb-2'><strong>Fri, 16 Jun, 2023</strong></h4>
                            <h3 className='text-white pb-2'><strong>Lets test 🐀</strong></h3>
                            <p className='text-white text-[1.2rem] pb-3 leading-6'>
                                There's been a lot of work happening behind the scenes on the concept.
                            </p>
                            <p className='text-white text-[1.2rem] pb-3 leading-6'>
                                We've iterated through multiple versions of the smart contract. Now with a version that contains all data on-chain so all data is associated with your token on-chain and if you trade it, the stats will move with your combatant (token).
                            </p>
                            <p className='text-white text-[1.2rem] pb-3 leading-6'>
                                <strong>The Dashboard:</strong> The dashboard has opened. This is a simplified screen where you can do everything – grab your test tokens, see your token's stats, see upcoming games and opt-in to games.
                                 This page is not optimised for UX. It is developed so that it can be updated and iterated upon easily. Head to the Discord channel is something doesn't make sense and ask a question.
                            </p>
                            <br />
                            <p className='text-white text-[1.2rem] pb-3 leading-6'>
                                You'll need to do a few things to get set up. The chain being used is Polygon. Because we don't want to be requiring anyone to pay real gas fees, we're testing on the Polygon Test net (Mumbai).
                                This means you'll need to add this chain to your Metamask, then grab some test tokens from a faucet so that you can interact with the smart contract.
                            </p>
                            
                            <p className='text-white text-[1.2rem] pb-3 mt-6 leading-6'><strong>Let's get started 👇</strong></p>
                            <ul className='text-white text-[1.2rem] list-disc list-outside leading-6 ml-4'>
                                <li className='pb-2'>Make sure you're using the wallet you registered for the experiment with</li>
                                <li className='pb-2'>Add the Polygon Testnet to your MetaMask here: <span className=' inline-block ml-2'><AddMumbaiButton /></span></li>
                                <li className='pb-2'>If the above button doesn't work, you can add the Polygon Testnet to your metamask by going to <a href="https://mumbai.polygonscan.com/" target="_blank">mumbai.polygonscan.com</a></li>
                                <li className='pb-2'>At the bottom right of the site, in the footer, you'll see a button that says 'Add Mumbai Network', which will add it to your MetaMask
                                    <img src={mumbaiButton} className='border-[3px] border-white w-[220px]' />
                                </li>
                                <li className='pb-2'>It's time to grab some tokens. Visit a faucet, such as <a href="https://faucet.polygon.technology/" target="_blank">faucet.polygon.technology</a> and request Mumbai tokens</li>
                                <li className='pb-2'>You're all set to go. Click the link below to go to the dashboard – make sure your metamask is on the Polygon Testnet
                                    <img src={polygonNetwork} className='border-[3px] border-white w-[450px]' />
                                </li>
                            </ul>
                            <p className='text-white text-[1.2rem] pb-3 mt-6 leading-6'><strong>Important things to note</strong></p>
                            <ul className='text-white text-[1.2rem] list-disc list-outside leading-6 ml-4'>
                                <li className='pb-2'>Things can and will break. This is pre-alpha. The idea is to get things out there and getting an idea of what the concept is. There are plenty of UX affordances to be made.</li>
                                <li className='pb-2'>You will lose your tokens, and progress. As new versions of the contract are rolled out, you'll lose the tokens from the current build.</li>
                                <li className='pb-2'>The games right now are "free play" – you can enter a match then watch the match play out. These aren't timed, official matches yet. The wins aren't recorded on-chain yet.</li>
                            </ul>

                            <h4 className='mb-6 mt-12'>Ready?</h4>
                            <Link to="/experiments/rps/dashboard" className={` rounded-md font-teko text-[1.1rem] tracking-wider uppercase no-underline text-white border-2 border-white px-5 pt-3 py-2 transition-colors bg-blue-600 hover:bg-blue-700 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`}>            
                                <span className='mr-2'>🐀</span>
                                Proceed to the Dashboard
                            </Link>
                        </div>
                    </div>
                </motion.div>
            </>
        )
    }

    return(<>
            <AnimatePresence>
                <div style={{backgroundImage: `url(${gridPaper})`, backgroundSize: '8px', backgroundRepeat: 'repeat'}} className='bg-blue-600 pt-16'>
                    <div className='py-12 container mx-auto text-center px-6 lg:px-0 '>
                        { !props.isConnected &&
                            <Connect connect={props.connectFunc} />
                        }

                        { (props.isHolder) ?
                            renderExperiment()
                        :
                            <p className='min-h-[92vh]'>
                                You must be an A.E. holder and registered for the experiments. To register, please visit the <Link to="/experiments">experiments</Link> page.
                            </p>
                        }
                    </div>
                </div>
            </AnimatePresence>
        </>);
}
