import crypto from 'crypto-browserify';

export function getKeyFromPassphrase(passphrase, salt) {
    return crypto.pbkdf2Sync(passphrase, salt, 100000, 32, 'sha512');
  };
  
  export function encrypt(text, key) {
    const iv = crypto.randomBytes(16);
    const cipher = crypto.createCipheriv('aes-256-cbc', key, iv);
    const encrypted = Buffer.concat([cipher.update(text, 'utf8'), cipher.final()]);
    return iv.toString('hex') + ':' + encrypted.toString('hex');
  };
  
  export function decrypt(cipherText, key) {
    const parts = cipherText.split(':');
    const iv = Buffer.from(parts.shift(), 'hex');
    const encrypted = Buffer.from(parts.join(':'), 'hex');
    const decipher = crypto.createDecipheriv('aes-256-cbc', key, iv);
    return decipher.update(encrypted) + decipher.final('utf8');
  };

  export function generateRandomSalt() {
    const buffer = crypto.randomBytes(8);
    return buffer.toString('hex');
  }
  
//   module.exports = {
//     encrypt,
//     decrypt,
//     getKeyFromPassphrase,
//     generateRandomSalt
//   }