import React, {useState, useEffect} from 'react';
import { Connect } from  './components'; 

import ae_to_sat_tokens from './show_and_tell/collection_metadata_consolidated.json';
import ae_allocation from './show_and_tell/allocation_list.json';

export default function Pixelplayground(props) {
    const [selectedImg, setSelectedImg] = useState(null);
    const [aeData, setAeData] = useState(null);
    const [imgLoc, setImgLoc] = useState(null);
    
    useEffect(() => {
        if(props.isConnected) {
            props.getTokens();
        }
    }, [props.isConnected])

    
    useEffect(() => {
        if(props.tokenIDs) {
            let imgArr = []
            if(props.tokenIDs.length > 0 && props.tokenIDs[0] != 'undefined') {
                for(let i = 0; i < props.tokenIDs.length; i++) {
                    imgArr.push(require(`./img/ae_students/${props.tokenIDs[i]}.png`))
                }
                setImgLoc(imgArr)
            }
        }  else {
            console.log("YOU ARE NOT A HOLDER")
            let imgArr = []
            imgArr.push('none')
            setImgLoc(imgArr)
        }
    }, [props.tokenIDs])

    return (
        <>
            { (props.isHolder === false) && <div className='absolute top-15 w-full bg-blue-300 text-center py-1 text-black text-xs'>Non Alpha Elementary holders are welcome, however export size is limited and will always include a watermark.</div>}
            <div className="w-full md:min-h-[935px] h-screen flex flex-row mt-[60px] md:flex-col place-content-around" id="artclass" >
                <div className="mx-auto">
                <h3 className='font-nanum text-white font-lg lg:text-[2rem] text-center pt-6'>Welcome to the alpha elementary</h3>
                    <h2 className='text-white text-center pb-6'>Pixel Playground</h2>
                    <div className={`bg-white md:rounded-xl px-3 py-3 flex flex-col md:flex-row relative drop-shadow-md delay-100 transition-all duration-400 top-0 opacity-100 w-[1015px] h-[825px] justify-center ${!props.isConnected && "pt-8"}`}>
                        {!props.isConnected && <Connect connect={props.connectFunc} />}
                        { (imgLoc && props.isConnected ) && <iframe src={`/creator/pixel_studio/index.html?img=${imgLoc}`} width="1000" height="800" /> }
                        { (!imgLoc && props.isConnected ) && <div className='bg-[#E2E2E2] text-black text-center'>Loading...</div> }
                    </div>
                </div>
            </div>
        </>
    )
}
