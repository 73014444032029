import React, { useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { useMediaQuery } from 'react-responsive';
import { motion } from 'framer-motion';

// Images
import heroPoster from './img/ExiledTeaser_1920x1080px.jpg';
import heroPosterMob from './img/ExiledTeaser_1080x1920px.jpg';
import aeExiledBanner from './img/ae-aex.png';
import fingerPoint from './img/finger-w.svg';

// Sample NFT images
import preview_1 from './img/preview_nfts/2278.png';
import preview_2 from './img/preview_nfts/2902.png';
import preview_3 from './img/preview_nfts/955.png';
import preview_4 from './img/preview_nfts/694.png';
import preview_5 from './img/preview_nfts/1499.png';
import preview_6 from './img/preview_nfts/664.png';
import preview_7 from './img/preview_nfts/641.png';
import preview_8 from './img/preview_nfts/2431.png';

import hogchop_pic from './img/hogchop_profile.jpg';
import sajo_pic from './img/sajo_profile.png';
import twitterWhite from './img/ae-twitter.svg';
import openSeaBlack from './img/opensea.svg';

// import hypeVid from './vid/AE_Teaser_V3.mp4';

import satComic from './img/v2_assets/sat_ministrip.jpg';
import bombBoyPromo from './img/bomb_boy_promo/tee-hero-banner.jpg';
// import promoCross from './img/bomb_boy_promo/promo-cross.svg';



export default function IndexPage (props) {
    const isMobile = useMediaQuery({query: `(max-width: 780px)`});
    const isMedScreen = useMediaQuery({query: `(max-width: 1280px)`});
    const isLargeScreen = useMediaQuery({query: `(min-width: 2200px)`});
    const [playVid, setPlayVid] = useState(false);

    function setVidPlaying() {
        setPlayVid(true);
    }

    function stopVidPlaying() {
        setPlayVid(false);
    }

    return(<>
            <Splash mobile={isMobile} isLargeScreen={isLargeScreen} isMedScreen={isMedScreen} isConnected={props.isConnected}
            playVid={playVid}
            setVidPlaying={setVidPlaying}
            stopVidPlaying={stopVidPlaying}
             /> 
            <About />
            <AboutSAT />
            <Roadmap />
            <Promo mobile={isMobile} />
            <Login connectFunc={props.connectFunc} isConnected={props.isConnected} mobile={isMobile}/>
            <Faculty />
        </>
    );
}

class Promo extends React.Component {

    get BombBoyPromoDesk() {
        const jumbotron = (
            <>
            <div className="flex flex-col w-screen pt-8 bg-white">
                <div className="container mx-auto grid grid-cols-1 md:grid-cols-5">
                    <div className="col-span-1 md:col-span-3 flex flex-col" id="bomb_boy">
                        <div id="CTA" className='w-full py-8 md:py-16 my-auto'>
                        <h3 className='text-center font-nanum text-black font-lg text-[2rem] mb-2'>Limited edition</h3>
                        <h1 className='text-center text-black text-[72px] leading-[48px] lg:leading-[100px] lg:text-[120px] font-teko font-medium'>Bomb boy tee</h1>
                        <h3 className='text-center font-nanum text-black text-[1.6rem] leading-[1.4rem] mt-4'>Exclusive to A.E. Members for a limited time.</h3>
                        <div className="w-full text-center">
                        <a href={'https://store.alphaelementary.io'} target={"_blank"} className="no-underline hover:no-underline std_btn text-white px-24 py-2 rounded-lg 
                            cursor-pointer no-underline
                            transition-all mt-6 mx-auto inline-block
                            bg-black hover:bg-black/75 active:bg-black/60 hover:no-underline">
                            SHOP NOW</a>
                        </div>
                        </div>
                    </div>
                    <div className="col-span-1 md:col-span-2  h-full flex flex-col">
                    <a href={'https://store.alphaelementary.io'} target={"_blank"} className="mt-auto">
                        <img src={bombBoyPromo} alt="Be the alpha in the playground." className="" />
                    </a>
                    </div>
                </div>
            </div>
            </>
        );

        return jumbotron;
    }

    render() {
        // uses a media query to determine which one to render
        return <>
            { this.props.mobile ? this.BombBoyPromoDesk : this.BombBoyPromoDesk }
            </>;
    }
}

class Splash extends React.Component {

    get jumbo() {
        const jumbotron = (
            <>
            <div className="flex flex-col items-center min-h-screen">
                <motion.img initial={{opacity: 0.1}} animate={{opacity: 1}} transition={{duration: 2, delay: 0}} src={aeExiledBanner} className="mt-44 lg:w-[450px]" />
                <h2 className="mb-6 text-center mt-14">THE ALPHA EXILED ARE COMING.</h2>
                <p className="text-white text-sm text-center w-3/4 lg:w-[600px]">
                Welcome to the Alpha Elementary.
                </p><p className="text-white text-sm text-center w-3/4 lg:w-[600px]">
We are evolving, and so is our genesis collection. The Alpha Exiled is expanding our universe, whilst delving further into the culture of web3, the technology that powers it, and of course &#8212; the art that defines it.
                </p><p className="text-white text-sm text-center w-3/4 lg:w-[600px] mb-24">
Learn more about our journey so far.
                </p>
                <motion.img initial={{y: -20, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{duration: 2}} src={fingerPoint} className="w-6" alt="Read more below about this bunch of kids running amok" />
            </div>
            </>
        );

        return jumbotron;
    }

    get jumboMob() {
        const jumbotron = (
            <>
            <div className="flex flex-col items-center min-h-screen">
                <motion.img initial={{opacity: 0.1}} animate={{opacity: 1}} transition={{duration: 2, delay: 0}} src={heroPosterMob} className="mt-8 w-full" />
                <h2 className="mb-6 text-center mt-14">THE ALPHA EXILED ARE COMING.</h2>
                <p className="text-white text-sm text-center w-3/4">
                Welcome to the Alpha Elementary.
                </p><p className="text-white text-sm text-center w-3/4">
We are evolving, and so is our genesis collection. The Alpha Exiled is expanding our universe, whilst delving further into the culture of web3, the technology that powers it, and of course &#8212; the art that defines it.
                </p><p className="text-white text-sm text-center w-3/4 mb-24">
Learn more about our journey so far.
                </p>
                <motion.img initial={{y: -20, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{duration: 2}} src={fingerPoint} className="w-6" alt="Read more below about this bunch of kids running amok" />
            </div>
            </>
            );

        return jumbotron;
    }

    render() {
        // uses a media query to determine which one to render
        // removed pt-10
        return <div className="relative overflow-x-hidden bg-black">
            { this.props.mobile ? this.jumboMob : this.jumbo }
            </div>;
    }
}

// 
class Roadmap extends React.Component {
    frame_class = "scroll-m-0 snap-center snap-always w-[600px] text-center"
    img_class = "w-[600px] pb-4"

    get roadmap() {
        const roadmap = (
            <div className="bg-black scroll-mt-12" id="roadmap">
                <div className="container mx-auto pt-12 lg:pt-20 pb-0 lg:py-20 flex flex-col">
                    <div>
                        <h2 className="mb-6 text-center lg:text-left">The Journey So far...</h2>
                    </div>
                    <div className="overflow-x-scroll overflow-y-hidden no-scrollbar snap-x snap-mandatory my-4 px-1 lg:px-0">

                        <div className="flex flex-row space-x-2 lg:space-x-2 w-[320%] md:w-[220%] lg:w-[100%]">
                            <div className={this.frame_class}>
                                <img src={require(`./img/roadmap/Panel01.png`)} className={this.img_class} />
                                <h5>Class Enrolled &amp; Photo Day</h5>
                            </div>
                            <div className={this.frame_class}>
                                <img src={require(`./img/roadmap/Panel02.png`)} className={this.img_class} />
                                <h5>IRL Art prints &amp; giveaways</h5>
                            </div>
                            <div className={this.frame_class}>
                                <img src={require(`./img/roadmap/Panel03.png`)} className={this.img_class} />
                                <h5>Collectible (POAPs)</h5>
                            </div>
                            <div className={this.frame_class}>
                                <Link to="/show_and_tell" ><img src={require(`./img/roadmap/Panel04.png`)} className={this.img_class} /></Link>
                                <h5>Show 'N' Tell</h5>
                            </div>
                            <div className={this.frame_class}>
                                <a href={'https://store.alphaelementary.io'} target={"_blank"}><img src={require(`./img/roadmap/Panel05.png`)} className={this.img_class} /></a>
                                <h5>Apparel | Merch Collectibles</h5>
                            </div>
                            <div className={this.frame_class}>
                                <img src={require(`./img/roadmap/Panel06.png`)} className={this.img_class} />
                                <h5>Website Relaunch</h5>
                            </div>
                            <div className={this.frame_class}>
                                <img src={require(`./img/roadmap/Panel07.png`)} className={this.img_class} />
                                <h5>The Catalyst</h5>
                            </div>
                            <div className={this.frame_class}>
                                <img src={require(`./img/roadmap/Panel08.png`)} className={this.img_class} />
                                <h5>Alpha Exiled</h5>
                            </div>
                        </div>
                            
                    </div>
                </div>
            </div>
        );

        return roadmap;
    }

    render() {
        return <>{ this.roadmap }</>;
    }

}

class About extends React.Component {

    get about() {
        const about = (
            <div className="bg-black scroll-mt-12 "
            id="about">
                <div className=" mx-auto pt-12 pb-0">
                        <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 pt-20 lg:py-0 px-0 bg-[#F7F7F7] text-black">

                        <div className="lg:w-[40%] text-center my-auto lg:pr-[20px] lg:text-left px-8 lg:pl-16">
                            <h2 className="mb-6 lg:text-[45px] lg:leading-9 3xl:leading-[56px] 3xl:text-[66px]">WHAT IS<br />ALPHA ELEMENTARY?</h2>

                            <p className="2xl:text-sm text-sm">
                            Alpha Elementary {"(AE)"} is home to 3,000 hand crafted youngsters, each belonging to one of four unique school houses: Organica, Neue, Psychedelia and Retrogroove. Holding an AE Kid means you're part of the playground, and also our expanding ecosystem. These kids will also grant their holder a free ride into the Alpha Exiled universe. If you aren't 'enrolled', find your first Alpha by checking out the collection.
                                </p>
                            <div className="flex flex-col w-[240px] space-y-4 mx-auto lg:ml-0">
                                <a href="https://opensea.io/collection/alphaelementary" target="_blank">
                                    <button className="std_btn text-black hover:text-white rounded-lg 
                            inline-block cursor-pointer transition-colors no-underline
                            hover:bg-black active:bg-black/75 hover:no-underline border-2 border-black">View the Collection</button>
                                </a>
                                <Link  onClick={() => {window.scrollTo(0,0);}} to="/houses" >
                                    <button className="std_btn text-black hover:text-white rounded-lg 
                            inline-block cursor-pointer transition-colors no-underline
                            hover:bg-black active:bg-black/75 hover:no-underline border-2 border-black">Explore the Houses</button>
                                </Link>
                            </div>
                        </div>

                        <div className="flex lg:flex-col flex-row">
                            <div className="flex flex-col lg:flex-row">
                                <div className="relative overflow-hidden group">
                                    <a href="https://opensea.io/assets/0x692038c37f56e1772ce1d61cd0ff2d4289028458/2278" target="_blank">
                                        <img src={preview_1} className="" />
                                    </a>
                                    <img src={openSeaBlack} alt="Witness the collection" className="invert w-8 absolute -bottom-8 left-4 group-hover:bottom-4 transition-all" />
                                </div>
                                <div className="relative overflow-hidden group">
                                    <a href="https://opensea.io/assets/0x692038c37f56e1772ce1d61cd0ff2d4289028458/2902" target="_blank">
                                        <img src={preview_2} className="" />
                                    </a>
                                    <img src={openSeaBlack} alt="Witness the collection" className="invert w-8 absolute -bottom-8 left-4 group-hover:bottom-4 transition-all" />
                                </div>
                                <div className="relative overflow-hidden group">
                                    <a href="https://opensea.io/assets/0x692038c37f56e1772ce1d61cd0ff2d4289028458/955" target="_blank">
                                        <img src={preview_3} className="" />
                                    </a>
                                    <img src={openSeaBlack} alt="Witness the collection" className="invert w-8 absolute -bottom-8 left-4 group-hover:bottom-4 transition-all" />
                                </div>
                                <div className="relative overflow-hidden group lg:hidden 2xl:block">
                                    <a href="https://opensea.io/assets/0x692038c37f56e1772ce1d61cd0ff2d4289028458/694" target="_blank">
                                        <img src={preview_4} className="" />
                                    </a>
                                    <img src={openSeaBlack} alt="Witness the collection" className="invert w-8 absolute -bottom-8 left-4 group-hover:bottom-4 transition-all" />
                                </div>
                            </div>
                            <div className="flex flex-col lg:flex-row">
                            <div className="relative overflow-hidden group">
                                    <a href="https://opensea.io/assets/0x692038c37f56e1772ce1d61cd0ff2d4289028458/1499" target="_blank">
                                        <img src={preview_5} className="" />
                                    </a>
                                    <img src={openSeaBlack} alt="Witness the collection" className="invert w-8 absolute -bottom-8 left-4 group-hover:bottom-4 transition-all" />
                                </div>
                                <div className="relative overflow-hidden group">
                                    <a href="https://opensea.io/assets/0x692038c37f56e1772ce1d61cd0ff2d4289028458/664" target="_blank">
                                        <img src={preview_6} className="" />
                                    </a>
                                    <img src={openSeaBlack} alt="Witness the collection" className="invert w-8 absolute -bottom-8 left-4 group-hover:bottom-4 transition-all" />
                                </div>
                                <div className="relative overflow-hidden group">
                                    <a href="https://opensea.io/assets/0x692038c37f56e1772ce1d61cd0ff2d4289028458/641" target="_blank">
                                        <img src={preview_7} className="" />
                                    </a>
                                    <img src={openSeaBlack} alt="Witness the collection" className="invert w-8 absolute -bottom-8 left-4 group-hover:bottom-4 transition-all" />
                                </div>
                                <div className="relative overflow-hidden group lg:hidden 2xl:block">
                                    <a href="https://opensea.io/assets/0x692038c37f56e1772ce1d61cd0ff2d4289028458/2431" target="_blank">
                                        <img src={preview_8} className="" />
                                    </a>
                                    <img src={openSeaBlack} alt="Witness the collection" className="invert w-8 absolute -bottom-8 left-4 group-hover:bottom-4 transition-all" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        return about;
    }

    render() {
        return <>{ this.about }</>;
    }

}

class AboutSAT extends React.Component {

    get about() {
        const about = (
            <div className="scroll-mt-12 "
            id="about">
                <div className="pt-4 pb-0">
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 space-y-0 lg:space-y-8 pt-20 lg:py-0 px-0 lg:pl-10 bg-white text-black items-center mb-10">

                        <div className="col-span-1 my-10 px-6 xl:w-[80%] text-center lg:text-left order-2 lg:order-1">
                            <h2 className="lg:text-[45px] lg:leading-9 3xl:leading-[56px] 3xl:text-[66px] mb-6">SHOW &amp; TELL AIRDROP experience</h2>
                            <p className="2xl:text-sm text-sm">
                            Show and Tell is a great way to break the ice in class. At Alpha Elementary, every Alpha received a free AirDrop ticket to Show and Tell. When it was time for their class to stand up and show off, their token NFT turned into a backpack, which revealed their item through an interactive comic. Each item relates to the Alpha that brought it in, although we did hear some of the teachers were messing with backpacks... 
                            </p>
                            <div className="flex flex-col w-[240px] space-y-4 text-center mx-auto lg:ml-0">
                                <a href="https://opensea.io/collection/show-and-tell-at-alpha-elementary" target="_blank" alt="Trade your kids belongings" className="std_btn text-black hover:text-white px-12 py-2 rounded-lg 
                            inline-block cursor-pointer transition-colors no-underline
                            bg-white hover:bg-black active:bg-black/75 hover:no-underline border-2 border-black">
                                View the Collection
                                </a>
                                <Link onClick={() => {window.scrollTo(0,0);}} to="/show_and_tell" alt="Unzip your backpack" className="std_btn text-black hover:text-white px-12 py-2 rounded-lg 
                            inline-block cursor-pointer transition-colors no-underline
                            bg-white hover:bg-black active:bg-black/75 hover:no-underline border-2 border-black">
                                Reveal a backpack
                                </Link>
                            </div>
                        </div>
                        <div className="col-span-1 lg:col-span-2 px-4 mx-auto order-1 pb-6 lg:pb-0 lg:order-2">
                            <img src={satComic} alt="This teacher looks suss AF" className="2xl:max-w-[80%] mx-auto" />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5">
                        <div className="relative overflow-hidden group">
                            <a href="https://opensea.io/collection/show-and-tell-at-alpha-elementary?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Artifact&search[stringTraits][0][values][0]=Bonsai" target="_blank">
                                <img src="https://alphaelementary.io/artifacts/500/bonsai_psychedelia.png" className="" />
                            <img src={openSeaBlack} alt="Witness the collection" className="invert w-8 absolute -bottom-8 left-4 group-hover:bottom-4 transition-all" />
                            </a>
                        </div>
                        <div className="relative overflow-hidden group">
                            <a href="https://opensea.io/collection/show-and-tell-at-alpha-elementary?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Artifact&search[stringTraits][0][values][0]=Skull" target="_blank">
                                <img src="https://alphaelementary.io/artifacts/500/skull_neue.png" className="" />
                            <img src={openSeaBlack} alt="Witness the collection" className="w-8 absolute -bottom-8 left-4 group-hover:bottom-4 transition-all" />
                            </a>
                        </div>
                        <div className="relative overflow-hidden group">
                            <a href="https://opensea.io/collection/show-and-tell-at-alpha-elementary?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Artifact&search[stringTraits][0][values][0]=Eye" target="_blank">
                                <img src="https://alphaelementary.io/artifacts/500/eye_retrogroove.png" className="" />
                            <img src={openSeaBlack} alt="Witness the collection" className="invert w-8 absolute -bottom-8 left-4 group-hover:bottom-4 transition-all" />
                            </a>
                        </div>
                        <div className="relative overflow-hidden group">
                            <a href="https://opensea.io/collection/show-and-tell-at-alpha-elementary?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Artifact&search[stringTraits][0][values][0]=Decapod%20Terrarium" target="_blank">
                                <img src="https://alphaelementary.io/artifacts/500/decapod_terrarium_b_organica.png" className="" />
                            <img src={openSeaBlack} alt="Witness the collection" className="invert w-8 absolute -bottom-8 left-4 group-hover:bottom-4 transition-all" />
                            </a>
                        </div>
                        <div className="relative overflow-hidden group hidden 2xl:block">
                            <a href="https://opensea.io/collection/show-and-tell-at-alpha-elementary?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Artifact&search[stringTraits][0][values][0]=Beatbot" target="_blank">
                                <img src="https://alphaelementary.io/artifacts/500/beatbot_retrogroove.png" className="" />
                            <img src={openSeaBlack} alt="Witness the collection" className="invert w-8 absolute -bottom-8 left-4 group-hover:bottom-4 transition-all" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );

        return about;
    }

    render() {
        return <>{ this.about }</>;
    }

}

class Login extends React.Component {

    get login() {
        const result = (
                <div className="mx-auto py-24 lg:py-44" id="member_bg">
                    <div className="flex flex-col lg:flex-row px-0 text-black">

                        <div className="lg:w-[40%] text-center my-auto mx-auto lg:pl-0 2xl:pl-[30px] lg:pr-[30px] lg:text-center px-8 lg:px-0">
                            <h2 className="mb-6">A.E. Members</h2>

                                { this.props.isConnected ? 
                                <Link onClick={() => {window.scrollTo(0,0);}} to="/students" className="std_btn text-white hover:text-black px-12 py-2 rounded-lg 
                        inline-block cursor-pointer transition-colors no-underline
                        bg-black hover:bg-white active:bg-black hover:no-underline border-2 border-black">Hang with your Alphas</Link>
                                :
                                <button onClick={this.props.connectFunc} className="std_btn text-white hover:text-white px-12 py-2 rounded-lg 
                        inline-block cursor-pointer transition-colors no-underline
                        bg-black hover:bg-[#343434] active:bg-black hover:no-underline border-2 border-black">Connect Wallet</button>
                        }
                        </div>

                    </div>
                </div>
        );

        return result;
    }

    render() {
        return <>{ this.login }</>;
    }

}

class Journey extends React.Component {

    get journey() {
        const journey = (
            <>
            <div className="scroll-mt-12 overflow-clip " id="classroom">
                <div className="container mx-auto px-4 lg:px-8 pt-[200px] w-[100%] lg:w-[65%]">

                    <div className="px-3 pt-8 lg:px-20 lg:pt-14 text-primary_text" id="paper">
                        <div className="text-center px-8 lg:px-0">
                            <h1 className="tracking-tight pb-6 lg:pb-14 pt-4 lg:pt-8 px-6 text-center leading-[3.5rem] lg:leading-[4.5rem] lg:text-7xl">CLASS AGENDA</h1>
                        </div>
                        <div className="py-8 mx-auto">
                            <div className="text-center px-8 lg:px-0">
                            {/* <h3 className="pb-5 ruler">HiGH QUALITY SIGNED ARTWORK GIVEAWAYS TO EXISTING COLLECTORS</h3> */}

                            <h3 className="pb-5 ruler">ONGOING Physical and digital GIVEAWAYS TO EXISTING COLLECTORS</h3>

                            <h3 className="pb-5 pt-5 ruler">COLLABORATIONS AND PARTNERSHIPS TO GROW THE BRAND</h3>

                            <h3 className="pb-5 pt-5 ruler">EXPANDING OUT OUR UNIVERSE AND INTRODUCING THE 'ALPHA ACADEMY'</h3>

                            <div className="py-10">
                                <p className="text-lg">
                                    This is just the beginning. At Alpha Elementary we believe in these kids and the potential for an exciting future filled with curiosity, hard work and adventure. We also believe it takes a village to raise an Alpha and want this to be a collaborative effort with our community to help define the path for these children to take.
                                </p>

                                <p className="text-lg">
                                    We don't want to make half-hearted promises as an incentive to mint. As an artwork holder, you will be part of future rewards, drops and, most importantly, it's evolution.
                                </p>

                                <p className="text-lg">
                                    We hope you join us on their journey. 
                                </p>
                            </div>
                            </div> 
                        </div>
                    </div>

                </div>

            </div>
            </>
        );

        return journey;
    }

    render() {
        return <>{ this.journey }</>;
    }

}

class Faculty extends React.Component {
    get bios() {
        const faculty = [
            {img: sajo_pic, name: "SaJo", dep: "Art Department", bio: "SaJo breathes all things creative and design. Having a career as an illustrator, senior designer and art director, SaJo left his IRL job in 2021 to live in the digital art realm. Creator of the Xile Series and XAlt collection. Also, a former child.", twitter: "SaJoCreate", opensea: "https://opensea.io/SaJo_Create"},
            {img: hogchop_pic, name: "HogChop", dep: "Technology Department", bio:
            "HogChop is all about the tech. Lead developer on the project (alright, only developer). He also teaches Programming for Generative Design at a Sydney based University, which is incredibly handy when building a generative art collection.", twitter: "hogchop1"}
        ];

        const openS = (<a href={faculty[0].opensea} className=" inline-block" target="_blank"><img src={openSeaBlack} className="w-10 lg:w-8 hover:opacity-75 pt-4 lg:pt-2 ml-6 lg:ml-4 invert" /></a>);

        const bio = (
            <div className="bg-tertiary text-white scroll-mt-12"
            id="faculty">
                <div className="container mx-auto py-12 ">
                    <h3 className="tracking-tight pt-6 pb-10 text-center lg:text-left leading-[3.5rem]">The Faculty</h3>
                    <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 py-8">
                        {
                            faculty.map((person, index) => (
                                <div className="flex flex-col lg:flex-row" key={index}>
                                    <div className="px-20 lg:pr-5 lg:pl-0 mb-5 ">
                                        <img src={person.img}
                                            className=""
                                        />
                                    </div>
                                    <div className="text-center lg:text-left self-top">
                                        <h2>{person.name}</h2>
                                        <h4 className="pb-4">{person.dep}</h4>
                                        <p className="px-8 lg:px-0 lg:pr-20">{person.bio}</p>

                                        <a href={ `https://twitter.com/${person.twitter}` }
                                        className=" inline-block"
                                        target="_blank">

                                            <img src={twitterWhite}
                                            className="w-10 lg:w-8 hover:opacity-75 pt-4 lg:pt-2" />

                                        </a>
                                        { person.opensea && openS }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        );

        return bio;
    }

    render() {
        return <>{ this.bios }</>;
    }
}
