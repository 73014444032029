import React, {useState, useEffect} from 'react';
import { useMutation, useLazyQuery, gql } from '@apollo/client';
import { getKeyFromPassphrase, encrypt, generateRandomSalt} from './crypto_helper';
import { AnimatePresence, motion } from 'framer-motion';
import { HashLink as Link } from 'react-router-hash-link';
import { debounce } from 'lodash';
import { TwitterOauthButton } from "./components/twitterAuth";
import { saveToLocalStorage, loadFromLocalStorage } from './components/localStorageUtils';


import btnLoading from './img/icons/loader.svg';
import exiledToken from './img/exiled/exiled_token.png';

// let numToLoad = 36;

const ADD_APPLICANT = gql`
    mutation AddApplicant($input: CreateUserInput!) {
        createUser(input: $input) {
            wallet
            email
            elementary_token_ids
            twitter_id
            twitter_is_following
            twitter_followers
            discord_id
            discord_username
            discord_in_ours
            status
            approved
            salt
            q1
            q2
            q3
        }
    }
`;

const GET_TWITTER_TEMPLATE = gql`
    query CheckTwitter($twtID: twitterID) {
        checkTwitter(input: $twtID) {
            isFollowing
            followerNum
        }
    }
`;

const GET_DISCORD_USER = gql`
    mutation AuthenticateDiscord($code: String!) {
        authenticateDiscord(code: $code) {
            id 
            username
            inDiscord
        }
    }
`

const CHECK_UNREGISTERED_WALLET = gql`
    query CheckUnregisteredWallet($input: String!) {
        checkUnregisteredWallet(input: $input)
    }
`

const GET_USER = gql`
    query GetUser($input: String!) {
        getUser(wallet: $input) {
            approved
            status
        }
    }
`


export default function JoinWhitelist (props) {
    
    const mySalt = generateRandomSalt();

    const formPages = {
        "landing": 0,
        "wallet and twitter": 1,
        "safety": 2,
        "ae_holder": 3,
        "question 1": 4,
        "discord_id": 5,
        "question 2": 6,
        "email address": 7,
        "question 3": 8,
        "ae_claim_no": 9,
        "completion": 10,
        "application status": 11
    }

    const [ pageIdx, setPageIdx ] = useState(0);
    const [ gridColumns, setGridColumns ] = useState(1);
    const [ emailError, setEmailError ] = useState(false);
    const [ holderConfirmation, setHolderConfirmation ] = useState(false);
    const [ userData, setUserData ] = useState({wallet: props.userAddress ? props.userAddress : null, salt: "", approved: false});
    const [ isUniqueWallet, setIsUniqueWallet] = useState(null);
    const [hasCheckedWallet, setHasCheckedWallet] = useState(false);

    const [checkUniqueWallet, {loading: walletLoading, error: walletError, data: walletResult}] = useLazyQuery(CHECK_UNREGISTERED_WALLET, {
        notifyOnNetworkStatusChange:true
    });

    // for fetching if no local user record saved
    const [checkUserStatus, {loading: userStatusLoading, error: userStatusError, data: userStatusResult}] = useLazyQuery(GET_USER, {
        notifyOnNetworkStatusChange:true
    });

    const [userStatus, setUserStatus] = useState(null); // manage the user data from the DB

    const [getFollowerStatus, {loading: twtLoading, error: twtError, data: twitterData}] = useLazyQuery(GET_TWITTER_TEMPLATE, {
        notifyOnNetworkStatusChange:true
    });
    // GET DISCORD USER VIA SERVER
    const [code, setCode] = useState(null);
    const [getDiscordUser, {loading: discordLoading, error: discordError, data: discordUserData}] = useMutation(GET_DISCORD_USER);

    useEffect(()=> {
        if(code) {
            console.log("got the code: " + code)
            getDiscordUser({variables: {"code": code}});
        }
    }, [code])

    // If user is checking status grab user from local storage
    // there should be a user from Local Storage because the Navbar is responsible for checking if the user has a record and then saving it to local storage
    useEffect(()=> {
        if(pageIdx == formPages["application status"] && props.userAddress.length > 0) {
            // checkUserStatus({variables: {"input": props.userAddress}});
            const localUser = loadFromLocalStorage("getUser");
            if(props.userAddress.length > 0 && localUser && localUser.wallet === props.userAddress) {
                setUserStatus(localUser)
            } else if(props.userAddress.length > 0 && !userStatusLoading) {
                console.log("Checking Allowlist status");
                checkUserStatus({variables: {"input": props.userAddress}});
            }
        }
    }, [pageIdx])

    // IF new data is downloaded from DB, save it to local storage
  useEffect(() => {
    if(userStatusResult) {
      setUserStatus(userStatusResult)
      let tmpLocalStorage = {...userStatusResult, wallet: props.userAddress}
      // save to local storage
      saveToLocalStorage("getUser", tmpLocalStorage, 100);
    }
  }, [userStatusResult])

    useEffect(()=> {
        if(userStatus) {
            console.log("User status data received");
            console.log(userStatus);
        }
    }, [userStatus])

    useEffect(() => {
        if(discordUserData) {
            console.log(`I've got the details:`)
            console.log(discordUserData)
            setUserData(userData => ({...userData, discord_id: discordUserData.authenticateDiscord.id, discord_username: discordUserData.authenticateDiscord.username, discord_in_ours: discordUserData.authenticateDiscord.inDiscord}));
        }
    }, [discordUserData])
    
    const [mutateFunction, { data, loading, error }] = useMutation(ADD_APPLICANT, 
        {
            notifyOnNetworkStatusChange: true
        }
        );

    loading && console.log("Submitting...");
    error && console.log("Submission error: " + error.message);

    const cardAnim = {
        hidden: {
            x: "105vw"
        },
        show: {
            x: 0
        },
        exit: {
            x: "-105vw"
        }
    }

    useEffect(()=> {
        if(userData.twitter_id) {
            console.log(userData.twitter_id)
            getFollowerStatus({variables: {"twtID": {name: userData.twitter_id}}})
        }
    }, [userData.twitter_id])

    // update userData with twitterDetails once they've been returned
    useEffect(()=> {
        if(twitterData) {
            console.log("Twitter data received")
            console.log(twitterData)
            setUserData(userData => ({...userData, twitter_followers: twitterData.checkTwitter.followerNum, twitter_is_following: twitterData.checkTwitter.isFollowing}));
        }
    }, [twitterData])
    

    useEffect(() => {
        if(props.userAddress != null && props.userAddress.length != 0 && !hasCheckedWallet) {
            setUserData((userData) => ({ ...userData, wallet: props.userAddress }));
            // check DB to see if this wallet has already been registered
            debouncedCheckUniqueWallet(props.userAddress);
            if(formPages.safety === pageIdx) {
                goToPage("wallet and twitter");
            }
        }
    }, [props.userAddress])

    const debouncedCheckUniqueWallet = debounce((userAddress) => {
        checkUniqueWallet({ variables: { input: userAddress } });
        console.log(userAddress)
        setHasCheckedWallet(true);
    }, 3000);

    // store result of wallet existing in DB or not
    useEffect(() => {
        if(walletResult) {
            setIsUniqueWallet(walletResult.checkUnregisteredWallet)
        }
    }, [walletResult])

    useEffect(() => {
        if(props.isConnected === true) {
            props.fetchUserTokens()
        }
    }, [props.isConnected])

    // if props.userTokens is > 0, update setUserData with the user's token array
    useEffect(() => {
        console.log("User tokens received")
        console.log(props.tokenIDs && props.tokenIDs.length > 0)
        if(props.tokenIDs && props.tokenIDs.length > 0) {
            setUserData(userData => ({...userData, elementary_token_ids: props.tokenIDs, approved: true, status: "reviewed"}))
            const numImages = props.tokenIDs.length;
            const columns = Math.ceil(Math.sqrt(numImages));
            console.log(`Grid cols: ${columns}`)
            setGridColumns(columns);
        }
    }, [props.tokenIDs])

    // Discord auth
    const handleLoginClick = () => {
        const authWindow = window.open("https://discord.com/api/oauth2/authorize?client_id=1087943597959172138&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fjoinwl&response_type=code&scope=identify%20guilds", 'Discord Authorization', 'width=500,height=800');
        const checkAuthInterval = setInterval(() => {
        if (authWindow.closed) {
            clearInterval(checkAuthInterval);
            return;
        }

        try {
            const urlParams = new URLSearchParams(authWindow.location.search);
            const authCode = urlParams.get('code');

            if (authCode) {
            authWindow.close();
            setCode(authCode);
            }
        } catch (e) {
            // Ignore exception caused by cross-origin access
        }
        }, 1000);
    };

    // For handling user input searching for an individual ID
    const [typingTimeout, setTypingTimeout] = useState(null);

    function updateFormData(event) {
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        setTypingTimeout(
            setTimeout(() => {
                setUserData(userData => ({...userData, [event.target.name]: event.target.value}));
            }, 300)
        );
    }

    function handleSubmit() {
        let tempData = userData;

        if(props.isHolder) {
            // if you have an A.E. you are auto approved
            tempData = {...tempData, approved: true, status: "reviewed"}
        }

        // encrypt email if present
        if(userData.email) {
            let key = getKeyFromPassphrase(process.env.REACT_APP_PASSPHRASE, mySalt);
            let emailAdd = tempData.email;
            let encryptedEmail = encrypt(emailAdd, key)
            tempData = {...tempData, email: encryptedEmail, salt: mySalt};
            // save encrypted email & salt
        }
        console.log(tempData)
        mutateFunction({variables: { input: tempData }})
    }

    function loader() {

        const loaderAnimation = (<>
            <span className='mx-auto'>
                <img src={btnLoading} className="w-12 animate-spin invert mx-auto" />
            </span>
        </>)

        return loaderAnimation
    }

    useEffect(() => {
        return () => {
            clearTimeout(typingTimeout);
        };
    }, [typingTimeout]);

    function goToPage(pageName) {
        if(pageIdx < Object.entries(formPages).length) {
            setPageIdx(formPages[pageName]);
        }
    }

    function answerQuestion(qNum, Ans) {
        setUserData(userData => ({...userData, [qNum]: Ans}));
    }

    function isValidEmail(email) {
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;
        return emailRegex.test(email);
      }

    function handleKeyPress(e, targetPage) {
        if(e.key === 'Enter' || e.key === 13) {
            e.preventDefault();
            goToPage(targetPage);
        }
    }

    function getUserReviewedStatusPage() {
        if(userStatus) {
            return(
                <motion.div key="completion" className="text-black shadow-xl w-3/4 border border-slate-100 bg-white mx-auto rounded-2xl text-center min-h-[600px]" variants={cardAnim} initial="hidden" animate="show" exit="exit">
                    { userStatus?.getUser.status === "pending" ?
                        // NOT YET REVIEWED
                        <div className='flex flex-row space-x-6'>
                        <div className='bg-slate-50 rounded-lg w-1/2'>
                            <img src="https://placehold.co/600x400" alt="Here is your image" className='' />
                        </div>
                        <div className='w-1/2'>
                            <h4>Pending</h4>
                            <div className='my-4 space-y-4 text-black'>
                                Your application is still pending. This should take 24 - 48 hours to review. If there is a problem, open a #support-ticket in our Discord.
                            </div>
                        </div>
                        </div>   
                        :
                        <>
                        { userStatus?.getUser.approved === true ?
                            <> 
                            {/* APPROVED */}
                            <div className='flex flex-row space-x-6'>
                                <div className='bg-slate-50 rounded-lg w-1/2'>
                                    <img src="https://placehold.co/600x400" alt="Here is your image" className='' />
                                </div>
                                <div className='w-1/2'>
                                    <h4>You're Approved</h4>
                                    <div className='my-4 space-y-4 text-black'>
                                        You've all set.
                                        
                                        Now let the world know #WeAreExiled.

                                        See you in The Block.
                                    </div>
                                </div>
                            </div>
                            </>
                        :
                            // REJECTED
                            <div className='flex flex-row space-x-6'>
                                <div className='bg-slate-50 rounded-lg w-1/2'>
                                    <img src="https://placehold.co/600x400" alt="Here is your image" className='' />
                                </div>
                                <div className='w-1/2'>
                                    <h4>Application Declined</h4>
                                    <div className='my-4 space-y-4 text-black'>
                                        Sorry, your application has not been approved.
                                    </div>
                                </div>
                            </div>
                        }
                        </>
                    }
                    <button onClick={()=>{goToPage("landing")}} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Back</button>
                </motion.div>
                )
        } else {
            return (
                <motion.div key="completion" className="text-black shadow-xl w-3/4 border border-slate-100 bg-white mx-auto rounded-2xl text-center min-h-[600px]" variants={cardAnim} initial="hidden" animate="show" exit="exit">
                    <div className='flex flex-row space-x-6'>
                        { (userStatus == null && !userStatusLoading) ?
                        <div>
                            <h4>No Application Found</h4>
                            <div className='my-4 space-y-4 text-black'>
                                Please ensure the wallet you've connected has applied for the allowlist.
                            </div>
                        </div>
                        :
                        <>
                        <h4>Loading</h4>
                        <div className='my-4 space-y-4 text-black'>
                            { loader() }
                        </div>
                        </>
                    }
                    </div>   
                        <button onClick={()=>{goToPage("landing")}} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Back</button>
                </motion.div>
            )
        }
    }

    function allowlistPages() {
        switch(pageIdx) {
            case 0: // landing
                return(
                    <motion.div key="landing" className="text-black shadow-xl w-3/4 border border-slate-100 bg-white mx-auto rounded-2xl text-center min-h-[600px]" variants={cardAnim} initial="hidden" animate="show" exit="exit">
                        <div className='py-4'>
                            <h2>Get Whitelisted</h2>
                            <TwitterOauthButton />
                            {/* <button onClick={loginWithTwitter}>Sign In With Twitter</button> */}
                            <div className='grid grid-cols-2 gap-8 px-8'>
                                <div className='text-center mx-auto'>
                                    <h4 className='text-black'>Apply for the Allowlist</h4>
                                    <p className='text-black'>It won't take long, and we'll show you some cool shit.</p>
                                    { (isUniqueWallet !== false) && <button disabled={isUniqueWallet !== true && props.isConnected} onClick={() => {goToPage("wallet and twitter")}} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black mb-4 px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Apply Now</button>}
                                    { (isUniqueWallet === false && props.isConnected) && <div className='px-4 py-3 mb-2 border border-yellow-500 bg-yellow-50 rounded-md'><p className='pb-0'>This wallet has already applied for the whitelist.</p></div> }
                                    { (walletLoading && props.isConnected) && <p className='text-sx italic mt-2 animate-pulse'>Verifying wallet status...</p>}
                                </div>

                                <div className=''>
                                    <h4 className='text-black'>Check Status</h4>
                                    <p className='text-black'>Check the status of your application.</p>
                                    
                                    <button disabled={!props.userAddress} onClick={() => {goToPage("application status")}} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">{ !props.userAddress ? "Connect Wallet" : "Check Now" }</button>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                )
            case 1: // connect wallet
                return(
                    <motion.div key="connect_wallet" className="text-black shadow-xl w-3/4 border border-slate-100 bg-white mx-auto rounded-2xl text-center min-h-[600px]" variants={cardAnim} initial="hidden" animate="show" exit="exit">
                        <div className='py-4'>
                            <h2>Landing Page</h2>
                            <div className='grid grid-cols-2 gap-8 px-8'>
                                <div className='text-center mx-auto'>
                                    <h4 className='text-black'>Your Mint Wallet</h4>
                                    <p className='text-black'>Please connect with the wallet you intend to mint with in order for your wallet to be whitelisted.</p>
                                    { !props.userAddress ? <button onClick={() => {goToPage("safety")}} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Let's go</button>
                                        :
                                        <>
                                            <button disabled className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1">Connected</button>
                                            <p className='text-black text-xs font-mono'>{props.userAddress.substring(0,8)}...{props.userAddress.substring(32,42)}</p>
                                        </>
                                    }
                                </div>

                                <div className=''>
                                    <h4 className='text-black'>Connect Twitter</h4>
                                    <p className='text-black text-xs italic'>Optional</p>

                                    {/* TEMP */}
                                    
                                    <input type="text" id="twitter_id" name="twitter_id" placeholder='myTwitterName' className='border border-black px-3 py-2 text-xs my-3 w-full rounded-sm' onChange={(e)=>{updateFormData(e)}} />
                                    
                                    {/* Attempting to make this twitter btn work */}
                                    <p className='text-black'>Please verify your Twitter account. We will use this to check you're a follower of <a href="https://twitter.com/AlphaAcademyInc" className='' alt="Our twitter, obviously.">@AlphaAcademyInc</a>.</p>
                                </div>
                            </div>
                        </div>
                        { (isUniqueWallet === false) && <div className='px-4 py-3 mb-2 border border-red-400 bg-red-100 rounded-md'><p className='pb-0'><strong>Error:</strong> This wallet has already applied for the whitelist.</p></div> }
                        <button disabled={(isUniqueWallet !== true)} onClick={()=>{goToPage(props.isHolder === true ? "ae_holder" : "question 1")}} className={`rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white  disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`}>Next</button>
                        { walletLoading && <p className='text-sx italic mt-2 animate-pulse'>Verifying wallet status...</p>}
                    </motion.div>
                )
            case 2: // safety info
                return(
                    <motion.div key="safety_info" className="text-black shadow-xl w-3/4 border border-slate-100 bg-white mx-auto rounded-2xl text-center min-h-[600px]" variants={cardAnim} initial="hidden" animate="show" exit="exit">
                        <h2>Before you connect...</h2>
                        <p className='text-black'>
                            Web3 is full of awesome things, but it can be dicey out there. This space will only grow and mature if we all do our best to keep ourselves and each other safe.
                        </p>
                        <p className='text-black'>
                            Before connecting your wallet (especially if you are new to web3), let's just go over a few best practices when it comes to mints:
                        </p>
                        <ol className='list-decimal list-outside text-left'>
                            <li className='list-item'>Use a mint wallet. The wallet you connect here will be the one we whitelist for you to use on mint day. Ensure this wallet is intended to be used to mint, and does not store any assets long-term</li>
                            <li className='list-item'>Don't rush. People fall for obvious tricks when FOMO takes hold. Take a moment to ensure the URL is correct and nothing looks suspicious. </li>
                            <li className='list-item'>There's almost never “surprise” mints. Twitter scammers often tag people in surprise mints that no-one knew was happening. And that's because they aren't happening. You'll always know well in advance when the mint is happening.</li>
                        </ol>
                        <div className=''>
                            <p>TL;DR - always look out for the sharks.</p>
                            <p>Alright, enough preaching.</p>
                            <button onClick={props.connectFunc}
                            className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50"
                            >Connect Wallet</button>
                        </div>
                        <button  onClick={()=>{goToPage("wallet and twitter")}} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Back</button>
                    </motion.div>
                )
            case 3: // ae_holder
                return(
                    <motion.div key="ae_holder" className="text-black shadow-xl w-3/4 border border-slate-100 bg-white mx-auto rounded-2xl text-center min-h-[600px]" variants={cardAnim} initial="hidden" animate="show" exit="exit">
                        <div className='w-1/2 mx-auto'>
                            <h4 className='text-black'>GM, looks like you've got some Alphas in your wallet.</h4>
                            {/* <p className='text-black text-xs italic'>All current A.E. holders are elligable for .</p> */}

                            <p className='text-black'>
                            Looks like you're holing Alpha Elementary kids. By completing this process, you're registering your A.E. Tokens to allow you to claim a free mint per token held.
                            <br />
                            <br />
                            Before continuing, there are a few important things to be aware of:
                            </p>

                            <ol className='list-decimal list-outside text-left'>
                                <li className='list-item'>You must still hold these tokens in your wallet during the mint</li>
                                <li className='list-item'>1 Aalpha Elementary token = 1 free mint</li>
                                <li className='list-item'>If you sell all your Alpha Elementary NFTs prior to mint, you will still be whitelisted, but not eligible to claim any tokens for free.</li>
                            </ol>
                            
                            <span alt="Agree to the terms above" className='flex flex-row items-baseline space-x-3 mt-4'>
                                <input onChange={(e) => {setHolderConfirmation(e.target.checked)}} id="ae_confirmation" name="ae_confirmation" type="checkbox" className='text-2xl scale-125' /><label htmlFor='ae_confirmation' className='text-black italic'>I understand</label>
                            </span>
                        </div>
                        <button onClick={()=>{goToPage("wallet and twitter")}} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Back</button>
                        <button disabled={holderConfirmation !== true} onClick={()=>{goToPage("ae_claim_no")}} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Next</button>
                    </motion.div>
                )
            case 9: // ae_claim_no
                return(
                    <motion.div key="ae_claim_no" className="text-black shadow-xl w-3/4 border border-slate-100 bg-white mx-auto rounded-2xl text-center min-h-[600px]" variants={cardAnim} initial="hidden" animate="show" exit="exit">
                        <div className='py-4'>
                            <h2>Let's round up the kids</h2>
                            <div className='grid grid-cols-2 gap-8 px-8'>
                                <div className='text-center mx-auto'>
                                    <h4 className='text-black'>Your A.E. Fam</h4>
                                    <div className={`grid grid-cols-${gridColumns} gap-4`}>
                                    {/* <div className='grid grid-cols-3 gap-4'> */}
                                        { props?.tokenIDs && props.tokenIDs.map((id, index) => {
                                                return(
                                                    <img key={index} src={ require(`./img/ae_students/${id}.png`) } className="w-full h-auto" />
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className=''>
                                    <h4 className='text-black'>Your Exiled Free Mints</h4>
                                        <div className={`grid grid-cols-${gridColumns} gap-4`}>
                                            { props?.tokenIDs && props.tokenIDs.map((id, index) => {
                                                    return(
                                                        <img key={index} src={ exiledToken } className="w-full h-auto" />
                                                    )
                                                })
                                            }
                                        </div>
                                </div>
                            </div>
                        </div>
                        <button onClick={()=>{goToPage("ae_holder")}} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Back</button>
                        <button onClick={()=>{goToPage("question 1")}} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Next</button>
                    </motion.div>
                )
            case 4: // question 1
                return(
                    <motion.div key="question_1" className="text-black shadow-xl w-3/4 border border-slate-100 bg-white mx-auto rounded-2xl text-center min-h-[600px]" variants={cardAnim} initial="hidden" animate="show" exit="exit">
                        <h2>Question 1</h2>
                        <div className='flex flex-row space-x-6'>
                            <div className='bg-slate-50 rounded-lg w-1/2'>

                            </div>
                            <div className='w-1/2'>
                                <h4>Alright, you walk into The block and this MF approaches, says he has some great rugs for your apartment. What do you do?</h4>
                                <div className='my-4 space-y-4'>
                                    <button onClick={()=>{answerQuestion("q1", "A")}} className={`rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2  px-3 pt-2 py-1  w-2/3 ${userData.q1 === "A" ? "border-green-400 bg-green-400 hover:bg-green-300" : "border-black hover:bg-slate-100"}`}>Option A</button>
                                    <button onClick={()=>{answerQuestion("q1", "B")}} className={`rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2  px-3 pt-2 py-1  w-2/3 ${userData.q1 === "B" ? "border-green-400 bg-green-400 hover:bg-green-300" : "border-black hover:bg-slate-100"}`}>Option B</button>
                                    <button onClick={()=>{answerQuestion("q1", "C")}} className={`rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2  px-3 pt-2 py-1  w-2/3 ${userData.q1 === "C" ? "border-green-400 bg-green-400 hover:bg-green-300" : "border-black hover:bg-slate-100"}`}>Option C</button>
                                    <button onClick={()=>{answerQuestion("q1", "D")}} className={`rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2  px-3 pt-2 py-1  w-2/3 ${userData.q1 === "D" ? "border-green-400 bg-green-400 hover:bg-green-300" : "border-black hover:bg-slate-100"}`}>Option D</button>
                                </div>
                            </div>
                        </div>
                        <button onClick={()=>{goToPage(props.isHolder ? "ae_holder" : "wallet and twitter")}} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Back</button>
                        <button disabled={!userData.q1} onClick={()=>{goToPage("discord_id")}} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Next</button>
                    </motion.div>
                )
            case 5: // connect discord
                return(
                    <motion.div key="conn_discord" className="text-black shadow-xl w-3/4 border border-slate-100 bg-white mx-auto rounded-2xl text-center min-h-[600px]" variants={cardAnim} initial="hidden" animate="show" exit="exit">
                        <div className='w-1/2 mx-auto'>
                            <h4 className='text-black'>Let's check you like to hang with us</h4>
                            <p className='text-black text-xs italic'>Skip if you aren't on Discord</p>

                            {/* TEMP */}
                            {/* <a href="https://discord.com/api/oauth2/authorize?client_id=1087943597959172138&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fjoinwl&response_type=code&scope=identify" className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Connect with Discord</a> */}
                            <button onClick={handleLoginClick} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Connect with Discord</button>
                            
                            { discordLoading && loader() }
                            { userData.discord_username && <h4 className='py-4'>GM, { userData.discord_username }</h4>}
                            
                            {/* Attempting to make this twitter btn work */}
                            <p className='text-black'>If you aren't part of our Discord, it's never too late: <a href="https://discord.gg/WBsxBgRvdZ" className='' alt="Our twitter, obviously.">join the Discord</a>.</p>
                        </div>
                        <button onClick={()=>{goToPage("question 1")}} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Back</button>
                        <button  onClick={()=>{goToPage("question 2")}} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">{ userData.discord_id ? "Next" : "Skip"}</button>
                    </motion.div>
                )
            case 6: // question 2
                return(
                    <motion.div key="question_2" className="text-black shadow-xl w-3/4 border border-slate-100 bg-white mx-auto rounded-2xl text-center min-h-[600px]" variants={cardAnim} initial="hidden" animate="show" exit="exit">
                        <h2>Question 2</h2>
                        <div className='flex flex-row space-x-6'>
                            <div className='bg-slate-50 rounded-lg w-1/2'>

                            </div>
                            <div className='w-1/2'>
                                <h4>So you make it in the block. You're popular around the hood. What type of resident are you?</h4>
                                <div className='my-4 space-y-4'>
                                    <button onClick={()=>{answerQuestion("q2", "A")}} className={`rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2  px-3 pt-2 py-1  w-2/3 ${userData.q2 === "A" ? "border-green-400 bg-green-400 hover:bg-green-300" : "border-black hover:bg-slate-100"}`}>Option A</button>
                                    <button onClick={()=>{answerQuestion("q2", "B")}} className={`rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2  px-3 pt-2 py-1  w-2/3 ${userData.q2 === "B" ? "border-green-400 bg-green-400 hover:bg-green-300" : "border-black hover:bg-slate-100"}`}>Option B</button>
                                    <button onClick={()=>{answerQuestion("q2", "C")}} className={`rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2  px-3 pt-2 py-1  w-2/3 ${userData.q2 === "C" ? "border-green-400 bg-green-400 hover:bg-green-300" : "border-black hover:bg-slate-100"}`}>Option C</button>
                                    <button onClick={()=>{answerQuestion("q2", "D")}} className={`rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2  px-3 pt-2 py-1  w-2/3 ${userData.q2 === "D" ? "border-green-400 bg-green-400 hover:bg-green-300" : "border-black hover:bg-slate-100"}`}>Option D</button>
                                </div>
                            </div>
                        </div>
                        <button onClick={()=>{goToPage("discord_id")}} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Back</button>
                        <button disabled={!userData.q2} onClick={()=>{goToPage("email address")}} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Next</button>
                    </motion.div>
                )
                case 7: // email address
                return(
                    <motion.div key="email_address" className="text-black shadow-xl w-3/4 border border-slate-100 bg-white mx-auto rounded-2xl text-center min-h-[600px]" variants={cardAnim} initial="hidden" animate="show" exit="exit">
                        <div className='w-1/2 mx-auto'>
                            <h4 className='text-black'>Wanna be penpalls?</h4>
                            <p className='text-black text-xs italic'>We're feeling a little web2-y today. This step is optional.</p>

                            <input type="email" id="email" name="email" placeholder='jimmy@theblock.xyz' className='border border-black px-3 py-2 text-xs my-3 w-full rounded-sm' onChange={(e)=>{updateFormData(e)}} onClick={()=>{setEmailError(false)}} />
                            { emailError && <div className='px-4 py-3 mb-2 border border-red-400 bg-red-100 rounded-md'><p className='pb-0'><strong>Error:</strong> your email address appears to be invalid. Please ensure it is correctly formatted, or delete it before clicking next.</p></div> }
                            
                            {/* Attempting to make this twitter btn work */}
                            <p className='text-black'>Your email is stored encrypted.</p>
                        </div>
                        <button onClick={()=>{goToPage("question 2")}} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Back</button>
                        <button  onClick={()=>{
                            if(userData.email && isValidEmail(userData.email)) {
                                goToPage("question 3")
                            } else if(!userData.email) {
                                goToPage("question 3")
                            } else {
                                setEmailError(true)
                            }
                        }} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">{ userData.email ? "Next" : "Skip"}</button>
                    </motion.div>
                )
            case 8: // question 3
                return(
                    <motion.div key="question_3" className="text-black shadow-xl w-3/4 border border-slate-100 bg-white mx-auto rounded-2xl text-center min-h-[600px]" variants={cardAnim} initial="hidden" animate="show" exit="exit">
                        <h2>Final Question</h2>
                        <div className='flex flex-row space-x-6'>
                            <div className='bg-slate-50 rounded-lg w-1/2'>

                            </div>
                            <div className='w-1/2'>
                                <h4>You mint this. What do you choose to do with your ip?</h4>
                                <div className='my-4 space-y-4'>
                                    <button onClick={()=>{answerQuestion("q3", "A")}} className={`rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 px-3 pt-2 py-1  w-2/3 ${userData.q3 === "A" ? "border-green-400 bg-green-400 hover:bg-green-300" : "border-black hover:bg-slate-100"}`}>Option A</button>
                                    <button onClick={()=>{answerQuestion("q3", "B")}} className={`rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 px-3 pt-2 py-1  w-2/3 ${userData.q3 === "B" ? "border-green-400 bg-green-400 hover:bg-green-300" : "border-black hover:bg-slate-100"}`}>Option B</button>
                                    <button onClick={()=>{answerQuestion("q3", "C")}} className={`rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 px-3 pt-2 py-1  w-2/3 ${userData.q3 === "C" ? "border-green-400 bg-green-400 hover:bg-green-300" : "border-black hover:bg-slate-100"}`}>Option C</button>
                                    <button onClick={()=>{answerQuestion("q3", "D")}} className={`rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 px-3 pt-2 py-1  w-2/3 ${userData.q3 === "D" ? "border-green-400 bg-green-400 hover:bg-green-300" : "border-black hover:bg-slate-100"}`}>Option D</button>
                                </div>
                            </div>
                        </div>
                        { loading ? loader() : <button disabled={!userData.q3} onClick={()=>{
                            console.log(userData)
                            handleSubmit()
                            goToPage("completion")
                        }} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Submit</button>
                        }
                    </motion.div>
                )
            case 10: // completion
                return(
                    <motion.div key="completion" className="text-black shadow-xl w-3/4 border border-slate-100 bg-white mx-auto rounded-2xl text-center min-h-[600px]" variants={cardAnim} initial="hidden" animate="show" exit="exit">
                        <h2>Thanks</h2>
                        { userData.approved ?
                            <> 
                            {/* A.E. holder or whitelisted project is auto approved */}
                            <div className='flex flex-row space-x-6'>
                                <div className='bg-slate-50 rounded-lg w-1/2'>
                                    <img src="https://placehold.co/600x400" alt="Here is your image" className='' />
                                </div>
                                { !loading ?
                                <div className='w-1/2'>
                                    <h4>Approved</h4>
                                    <div className='my-4 space-y-4'>
                                        You've all set. Now share your excitement on Twitter #WeAreExiled.

                                        See you in The Block.
                                    </div>
                                </div>
                                :
                                    <>{ loader() }</>
                                }

                            </div>
                            </>
                            :
                            <>
                            {/* General public not instantly approved */}
                                <div className='flex flex-row space-x-6'>
                                    <div className='bg-slate-50 rounded-lg w-1/2'>
                                        <code className='w-1/2 mt-32'>
                                            { JSON.stringify(userData) }
                                        </code>
                                    </div>
                                    <div className='w-1/2'>
                                        <h4>Thanks for your interest</h4>
                                        <div className='my-4 space-y-4'>
                                            { loading && loader() }
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        <Link to="/" className="rounded-md font-teko text-[1.1rem] text-black tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white hover:no-underline disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Home</Link>
                        {/* TESTING */}
                        { loading ? loader() : <button disabled={!userData.q3} onClick={()=>{
                            console.log(userData)
                            handleSubmit()
                            goToPage("completion") //tokenIDs
                        }} className="rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 hover:bg-black hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">Submit</button>
                        }
                    </motion.div>
                )
            case 11: // application status
                return getUserReviewedStatusPage()
            default: 
                return null;
        }
    }

    return(<>
        <div className='py-24 container mx-auto'>
            <AnimatePresence exitBeforeEnter>
                { allowlistPages() }
            </AnimatePresence>
        </div>
        </>);
}



// function useTwitterAuth() {
//     const [twitterID, setTwitterID] = useState(null);
  
//     const loginWithTwitter = () => {
//       window.open('https://api.twitter.com/oauth/authenticate?oauth_token=...', '_self');
//     };
  
//     const handleTwitterCallback = (callbackURL) => {
//       const urlParams = new URLSearchParams(callbackURL.split('?')[1]);
//       const oauthToken = urlParams.get('oauth_token');
//       const oauthVerifier = urlParams.get('oauth_verifier');
  
//       fetch('https://api.twitter.com/oauth/access_token', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
//         },
//         body: `oauth_token=${oauthToken}&oauth_verifier=${oauthVerifier}`
//       })
//       .then(res => res.json())
//       .then(data => {
//         setTwitterID(data.user_id);
//       });
//     };
  
//     return { twitterID, loginWithTwitter, handleTwitterCallback };
//   }
  
//   function SignInWithTwitter() {
//     const { loginWithTwitter, handleTwitterCallback } = useTwitterAuth();
  
//     return (
//       <div>
//         <button onClick={loginWithTwitter}>Sign in with Twitter</button>
//         <div>
//           {
//             twitterID 
//               ? `Your Twitter ID is: ${twitterID}`
//               : null 
//           }
//         </div>
//       </div>
//     );  
//   }





// The full corrected code would be:

// function useTwitterAuth() {
//   const [authToken, setAuthToken] = useState(null);

//   const loginWithTwitter = () => {
//     // ...
//   };  

//   const handleTwitterCallback = (callbackURL) => {
//     const urlParams = new URLSearchParams(callbackURL.split('?')[1]);
//     const code = urlParams.get('code');

//     // Get access token
//     fetch('https://api.twitter.com/2/oauth2/token', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
//         'Authorization': `Basic ${btoa(`${process.env.AA_TWITTER_A2_CLIENT_ID}:${process.env.AA_TWITTER_A2_CLIENT_SECRET}`)}`
//       },
//       body: `grant_type=authorization_code&client_id=${process.env.AA_TWITTER_A2_CLIENT_ID}&client_secret=${process.env.AA_TWITTER_A2_CLIENT_SECRET}&code=${code}&redirect_uri=http%3A%2F%2Flocalhost`
//     })
//     .then(res => res.json())
//     .then(data => {
//       setAuthToken(data.access_token);
//     });
//   }    
// }