import React, {useState, useEffect} from 'react';
import { useQuery, useLazyQuery, gql } from '@apollo/client';
import Select from 'react-select';

import AEDark from './img/AE_logo_V2.svg';
import traits from './metadata/trait_types.json';

import ae_allocation from './show_and_tell/allocation_list.json';

let traitNames = [{value: "", label: "Select a Trait Type"}];
let testArray;

const QUERY_ALL_ELEMENTARIES = gql`
    query GetAllElementaries($searchAeCollection: SearchAeCollection, $offset: Int, $limit: Int) {
        elementaries(input: $searchAeCollection, offset: $offset, limit: $limit) {
            elementary {
                id
                house
            }
            after
        }
    }
`

const QUERY_ALL_EXILED = gql`
    query GetAllExiles {
        exiles {
            id
            element
            student_id
        }
    }
`

const GET_EXILED_BY_ELEMENT = gql`
    query Exiled($element: String!) {
        exiled(element: $element) {
            id
            element
            student_id
        }
    }
`

// exiled(element: String!): Exiled!

export default function QueryCollection (props) {

    const [ elementSelected, setElementSelected ] = useState()
    const {data, loading, error} = useQuery(QUERY_ALL_EXILED);
    const {data: userData} = useQuery(QUERY_ALL_ELEMENTARIES);
    const [ fetchExiles, {data: exilesData, error: exilesError}] = useLazyQuery(GET_EXILED_BY_ELEMENT)

    if (error) {
        console.log(error)
    }
    if(userData) {
        console.log("User Data Collected")
        console.log(userData)
    }

    const [isOwner, setIsOwner] = useState(false);
    // const [houseSelected, setHouseSelected] = useState("Select a trait type...");
    // const [currentTraits, setCurrentTraits] = useState("Select a trait type...");
    // const [currentTraitName, setCurrentTraitName] = useState("Select a trait type...");
    // const [result, setResult] = useState([]);

    let trait_names_json = "";

    // // Form options
    // const houseList = [
    //     {value: "all", label: "⭐️ All"},
    //     {value: "Organica", label: "🌱 Organica"},
    //     {value: "Neue", label: "⚫️ Neue"},
    //     {value: "Psychedelia", label: "🍄 Psychedelia"},
    //     {value: "Retrogroove", label: "📺 Retrogroove"}
    // ]
    // Form options
    const elementsList = [
        {value: "All", label: "⭐️ All"},
        {value: "Dopium", label: "🌱 Dopium"},
        {value: "Vibium", label: "⚫️ Vibium"},
        {value: "Corruptium", label: "🍄 Corruptium"},
        {value: "Delinquium", label: "📺 Delinquium"}
    ]
    
    // let traitType = traits.map( test => ({ value: test.value, label: test.label}));

    useEffect(() => {
        if(props.isConnected) {
            checkOwner()
        }
      }, [props.isConnected])

      useEffect(() => {
        console.log("I've received the user data")
        console.log(userData)
      }, [userData])

    

    function checkOwner() {
        if(String(props.userAddress).toLowerCase() == '0xaD04907941f84d7f63Bd51e8066Ac08847895A2E'.toLowerCase()) {
            console.log("you are on the list")
            setIsOwner(true);
        } else {
            console.log("Incorrect account")
        }
    }

    // function getTraitNames(event) {
    //     setCurrentTraits(event.value.toLowerCase());
    //     trait_names_json = require(`./metadata/${event.label.toLowerCase()}.json`);
    //     traitNames = trait_names_json.map( tn => ({ value: tn.value, label: tn.label}));
    // }

    // function setHouse(event) {
    //     setHouseSelected(event.value);
    // }

    // function setTraitName(event) {
    //     setCurrentTraitName(event.value);
    // }

    // function copyData() {
    //     if(result) {
    //         console.log(result);
    //         navigator.clipboard.writeText(`[${result}]`);
    //     }
    // }

    // async function returnIds(event) {
    //     event.preventDefault();
    //     console.log(`https://thhyurue87.execute-api.ap-southeast-2.amazonaws.com/getArtworkIds?houseName=${houseSelected}&traitType=${currentTraits}&traitName=${currentTraitName}`)
    //     fetch(`https://thhyurue87.execute-api.ap-southeast-2.amazonaws.com/getArtworkIds?houseName=${houseSelected}&traitType=${currentTraits}&traitName=${currentTraitName}`)
    //         .then(res => res.json())
    //         .then(
    //             (result) => {
    //                 setResult(result)
    //                 // testArray = result
    //             });
    //     // setResult()
    // }

      const connectBtn = (<>
        <button onClick={props.connectFunc}
            className="
            flex tracking-wider mx-auto invert blk_btn
            ">
                Connect Wallet (must be contract owner)
            </button>
        </>);

    // function searchCollection() {

    //     const searchForm = (<>
    //     <form onSubmit={returnIds}>
    //         <div className="flex space-x-5">
    //             <div className='w-full'>
    //                 <label className='text-center w-full block font-bold text-lg pb-3'>Select house...</label>
    //                 <Select options={houseList} onChange={setHouse}
    //                 className="text-black w-full font-bold"
    //                 />
    //             </div>
    //             <div className='w-full'>
    //                 <label className='text-center w-full block font-bold text-lg pb-3'>Select Trait Type...</label>
    //                 <Select options={traitType} onChange={getTraitNames}
    //                 className="text-black w-full font-bold"
    //                 />
    //             </div>
    //             <div className='w-full'>
    //                 <label className='text-center w-full block font-bold text-lg pb-3'>Select Trait Name...</label>
    //                 <Select options={traitNames} onChange={setTraitName}
    //                 className="text-black w-full font-bold"
    //                 />
    //             </div>
    //         </div>
    //         <button type={"submit"} className="tracking-wider mx-auto invert blk_btn block mt-10 w-[250px]">Search</button>
    //     </form>
    //     <div className='flex w-full pt-10 flex-col'>
    //         <p className='text-[1.6rem] text-center'>{result.length} results for Artworks from <span className=' font-bold'>{houseSelected == 'all' ? "all houses" : houseSelected}</span> with the trait: <span className=' font-bold'>{currentTraitName}</span>:</p>
    //         <p className='flex text-lg text-left flex-wrap'>
    //             {result.map((itm) => (
    //                 <span className='px-10 py-2'>AE: 
    //                     <a href={`https://opensea.io/assets/0x692038c37f56e1772ce1d61cd0ff2d4289028458/${itm}`} target="_blank" className='text-white no-underline'>
    //                         {itm}
    //                     </a> | SAT:
    //                     <a href={`https://opensea.io/assets/0xb6b4ca6fe80008c429b713c26d6dcebc4ac32893/${ae_allocation[itm-1].sat_tokenId}`} target="_blank" className='text-white no-underline'>
    //                         {ae_allocation[itm-1].sat_tokenId}
    //                     </a>
    //                 </span>
    //             ))}
    //         </p>
    //         <span>
    //             <button onClick={copyData} className="flex tracking-wider mx-auto invert blk_btn">
    //             Copy AE IDs to Clipboard
    //             </button>
    //         </span>
    //     </div>
    //     </>);

    //     return (<>
    //         { isOwner ? searchForm : <span className='text-center'>Access denied. You musts be connected with the owner wallet to view this page.</span> }
    //     </>);

    // }
    
    return(<>
            <div className="relative overflow-x-hidden pt-10 min-h-screen max-h-auto flex flex-col place-content-around" id="collectionBg">
                <div className="lg:container mx-auto pt-14 pb-20">

                    <div className='flex flex-col w-full  lg:space-y-12' >
                        <h2 className='text-white text-center'>Query Collection</h2>
                        <p className='text-white text-center'>Return the token IDs based on traits</p>
                        <div>
                            { userData && String(userData)}
                        </div>
                        <div className='lg:w-full mx-auto' id="students">
                            { props.isAdmin ? <>
                                { loading ? (
                                    <>
                                        <h3>Loading...</h3>
                                    </>
                                ):
                                (<>
                                    <div>
                                        <Select options={elementsList}
                                        onChange={(event) => setElementSelected(event.value)}
                                        className="text-black font-montserrat font-semibold w-64 mb-4" />
                                        <button onClick={() => {
                                            fetchExiles({variables: {element: elementSelected}})
                                        }}>Fetch Data</button>
                                    </div>
                                    <ul>
                                        { exilesData && <h3>{exilesData.exiled.id} {exilesData.exiled.element}</h3>}
                                    </ul>
                                    <ul>
                                        { data && data.exiles.map((alpha, index) => {
                                            return <li key={index}>{alpha.id} <strong>{alpha.element}, {alpha.student_id}</strong></li>
                                        })}
                                    </ul>
                                </>)
                                }
                            </> : connectBtn }
                        </div>
                        {/* { props.isConnected && <PrimaryButtonNarrow click={props.fetchUserTokens} target="_blank" >Refresh</PrimaryButtonNarrow> } */}
                        <img src={AEDark} className='w-20 mx-auto' />
                    </div>

                </div>
            </div>
        </>);
}