import React, {useState, useEffect} from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { motion, AnimatePresence } from 'framer-motion'
import { gql, useMutation } from '@apollo/client';
import { saveToLocalStorage, loadFromLocalStorage } from '../components/localStorageUtils';

import { useMediaQuery } from 'react-responsive';
import { PageHeader, Connect } from  '../components';

import unrevealedSAT from '../img/show_and_tell/coming_soon.png';
import btnLoading from '../img/icons/loader.svg';
import ae_to_sat_tokens from '../show_and_tell/collection_metadata_consolidated.json';
import ae_allocation from '../show_and_tell/allocation_list.json';


const ADD_REMOVE_FRIEND = gql`
    mutation AddRemoveAeFren($input: FrenUpdate!) {
        addRemoveAeFren(input: $input)
    }
`

export default function Students (props) {
    const isMobile = useMediaQuery({query: `(max-width: 600px)`});
    const [winnerStatus, setWinnerStatus] = useState(false);
    const [satArray, setSatArray] = useState();
    const [fetched, setFetched] = useState(false);
    const [userStatus, setUserStatus] = useState(null);

    const [winnerList, setWinnerList] = useState('');

    const [ addRemoveAeFren, {data: frens, loading: loadingFren, error: errorFren}] = useMutation(ADD_REMOVE_FRIEND);

    useEffect(() => {
        if(frens && userStatus) {
            let tmpStorage = userStatus;
            tmpStorage = {... tmpStorage, getUser: {... tmpStorage.getUser, elementary_frens: frens.addRemoveAeFren}}
            console.log(tmpStorage)
            setUserStatus(tmpStorage)
            saveToLocalStorage("getUser", tmpStorage, 15);
        }
    }, [frens])

    // if user's wallet is connected, check to see if they've registered for Allowlist
    useEffect(()=> {
        // get local user (if exists)
        console.log("trying to fetch user again...")
        const localUser = loadFromLocalStorage("getUser");
        if(props.userAddress.length > 0 && localUser && localUser.wallet === props.userAddress) {
        console.log("Got from local storage")
        setUserStatus(localUser)
        } 
        // currently if the above isn't all TRUE, we do NOT fetch the user from the DB
    }, [props.userAddress])

    function getPrintWinners() {
      let winners_tmp = [];
      let temp_winners = fetch('https://jln0b9yxo0.execute-api.ap-southeast-2.amazonaws.com/default/aeCompWL?comp=print')
        .then(res => res.json())
        .then((result) => {
          for(let i = 0; i < result.length; i++) {
            winners_tmp[i] = result[i].toLowerCase()
          }
          setWinnerList(winners_tmp)
        })
    }

    // automatically check tokens once wallet is connected
    useEffect(() => {
        if(props.isConnected) {
            props.getTokens()
            props.fetchUserTokens()
            getPrintWinners()
        }
    }, [props.isConnected])
    
    useEffect(() => {
        if(winnerList) {
            checkPrintWinner()
        }
    }, [winnerList])
    
    
    useEffect(() => {
        (async function() { 
            if(props.satTokens && props.tokenIDs) {
                if(fetched == false) {
                    const a = await getSatTokens()
                    setSatArray(artefact_data)
                    setFetched(true)
                }
            }
        })()
    }, [props.satTokens && props.tokenIDs])
    

    let artefact_data = []
    async function getSatTokens() {
        let count = 0;
        for(let token = 0; token < props.satTokens.length; token++) {
            // console.log("PART 1")
            for(let tokenList of ae_to_sat_tokens) {
                // console.log("PART 2")
                for(let i = 0; i < tokenList.sat_tokenIDs.length; i++) {
                    // console.log("PART 3")
                    // console.log(tokenList.sat_tokenIDs[i])
                    // console.log(parseInt(props.satTokens[token]))
                    if(parseInt(props.satTokens[token]) == tokenList.sat_tokenIDs[i]) {
                        // then get the AE token with same index:
                        let aeTokenId = tokenList.ae_tokenIDs[i]
                        // console.log(ae_allocation[aeTokenId-1])
                        let artefactName;
                        if(!ae_allocation[aeTokenId-1].show_andtell_item) {
                            artefactName = "error"
                        } else {
                            artefactName = ae_allocation[aeTokenId-1].show_andtell_item
                        }
                        let rev_img = ae_allocation[aeTokenId-1].img_add
                        // let rev_img = "placeholder.png"
                        let artefact;
                        let ae_owned = true;
                        let item_stolen;
                        artefact = ae_allocation[aeTokenId-1].artefactid
                        item_stolen = ae_allocation[aeTokenId-1].stolen
                        let sat_img;
                        let status; 
                        let URL = `https://ae-show-and-tell.s3.amazonaws.com/${props.satTokens[token]}?${Math.floor(Math.random()*99999)}`
                        // console.log(URL)
                        await fetch(URL)
                            .then(res => res.json())
                            .then((result) => {
                                // console.log(`https://ae-show-and-tell.s3.amazonaws.com/${token}?${Math.floor(Math.random()*99999)}`)
                                // console.log(`showing resoluts for ${token}.json`)
                                // console.log("/////////////////////////////////")
                                // console.log("/////////////////////////////////")
                                // console.log(result)
                                sat_img = result.image;
                                // if(sat_img == "https://alphaelem.s3.amazonaws.com/Backpack_Retro.jpg" || sat_img == "https://alphaelem.s3.amazonaws.com/Backpack_Psyche.jpg" || sat_img == "https://alphaelem.s3.amazonaws.com/Backpack_Neue.jpg" || sat_img == "https://alphaelem.s3.amazonaws.com/Backpack_Organica.jpg") {
                                if(sat_img == "ipfs://QmXm9yBm6MFiPL7Y4g3HyYjDbUxE7gpE68zmiKmhnj1wGH") {
                                    sat_img = "https://www.alphaelementary.io/backpacks/Backpack_Neue.jpg"
                                    console.log("BACKPACK FOUND" + sat_img);
                                    status = "ready"
                                } else if (sat_img == "ipfs://QmeLrcEnBuebFnNKo34JEur7BTfR9qK98BEYXM7fwF1ZfW") {
                                    sat_img = "https://www.alphaelementary.io/backpacks/Backpack_Psyche.jpg"
                                    status = "ready"
                                } else if (sat_img == "ipfs://Qma7RAYq3Vtr2YBP4ieK3FEuD8ux164F5V9EzzjsS9g1tL") {
                                    sat_img = "https://www.alphaelementary.io/backpacks/Backpack_Organica.jpg"
                                    status = "ready"
                                } else if (sat_img == "ipfs://QmZMpEwHZ31K1hETDbT6jgLhtkTzHYo9FnDNnHrWehg1ZM") {
                                    sat_img = "https://www.alphaelementary.io/backpacks/Backpack_Retro.jpg"
                                    status = "ready"
                                } else if (sat_img == "https://alphaelem.s3.amazonaws.com/backpack_fourhouse.png") {
                                    sat_img = "https://www.alphaelementary.io/backpacks/Backpack_fourhouse.png"
                                    status = "ready"
                                } else if (sat_img == "ipfs://QmR23xFPYT5K7PbteatEuJnHXHNurR6Aib1m8FbKZxzHqW") {
                                    // console.log("registered arterfact in metadata")
                                    sat_img = "coming_soon.png"
                                    status = "pre"
                                } else {
                                    status = "revealed"
                                }
                                    // iterate through AE tokens. If we don't own that token, we won't save it to our array (therefore not rendering the artwork)
                                    if(props.tokenIDs.indexOf(String(aeTokenId)) == -1) {
                                        ae_owned = false;
                                    }
                                    // [0] = Show and Tell Token Number
                                    // [1] = Student token ID 
                                    // [2] = artefact ID (for lookup in json)
                                    // [3] = if item is stolen (true/false)
                                    // [4] = filename of image (if unrevealed will be backpack of coming soon image)
                                    // [5] = name of the artefact
                                    // [6] = filename of to be revealedd image
                                    // [7] = status: "pre" "ready" "revealed"
                                    // [8] = if the user holds this AE or not
                                    artefact_data.push([parseInt(props.satTokens[token]), aeTokenId, artefact, item_stolen, sat_img, artefactName, rev_img, status, ae_owned]);
                                    // console.warn(`creating record: ${artefact_data[artefact_data.length-1][4]}`)
                            })
                         }
                    }
                }
            }
            return artefact_data
    }


        async function revealArtefacts(sat_id, artefact_id, inx, img, itmName) {
            console.log("REVEALING ARTEFACT")
            document.getElementById(`${inx}_btn`).innerHTML =`Revealing...`;
            // event.preventDefault();
            // alert(props.tokenIDs);
            // console.log(`https://cw6tu3q794.execute-api.ap-southeast-2.amazonaws.com/revealArtefact?satTokenId=${sat_id}&artefactId=${artefact_id}`)
            fetch(`https://cw6tu3q794.execute-api.ap-southeast-2.amazonaws.com/revealArtefact?satTokenId=${sat_id}&artefactId=${artefact_id}`, {method: "POST"})
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("SUCCESS!")
                        props.notification("Artefact revealed! Please allow a few minutes for updates to display on OpenSea.", "success")
                        setFetched(false)
                        console.log(`https://www.alphaelementary.io/artifacts/500/${img}`)
                        document.getElementById(`${sat_id}`).src=`https://www.alphaelementary.io/artifacts/500/${img}`
                        document.getElementById(`${inx}_name`).innerHTML =`${itmName}`;
                        document.getElementById(`${inx}_btn`).innerHTML =`Ta-da!`;
                        document.getElementById(`${inx}_btn`).disabled = true;
                    })
                    .catch(function(error) {
                        console.log(error)
                        document.getElementById(`${inx}_btn`).innerHTML =`Error, please try again.`;
                    })
                    
        }

    function getSATGall() {
        let galleryList = (
            <>
                <div className='text-black flex flex-row items-center'><img src={btnLoading} className='animate-spin w-6 mr-2 invert' /> Snooping in some backpacks...</div>
            </>
        );

        if(props.satTokens) {
            galleryList = satArray.map(function(token, index) {
            return <>
                    <div className="text-center">
                        <div className='relative'>
                            {/* IMAGE IF UNREVEALED */}
                            { (token[7]) == 'ready' &&
                                <Link to={{pathname: "/show_and_tell/reveal", state: {sat_id: token[0], ae_student: token[1], artefact_id: token[2], artefact_name: token[5], image_name: token[4], rev_img: token[6], stolen: token[3]} }}>
                                <img src={ token[4] } id={token[0]} className='w-fit rounded-lg' /></Link>
                            }

                            {/* Not ready to reveal */}
                            { token[7] == 'pre' &&
                                <img id={token[0]} src={ unrevealedSAT } className='w-fit rounded-lg' />
                            }

                            {/* Revealed */}
                            { token[7] == 'revealed' &&
                                <img src={ `https://www.alphaelementary.io/artifacts/500/${token[6]}` } className='w-fit rounded-lg' />
                            }

                            {/* RENDER MATCHING AE */}
                            { token[1] <= 3000 &&
                                <a href={`https://opensea.io/assets/ethereum/0x692038c37f56e1772ce1d61cd0ff2d4289028458/${token[1]}`} alt="view AE on OpenSea" target="_blank">
                                { props.tokenIDs.includes(token[1]) ? <img src={ require(`../img/ae_students/${token[1]}.png`) } className='w-[26%] rounded-full absolute bottom-[-15px] right-[-15px]' /> : <img src={ require(`../img/ae_students/${token[1]}.png`) } className='w-[26%] rounded-full absolute bottom-[-15px] right-[-15px] grayscale transition-all contrast-50 hover:contrast-100' />}
                            </a>}
                        </div>
                        
                        {/* NAME OF THE ITEM */}
                        <h4 id={index+"_name"} className='text-black py-4'>
                            {token[7] == 'revealed' && token[5]}
                            {token[7] == 'ready' && "Unopened Backpack"}
                            {token[7] == 'pre' && "Something's coming..."}
                        </h4>

                        {/* BUTTONS */}
                        { token[7] == 'revealed' &&
                        <Link id={index+"_btn"} to={`/show_and_tell/${token[0]}`} 
                        className="std_btn block
                         bg-white text-black border-[2px] border-black
                        hover:bg-black hover:text-white active:bg-black
                        text-center rounded-lg cursor-pointer transition-colors hover:no-underline no-underline">
                            Inspect
                        </Link>
                        }

                        { token[7] == 'ready' &&
                        <button id={index+"_btn"} onClick={() => revealArtefacts(token[0],  token[2], index, token[6], token[5])}
                        className="std_btn block
                         bg-white text-black border-[2px] border-black
                        hover:bg-black hover:text-white active:bg-black
                        text-center rounded-lg cursor-pointer transition-colors hover:no-underline no-underline">
                            Quick Reveal
                        </button>
                        }
                    </div>
                </>
            });
        }

        return( <>
            <div className={`grid
                grid-cols-2 gap-8
                md:grid-cols-3 md:gap-8
                lg:grid-cols-4
                `}>
                { galleryList }
            </div>
        </> )

    }
    const [ selectedId, setSelectedId ] = useState(null)

    useEffect(() => {
        console.log(selectedId)
    }, [selectedId])

    function getAlphas() {
        let ae_gallery = (
            <>
                <div className='text-black w-full flex flex-row items-center'><img src={btnLoading} className='animate-spin w-6 mr-2 invert' /> Rounding up the kids...</div>
            </>
        );
        
        if (props.tokenIDs) {
            ae_gallery = props.tokenIDs.map(function (aeToken) {
                return (
                    <motion.div className="text-center" key={aeToken}>
                        <motion.div className="text-center">
                            <motion.img src={ require(`../img/ae_students/${aeToken}.png`)} className='w-fit rounded-lg cursor-pointer' onClick={() => setSelectedId(aeToken)} />
                            <motion.h4 className='text-black py-4'>#{aeToken}</motion.h4>
                            <Link to={`/students/${aeToken}`} className="std_btn block mx-auto
                            bg-white text-black border-[2px] border-black
                            hover:bg-black hover:text-white active:bg-black
                            text-center rounded-lg cursor-pointer transition-colors hover:no-underline no-underline">View Report Card</Link>
                        </motion.div>
                    </motion.div>
                );
            });
            
        }

        return(
            <>
                <div className=''>
                    <h3 className='text-black mb-4'>Your Class</h3>
                    <div className={`grid
                    grid-cols-2 gap-8
                    md:grid-cols-3 md:gap-8
                    lg:grid-cols-4
                    `}>
                        { ae_gallery }
                    </div>
                </div>
                <AnimatePresence>
                    { selectedId && (
                        <motion.div initial={{opaicty: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{ when: "beforeChildren", staggerChildren: 0.3 }}
                          className="text-center fixed top-0 left-0 w-screen h-screen flex bg-black/40 z-50" key={selectedId} onClick={() => setSelectedId(null)}>
                            <motion.div className="text-center block mx-auto my-auto bg-white px-4 pt-4 rounded-2xl"
                            initial={{y: 100, opacity: 1}}
                            animate={{y: 0, opacity: 1}}
                            exit={{opacity: 1, scale: 0.9}}
                            transition={{type: "spring", bounce: 0.1, duration: 0.3}}
                            >
                                <motion.img src={ require(`../img/ae_students/${selectedId}.png`)} className='w-fit rounded-lg cursor-pointer'  />
                                <div className='flex flex-row-reverse place-content-between'>
                                    <motion.h4 className='text-black py-4'>#{selectedId}</motion.h4>
                                    <span className=''><Link to={`/students/${selectedId}`} className="block my-2 std_btn pl-0
                                    bg-white text-black border-[2px] border-black
                                    hover:bg-black hover:text-white active:bg-black
                                    text-center rounded-lg cursor-pointer transition-colors hover:no-underline no-underline">View Report Card</Link></span>
                                </div>
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </>
        );
    }

    function getElemFrens() {
        let ae_frens_gallery;

        // { userStatus && <button disabled={loadingFren} className='text-black disabled:text-slate-300' onClick={()=>{addRemoveAeFren({variables: {input: {wallet: props.userAddress, ae_id: Number(selectedToken)}}})}}>{ userStatus.getUser.elementary_frens.includes(Number(selectedToken)) ? "Remove Fren" : "Add Fren" }</button> }
        
        if (userStatus?.getUser) {
            ae_frens_gallery = userStatus?.getUser.elementary_frens.map(function(aeToken) {
                return (
                    <motion.div className="text-center" key={aeToken}>
                        <motion.div className="text-center">
                            <motion.img src={ require(`../img/ae_students/${aeToken}.png`)} className='w-fit rounded-lg cursor-pointer' onClick={() => setSelectedId(aeToken)} />
                            <motion.h4 className='text-black py-4'>#{aeToken}</motion.h4>
                            <button disabled={loadingFren} onClick={()=>{addRemoveAeFren({variables: {input: {wallet: props.userAddress, ae_id: Number(aeToken)}}})}} className="std_btn_no_y_pad px-4 py-2 mx-auto
                            bg-white text-black border-[2px] border-black disabled:opacity-40
                            hover:bg-black hover:text-white active:bg-black
                            text-center rounded-lg cursor-pointer transition-colors hover:no-underline no-underline">{ userStatus.getUser.elementary_frens.includes(Number(aeToken)) ? "Remove Fren" : "Add Fren" }</button>
                        </motion.div>
                    </motion.div>
                );
            });
            
        }

        return(
            <>
                <div className=''>
                    <h3 className='text-black mb-4'>Alpha Elementary Frens</h3>
                    <div className={`grid
                    grid-cols-4 gap-8
                    md:grid-cols-4 md:gap-4
                    lg:grid-cols-8 lg:gap-8
                    `}>
                        { ae_frens_gallery }
                    </div>
                </div>
                <AnimatePresence>
                    { selectedId && (
                        <motion.div initial={{opaicty: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{ when: "beforeChildren", staggerChildren: 0.3 }}
                          className="text-center fixed top-0 left-0 w-screen h-screen flex bg-black/40 z-50" key={selectedId} onClick={() => setSelectedId(null)}>
                            <motion.div className="text-center block mx-auto my-auto bg-white px-4 pt-4 rounded-2xl"
                            initial={{y: 100, opacity: 1}}
                            animate={{y: 0, opacity: 1}}
                            exit={{opacity: 1, scale: 0.9}}
                            transition={{type: "spring", bounce: 0.1, duration: 0.3}}
                            >
                                <motion.img src={ require(`../img/ae_students/${selectedId}.png`)} className='w-fit rounded-lg cursor-pointer'  />
                                <div className='flex flex-row-reverse place-content-between'>
                                    <motion.h4 className='text-black py-4'>#{selectedId}</motion.h4>
                                    <span className=''><Link to={`/students/${selectedId}`} className="block my-2 std_btn pl-0
                                    bg-white text-black border-[2px] border-black
                                    hover:bg-black hover:text-white active:bg-black
                                    text-center rounded-lg cursor-pointer transition-colors hover:no-underline no-underline">View Report Card</Link></span>
                                </div>
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </>
        );
    }

    function showLoading() {
        if(props.satTokens && satArray == null) {
            return (<div className='text-black flex flex-row items-center'><img src={btnLoading} className='animate-spin w-6 mr-2 invert' /> Snooping in some backpacks...</div>);
        } 
    }

    function message() {
        if(props.aeBal) {
            if (props.aeBal == 0) {
                return("Looks like your Alphas have run off! Probably playing hide and seek.");
            } else if (props.aeBal == 1) {
                return("Thanks for dropping by, looks like your Alpha could use a buddy!");
            } else if (props.aeBal < 6) {
                return("Nice to see the kids hanging out together");
            } else if (props.aeBal > 6) {
                return("That's a nice lookin' class you've got there.");
            }
        }
    }

    function checkPrintWinner() {
        console.log("Checking print status")
        // console.log(String(props.userAddress).toLowerCase())
        // console.log(props.winnerList)
        if(winnerList.indexOf(String(props.userAddress).toLowerCase()) != -1 ) {
            setWinnerStatus(true);
        }
    }


    
    return(<>
            <PageHeader header="Roll Call" subheader={ (!props.isConnected) ?  "Time to take your seats." : message()} />
            <div className="w-full" >
                <div className="container pt-14 pb-20 px-4 md:px-0 mx-auto">
                              {!props.isConnected && <Connect connect={props.connectFunc} />}
                        <div className='' id="students">
                            
                            { (props.isConnected) && 
                            (<>
                                <div className='float-right'>
                                    <Link onClick={() => {window.scrollTo(0,0);}} to="/artclass" className="std_btn mx-auto
                            bg-white text-black border-[2px] border-black
                            hover:bg-black hover:text-white active:bg-black
                            text-center rounded-lg cursor-pointer transition-colors hover:no-underline no-underline">🎨 Art Class</Link>
                                </div>
                                <div className='mb-14'>
                                    { getAlphas() }
                                </div>
                                { userStatus?.getUser.elementary_frens &&
                                <div className='mb-14'>
                                    { getElemFrens() }
                                </div> 
                                }
                            </>)
                            }

                            { props.isConnected &&  
                            <div className=''>
                                <h3 className='text-black mb-14'>Show &amp; Tell Artefacts</h3>
                                    { satArray != null && getSATGall() }
                                    { showLoading()}
                            </div> }
                            
                                { (props.isConnected) && 
                                (<>
                                    <div className='mb-14 mt-10'>
                                        { winnerStatus ? (<>
                                            <div className=''>
                                                <h3 className='text-black mb-4'>Print Competition Round: {props.printRound}</h3>
                                                <div className={`px-8 py-4 bg-green-400/10 text-center rounded-lg border-2 border-green-400`}>
                                                    <h4 className='text-black mb-4 mt-4'>Congratulations! You are a print winner 🎉🎉🎉</h4>
                                                    <p className='text-black'><Link to={`/winner_form`}>Click here</Link> to fill out the print winner form</p>
                                                </div>
                                            </div>
                                    </>) : 
                                    (<>
                                        <div className=''>
                                            <h3 className='text-black mb-4'>Print Competition Round: {props.printRound}</h3>
                                            <div className={`px-8 py-4 bg-slate-400/10 text-center rounded-lg border-2 border-slate-400`}>
                                            <p className='text-black mt-6'>Sorry, you are not a winner this time.</p>
                                            </div>
                                        </div>
                                </>)
                                    }
                                    </div>
                                </>)
                                }
                        </div>
                </div>
            </div>
        </>);
}