import React from 'react';
import MetaMaskIcon from '../img/metamask_logo.svg'

const AddMumbaiButton = () => {

  const addNetwork = async () => {
    try {
      // wasAdded is a boolean. If added it returns true
      const wasAdded = await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0x13881', // Mumbai Testnet Chain ID
          chainName: 'Mumbai',
          nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18
          },
          rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
          blockExplorerUrls: ['https://mumbai.polygonscan.com/']
        }]
      });

      if (wasAdded) {
        console.log('Mumbai Testnet was added');
      } else {
        console.log('Mumbai Testnet was not added');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <button onClick={addNetwork} className={`rounded-md font-teko text-[1.1rem] tracking-wider flex flex-row mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-600 hover:bg-blue-700 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`}>
      <img src={MetaMaskIcon} className='mr-2 h-5' />
      Add Mumbai Network
    </button>
  );
};

export default AddMumbaiButton;
