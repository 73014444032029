import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, useLazyQuery, useMutation, gql} from '@apollo/client'
import { offsetLimitPagination } from '@apollo/client/utilities';
import { Helmet } from 'react-helmet';
import { saveToLocalStorage, loadFromLocalStorage } from './components/localStorageUtils';

import Navbar from './nav-v2';
import AcademyHome from './academy_home';
import ElementaryHome from './elementary_home';
import Footer from './footer';
import WinnerForm from './poster_winner';
import QueryCollection from './query_collection';
import SATReveal from './show_and_tell/show_and_tell_reveal';
import SAT from './show_and_tell/show_and_tell';
import PrevRev from './preview-reveal';
import Elements from './elements';
import Houses from './houses';
import Pixelplayground from './pixelplayground';
import Artclass from './artclass';
import Gallery from './gallery';
import JoinWhitelist from './allowlist';
import Whitelist from './whitelist';
import Brand from './brand';

// EXPERIMENTS 
import Experiments from './experiments/experiments';
import RockPaperScissors from './experiments/rock_paper_scissors';
import RPSDashboard from './experiments/rps_dashboard';
import RPSGameScreen from './experiments/rps_game';

// AE Gallery
import Student from './students/student';
import Artefacts from './students/artefacts';
import Students from './students/students';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {ethers, errors, providers} from 'ethers';
import Web3Modal from 'web3modal';
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';

import AlphaElem_ABI from './web3/alpha_elementary.json';
import ShowAndTell_ABI from './web3/showandtell.json';
import RPS_ABI from './web3/rps.json';
import ReactGA from 'react-ga';

const keccak256 = require('keccak256');
const { MerkleTree } = require('merkletreejs');

ReactGA.initialize('UA-217208835-1');
ReactGA.pageview(window.location.pathname + window.location.search);

let whitelist = [];
let xileWhiteList = [];
// let xileList = ['0xA686e42124B746159552eA4868a5029C7a498eC1','0x16C0Bb426796d6465cb123Bd512a8A8cBf280aFc'];

// // let xileList_test = ['', '']; 
// let whitelist_test = ['0x16C0Bb426796d6465cb123Bd512a8A8cBf280aFc', '0x8c389c8aA3b273cE7859DaEF45c0f48F9c7E247D', ];

// // let proofWL
// // const leafNodes = wList.map(addr => keccak256(addr));
// // const whitelist = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

// const xileLeafNodes = xileList.map(addr => keccak256(addr));
// const xileWhiteList = new MerkleTree(xileLeafNodes, keccak256, { sortPairs: true });

// Live list
// const xileLeafNodes = xileList_test.map(addr => keccak256(addr));
// const xileWhiteList = new MerkleTree(xileLeafNodes, keccak256, { sortPairs: true });

// // Live list
// const WLTestLeafNodes = whitelist_test.map(addr => keccak256(addr));
// const whitelist_merkle = new MerkleTree(WLTestLeafNodes, keccak256, { sortPairs: true });

// FOR MERKLE ROOT TESTING
// const rootWL = whitelist.getRoot().toString('hex');
// console.log("presale root:")
// console.log(rootWL);

// // // TESTING
// console.log(xileWhiteList.toString())
// const xl = xileWhiteList.getRoot().toString('hex');

// console.log("Limitless root:")
// console.log(xl);

// const leaf = keccak256("0xA686e42124B746159552eA4868a5029C7a498eC1");
// let proofWL= xileWhiteList.getProof(leaf);
// console.log("Limitless leaf:")
// console.log(xileWhiteList.getProof(leaf));

// FOR MERKLE ROOT 
// const rootWL = whitelist.getRoot().toString('hex');
// console.log("Printing root:")
// console.log(rootWL);

// const xl = xileWhiteList.getRoot().toString('hex');
// console.log("Printing root:")
// console.log(xl);

const GET_USER = gql`
    query GetUser($input: String!) {
        getUser(wallet: $input) {
            twitter_id
            approved
            status
            elementary_token_ids
            elementary_frens
            discord_username
            pfp
        }
    }
`

const UPDATE_PFP = gql`
  mutation UpdateUser($input: UpdateUserInput) {
    updateUser(input: $input) {
      wallet
      pfp
    }
  }
`

function App() {
  
  const [authenticatedUser, setAuthenticatedUser] = useState(null);

  // MANAGE USER ACCOUNTS
  const [checkUserStatus, {loading: userStatusLoading, error: userStatusError, data: userStatusResult}] = useLazyQuery(GET_USER, {
    notifyOnNetworkStatusChange:true,
    onCompleted: data => {
      console.log("Wallets fetched")
      setAuthenticatedUser(data)
      let tmpLocalStorage = {...data, wallet: userAddress}
      // save to local storage
      saveToLocalStorage("getUser", tmpLocalStorage, 15);
    }
  });

  const [updateUserPfp, {loading: pfpLoading, error: pfpError, data: pfpResult}] = useMutation(UPDATE_PFP, {
    notifyOnNetworkStatusChange:true,
    onCompleted: data => {
      // update user object if successful
      let tmpUserObj = {...authenticatedUser, getUser: {...authenticatedUser.getUser, pfp: data.updateUser.pfp}, wallet: userAddress};
      console.log(tmpUserObj)
      saveToLocalStorage("getUser", tmpUserObj, 15); // save to local storage
      setAuthenticatedUser(tmpUserObj) // update the state, pushing through to site
    }
  });
  // END USER ACCOUNTS CODE

  useEffect(()=>{
    console.log(authenticatedUser)
  }, [authenticatedUser])

  // STATES
  // status: HYPE // WHITELIST_CHECK (before presale/check whitelist) // PRESALE // SALE // CLOSED
  const launchStatus = 'SALE';
  const artworkRevealed = true;
  let contractAddress;
  let contractSATAddress;
  
  const testing = false;
  
  if (testing == false) {
    contractAddress = '0x692038c37f56e1772ce1d61cd0ff2d4289028458'; // mainnet AE
    contractSATAddress = '0xb6b4ca6fe80008c429b713c26d6dcebc4ac32893'; // mainnet SAT
  } else {
    contractAddress = '0x32a70ec02d444dabf55b2e9a3eb46b4ad2758cbe'; // rinkeby AE
    contractSATAddress = '0x9A87A496bA75273620d199d7b0B6724A10449ac8'; // rinkeby SAT
  }

  const rps_contract_address = '0x43786B5960c82A802503E5AB2bF7867Cd9EDA3cC';

  let openSeaURL = '';
  let sat_openSeaURL = ''
  if(testing == true) {
    openSeaURL = `https://testnets.opensea.io/assets/${contractAddress}/`;
    sat_openSeaURL = `https://testnets.opensea.io/assets/${contractSATAddress}/`;
  } else {
    openSeaURL = `https://opensea.io/assets/${contractAddress}/`;
    sat_openSeaURL = `https://opensea.io/assets/${contractSATAddress}/`;
  }
  // const etherscanURL = "https://api.etherscan.io/api?module=transaction&action=gettxreceiptstatus&txhash=";
  // const etherscanURLAPI = `&apikey=${process.env.ETHERSCAN_API}`;

  // FOR ACCESSING AND STORING USER ACCOUNT DATA
  
  const [errorMessage, setErrorMessage] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isMod, setIsMod] = useState(false);
  const [isHolder, setIsHolder] = useState();
  const [aeBal, setAeBal] = useState(null);
  const [satBal, setSatBal] = useState(null);
  const [currentNetwork, setCurrentNetowk] = useState(null);

  // RPS Vars
  const [rpsTokens, setRpsTokens] = useState(null);
  const [rpsTokenIds, setRpsTokenIds] = useState(null);
  const [rpsGames, setRpsGames] = useState(null);
  const [rpsGamePlayers, setRpsGamePlayers] = useState(null);
  const [rpsMatchPlayerData, setRpsMatchPlayerData] = useState(null);
  const [rpsLoading, setRpsLoading] = useState(false);
  const [enterMatchLoading, setEnterMatchLoading] = useState(false);
  const [rpsMatchSeed, setRpsMatchSeed] = useState(null)
  const [leaderboardWallets, setLeaderboardWallets] = useState([])
  
  const resetMatchState = () => {
    setRpsMatchSeed(null)
    setRpsMatchPlayerData(null)
    setRpsLoading(false)
  }

  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [contract, setContract] = useState(null);
  const [SATContract, setSATContract] = useState(false);
  const [RPSContract, setRPSContract] = useState(false);
  const [contractConnected, setContractConnected] = useState(false);
  
  // Whitelists
  const [whitelistStatus, setWhitelistStatus] = useState(null);
  const [sessionState, setSessionState] = useState('disconnected'); // disconnected // conn_authFailed // conn_xile_mint // conn_presale_mint// conn_sale_mint // conn_transProcessing


  // CONTRACT DETAILS
  const [maxNFTs, setmaxNFTs] = useState(null);
  const [currentMinted, setCurrentMinted] = useState(null);
  const [presaleStatus, setPresaleStatus] = useState(null);
  const [saleStatus, setSaleStatus] = useState(null);
  const [mintPrice, setMintPrice] = useState(null);
  // const [xilePrice, setXilePrice] = useState(null);

  const [mintQuant, setMintQuant] = useState(1);
  // const [ethURL, setEthURL] = useState(null);
  const [isMounted, setIsMounted] = useState(false); 
  const [userTokens, setUserTokens] = useState(null);
  const [userSATTokens, setUserSATTokens] = useState(null);
  const [userTokensIDs, setUserTokensIDs] = useState(null);

  // Wallet connect manager states
  const [web3Modal, setWeb3Modal] = useState(null)
  const [userAddress, setUserAddress] = useState("")
  const [loggedIn, setLoggedIn] = useState(false)

  const incTkns = () => {
    if(mintQuant < 4) {
      setMintQuant(mintQuant+1);
      // console.log(mintQuant)
    }
  }
  
  const decTkns = () => {
    if(mintQuant > 1) {
      setMintQuant(mintQuant-1);
      // console.log(mintQuant);
    }
  }


  // New Connection Method
  // on connection set:
  // Provider (setProvider / provider)
  // Default Account (setDefaultAccount / defaultAccount)
  // Signer (setSigner / signer)
  // Contract (setContract / contract)
  // SATContract (setSATContract / SATContract)
  // is contract initialised? true (setContractConnected / contractConnected)

  useEffect(() => {
    // const authToken = Cookies.get('fauna-auth');
    // if(authToken) {
    //   setLoggedIn(true)
    // }
    
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: "6154f9fb3d384e7b89e70f710ccd309d",
        }
      },
      coinbasewallet: {
        package: CoinbaseWalletSDK, 
        options: {
          appName: "Alpha Elementary NFT",
          infuraId: "6154f9fb3d384e7b89e70f710ccd309d",
        }
      },
    };

    const newWeb3Modal = new Web3Modal({
      cacheProvider: true, // very important
      network: "mainnet",
      providerOptions,
      theme: {
        background: "rgb(0,0,0)",
        main: "rgb(255,255,255)",
        secondary: "rgb(255,255,255)",
        hover: "rgb(25,25,25)",
        border: "rgb(25,25,25)",
      },
    });

    setWeb3Modal(newWeb3Modal)
  }, [])

  // connect automatically without pop-up if user is already connected
  useEffect(() => {
    if(localStorage.getItem("account") != null) {
      if(window.location.pathname != '/show_and_tell/reveal') {
        connectWalletHandler();
      }
    } else if(web3Modal && web3Modal.cacheProvider) {
      connectWalletHandler();
    }
  }, [web3Modal])

  useEffect(() => {
    if(userAddress != "") {
      checkisAdmin();
    }
  }, [userAddress])
  
  useEffect(() => {
    if(aeBal > 0) {
      setIsHolder(true);
    } else {
      setIsHolder(false);
    }
  }, [aeBal])

  async function connectWalletHandler() {
    console.log("Connection request received");

    let providerTemp;

    if(web3Modal) {
      providerTemp = await web3Modal.connect();

      // as per https://fauna.com/blog/authenticating-users-with-a-blockchain-wallet-and-fauna
      addListners(providerTemp);

      const ethersProvider = new providers.Web3Provider(providerTemp);
      const signer_tmp = ethersProvider.getSigner();
      const userAddressTemp = await signer_tmp.getAddress();
      
      localStorage.setItem("account", userAddressTemp)
      const network_tmp = await ethersProvider.getNetwork()

      setProvider(ethersProvider);
      setSigner(signer_tmp);
      setUserAddress(userAddressTemp);
      setCurrentNetowk(network_tmp.chainId);

      connectToContracts(signer_tmp, network_tmp.chainId);

      if(authenticatedUser === null) {
        const localUser = loadFromLocalStorage("getUser"); // get any local use data

        // see if that local user wallet matches
        if(localUser && localUser.wallet === userAddressTemp) {
          // existing user is stored locally
          setAuthenticatedUser(localUser); // update the user state
        } else if(userStatusLoading == false && !userStatusResult) {
          console.log("Grabbing user record from the server");
          checkUserStatus({variables: {"input": userAddressTemp}})
        }
      }

      if(network_tmp.chainId != 1) {
        toast.warn("Warning! Your wallet is not connected to Mainnet.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  // handle network or account changing
  async function addListners(web3ModalProvider) {
    web3ModalProvider.on("accountsChanged", (accounts) => {
      window.location.reload()
    });

    // when chainID changes
    web3ModalProvider.on("chainChanged", (chainId) => {
      window.location.reload()
    })
  }

  async function clearProvider() {
    web3Modal.clearCachedProvider();
    localStorage.removeItem("account");
    window.location.reload()
    console.log("provider cleared")
  }

  // Connect to contracts
  function connectToContracts(acc_signer, chainId) {
    // Alpha Elementary Smart Contract
    let tempContract;
    if(chainId == 1) {
      // If chainId == 1, user is connected to mainnet
      tempContract = new ethers.Contract(contractAddress, AlphaElem_ABI, acc_signer);
      setContract(tempContract);
    } else {
      // otherwise they are on a different network. Use an second provider to connect to mainnet
      const infuraProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`)
      tempContract = new ethers.Contract(contractAddress, AlphaElem_ABI, infuraProvider);
      setContract(tempContract);
    }
    
    // Show And Tell Smart Contract
    let tempSATContract = new ethers.Contract(contractSATAddress, ShowAndTell_ABI, acc_signer);
    setSATContract(tempSATContract);
    
    let tempRPSContract = new ethers.Contract(rps_contract_address, RPS_ABI, acc_signer);
    setRPSContract(tempRPSContract);
    // console.log(tempContract)

    setContractConnected(true)
    console.log("Connelcted")
  }

  function checkisAdmin() {
    if(String(userAddress).toLowerCase() == '0xaD04907941f84d7f63Bd51e8066Ac08847895A2E'.toLowerCase()) {
        console.log("you are admin")
        setIsAdmin(true);
    }
    if(String(userAddress).toLowerCase() == '0x56817FbB6F43C6d3C213C27516cA654C0E7756eE'.toLowerCase() || String(userAddress).toLowerCase() == '0xCeA38b3bE184d05B0a6619B57E7E65fef17Fc2e7'.toLowerCase() || String(userAddress).toLowerCase() == '0x8c389c8aA3b273cE7859DaEF45c0f48F9c7E247D'.toLowerCase()) {
        setIsMod(true);
    }
}

async function checkisHolder() {
  if(contract) {
    await contract.balanceOf(userAddress)
    .then(function(result) {
      setAeBal(parseInt(String(result)))
    });
  }
  if(SATContract) {
    await SATContract.balanceOf(userAddress)
    .then(function(result) {
      setSatBal(parseInt(String(result)))
    });
  }
}

useEffect(() => {
  if(isMounted) {
    checkisHolder();
    getTokens();
  } else {
    // useEffect always fires on mount, this is to stop getValues from running on mount
    setIsMounted(true);
  }
}, [contract])


  // async function getValues() {
    
  //   setCurrentNetowk(network_tmp.chainId);
  // }

  // WALLET UPDATE: COMMENTING THE BELOW OUT
  // WALLET UPDATE: COMMENTING THE BELOW OUT

  // const provider_wc = new WalletConnectProvider({
  //   infuraId: "ac8a3d0b30984958871fa8b829019a77",
  // });
  
  // async function connectWalletHandler() {
  //   // close wallet selector window
  //   toggleWalletManager();

  //   await provider_wc.enable()
  //   .then(function() {
  //   accountChangedHandler(provider_wc.wc.accounts[0]);
  //   setConnButtonText('Wallet Connected');
  //   });
  // }

  // async function connectMetaMask() {
  //   // close wallet selector window
  //   toggleWalletManager();

  //   if (window.ethereum) {
  //         window.ethereum.request({method: 'eth_requestAccounts'})
  //         .then(result => {
  //             accountChangedHandler(result[0]);
  //             setConnButtonText('Wallet Connected');
  //         })
  //     } else {
  //         alert("Please install MetaMask, or use the WalletConnect option to connect with your provider.");
  //     }
  // }

  // function accountChangedHandler(newAccount) {
  //     setDefaultAccount(newAccount);
  //     updateEthers();
  // }


  // function updateEthers() {
  //   let tempProvider;

  //   if(provider_wc.connected) {
  //     tempProvider = new ethers.providers.Web3Provider(provider_wc);
  //   } else {
  //     tempProvider = new ethers.providers.Web3Provider(window.ethereum);
  //   }
  //   setProvider(tempProvider);

  //   let tempSigner = tempProvider.getSigner();
  //   setSigner(tempSigner);

  //   let tempContract = new ethers.Contract(contractAddress, AlphaElem_ABI, tempSigner);
  //   setContract(tempContract);

  //   let tempSATContract = new ethers.Contract(contractSATAddress, ShowAndTell_ABI, tempSigner);
  //   setSATContract(tempSATContract);
  //   setContractConnected(true);

  //   console.log("CONNECTED");
  // }

  // useEffect(() => {
  //   if(isMounted) {
  //     console.log("checking current minted")
  //     if (parseInt(currentMinted) > 3000) {
  //       setSessionState('sale_closed');
  //     }
  //   } else {
  //     // useEffect always fires on mount, this is to stop getValues from running on mount
  //   }
  // }, [currentMinted])

  // async function disconnect_wall() {
  //     provider_wc.disconnect();
  //     window.location.reload();
  // }

  // function toggleWalletManager() {
  //   setWalletModal(!walletModal);

  // WALLET UPDATE ENDS
  // WALLET UPDATE ENDS
  // WALLET UPDATE ENDS
  // WALLET UPDATE ENDS

  // GET BASIC DATA FROM SMART CONTRACT
  // async function getValues() {
  //   let network_tmp = await provider.getNetwork(); 
  //   setCurrentNetowk(network_tmp.chainId);

  //   let maxSupply = await contract.MAX_NFT();
  //   setmaxNFTs(maxSupply);

  //   let currentMinted = await contract.totalSupply();
  //   setCurrentMinted(String(currentMinted));

  //   let mintPrc = await contract.PRICE();
  //   setMintPrice(ethers.utils.formatEther(mintPrc));

  //   let tx = await contract.balanceOf(userAddress)
  //   setUserTokens(String(tx));

  //   let presale_temp = await contract.preLiveToggle();
  //   setPresaleStatus(presale_temp);
  //   console.log(presale_temp)

  //   let sale_temp = await contract.saleLiveToggle();
  //   setSaleStatus(sale_temp);

  //   // let xileprc_temp = await contract.XILE_PRICE();
  //   // setXilePrice(ethers.utils.formatEther(xileprc_temp))
  // }


  // GET USER TOKENS
  async function getTokens() {
    // console.log("fetching user tokens...");

    if(userAddress == null) {
      toast.warn("Please connect your wallet first!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    } else {
        let tx = await contract.getOwnersTokens(userAddress)
        .catch(function(error) {
          console.log(error.message)
          setIsHolder(false)
        });
        let tempIDs = String(tx).split(',')
        if (tempIDs[0] != 'undefined') {
          // convert array of strings to ints
          tempIDs = tempIDs.map(Number);
          setUserTokensIDs(tempIDs);
        }
    }
  }

  // GET USER TOKENS
  async function getSATTokens() {
  
    if(userAddress == null) {
      toast.warn("Please connect your wallet first!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    } else {
      console.log("fetching SAT Tokens...");
        let tx = await SATContract.getOwnersTokens(userAddress)
        // let tx = await SATContract.owner()
        .catch(function(error) {
          console.log(error.message)
          toast.error(error.error.message.replace('SAT execution reverted: ', ''), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        setUserSATTokens(String(tx).split(','));
        // setUserSATTokens(tx);
    }
  }

  function checkWhitelist() {
    // hold the result of whitelist
    let status;

    let leaf = keccak256(userAddress);
    let rootWL_check = whitelist.getRoot().toString('hex');
    let proofWL= whitelist.getProof(leaf);
  
    let xlRoot = xileWhiteList.getRoot().toString('hex');
    let xlProof = xileWhiteList.getProof(leaf);
  
    let wlst = whitelist.verify(proofWL, leaf, rootWL_check);
    let xlist = xileWhiteList.verify(xlProof, leaf, xlRoot);
      
    if (launchStatus == 'PRESALE') {
      if (xlist) {
        status = 'xile';
        setSessionState('conn_xile_mint');
      } else if (wlst) {
        status = 'presale';
        setSessionState('conn_presale_mint');
      } else {
        status = 'none';
        setSessionState('conn_authFailed');
      }
    } else if (launchStatus == 'SALE') {
        console.log("sale is open")
        status = 'conn_sale_mint'
        setSessionState('conn_sale_mint');
    }

    setWhitelistStatus(status);
  }

  async function mintArtwork(event) {
    event.preventDefault(); 

    // let mintPrice = 0.07;

    // let mintPC = String((mintPrice * mintQuant).toFixed(2));
    // console.log("Mint price: " + mintPC);

    // let overrides = {
    //     // To convert Ether to Wei:
    //     value: ethers.utils.parseEther(mintPC) // ether in this case MUST be a string
    // };

    // if(userAddress == null) {
    //     alert("Please connect your wallet first!");
    // } else {
    //     let tx = await contract.aeMint(mintQuant, overrides)
    //     .then(function(result){
    //         console.log(result.hash);
    //         etherscanURL(result.hash);
    //     })
    //     .catch(function(error) {
    //         // console.warn("Some kind of error")
    //         // console.warn(error)
    //         failureCallback(error);
    //     });
    // }
  }

  async function etherscanURL(hash) {
    // setSessionState('conn_transProcessing');
    // if(testing == true) {
    //   setEthURL(`https://rinkeby.etherscan.io/tx/${hash}`);
    // } else {
    //   setEthURL(`https://etherscan.io/tx/${hash}`);
    // }
  }

  async function mintPreArtwork(event) {
    event.preventDefault(); 

    // let mintPrice = 0.07;
    
    // let leaf = keccak256(userAddress.toString());
    // let proofWL = whitelist.getHexProof(leaf);
    
    // let mintPC = String((mintPrice * mintQuant).toFixed(2));

    // let overrides = {
    //     // To convert Ether to Wei:
    //     value: ethers.utils.parseEther(mintPC) // ether in this case MUST be a string
    // };


    // if(userAddress == null) {
    //     alert("Please connect your wallet first!");
    // } else {
    //     let tx = await contract.preMint(mintQuant, proofWL, overrides)
    //     .then(function(result){
    //         console.log(result.hash);
    //         // setResult(`https://rinkeby.etherscan.io/tx/${result.hash}`);
    //         // getRecentToken();
    //         etherscanURL(result.hash);
    //     })
    //     .catch(function(error) {
    //         // console.warn("Some kind of error")
    //         // console.warn(error)
    //         failureCallback(error);
    //     });
    // }
  }

  function checkOwner(tkn) {
    // let tx = await contract.preMint(mintQuant, proofWL, overrides)
    //     .then(function(result){
    //         console.log(result.hash);
    //         // setResult(`https://rinkeby.etherscan.io/tx/${result.hash}`);
    //         // getRecentToken();
    //         etherscanURL(result.hash);
    //     })
    return false;
  }

  // async function mintRPSToken(mintNum) {
  //   // event.preventDefault(); 
  //   // console.log(`num to mint: ${mintNum}`)

  //   if(userAddress == null) {
  //       alert("Please connect your wallet first!");
  //   } else {
  //       let tx = await RPSContract.saleMint(mintNum)
  //       .then(function(result) {
  //           console.log(result.hash);
  //       })
  //       .catch(function(error) {
  //           failureCallback(error);
  //       });
  //   }
  // }

  async function mintRPSToken(mintNum) {
    if(userAddress == null) { return alert("Please connect your wallet first!"); }

    setRpsLoading(true)

    try {
      const txResponse = await RPSContract.saleMint(mintNum);
      console.log('Transaction hash:', txResponse.hash);
      const receipt = await txResponse.wait();
      if (receipt.status === 1) {
          // Transaction was successful
          console.log('Transaction was successful!');
          // Call your function here
          // yourFunction();
          toastNotification("Transaction complete!", "success")
          setRpsLoading(false)
          getRPSTokens()
        } else {
          // Transaction failed
          setRpsLoading(false)
          console.log('Transaction failed.');
        }
      } catch (error) {
        setRpsLoading(false)
        console.log('Transaction error:', error);
        // failureCallback(error);
    }
  }

  async function playerEnterMatch(tokenID, matchID) {
    if(userAddress == null) { return alert("Please connect your wallet first!"); }

    setEnterMatchLoading(true)
    try {
      const txResponse = await RPSContract.playerEnterMatch(tokenID, matchID)
      let receipt = await txResponse.wait();
      if (receipt.status === 1) {
        setEnterMatchLoading(false);

        // refresh match data
        getActiveGames()
      } else {
        console.log("there was an error processing your request")
        setEnterMatchLoading(false);
      }
    } catch (error) {
      failureCallback(error);
      setEnterMatchLoading(false)
    }
  }

  async function playerLeaveMatch(tokenID, matchID) {
    if(userAddress == null) { return alert("Please connect your wallet first!"); }

    setEnterMatchLoading(true)
    try {
      let txResponse = await RPSContract.removePlayerFromMatch(tokenID, matchID)
      const receipt = await txResponse.wait();
      if (receipt.status === 1) {
        setEnterMatchLoading(false);
        
        // refresh match data
        getActiveGames()
      } else {
        console.log("there was an error processing your request")
        setEnterMatchLoading(false);
      }
    } catch (error) {
      failureCallback(error);
      setEnterMatchLoading(false)
    }
  }

  async function getCurrentMatchPlayerData(matchID) {
    console.log("FETCHING MATCH PLAYER DATA")
    // Can remove this code and just keep the code inside "ELSE" after match 8
    if(matchID == 8 || matchID == 9) {
      // FOR MATCH 8 ONLY TO INCLUDE ALL
        let totalSupply = await RPSContract.totalSupply();
        let tokenDataPromises = [];
        for(let i = 7; i < 21; i++) {
          tokenDataPromises.push(RPSContract.playerTokenData(i));
        }
        Promise.all(tokenDataPromises)
          .then(tmpPlayerArray => {
            // All data has been fetched at this point
            // tmpPlayerArray is an array of token data
            setRpsMatchPlayerData(tmpPlayerArray)
        })
        .catch((error) => {
          // An error occurred while fetching the data
          console.log("Error getting match player data");
          console.log(error.message)
          setRpsMatchPlayerData([])
    });  
  } else {
      let txResponse = await RPSContract.getMatchPlayerData(matchID)
      .then((data) => {
        // console.log("SUCCESS, match player data retrieved")
        // console.log(data)
        setRpsMatchPlayerData(data)
      })
      .catch((error) => {
        console.log("Error getting match player data");
        console.log(error.message)
        setRpsMatchPlayerData([])
      })   
    }
  }

  async function getWalletsOfPlayers(tokenArray) {
    try {
          let txResponse = await RPSContract.returnAddressesOfHolder(tokenArray);
          return txResponse;
      } catch (error) {
          console.log(error.message);
          return null; // or throw an error if you prefer
      }   
  }

  async function getSeedForMatch(matchId) {
    try {
          let txResponse = await RPSContract.getMatchSeed(matchId);
          setRpsMatchSeed(txResponse.toNumber());
      } catch (error) { 
          console.log(error.message);
          console.log("⛔️ Match seed not found")
          setRpsMatchSeed(null)
          // return null; // or throw an error if you prefer
      }   
  }

  // Giving up on this for now
  // async function getLeaderboardWallets(tokenIds) {
  //   try {
  //     let txResponse = await RPSContract.returnAddressesOfHolder(tokenIds);
  //     setLeaderboardWallets(txResponse);
  //   } catch (error) { 
  //       console.log(error.message);
  //       // return null; // or throw an error if you prefer
  //   }
  // }
  function updatePfp(pfpId) {
    console.log(pfpId)
    if(pfpId > 0 && pfpId < 3001) {
      console.log("Attempting to update PFP...")
      updateUserPfp({variables: {"input": {"wallet": userAddress, "pfp": pfpId}}})
    }
  }


  async function createNewMatch(numMatches, maxPlayers, matchTimes, rewardMatch, rewardAmount) {
    // event.preventDefault(); 
    console.log("I've been clicked")
    console.log(`creating new match with following data: numMatches: ${numMatches}, maxPlayers: ${maxPlayers}, matchTimes: ${matchTimes}, rewardMatch: ${rewardMatch}, rewardAmount: ${rewardAmount}`);

    if(userAddress == null) {
        alert("Please connect your wallet first!");
    } else {
        let tx = await RPSContract.createMatch(maxPlayers, matchTimes, rewardMatch, rewardAmount)
        .then(function(result) {
            console.log(`https://mumbai.polygonscan.com/tx/${result.hash}`);
        })
        .catch(function(error) {
            failureCallback(error);
        });
    }
  }

  async function getRPSTokens() {
    if(userAddress) {
      let tkns = await RPSContract.getWalletTokenData(userAddress)
      .then(function(data) {
        // console.log(data)
        // console.log((data[0].tokenID.toNumber()))
        setRpsTokens(data);
        let tmpIds = data.map(item => item.tokenID.toNumber());
        setRpsTokenIds(tmpIds)
      })
    }
  }

  async function updatePlayers(matchId, playerData) {
      let update = await RPSContract.updateGameData(matchId, playerData.playerIDs, playerData.wins, playerData.kills, playerData.losses, playerData.maxSpeed)
      .then(function(data) {
        console.log(data)
      })
  }

  async function awardWinner(matchId, tokenId) {
    console.log(`${matchId}, token: ${tokenId}`)
      let update = await RPSContract.awardWinner(matchId, tokenId)
      .then(function(data) {
        console.log(data)
      })
  }

  async function getActiveGames() {
    if(RPSContract) {
      // console.log("LOOKING FOR EVENTS")
      const matches = await RPSContract.getAllMatches();

      let tmp = [];
      let tmp_match_players = {};
      for(let match of matches) {
        let tmpGameObj = {
          matchId: match.matchId.toNumber(),
          matchOpen: match.matchOpen,
          matchSeedGenerated: !ethers.BigNumber.from(match.matchSeed).isZero(),
          maxPlayers: match.maxPlayers.toNumber(),
          matchTime: match.matchTime.toNumber(),
          rewardMatch: match.rewardMatch,
        }

        if(match.rewardMatch) {
          tmpGameObj.rewardPaid = match.rewardPaid;
          let weiAmount = ethers.BigNumber.from(match.rewardAmount);
          tmpGameObj.rewardAmount = ethers.utils.formatEther(weiAmount);
        }

        tmp.push(tmpGameObj)
        
        const specificMatch = await RPSContract.getMatch(match.matchId.toNumber());
        let match_players = [];
        for(let player of specificMatch[1]) {
          match_players.push(player.toNumber())
        }
        if(match_players.length > 0) {
          tmp_match_players[specificMatch[0].matchId.toNumber()] = match_players;
        }
      }
      setRpsGames(tmp)
      setRpsGamePlayers(tmp_match_players);
    }
  }

  async function generateMatchSeed(matchId) {
    console.log(matchId)
    try {
      const overrides = {
          gasLimit: 350000, 
      };
      const txResponse = await RPSContract.generateMatchSeed(matchId, overrides);
      let res = txResponse.wait();
      if(res.status === 1) {
        toastNotification(`Seed successfully generated for match ${matchId}`, "success")
        getActiveGames() // refetch match data
      } else {
        toastNotification(`There was an error`, "error")
      }
    } catch(error) {
        console.log(error);
        toastNotification(`There was an error: ${error}`, "error")
    }
  }

  function failureCallback(e) {
      // console.warn(e.error);
      let msg = String(e.error.message);
      setErrorMessage(msg);
      toast.error(msg.replace('execution reverted: ', ''), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  }

  function toastNotification(message, state) {
      if(state == "success") {
        toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (state == "error") {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }
  }

  return (
    <Router>
      <Helmet>
        <title>Alpha Academy</title>
          <meta name='description' content='Alpha Elementary is an NFT project that features some of the best art around, created by @SaJo and @HogChop' />
          <meta name='keywords' content='An NFT and Technology art project on Ethereum Blockchain' />
      </Helmet>
    <div>
      {/* For notifications */}
      <ToastContainer />

      {/* for the hunt, it will decide if you should get a clue or not on page load */}
      {/* <EggClue /> */}

      { window.location.pathname != '/' && (<Navbar 
        isConnected={contractConnected}
        connectFunc={connectWalletHandler}
        disconnect={clearProvider}
        projectStatus={launchStatus} 
        userAddress={userAddress}
        isHolder={isHolder}
        authedUser={authenticatedUser}
        userStatusLoading={userStatusLoading}
        />) }
      
      <Routes>

      <Route exact={true} path="/" element={<AcademyHome />} />

      <Route exac={true} path="/elementary" element={<ElementaryHome 
          isConnected={contractConnected}
          connectFunc={connectWalletHandler} 
          projectStatus={launchStatus} 
          userAddress={userAddress}
          maxNFTs={maxNFTs}
          currentMinted={currentMinted}
          mintPrice={mintPrice}
          whitelistStatus={whitelistStatus}
          />} />

        <Route exact={true} path="/show_and_tell/" element={<SAT 
          isConnected={contractConnected}
          connectFunc={connectWalletHandler} 
          userAddress={userAddress}
          fetchUserTokens={getTokens}
          tokenIDs={userTokensIDs}
          userTokens={userTokens}
          openSeaURL={openSeaURL}
          sat_openSeaURL={sat_openSeaURL}
          getTokens={getSATTokens}
          satTokens={userSATTokens}
          notification={toastNotification}
          />} />

        <Route path="/show_and_tell/reveal" element={<SATReveal 
          isConnected={contractConnected}
          userAddress={userAddress}
          fetchUserTokens={getTokens}
          tokenIDs={userTokensIDs}
          userTokens={userTokens}
          sat_openSeaURL={sat_openSeaURL}
          notification={toastNotification}
          />} />

        <Route path="/students" element={<Students 
          isConnected={contractConnected}
          connectFunc={connectWalletHandler} 
          userAddress={userAddress}
          fetchUserTokens={getTokens}
          tokenIDs={userTokensIDs}
          userTokens={userTokens}
          openSeaURL={openSeaURL}
          sat_openSeaURL={sat_openSeaURL}
          getTokens={getSATTokens}
          satTokens={userSATTokens}
          notification={toastNotification}
          aeBal={aeBal}
          // setUserStatus={setUserStatus}
          // userStatus={userStatus}
          />} />

        <Route path="/students/:id" element={<Student userAddress={userAddress} notification={toastNotification} isConnected={contractConnected} getTokens={getTokens} tokenIDs={userTokensIDs}/>} />
        <Route path="/show_and_tell/:id" element={<Artefacts userAddress={userAddress} notification={toastNotification} isAdmin={isAdmin} isConnected={contractConnected} getTokens={getSATTokens} satTokens={userSATTokens} isMod={isMod}/>} />

        <Route path="/elements" element={<Elements userAddress={userAddress} notification={toastNotification} connectFunc={connectWalletHandler} isConnected={contractConnected} />} />
        {/* <Route path="/catalyst" element={<Catalyst userAddress={userAddress} notification={toastNotification} connectFunc={connectWalletHandler} isConnected={contractConnected} />} /> */}

        <Route path="/houses" element={<Houses userAddress={userAddress} notification={toastNotification} connectFunc={connectWalletHandler} isConnected={contractConnected} />} />

        <Route path="/winner_form" element={<WinnerForm
          isConnected={contractConnected}
          connectFunc={connectWalletHandler}
          fetchUserTokens={getTokens}
          userAddress={userAddress}
          />} />

        <Route exact={true} path="/artclass" element={<Artclass
          isConnected={contractConnected}
          connectFunc={connectWalletHandler}
          getTokens={getTokens}
          tokenIDs={userTokensIDs}
          getSATTokens={getSATTokens}
          satTokens={userSATTokens}
          userAddress={userAddress}
          isHolder={isHolder}
          />} />

        <Route path="/artclass/pixel-playground" element={<Pixelplayground
          isConnected={contractConnected}
          connectFunc={connectWalletHandler}
          getTokens={getTokens}
          tokenIDs={userTokensIDs}
          getSATTokens={getSATTokens}
          satTokens={userSATTokens}
          userAddress={userAddress}
          isHolder={isHolder}
          />} />

        <Route path="/search_collection" element={<QueryCollection
          isConnected={contractConnected}
          connectFunc={connectWalletHandler}
          fetchUserTokens={getTokens}
          userAddress={userAddress}
          isAdmin={isAdmin}
          />} />
          
        <Route path="/gallery" element={<Gallery
          isConnected={contractConnected}
          connectFunc={connectWalletHandler}
          fetchUserTokens={getTokens}
          userAddress={userAddress}
          notification={toastNotification}
          // setUserStatus={setUserStatus}
          // userStatus={userStatus}
          />} />
        
        <Route path="/allowlist" element={<JoinWhitelist
          isConnected={contractConnected}
          connectFunc={connectWalletHandler}
          fetchUserTokens={getTokens}
          userAddress={userAddress}
          notification={toastNotification}
          isHolder={isHolder}
          tokenIDs={userTokensIDs}
          />} />

        <Route path="/whitelist" element={<Whitelist
          isConnected={contractConnected}
          connectFunc={connectWalletHandler}
          userAddress={userAddress}
          isAdmin={isAdmin}
          notification={toastNotification}
          />} />

        <Route path="/experiments" element={<Experiments
          isConnected={contractConnected}
          connectFunc={connectWalletHandler}
          userAddress={userAddress}
          fetchUserTokens={getTokens}
          tokenIDs={userTokensIDs}
          notification={toastNotification}
          isHolder={isHolder}
          />} />

        <Route path="/experiments/rps" element={<RockPaperScissors
          isConnected={contractConnected}
          connectFunc={connectWalletHandler}
          userAddress={userAddress}
          notification={toastNotification}
          isHolder={isHolder}
          authedUser={authenticatedUser}
          />} />

        <Route path="/experiments/rps/dashboard" element={<RPSDashboard
          resetMatchState={resetMatchState}
          isConnected={contractConnected}
          connectFunc={connectWalletHandler}
          userAddress={userAddress}
          notification={toastNotification}
          mintRPSToken={mintRPSToken}
          rpsLoading={rpsLoading}
          enterMatchLoading={enterMatchLoading}
          createNewMatch={createNewMatch}
          getRPSTokens={getRPSTokens}
          getActiveGames={getActiveGames}
          playerEnterMatch={playerEnterMatch}
          playerLeaveMatch={playerLeaveMatch}
          rpsGames={rpsGames}
          rpsGamePlayers={rpsGamePlayers}
          rpsTokenIds={rpsTokenIds}
          rpsTokens={rpsTokens}
          currentNetwork={currentNetwork}
          generateMatchSeed={generateMatchSeed}
          rpsAddress={rps_contract_address}
          authedUser={authenticatedUser}
          userAETokens={userTokensIDs}
          updatePfp={updatePfp}
          pfpLoading={pfpLoading}
          />} />

        <Route path="/experiments/rps/game/:id" element={<RPSGameScreen
          resetMatchState={resetMatchState}
          isConnected={contractConnected}
          connectFunc={connectWalletHandler}
          userAddress={userAddress}
          notification={toastNotification}
          getCurrentMatchPlayerData={getCurrentMatchPlayerData}
          getRPSTokens={getRPSTokens}
          rpsTokenIds={rpsTokenIds}
          rpsMatchPlayerData={rpsMatchPlayerData}
          getWalletsOfPlayers={getWalletsOfPlayers}
          getSeedForMatch={getSeedForMatch}
          rpsMatchSeed={rpsMatchSeed}
          updatePlayers={updatePlayers}
          awardWinner={awardWinner}
          getActiveGames={getActiveGames}
          currentNetwork={currentNetwork}
          rpsGames={rpsGames}
          />} />

        <Route path="/preview_reveal" element={<PrevRev
          isConnected={contractConnected}
          connectFunc={connectWalletHandler}
          fetchUserTokens={getTokens}
          userAddress={userAddress}
          />} />


        <Route path="/brand" element={<Brand />} />
      </Routes>
      

         {/* Don't show footer on Alpha placeholder */}
         { window.location.pathname !== '/' && <Footer /> }
    </div>
    </Router>
  );
}

// export default App;

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        elementaries: offsetLimitPagination(),
        
      }
    }
  }
})

const client = new ApolloClient({
  // cache: new InMemoryCache(), // this basically lets Apollo help you manage caching for performance
  // uri: "http://localhost:4000/graphql",
  link: createHttpLink({ uri: "/api/graphql" }),
  cache,
});

const Main = () => (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

export default Main;