import React, {useState, useEffect} from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import { useMediaQuery } from 'react-responsive';
import { PrimaryButtonNarrow, ButtonNarrowCol, PrimaryButton } from  '../components';

import ae_unrevealed from '../img/A_E_Preveal.gif';
import unrevealedSAT from '../img/show_and_tell/coming_soon.png';
import neue_backpack from '../img/show_and_tell/Backpack_Neue.jpg';
import btnLoading from '../img/icons/loader.svg';
import ae_to_sat_tokens from './collection_metadata_consolidated.json';
import ae_allocation from './allocation_list.json';
import { getAccountPath } from 'ethers/lib/utils';

// let quickRevIndx = []

export default function MyCollection (props) {
    const isMobile = useMediaQuery({query: `(max-width: 600px)`});
    const [satArray, setSatArray] = useState();
    // const [imageArr, setImageArr] = useState([]);
    const [fetched, setFetched] = useState(false);
    const [caption, setCaption] = useState();
    const [newImg, setNextImg] = useState();
    // const [fetchOnce, setFetchOnce] = useState(false);
    
    const oneBus = "py-5 mx-8 mb-8 flex flex-row justify-end rounded-xl busloads pb-[250px] pt-5 pl-[80px] pr-[500px] w-[100%]";
    const moreBus = "py-5 mx-8 mb-8 flex flex-row justify-end rounded-xl busloads pb-[250px] pt-5 pr-[510px] w-[100%]"

    let artefact_images = []
    let artefact_data_set = []

    // automatically check tokens once wallet is connected
    useEffect(() => {
        if(props.isConnected) {
            props.getTokens()
            props.fetchUserTokens()
        }
    }, [props.isConnected])
    
    
    
    useEffect(() => {
        (async function() { 
            if(props.satTokens && props.tokenIDs) {
                if(fetched == false) {
                    console.log("I'm called here");
                    const a = await getSatTokens()
                    setSatArray(artefact_data)
                    setFetched(true)
                }
                // let tmpItemArr = getSatTokens();
                // setSatArray(getSatTokens())
                // countItems(tmpItemArr[1])
            }
        })()
    }, [props.satTokens && props.tokenIDs])
    
    // useEffect(() => {
    //     // console.log("cap" + caption)
    //     // console.log("Items ready"+ itemsReady)
    //     // countItems(itemsReady)
    //     console.log("are tokens same length as array")
    //     console.log(props.satTokens)
    //     console.log(props.satTokens == satArray.length)
    //     console.log(satArray.length)
    //   }, [props.satTokens == satArray.length, satArray.length >= 1])

      
    // WE NEED 2 THINGS TO REVEAL: the Show and Tell Token ID AND the corresponding artefactID
    // step 1 find out which object contains SAT token number that matches the user's (eg 23, 457 etc) parseInt()

    let artefact_data = []
    async function getSatTokens() {
        let count = 0;
        for(let token = 0; token < props.satTokens.length; token++) {
            // console.log("PART 1")
            for(let tokenList of ae_to_sat_tokens) {
                // console.log("PART 2")
                for(let i = 0; i < tokenList.sat_tokenIDs.length; i++) {
                    // console.log("PART 3")
                    // console.log(tokenList.sat_tokenIDs[i])
                    // console.log(parseInt(props.satTokens[token]))
                    if(parseInt(props.satTokens[token]) == tokenList.sat_tokenIDs[i]) {
                        console.log(parseInt(props.satTokens[token]))
                        // then get the AE token with same index:
                        // console.log("ITERATING THROUGH")
                        let aeTokenId = tokenList.ae_tokenIDs[i]
                        // console.log(ae_allocation[aeTokenId-1])
                        let artefactName;
                        if(!ae_allocation[aeTokenId-1].show_andtell_item) {
                            artefactName = "error"
                        } else {
                            artefactName = ae_allocation[aeTokenId-1].show_andtell_item
                        }
                        let rev_img = ae_allocation[aeTokenId-1].img_add
                        // let rev_img = "placeholder.png"
                        let artefact;
                        let ae_owned = true;
                        let item_stolen;
                        artefact = ae_allocation[aeTokenId-1].artefactid
                        item_stolen = ae_allocation[aeTokenId-1].stolen
                        let sat_img;
                        let status; 
                        let URL = `https://ae-show-and-tell.s3.amazonaws.com/${props.satTokens[token]}?${Math.floor(Math.random()*99999)}`
                        // console.log(URL)
                        await fetch(URL)
                            .then(res => res.json())
                            .then((result) => {
                                // console.log(`https://ae-show-and-tell.s3.amazonaws.com/${token}?${Math.floor(Math.random()*99999)}`)
                                // console.log(`showing resoluts for ${token}.json`)
                                // console.log("/////////////////////////////////")
                                // console.log("/////////////////////////////////")
                                // console.log(result)
                                sat_img = result.image;
                                // if(sat_img == "https://alphaelem.s3.amazonaws.com/Backpack_Retro.jpg" || sat_img == "https://alphaelem.s3.amazonaws.com/Backpack_Psyche.jpg" || sat_img == "https://alphaelem.s3.amazonaws.com/Backpack_Neue.jpg" || sat_img == "https://alphaelem.s3.amazonaws.com/Backpack_Organica.jpg") {
                                if(sat_img == "ipfs://QmXm9yBm6MFiPL7Y4g3HyYjDbUxE7gpE68zmiKmhnj1wGH") {
                                    sat_img = "https://www.alphaelementary.io/backpacks/Backpack_Neue.jpg"
                                    console.log("BACKPACK FOUND" + sat_img);
                                    status = "ready"
                                } else if (sat_img == "ipfs://QmeLrcEnBuebFnNKo34JEur7BTfR9qK98BEYXM7fwF1ZfW") {
                                    sat_img = "https://www.alphaelementary.io/backpacks/Backpack_Psyche.jpg"
                                    status = "ready"
                                } else if (sat_img == "ipfs://Qma7RAYq3Vtr2YBP4ieK3FEuD8ux164F5V9EzzjsS9g1tL") {
                                    sat_img = "https://www.alphaelementary.io/backpacks/Backpack_Organica.jpg"
                                    status = "ready"
                                } else if (sat_img == "ipfs://QmZMpEwHZ31K1hETDbT6jgLhtkTzHYo9FnDNnHrWehg1ZM") {
                                    sat_img = "https://www.alphaelementary.io/backpacks/Backpack_Retro.jpg"
                                    status = "ready"
                                } else if (sat_img == "https://alphaelem.s3.amazonaws.com/backpack_fourhouse.png") {
                                    sat_img = "https://www.alphaelementary.io/backpacks/Backpack_fourhouse.png"
                                    status = "ready"
                                } else if (sat_img == "ipfs://QmR23xFPYT5K7PbteatEuJnHXHNurR6Aib1m8FbKZxzHqW") {
                                    // console.log("registered arterfact in metadata")
                                    sat_img = "coming_soon.png"
                                    status = "pre"
                                } else {
                                    status = "revealed"
                                }
                                    // iterate through AE tokens. If we don't own that token, we won't save it to our array (therefore not rendering the artwork)
                                    if(props.tokenIDs.indexOf(String(aeTokenId)) == -1) {
                                        ae_owned = false;
                                    }
                                    // MATT 1 - here I call the getImages function to get the json file relating to that image and extract the provided image name
                                    // sat_img = getImages(props.satTokens[token])
                                    // [0] = Show and Tell Token Number
                                    // [1] = Student token ID 
                                    // [2] = artefact ID (for lookup in json)
                                    // [3] = if item is stolen (true/false)
                                    // [4] = filename of image (if unrevealed will be backpack of coming soon image)
                                    // [5] = name of the artefact
                                    // [6] = filename of to be revealedd image
                                    // [7] = status: "pre" "ready" "revealed"
                                    // [8] = if the user holds this AE or not
                                    artefact_data.push([parseInt(props.satTokens[token]), aeTokenId, artefact, item_stolen, sat_img, artefactName, rev_img, status, ae_owned]);
                                    // console.warn(`creating record: ${artefact_data[artefact_data.length-1][4]}`)
                            })
                         }
                    }
                }
            }
            
            // console.log("I HOPE THIS WORKS")
            // console.log(artefact_data)
            return artefact_data
    }

    function sleep(milliseconds) {
        var start = new Date().getTime();
        for (var i = 0; i < 1e7; i++) {
          if ((new Date().getTime() - start) > milliseconds){
            break;
          }
        }
      }

        async function revealArtefacts(sat_id, artefact_id, inx, img, itmName) {
            console.log("REVEALING ARTEFACT")
            document.getElementById(`${inx}_btn`).innerHTML =`Revealing...`;
            // event.preventDefault();
            // alert(props.tokenIDs);
            // console.log(`https://cw6tu3q794.execute-api.ap-southeast-2.amazonaws.com/revealArtefact?satTokenId=${sat_id}&artefactId=${artefact_id}`)
            fetch(`https://cw6tu3q794.execute-api.ap-southeast-2.amazonaws.com/revealArtefact?satTokenId=${sat_id}&artefactId=${artefact_id}`, {method: "POST"})
                .then(res => res.json())
                .then(
                    (result) => {
                        // console.log(result)
                        console.log("SUCCESS!")
                        props.notification("Artefact revealed! Please allow a few minutes for updates to display on OpenSea.", "success")
                        setFetched(false)
                        console.log(`https://www.alphaelementary.io/artifacts/500/${img}`)
                        document.getElementById(`${sat_id}`).src=`https://www.alphaelementary.io/artifacts/500/${img}`
                        document.getElementById(`${inx}_name`).innerHTML =`${itmName}`;
                        document.getElementById(`${inx}_btn`).innerHTML =`Ta-da!`;
                        document.getElementById(`${inx}_btn`).disabled = true;
                    })
                    .catch(function(error) {
                        console.log(error)
                        document.getElementById(`${inx}_btn`).innerHTML =`Error, please try again.`;
                        // props.notification("Something went wrong. Please try again in a moment.", "error")
                    })
                    
                    // console.log(String(img))
                    // console.log(img[0])
                    // setNextImg(sat_id)
                    
        }
    
        // function countItems(ct) {
        //     let count = ct
        //     console.log("count is - " + count)
        //     console.log(count==0)
        //     console.log(count==1)
        //     console.log(count == 1 && props.userTokens == 1)
        //     console.log(count >= 1 && props.userTokens > 1)
        //     if(count == 0) {
        //         setCaption("Looks like you don't have any backpacks to open.")
        //         console.log("A")
        //     } else if (count == 1 && props.userTokens == 1) {
        //         setCaption("Looks like your kid has got something to show.")
        //         console.log("B")
        //     } else if (count == 1 && props.userTokens == 0) {
        //         setCaption("Looks like you've got a backpack to open!")
        //         console.log("C")
        //     } else if (count >= 1 && props.userTokens > 1) {
        //         setCaption(`Looks like ${count} of your kids have got something to show!`)
        //         console.log("D")
        //     } else {
        //         setCaption("Nothing!")
        //     }
        // }
        

      const connectBtn = (<>
        <button onClick={props.connectFunc}
            className="tracking-tight uppercase text-xl font-bebas text-white px-12 py-2 rounded-lg 
            inline-block cursor-pointer transition-colors no-underline
            bg-salmon hover:bg-[#C33C3C] active:bg-[#C33C3C] hover:no-underline">
                Connect Wallet
            </button>
        </>);

    function getGall() {
        let galleryList = (<><div className='text-black'>Please wait...</div></>);

        if(props.satTokens) {
            galleryList = satArray.map(function(token, index) {
            return <>
                    <div id="card" className='mx-8 mb-8 flex flex-col items-center basis-1/5 space-y-2'>         
                    <div className='w-full relative'>
                            {/* Ready to reveal */}
                            { (token[7]) == 'ready' &&
                            <Link onClick={() => {window.scrollTo(0,0);}} to={{pathname: "/show_and_tell/reveal", state: {sat_id: token[0], ae_student: token[1], artefact_id: token[2], artefact_name: token[5], image_name: token[4], rev_img: token[6], stolen: token[3]} }}>
                            <img src={ token[4] } id={token[0]} className='min-w-[260px] rounded-md hover:scale-105 transition-transform' /></Link>}

                            {/* CODE FOR QUICK REVEAL
                            { newImg == token[0] &&
                            <img src={ "https://www.alphaelementary.io/backpacks/Backpack_Neue.jpg" } className='min-w-[260px] rounded-md ' /> } */}

                            {/* Not ready to reveal */}
                            { token[7] == 'pre' &&
                            <img id={token[0]} src={ unrevealedSAT } className='min-w-[260px] rounded-md ' /> }

                            {/* Revealed */}
                            { token[7] == 'revealed' &&
                            <img src={ `${token[4]}` } className='min-w-[260px] rounded-md ' /> }

                            {/* SHOW AE IF USER HAS MATCHING AE */}
                            { token[1] <= 3000 &&
                            <a href={`https://opensea.io/assets/ethereum/0x692038c37f56e1772ce1d61cd0ff2d4289028458/${token[1]}`} alt="view AE on OpenSea" target="_blank">
                            { token[8] == true ? <img src={ require(`../img/ae_students/${token[1]}.png`) } className='w-[26%] rounded-full absolute bottom-[-15px] right-[-15px]' /> : <img src={ require(`../img/ae_students/${token[1]}.png`) } className='w-[26%] rounded-full absolute bottom-[-15px] right-[-15px] grayscale transition-all contrast-50 hover:contrast-100' />}
                            </a>}
                            {/* { token[1] && <img src={ require(`../img/ae_students/${token[1]}.png`) } className='w-[26%] rounded-full absolute bottom-[-15px] right-[-15px]' />} */}
                    </div>
                    {/* // function displayRevealButton() { */}
    {/* //     return (<>
    //         <form onSubmit={revealArtefacts}>
    //         <div className="flex space-x-5">
    //             <h3>Submitting with AE Artwork #{props.tokenIDs && props.tokenIDs[0]}</h3>
    //             <button type={"submit"} className="tracking-wider mx-auto invert blk_btn block mt-10 w-[250px]">Create record</button>
    //         </div>
    //     </form>
    //         </>); 
    // } */}

                    <h4 className='text-black'>{token[7] == 'revealed' && token[5]}</h4> 
                    <h4 id={index+"_name"} className='text-black'>{token[7] == 'ready' && "Unopened Backpack"}</h4>
                    <h4 className='text-black'>{token[7] == 'pre' && "Something's coming..."}</h4>
                    

                    {/* BUTTON TO REVEAL - ONLY SHOW IF JSON FILE RETURNS 'backpack.png' */}
                    <div className='flex flex-col space-y-1'></div>

                    {/* <Link to={{pathname: "/show_and_tell/reveal", state: {sat_id: token[0], ae_student: token[1], artefact_id: token[2], artefact_name: token[5], image_name: 'https://www.alphaelementary.io/backpacks/Backpack_Neue.jpg', rev_img: 'samurai_fighting_fish_neue.png', stolen: token[3]} }}
                    className="w-full tracking-tight px-7 py-[5px] leading-snug font-bebas uppercase text-sm bg-salmon text-white text-center rounded-lg cursor-pointer transition-colors hover:bg-[#C33C3C] active:bg-[#C33C3C] hover:no-underline no-underline">
                        OPEN BACKPACK #{token[0]} 
                    </Link> */}

                    { token[7] == 'ready' ?
                    <><Link onClick={() => {window.scrollTo(0,0);}} to={{pathname: "/show_and_tell/reveal", state: {sat_id: token[0], ae_student: token[1], artefact_id: token[2], artefact_name: token[5], image_name: token[4], rev_img: token[6], stolen: token[3]} }}
                    className="w-full tracking-tight px-7 py-[5px] leading-snug font-bebas uppercase text-sm bg-salmon text-white text-center rounded-lg cursor-pointer transition-colors hover:bg-[#C33C3C] active:bg-[#C33C3C] hover:no-underline no-underline">
                        OPEN BACKPACK #{token[0]}
                    </Link>
                    <button id={index+"_btn"} onClick={() => revealArtefacts(token[0],  token[2], index, token[6], token[5])}
                    className="w-full tracking-tight px-7 py-[5px] leading-snug font-bebas uppercase text-sm bg-[#77818C] text-white text-center rounded-lg cursor-pointer transition-colors hover:bg-[##5F6872] active:bg-[##5F6872] hover:no-underline no-underline">
                        Quick Reveal
                    </button></>
                    : <a href={String(props.sat_openSeaURL) + String(token[0])} target="_blank"
                    className="w-full tracking-tight px-7 py-[5px] leading-snug font-bebas uppercase text-sm bg-[#77818C] text-white text-center rounded-lg cursor-pointer transition-colors hover:bg-[##5F6872] active:bg-[##5F6872] hover:no-underline no-underline">
                    View on OpenSea</a>}
                    </div>
                    </>
            });
        }

        return (<>{galleryList}</>);

    }

    function showLoading() {
        if(props.satTokens && satArray == null) {
            return (<div className='flex flex-row items-center'>
                    <img src={btnLoading} className='animate-spin w-10 mr-2 invert' />
                    <p className='text-xl text-black py-3 ml-2'>Loading...</p>
            </div>);
        } 
    }

    function getMintModal() {

// artworkRevealed

        const noTokens = (<>
            <div id="card" className='bg-white  py-12 flex flex-col items-center content-between rounded-xl'>
                <h3 className='text-black'>No artworks found.</h3>
                <p className='text-black'>Please ensure you are connected with the correct wallet on Ethereum Mainnet.</p>
            </div>
        </>);
        

        return(<>
            { satArray ? getGall() : noTokens }
            {/* { gallery } */}
            </>
        );
    }

    
    return(<>
            <div id="show_and_tell_bg">
            <div className="relative overflow-x-hidden pt-10 min-h-screen max-h-auto flex flex-col place-content-around" >
                <div className="container mx-auto pt-14 pb-20">

                    <div className='flex flex-col w-full items-center' >
                        <h3 className='font-nanum text-black text-center font-lg lg:text-[2rem]'>Welcome to alpha elementary</h3>
                        <h1 className='text-black text-center mx-8 my-2 lg:mx-10 text-[80px] leading-[60px] lg:leading-[100px] lg:text-[120px]'>Show <span className="text-[#B7B7B7]">{isMobile ? "&" : "and" }</span> tell</h1>
                        <div className='w-full'><p className='text-lg text-black text-center mx-8 lg:mx-10 pb-8 lg:pb-20'>It's Show and Tell time.<br /> When your tokens turn into backpacks, you'll be able to unzip them and reveal what's inside.</p></div> 
                        { (!props.isConnected) && <div className='my-8'>
                              <img src={neue_backpack} className="w-96" /> 
                        </div> }
                        <div className='flex flex-row flex-wrap place-content-center' id="students">
                            { (!props.isConnected) ?  connectBtn : showLoading()}
                            
                            {/* { props.satTokens && getMintModal() } */}
                            { satArray != null && getGall() }
                            {/* { satArray.length > 0 && getGall() } */}
                           

                        </div>
                        {/* { props.isConnected && <p className='text-[18px] leading-4 max-w-xl text-center pb-0'><strong>Not seeing all your artworks?</strong><br /> If you recently minted one, please wait a moment then hit the refresh button below. Transactions can take a while to process.</p> } */}
                        {/* { props.isConnected && <PrimaryButtonNarrow click={props.fetchUserTokens} target="_blank" >Refresh</PrimaryButtonNarrow> } */}
                        {/* <h3 className='text-center text-black'>data: {satArray && satArray}</h3>
                        <h3 className='text-center text-black'>data: {satArray && satArray[0]}</h3>
                        <h3 className='text-center text-black'>data: {props.satTokens && props.satTokens}</h3> */}
                        

                    </div>

                </div>
            </div>
            </div>
        </>);
}