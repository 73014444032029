// This file is to save data to local storage with a time stamp
// If the timestamp exceeds a certain time, the data will return false
// otherwise it will return the data
// this function is intended to save DB calls, buy saving data locally and then checking the timestamp

export function saveToLocalStorage(key, value, expiryInMinutes) {
  console.log(value)
    const data = {
      value: value,
      expiry: new Date().getTime() + expiryInMinutes * 60 * 1000,
    };
    localStorage.setItem(key, JSON.stringify(data));
    // console.log("Updated local storage")
  }
  
  export function loadFromLocalStorage(key) {
    console.log("Checking to see if local user exists")
    const data = JSON.parse(localStorage.getItem(key));
  
    if (!data) {
      console.log("🚫 user not found")
      return false;
    }
  
    if (new Date().getTime() > data.expiry) {
      // console.log("⏰ user record expired")
      localStorage.removeItem(key);
      return false;
    }
    
    // console.log("✅ user found")
    // console.log(data.value)
    return data.value;
  }