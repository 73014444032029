import React, {useState, useEffect} from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import { useMediaQuery } from 'react-responsive';
import { PageHeader, Connect } from  './components';

// elements
import dopiumImg from './img/v2_assets/dopium.jpg';
import vibiumImg from './img/v2_assets/vibium.jpg';
import delinquiumImg from './img/v2_assets/delinquium.jpg';
import corruptiumImg from './img/v2_assets/corruptium.jpg';

// symbols
import dopiumSymbol from './img/v2_assets/dopium.svg';
import vibiumSymbol from './img/v2_assets/vibium.svg';
import delinquiumSymbol from './img/v2_assets/delinquium.svg';
import corruptiumSymbol from './img/v2_assets/corruptium.svg';

export default function Elements (props) {
    const elements = [
        {
            name: 'Dopium',
            image: dopiumImg,
            symbol: dopiumSymbol,
            description: <>This enigmatic element has just been chilling in the science classroom since it was discovered. Tests indicate that it is stable, non-reactive and feels nice to hold in the hand. Kind of warm.
            <br /><br />
            Further research pending.</>
        },
        {
            name: 'Vibium',
            image: vibiumImg,
            symbol: vibiumSymbol,
            description: <>This element has the students giddy - mostly because anyone who hangs around it long enough has their mood lifted and feels generally more enthusiastic. Enthusiastic for science, we hope.
            <br /><br />
            More details to follow more lab testing.</>
        },
        {
            name: 'Delinquium',
            image: delinquiumImg,
            symbol: delinquiumSymbol,
            description: <>This element is a little gnarly, it reacted in some unexpected ways during examination and occasionally emits some unexpected pops and crackles. Maybe this is the secret ingredient used in Pop Rocks?
            <br /><br />
            Additional findings will be added once more tests have been run. And maybe we can trick an Alpha into licking it to see what it tastes like!</>
        },
        {
            name: 'Corruptium',
            image: corruptiumImg,
            symbol: corruptiumSymbol,
            description: <>The faculty has found this one to be full of surprises. It's been incredibly difficult to analyse due to any equipment used to investigate it going haywire and sending back unusual data. Some Alphas swear that it even drains light away from nearby sources.
            <br /><br />
            More research will be posted once we've worked out how to analyse this thing!</>
        }
    ]
    
    function getElements() {
        let result = (elements.map(function(element, index) {
            return(
                <div className='mb-14 scroll-mt-20 flex flex-col items-center md:items-start' key={index} id={element.name}>
                    <div className='flex flex-row items-center'>
                    <img src={element.symbol} className="w-14 md:hidden mb-4 invert mr-4" /><h3 className='mb-4'>{element.name}</h3>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                        <img src={element.image} alt={`Some mysterious element called ${element.name}`} className={`w-full rounded-lg border-white/20 border-2`} />
                        <div className='text-center md:mx-10 md:w-[80%] lg:w-[65%] flex flex-col md:place-content-center'>
                            <img src={element.symbol} className="hidden md:block w-20 mb-8 mx-auto invert" />
                            <h4 className='mb-4'>Science Faculty's Notes</h4>
                            <p>{element.description}</p>
                        </div>
                    </div>
                </div>
            )
        }))

        return (result);
    }

    return(<>
            <PageHeader header="The Elements" subheader="A questionable science lesson." />
            <div className={`w-full transition-colors bg-black`}>
                <div className="container pt-14 pb-20 px-4 md:px-0 mx-auto">
                        <div className='' id="">
                            <>
                                <div className='flex flex-col-reverse mb-4 md:mb-0 lg:max-w-[900px] mx-auto'>
                                    <div className='mx-4 md:mx-8 lg:mx-20 md:mb-8' id="intro">
                                        <p className='text-lg text-center leading-6'>
                                        After the surprising events that occurred during the conclusion of Show and Tell, the science faculty has taken it upon themselves, along with a handful of eager students (hoping to score some brownie points, no doubt) to investigate.
                                        </p>
                                        <p className='text-lg text-center leading-6'>
                                        The findings of their analysis of the elements detected within the artefacts has been posted below for the A.E. community to examine.
                                        </p>
                                        <p className='text-lg text-center leading-6'>
                                        If only the teachers had been quick enough to discover the mysterious backpacks, maybe all this could have been avoided!<br /><br />Oh well. Let's hope no one gets fired.
                                        </p>
                                    </div>
                                    <div className='text-center'>
                                        <h4>The A.E. Periodic Table of Elements</h4>
                                        <div className='mx-auto flex space-x-4 text-center justify-center mt-4 mb-8 group'>
                                            <a href={`#${elements[0].name}`}>
                                                <img src={elements[0].symbol} className="w-20 invert opacity-75 transition-opacity duration-300 hover:opacity-100" alt="Click to jump to element" />
                                            </a>
                                            <a href={`#${elements[1].name}`}>
                                                <img src={elements[1].symbol} className="w-20 invert opacity-75 transition-opacity duration-300 hover:opacity-100" alt="Click to jump to element" />
                                            </a>
                                            <a href={`#${elements[2].name}`}>
                                                <img src={elements[2].symbol} className="w-20 invert opacity-75 transition-opacity duration-300 hover:opacity-100" alt="Click to jump to element" />
                                            </a>
                                            <a href={`#${elements[3].name}`}>
                                                <img src={elements[3].symbol} className="w-20 invert opacity-75 transition-opacity duration-300 hover:opacity-100" alt="Click to jump to element" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </>

                                { getElements() } 
                                <div className='text-center bg-[#151515] hover:bg-[#242424] transition-colors rounded-2xl py-6 px-4'>
                                    <h4 className='mb-4 mx-auto w-auto'>Science Faculty's Initial Conclusion</h4>
                                    <p className='lg:w-[50%] md:w-[75%] mx-auto w-full pb-0'>Probably nothing, Alphas are safe to continue to hold on to their Show and Tell items, nothing strange ever happens when mysterious elements are discovered.</p>
                                </div>
                            
                        </div>
                </div>
            </div>
        </>);
}