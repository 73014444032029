import React, {useState, useEffect} from 'react';
import { PageHeader} from  './components';
import { HashLink as Link } from 'react-router-hash-link';

import PixelPlaygroundImg from './img/v2_assets/pixel_studio.png'


export default function Pixelplayground(props) {
    
    useEffect(() => {
        if(props.isConnected) {
            props.getTokens();
        }
    }, [props.isConnected])


    return (
        <>
            <PageHeader header="Art Class" subheader="Time to pull that crayon out of your nose." />
            <div className={`w-full transition-colors bg-black`}>
                <div className="container pt-14 pb-20 px-4 md:px-0 mx-auto">
                        <div className='' id="">
                            <h4 className='mb-4 mx-auto w-auto text-center'>It's time to show us your creative side</h4>
                            <p className='text-lg leading-6 text-center md:w-3/4 mx-auto'>Welcome to the art classroom! At Alpha Elementary, we believe art is the most important subject, so we've launched a creative safe space for A.E. members to get messy.</p>
                        </div>
                        <div>
                            <div className='text-center md:text-left my-4 md:my-0'>
                                <h3 className='font-nanum text-white font-lg lg:text-[2rem] pt-6'>Alpha Elementary</h3>
                                <h2 className='mb-4 text-white'>Pixel Playground</h2>

                                <div className='grid grid-cols-1 md:grid-cols-3 md:space-x-16'>
                                    <div className='col-span-1 pl-[14%] md:pl-0 mb-8'>
                                        <img src={PixelPlaygroundImg} alt="Get those pixels on your paintbrush" className='mb-4' />
                                        <Link onClick={() => {window.scrollTo(0,0);}} to="/artclass/pixel-playground"  className="std_btn hidden md:block w-[84.5%]
                                         bg-white text-black
                                        hover:bg-white/75 hover:text-black active:bg-white/50
                                        text-center rounded-lg cursor-pointer transition-colors hover:no-underline no-underline">
                                            Launch Pixel Playground
                                        </Link>
                                        <button disabled="true" className="std_btn md:hidden block w-[84.5%]
                                         bg-white/40 text-black hover:bg-white/50 cursor-not-allowed
                                        text-center rounded-lg transition-colors hover:no-underline no-underline">
                                            Unavailable on mobile
                                        </button>
                                    </div>

                                    <div className='col-span-1 md:col-span-2 md:pt-14'>
                                        <h4 className='mb-2 text-white'>Create your own pixel art alphas</h4>
                                        <p className='mb-2'>Import your Alphas and draw your very own pixel version. Or, if you're the lazy kind of student, we've got a one-click solution for you. Just hit <span className=' font-semibold'>generate</span> and a mystical algorithm will do your homework for you.</p>
                                        <h4 className='mb-2 text-white'>Features</h4>
                                        <ul className='mb-8'>
                                            <li className=''><span className='text-lg font-bold'>&bull;</span> Create your own pixel art</li>
                                            <li className=''><span className='text-lg font-bold'>&bull;</span> Automatically generate an artwork from your A.E. collection</li>
                                            <li className=''><span className='text-lg font-bold'>&bull;</span> Bring your own NFT - upload any NFT and turn it into a pixel art!</li>
                                            <li className=''><span className='text-lg font-bold'>&bull;</span> Download your artwork to share and hang on the fridge</li>
                                        </ul>
                                        <h4 className='mb-2 text-white'>Create your own pixel art alphas</h4>
                                        <p className=''>Share your creations on Twitter and tag <a href="https://twitter.com/AlphaAcademyNFT" className='text-white font-semibold' target="_blank" >@AlphaAcademyNFT</a>.</p>
                                    </div>

                                </div>
                            </div>

                        </div>
                </div>
            </div>
        </>
    )
}
