import React, {useState, useEffect} from 'react';
import { useQuery, useLazyQuery, useMutation, gql } from '@apollo/client';
import { Network, Alchemy } from 'alchemy-sdk';
// import { getKeyFromPassphrase, decrypt} from './crypto_helper';


// Images
import btnLoading from './img/icons/loader.svg';


const GET_UNAPPROVED_USERS = gql`
  query GetUnapprovedUsers {
    getUnapprovedUsers {
      id
      wallet
      email
      twitter_id
      discord_id
      status
      approved
      salt
    }
  }
`;

const GET_REVIEWED_USERS = gql`
  query GetReviewedUsers {
    getReviewedUsers {
      id
      wallet
      email
      twitter_id
      discord_id
      status
      approved
      salt
    }
  }
`;

const UPDATE_STATUS = gql`
    mutation UpdateUser($input: UpdateUserInput) {
        updateUser(input: $input) {
            id
            wallet
            email
            twitter_id
            discord_id
            status
            approved
        }
    }
`;

const BATCH_APPROVE = gql`
    mutation BatchApprove($input: BatchUpdateMultipleUsers) {
        batchApprove(input: $input) {
            id
            approved
        }
    }
`;



export default function Whitelist (props) {

    const alchemy = new Alchemy({
        apiKey: '--WyxFNzSnAV12Bzf2C34iy_T8OpjGPx',
        network: Network.ETH_MAINNET,
    });

    // const [ frens, setFrens ] = useState([])
    const { loading, error, data } = useQuery(GET_UNAPPROVED_USERS, { notifyOnNetworkStatusChange:true });
    const { loading: reviewedLoading, error: reviewedError, data: reviewData, refetch } = useQuery(GET_REVIEWED_USERS, { notifyOnNetworkStatusChange:true });
    const [ mutateFunction, {loading: updateLoading, error: updateError}] = useMutation(UPDATE_STATUS, {
        notifyOnNetworkStatusChange:true
    });

    const [ batchMutateFunction, {loading: batchLoading, error: batchError}] = useMutation(BATCH_APPROVE, {
        notifyOnNetworkStatusChange:true
    });


    if (error) {
        console.warn(error)
    }

    function loader() {

        const loaderAnimation = (<>
            <span className='mx-auto'>
                <img src={btnLoading} className="w-12 animate-spin invert mx-auto" />
            </span>
        </>)

        return loaderAnimation
    }

    function setAction(action, id) {
        if(props.isAdmin === false) {
            alert("Warning. You are not signed in as admin.");
            return;
        }
        console.log(data.getUnapprovedUsers[id])
        mutateFunction({
            variables: {
                input: {
                    id: data.getUnapprovedUsers[id].id,
                    status: "reviewed",
                    approved: action
                }
            }
        })
    }

    function updateAction(action, id) {
        if(props.isAdmin === false) {
            alert("Warning. You are not signed in as admin.");
            return;
        }
        console.log("about to change this: " + reviewData.getReviewedUsers[id])
        mutateFunction({
            variables: {
                input: {
                    id: reviewData.getReviewedUsers[id].id,
                    status: "reviewed",
                    approved: action
                }
            }
        })
        .then(() => {
            refetch()
        })
        
    }

    function batchApprove() {
        if(data?.getUnapprovedUsers) {
            let myTempApprovalList = [];
            for(let i = 0; i < data.getUnapprovedUsers.length; i++) {
                // update conditional logic here to filter out others
                if(document.getElementById(`${data.getUnapprovedUsers[i].id}`).checked) {
                    myTempApprovalList.push(data.getUnapprovedUsers[i].id)
                } else {
                    // create list of possible rejected?
                }
            }
            console.log("The following have been approved:")
            console.log(myTempApprovalList)
            if(myTempApprovalList.length != 0) {
                batchMutateFunction({variables: { input: {id: myTempApprovalList, approved: true} }})
                .then((res) => {
                    console.log(res)
                    window.location.reload()
                })
            } else {
                alert("Error: no applicants selected.")
            }
        }
    }

    function selectAll() {
        for(let i = 0; i < data.getUnapprovedUsers.length; i++) {
            document.getElementById(`${data.getUnapprovedUsers[i].id}`).checked = true;
        }
    }

    function selectRec() {
        for(let i = 0; i < data.getUnapprovedUsers.length; i++) {
            if(true) { // selection criteria
                document.getElementById(`${data.getUnapprovedUsers[i].id}`).checked = true;
            }
        }
    }

    function deselectAll() {
        for(let i = 0; i < data.getUnapprovedUsers.length; i++) {
            document.getElementById(`${data.getUnapprovedUsers[i].id}`).checked = false;
        }
    }

    return(<>
           <div className='container mx-auto my-24'>
           { props.isAdmin === true ? 
           <>
           <h3 className='text-black'>Allow List applicants</h3>
           <div className='mb-8'>
                <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {/* Select */}
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Wallet Address
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Twitter Handle
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Discord ID
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                # Followers
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Follow Us
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                In our discord
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Email Provided
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white text-black divide-y divide-gray-200">
                        {data?.getUnapprovedUsers != undefined && data.getUnapprovedUsers.map((item, index) => (
                            <tr key={index}>
                                <td className="px-3 py-4 whitespace-nowrap"><input id={`${item.id}`} type="checkbox" className='text-2xl scale-125' /></td>
                                <td className="px-6 py-4 whitespace-nowrap">{item.wallet.substring(0,5)}...{item.wallet.substring(37,42)}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{item.twitter_id}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{item.discord_id}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{item?.followers ? item.follwers : "N/A"}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{item?.followUs ? 'Yes' : 'No'}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{item?.inOurDiscord ? 'Yes' : 'No'}</td>
                                {/* locally decrypt email - not actually required, remove this later */}
                                {/* <td className="px-6 py-4 whitespace-nowrap">{item.salt && decrypt(item.email, getKeyFromPassphrase(process.env.REACT_APP_PASSPHRASE, item.salt))}</td> */}
                                <td className="px-6 py-4 whitespace-nowrap">{item.email != "" ? 'Yes' : 'No'}</td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <span className='flex flex-row space-x-3'>
                                        <button onClick={() => {setAction(true, index)}} className='text-xl hover:text-[1.3rem]' title="approve">✅</button>
                                        <button onClick={() => {setAction(false, index)}} className='text-xl hover:text-[1.3rem]' title="reject">❌</button>
                                    </span>
                                </td>
                            </tr>
                        ))}
                        {/* if no new applications */}
                        {data?.getUnapprovedUsers.length == 0 &&
                            <tr>
                                <td colSpan={9} className="px-6 py-4 whitespace-nowrap pb-8 text-center italic">🟢 You're all caught up. There are no new applications to review at this time. 🟢</td>
                            </tr>
                        }
                    </tbody>
                </table>
                {/* show loading animation while fetching records */}
                <div>{loading && loader() }</div>

                {/* Approval btns */}
                <div className='flex flex-row place-content-between'>
                    <div className='space-x-4'>
                        <button onClick={() => {selectRec()}} className='text-black py-2 px-3 border border-black bg-white hover:bg-black hover:text-white transition-colors' title="reject">Select Recommended</button>
                        <button onClick={() => {selectAll()}} className='text-black py-2 px-3 border border-black bg-white hover:bg-black hover:text-white transition-colors' title="reject">Select All</button>
                        <button onClick={() => {deselectAll()}} className='text-black py-2 px-3 border border-black bg-white hover:bg-black hover:text-white transition-colors' title="reject">Deselect All</button>
                    </div>
                    <div className=''>
                        <button onClick={() => {batchApprove()}} className='text-black py-2 px-3 border border-black bg-white hover:bg-green-500 hover:border-green-500 hover:text-white transition-colors' title="reject">Approve Selected</button>
                    </div>
                </div>
                <div className='text-black mt-4'>
                    <p className='font-mono text-[0.7rem] pb-0'><strong>Current Selection Criteria:</strong></p>
                    <p className='font-mono text-[0.7rem] pb-0'>A: Twitter Followers: 10 or more and follows us + Discord ID provided and in our Discord Server, email not required</p>
                    <p className='font-mono text-[0.7rem] pb-0'>B: Twitter Followers: 10 or more and follows us, no discord ID, email provided</p>
                    <p className='font-mono text-[0.7rem] pb-0'>C: Twitter Followers: 15,000 or more and follows us, no discord ID</p>
                    <p className='font-mono text-[0.7rem] pb-0'>C: Does not have twitter, Discord ID provided and in our server, email provided</p>
                </div>
            </div>

            {/* List of those rejected */}
           <h3 className='text-black'>Reviewed Applicants</h3>
           <h4 className='text-black'>Rejected</h4>
                <table className={`min-w-full divide-y divide-gray-200 mb-8 ${reviewedLoading && "opacity-40"}`}>
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Wallet Address
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Twitter Handle
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Discord ID
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                # Followers
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Follow Us
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                In our discord
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Email Provided
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white text-black divide-y divide-gray-200">
                        {reviewData?.getReviewedUsers != undefined && reviewData.getReviewedUsers.map((item, index) => (
                            !item.approved && <tr key={index}>
                                <td className="px-6 py-4 whitespace-nowrap">{item.wallet.substring(0,5)}...{item.wallet.substring(37,42)}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{item.twitter_id}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{item.discord_id}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{item?.followers ? item.follwers : "N/A"}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{item?.followUs ? 'Yes' : 'No'}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{item?.inOurDiscord ? 'Yes' : 'No'}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{item.email != "" ? 'Yes' : 'No'}</td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <span className='flex flex-row space-x-3'>
                                        <button onClick={() => {updateAction(true, index)}} className='text-black py-2 px-3 border border-black bg-white hover:bg-black hover:text-white transition-colors' title="reject">Approve</button>
                                    </span>
                                </td>
                            </tr>
                        ))}
                        { reviewedLoading && loader() }
                    </tbody>
                </table>

            {/* List of those approved */}
           <h4 className='text-black'>Approved</h4>
                <table className={`min-w-full divide-y divide-gray-200 mb-8 ${reviewedLoading && "opacity-40"}`}>
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Wallet Address
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Twitter Handle
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Discord ID
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                # Followers
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Follow Us
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                In our discord
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Email Provided
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white text-black divide-y divide-gray-200">
                        {reviewData?.getReviewedUsers != undefined && reviewData.getReviewedUsers.map((item, index) => (
                            item.approved && <tr key={index}>
                                <td className="px-6 py-4 whitespace-nowrap">{item.wallet.substring(0,5)}...{item.wallet.substring(37,42)}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{item.twitter_id}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{item.discord_id}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{item?.followers ? item.follwers : "N/A"}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{item?.followUs ? 'Yes' : 'No'}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{item?.inOurDiscord ? 'Yes' : 'No'}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{item.email != "" ? 'Yes' : 'No'}</td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <span className='flex flex-row space-x-3'>
                                        <button onClick={() => {updateAction(false, index)}} className='text-black py-2 px-3 border border-black bg-white hover:bg-black hover:text-white transition-colors' title="reject">Reject</button>
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </> 
            :
                <div className='text-red-500'>Admin access only.</div>
            }
           </div>
        </>);
}
