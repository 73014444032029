import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from "react-router-dom"; 
import { useQuery, useLazyQuery, gql, useMutation } from '@apollo/client';
// import { IpfsImage } from 'react-ipfs-image';
import { Waypoint } from 'react-waypoint';
import { Network, Alchemy } from 'alchemy-sdk';
import { useMediaQuery } from 'react-responsive';
import { saveToLocalStorage, loadFromLocalStorage } from './components/localStorageUtils';


import { motion, AnimatePresence } from 'framer-motion'

import copyURL from './img/v2_assets/copy-gallery.svg'
import openseaGallery from './img/v2_assets/opensea-gallery.svg'

// Images
import btnLoading from './img/icons/loader.svg';
import CloseImg from './img/v2_assets/close_btn.svg'
import filterIco from './img/exiled/filter.svg'
import filterIcoV2 from './img/exiled/filter-blk.svg'

// import metadataNumbers from './metadata/medatada_analytics.json'

let assetsByCategories = require('./metadata/trait_names.json')

// let numToLoad = 36;

const QUERY_ALL_ELEMENTARIES = gql`
    query GetAllElementaries($searchAeCollection: SearchAeCollection, $offset: Int, $limit: Int) {
        elementaries(input: $searchAeCollection, offset: $offset, limit: $limit) {
            id
            after
        }
    }
`

const GET_ELEMENTARY = gql`
    query GetElementary($id: ID!) {
        elementary(id: $id) {
            id
            house
            background
            figure
            outfit
            head
            eyes
            mouth
            nose
            eyebrows
            accessory
            special
            unique
            cid
        }
    }
`

const ADD_REMOVE_FRIEND = gql`
    mutation AddRemoveAeFren($input: FrenUpdate!) {
        addRemoveAeFren(input: $input)
    }
`

export default function QueryCollection (props) {

    const alchemy = new Alchemy({
        apiKey: '--WyxFNzSnAV12Bzf2C34iy_T8OpjGPx',
        network: Network.ETH_MAINNET,
    });

    const isMobile = useMediaQuery({query: `(max-width: 1024px)`})

    const location = useLocation()
    const history = useNavigate()
    const URLparams = new URLSearchParams(location.search)

    // const [ frens, setFrens ] = useState([])
    const [ userFilters, setUserFilters ] = useState(null)
    const [userStatus, setUserStatus] = useState(null);
    const { data: elementaryRecords, loading, error, fetchMore, refetch } = useQuery(QUERY_ALL_ELEMENTARIES, {
        variables: {
            "offset": 1,
            "limit": 36,
            "searchAeCollection": userFilters
        },
        notifyOnNetworkStatusChange:true
    });

    // add friends
    const [ addRemoveAeFren, {data: frens, loading: loadingFren, error: errorFren}] = useMutation(ADD_REMOVE_FRIEND);

    const [ fetchAEData, {data: singleRecord, loading: loadingED}] = useLazyQuery(GET_ELEMENTARY);
    const [ selectedToken, setSelectedToken ] = useState(null)
    const [ ipfsImgLoaded, setIpfsImgLoaded ] = useState(false)
    const [ showNumbers, setShowNumbers ] = useState(true)
    const [ showMobileFilters, setShowMobileFilters ] = useState(false)
    const [ tokenOwner, setTokenOwner] = useState(null)

    if (error) {
        console.warn(error)
    } else if(errorFren) {
        console.warn(errorFren)
    }

    useEffect(() => {
        if(singleRecord) {
            if(singleRecord.elementary.background == 'Onyx' || singleRecord.elementary.unique == 'House Captain') {
                // make the close button white so that it is visible on the pop-up
                document.getElementById('close_btn').classList.remove('invert')
            }
        }
    }, [singleRecord])

    useEffect(() => {
        if(frens) {
            let tmpStorage = userStatus;
            tmpStorage = {... tmpStorage, getUser: {... tmpStorage.getUser, elementary_frens: frens.addRemoveAeFren}}
            console.log(tmpStorage)
            setUserStatus(tmpStorage)
            saveToLocalStorage("getUser", tmpStorage, 15);
        }
    }, [frens])

    // if user's wallet is connected, check to see if they've registered for Allowlist
    useEffect(()=> {
        // get local user (if exists)
        console.log("trying to fetch user again...")
        const localUser = loadFromLocalStorage("getUser");
        if(props.userAddress.length > 0 && localUser && localUser.wallet === props.userAddress) {
        console.log("Got from local storage")
        setUserStatus(localUser)
        } 
        // currently if the above isn't all TRUE, we do NOT fetch the user from the DB
    }, [props.userAddress])

    // For handling user input searching for an individual ID
    const [typingTimeout, setTypingTimeout] = useState(null);

    const handleChangeId = (event) => {
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        setTypingTimeout(
            setTimeout(() => {
                // console.log("Searching with new filters")
                if(Number(event.target.value) > 0 && Number(event.target.value) <= 3000) {
                    userFilters != null && resetForm(true)
                    setUserFilters({id: [(event.target.value)]})
                } else {
                    event.target.value != '' && alert("Please enter a valid ID number between 1 and 3,000")
                }
            }, 500)
        );
    };

    useEffect(() => {
        // on load, check to see if there are any URL params
        if(URLparams.get('id') != null) {
            setSelectedToken(Number(URLparams.get('id')))
        }
        document.body.classList.add('bg-off_white')
    }, []);

    useEffect(() => {
        return () => {
            clearTimeout(typingTimeout);
        };
    }, [typingTimeout]);
    
    useEffect(() => {
        // console.log("✅ Refetching with filters")
        // console.log(userFilters)
        refetch({
            "offset": 1,
            "limit": 36,
            "searchAeCollection": userFilters
           })
    }, [userFilters])
    
    useEffect(() => {
        // console.log("I should be attempting to fetch AE data now")
        if(selectedToken != null) {
            fetchAEData({variables: {id: selectedToken}})
            setIpfsImgLoaded(false)
            // console.log("Fetching owner data for token: " + selectedToken)
            fetchOwnerData()
        }
    }, [selectedToken])

    // ANIMATIONS
    const filtersVariants = {
        open: {opacity: 1, y: 0},
        closed: {opaicty: 0, y: "100vh"}
    }

    async function fetchOwnerData() {
        await alchemy.nft.getOwnersForNft('0x692038c37f56e1772ce1d61cd0ff2d4289028458', selectedToken)
                .then((res) => {
                    // console.log(res)
                    setTokenOwner(res.owners[0])
                })
                .catch((err) => {
                    // console.log(err)
                })
    }
    function loader() {

        const loaderAnimation = (<>
            <div className='mx-auto'>
                <img src={btnLoading} className="w-12 animate-spin invert mx-auto" />
            </div>
        </>)

        return loaderAnimation
    }

    function galleryGrid() {

        const gallery = (<>
        <div id="galleryContainer"
        className={`grid transition-all ${ showNumbers == true ? 'grid-cols-2 md:grid-cols-4 lg:grid-cols-4 2xl:grid-cols-6' : 'grid-cols-2 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-7' } text-black
        ${showNumbers ? "gap-4" : "gap-0"}
        `}>
            { elementaryRecords && elementaryRecords.elementaries.map((record, index) => {
                return(
                <Waypoint onEnter={()=>{
                    let fetchBuffer = 24;
                    if(isMobile) {fetchBuffer = 8}
                    if(record.id >= elementaryRecords.elementaries.length - fetchBuffer && !loading && elementaryRecords.elementaries[elementaryRecords.elementaries.length - 1].after != -1) {
                        // console.log("fetching more...")
                        fetchMore(
                            {
                                variables: {
                                    "offset": elementaryRecords.elementaries[elementaryRecords.elementaries.length - 1].after,
                                    "limit": isMobile ? 8 : 36
                                }, 
                                notifyOnNetworkStatusChange:true,
                        })
                    }
                }} key={record.id}>
                <div className='' >
                    <div className={`w-full aspect-square hover:cursor-pointer hover:scale-105 transition-all ${selectedToken == record.id ? "grayscale scale-95 opacity-80 hover:scale-[.98]" : " hover:scale-105"}`}>
                        <img src={ require(`./img/ae_students/${record.id}.png`) }  
                        className={`w-full transition-all ${showNumbers ? "rounded-md shadow-md hover:shadow-xl" : "rounded-none shadow-none"}`}
                        onClick={() => {
                            if(record.id != selectedToken) {
                                history(`/gallery?id=${record.id}`)
                                setSelectedToken(record.id)
                            } else {
                                history(`/gallery`)
                                setSelectedToken(null)
                                setTokenOwner(null)
                            }
                        }}
                         />
                    </div>
                    { showNumbers &&
                    <div className='text-black w-full text-center pt-2 pb-0 space-y-2 my-3'>
                        <h6 className='my-0 py-0 leading-none text-[0.58rem] text-black uppercase'>Alpha Elementary</h6>
                        <h4 className='font-teko text-[1.7rem]'>{record.id.toString().padStart(4, '0')}</h4>
                    </div> }
                </div>
            </Waypoint>
                )
            })
            
            }
        </div>
        </>)

        return gallery
    }

    function renderAEInfoCard() {

        const infoCardNew = (
            <motion.div initial={{y: 100, opacity: 0}} animate={{y: 0, opacity: 1}} exit={{scale: 0.95, transition: { duration: 0.05 } }}  transition={{type: "spring" , duration: 0.2, damping: 14}} className='relative  shadow-2xl z-[99] overflow-hidden rounded-md'
            onClick={(event) => {event.stopPropagation()}}>
                <div className='flex flex-col lg:flex-row h-screen overflow-scroll lg:overflow-hidden lg:h-auto bg-off_white'>
                    {/* 1000 x 1000 jpg */}
                    <div className='relative'>
                    { <img src={`https://alphaelem.s3.amazonaws.com/ae_students_1k/${selectedToken}.jpg`} className="w-screen aspect-square bg-slate-300 md:w-[550px] md:h-[550px] lg:w-[750px] lg:h-[750px] 2xl:w-[900px] 2xl:h-[900px] absolute" onLoad={() => {setIpfsImgLoaded(true)}} /> }
                    { <img src={ require(`./img/ae_students/${selectedToken}.png`) } className="w-screen aspect-square md:w-[550px] md:h-[550px] lg:w-[750px] lg:h-[750px] 2xl:w-[900px] 2xl:h-[900px] animate-pulse" /> }
                    </div>
                    {/* Preview before full image loads */}
                    { !ipfsImgLoaded && <div className='w-full h-full flex items-center md:w-[550px] md:h-[550px] lg:w-[750px] lg:h-[750px] 2xl:w-[900px] 2xl:h-[900px] absolute'>
                        <img src={btnLoading} className="w-10 animate-spin mx-auto" />
                    </div> }

                    <div className='w-full md:w-[550px] lg:w-[350px]'>
                        <div className='flex flex-col place-content-between h-full mt-4'>
                            <div className=''>
                                <div className='flex justify-end fixed top-3 right-3 lg:mx-4 lg:relative'>
                                    <button title="Close" id="closeImg" onClick={()=>{setSelectedToken(null); history(`/gallery`)}} ><img id="close_btn" src={CloseImg} className={`w-8 md:w-4 invert hover:scale-105 hover:opacity-80 hover:cursor-pointer`} /> </button>
                                </div>
                                <div className='mx-12'>
                                    <motion.div initial={{y: -30, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{delay: 0.4, type: "linear"}} className="mb-8">
                                        <p className='my-0 py-0 leading-none text-[0.65rem] text-black mb-0 uppercase'>Alpha Elementary</p>
                                        <h2 className='tracking-[3px] font-teko text-black mt-1 mb-0'>{selectedToken.toString().padStart(4, '0')}</h2>
                                        <div className='mb-4 flex flex-row space-x-1 items-center'>
                                            <a title="View on OpenSea" href={`https://opensea.io/assets/ethereum/0x692038c37f56e1772ce1d61cd0ff2d4289028458/${selectedToken}`} target="_blank"><img src={openseaGallery} alt="View on OpenSea" className='w-5 hover:opacity-75 transition-opacity'/></a>
                                            <a to="#" onClick={() => {
                                                navigator.clipboard.writeText(`https://www.alphaacademy.app/gallery?id=${selectedToken}`);
                                                props.notification("Link coppied to clipboard!", "success")
                                                }} className="">
                                                    <img src={copyURL} title="Copy link to this AE" className='w-5 hover:opacity-75 transition-opacity hover:cursor-pointer'/>
                                            </a>
                                            { userStatus !== null && <button disabled={loadingFren} className='text-black disabled:text-slate-300' onClick={()=>{addRemoveAeFren({variables: {input: {wallet: props.userAddress, ae_id: Number(selectedToken)}}})}}>{ (userStatus.getUser?.elementary_frens &&userStatus.getUser?.elementary_frens.includes(Number(selectedToken))) ? "Remove Fren" : "Add Fren" }</button> }
                                        </div>
                                    </motion.div>

                                    {/* LINKS HERE */}

                                    <div className=''>
                                        { singleRecord?.elementary && Object.entries(singleRecord?.elementary).map((asset, index) => {
                                            if(asset[0] != "cid" && asset[0] != "__typename" && asset[0] != "id" && asset[1] !=null) {
                                                return(
                                                    <>
                                                        <hr className='h-[1px] border-black lg:w-3/4 relative' />
                                                            <div className='relative group cursor-pointer hover:bg-slate-100' key={index}>
                                                                <p className='text-black uppercase text-[8pt] leading-3 mb-0 pb-0 pt-[3px] 2xl:py-1 mt-1'>{asset[0]}</p>
                                                                <h4 className='text-black font-teko -mt-1 text-[1.5rem] 2xl:text-[1.7rem] mb-[1px] 2xl:mb-1 flex flex-row'>{asset[1]} <img onClick={()=>{
                                                                            userFilters != null && resetForm(false)
                                                                            document.getElementById(`${asset[1]}_input`).checked = true
                                                                            replaceFilters(asset[0], asset[1])
                                                                            setSelectedToken(null)
                                                                            history(`/gallery`)
                                                                        }
                                                                    } title={`Filter by ${asset[1]}`} src={filterIco} className='h-[18px] mt-1.5 ml-2 hidden group-hover:block' /></h4>
                                                                {/* <div className='absolute hidden w-0 top-10 z-10 overflow-clip bg-white flex-col group-hover:lg:flex group-hover:lg:w-auto rounded-sm text-left py-1 px-1 border-[1px] border-slate-200'>
                                                                    { userFilters != null && <button onClick={() => { 
                                                                        document.getElementById(`${asset[1]}_input`).checked = true
                                                                        addToFilters(true, asset[0], asset[1])
                                                                    }}
                                                                    className="hover:bg-slate-100 text-black uppercase text-[8pt] leading-3 py-1"
                                                                    >Add to filters</button>}
                                                                    <button onClick={()=>{
                                                                        userFilters != null && resetForm(false)
                                                                        document.getElementById(`${asset[1]}_input`).checked = true
                                                                        replaceFilters(asset[0], asset[1])}}
                                                                    className="hover:bg-slate-100 whitespace-nowrap text-black uppercase text-[8pt] leading-3 py-1"
                                                                    >Filter collection by <strong>{asset[1]}</strong></button>
                                                                </div> */}
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                    { loadingED && <div className='flex flex-row justify-center items-center h-36'>
                                        { loader() }
                                    </div> }
                                </div>

                            </div>
                            <div className='mx-10'>
                                {tokenOwner && <p className='text-black text-[6pt]'><strong>OWNER:</strong> { tokenOwner }</p> }
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        )

        return infoCardNew;
        // return infoCard;
    }

    function showActiveFilters() {
        const activeFilters = (<>
            <div className='flex flex-row flex-wrap items-baseline'>
                { Object.keys(userFilters).map((key, index) => {
                    return userFilters[key].map((filter, index
                    ) => {
                        return (
                            <div key={index} id={filter} className='border border-slate-500 rounded-md pl-2 pr-1 pb-0.5 pt-[5px] mr-2 mb-2 flex flex-row items-center hover:bg-slate-100 transition-all'>
                                <span className='text-black uppercase text-[8pt] mr-2 font-acumin tracking-wider'><span className='font-acumin font-bold'>{key}:</span> {filter}</span>
                                <img title="Remove filter" src={CloseImg} className="w-4 invert hover:scale-105 hover:opacity-80 hover:cursor-pointer inline active:scale-95 pb-0.5"
                                onClick={() => {
                                    key != 'id' && (document.getElementById(`${filter}_input`).checked = false);
                                    key == 'id' && (document.getElementById('numberInput').value = '')
                                    document.getElementById(filter).classList.add('opacity-20')
                                    removeFilter(key, filter)
                                }}
                                />
                            </div>
                        )
                    })
                })}
                {/* Clear all filters option */}
                <div id="clearAll" className='border border-slate-700 rounded-md pl-2 pr-1 pb-0.5 pt-[5px] mr-2 mb-2 flex flex-row items-center hover:bg-slate-100 transition-all'>
                    <span className='text-black uppercase text-[8pt] mr-2 tracking-wider'>Clear All</span>
                    <img src={CloseImg} className="w-4 invert pb-0.5 hover:scale-105 hover:opacity-80 hover:cursor-pointer inline active:scale-95"
                    onClick={() => {
                        document.getElementById('clearAll').classList.add('opacity-20')
                        resetForm(false)
                        setUserFilters(null)
                    }}
                    />
                </div>
                { loading && <div id="filtersLoading">
                    <img src={btnLoading} className="w-8 animate-spin invert opacity-20 mx-auto" />
                </div> }
            </div>
        </>)
        return activeFilters;
    }

    function resetForm(isIdSearch) {
        for(let i = 0; i < Object.keys(userFilters).length; i++) {
            let key = Object.keys(userFilters)[i]
            for(let j = 0; j < userFilters[key].length; j++) {
                let filter = userFilters[key][j]
                key != 'id' && (document.getElementById(`${filter}_input`).checked = false)
                if (key == 'id') { 
                    if(isIdSearch == false) {
                        document.getElementById('numberInput').value = ''
                    }
                }
            }
        }
    }

    function removeFilter(category, filter) {
        let newFilters = userFilters;
        let index = newFilters[category].indexOf(filter);
            if(newFilters.hasOwnProperty(category) == true) {
                // console.log("Check to see")
                // check to see if there is more than 1 element in that category
                // if more than 1 element, just delete the element
                if(newFilters[category].length > 1) {
                    newFilters[category].splice(newFilters[category].indexOf(filter), 1)
                    setUserFilters(userFilters => ({...userFilters, [category]: newFilters[category]}))
                } else {
                    // otherwise, delete the category
                    delete newFilters[category];
                    // check to see if object is empty
                    if(Object.keys(newFilters).length == 0) {
                        // console.log("empty")
                        setUserFilters(userFilters => (null))
                    } else {
                        setUserFilters(userFilters => ({...userFilters}))
                    }
                }
                category != 'id' && (document.getElementById(`${filter}_input`).checked = false)
                category == 'id' && (document.getElementById('numberInput').value = '')
            } else {
                console.log("Something went wrong inside the filters")
            }
    }

    function addToFilters(checked, category, asset) {
        if(userFilters == null && checked) {
            // console.log("Added filter to new category")
            // console.log("Filters have changed")
            // console.log({[category]: [asset]})
            setUserFilters(userFilters => ({...userFilters, [category]: [asset]}))
        } else if (checked) {
            let newFilters = userFilters;

            // check to see if an id is being used as the filter. If so, delete it.
            if(newFilters?.id) {
                delete newFilters.id
                document.getElementById('numberInput').value = '';
            }
            if(newFilters.hasOwnProperty(category) == false) {
                // console.log("Added filter to new category")
                // if the category already exists, but there are no filters in the category, add the filter to the category
                newFilters[category] = [asset]
                // console.log(newFilters)
                setUserFilters(userFilters => ({... userFilters, [category]: [asset]}))
                // setUserFilters(newFilters)
            } else if (newFilters.hasOwnProperty(category) == true && newFilters[category].indexOf(asset) == -1) {
                // if the category already exists, add the new filter to the category and chcek it doesn't already exist 
                // console.log("Added filter to existing category with existing array")
                newFilters[category].push(asset)
                // console.log(`the following asset should be in there: ${asset}`)
                // console.log(newFilters)
                setUserFilters(userFilters => ({... userFilters, [category]: newFilters[category]}))
            } else {
                // console.log("Something went wrong inside the filters")
            }
        }
    }

    function replaceFilters(category, filter) {
        setUserFilters(userFilters => ({[category]: [filter]}))
    }

    // assetsByCategories
    function renderFilterList() {

        const filterAccordian = (<>
            <div className="flex flex-col w-screen md:w-[14rem] md:max-w-[14rem] md:mr-14 lg:w-[16rem] lg:max-w-[16rem] lg:mr-16 2xl:w-[25rem] 2xl:max-w-[25rem] 2xl:mr-24">
                <div>
                    <h4 className='text-black px-2 text-teko font-[400]'>Alpha Filter</h4>
                    {/* </div> */}
                </div>
                <form id="filtersForm">
                    {/* Create an input text box that only accepts numbers */}
                    <div className='mx-2 mt-2 mb-6'>
                        <input type="number" id="numberInput" className="text-black w-full border-[1px] antialiased rounded-[4px] border-black px-4 pb-1 pt-1.5 h-8 font-acumin_bold font-semibold text-[12pt]"  placeholder='SEARCH NUMBER' onChange={(event)=>{
                            handleChangeId(event)
                        }
                        } onKeyDown={(e) => {
                                if(e.key === "Enter") {
                                    e.preventDefault()
                                }
                            }} />
                    </div>
                    
                    {/* accordian header */}
                    { Object.keys(assetsByCategories).map((category, index) => {
                        return(<>
                            <Accordian key={index} category={category} addToFilters={addToFilters} removeFilter={removeFilter} mobile={false} />
                        </>)
                        })}
                </form>
            </div>
        </>)


        return filterAccordian;
    }

    function renderMobileFilterList() {

        const filterAccordian = (<>
            <div className="flex flex-col w-screen mt-2 px-2">
                <div className='flex flex-row place-content-between align-middle mr-2'>
                    <h4 className='text-black px-2 text-teko font-[400]'>Alpha Filter</h4>
                    <button title="Close" id="closeImg" onClick={()=>{setShowMobileFilters(false)}} ><img src={CloseImg} className="w-8 md:w-4 invert hover:scale-105 hover:opacity-80 hover:cursor-pointer" /></button>
                </div>
                { userFilters && 
                    <div className='px-2 mt-2'>
                        { showActiveFilters() }
                    </div>
                }
                <form id="filtersForm">
                    {/* Create an input text box that only accepts numbers */}
                    <div className='mx-2 mt-2 mb-6'>
                        <input type="number" id="numberInput" className="text-black w-full border-[1px] antialiased rounded-[4px] border-black px-4 pb-1 pt-1.5 h-8 font-acumin_bold font-semibold text-[12pt]"  placeholder='SEARCH NUMBER' onChange={(event)=>{
                            handleChangeId(event)
                        }
                        } onKeyDown={(e) => {
                                if(e.key === "Enter") {
                                    e.preventDefault()
                                }
                            }} />
                    </div>
                    
                    {/* accordian header */}
                    { Object.keys(assetsByCategories).map((category, index) => {
                        return(<>
                            <Accordian key={index} category={category} addToFilters={addToFilters} removeFilter={removeFilter} mobile={true} />
                        </>)
                        })}
                </form>
            </div>
        </>)

        return filterAccordian;
    }

    return(<>
           {/* <PageHeader header="Class of '22" subheader="There's never been a finer looking bunch." /> */}
           <button className={`rounded-md font-teko text-[1.1rem] tracking-wider uppercase  border-2 border-black px-3 pt-2 py-1 fixed bottom-4 ${ isMobile ? "right-[75px]" : "right-4"} z-[999] transition-all ${ showNumbers ? "bg-off_white hover:bg-gray-100 text-black" : "bg-black hover:bg-gray-900 text-white"}`}
               onClick={()=>{setShowNumbers(!showNumbers)}}
               >{ showNumbers ? "Showcase Mode" : "Off"}
           </button>
           { isMobile && <button className={`rounded-md font-teko text-[1.1rem] tracking-wider uppercase border-2 border-black px-3 pt-2 py-1 fixed bottom-4 right-4 z-[999] transition-all ${ !showMobileFilters ? "bg-off_white hover:bg-gray-100 text-black" : "bg-black hover:bg-gray-900 text-white"}`}
               onClick={()=>{setShowMobileFilters(!showMobileFilters)}}
               ><img src={filterIcoV2} className={`w-6 py-0.5`} title="Show filter list" />
           </button>}

           <div className={ isMobile ? `w-full mt-32 lg:mt-44 px-2 lg:px-8 flex flex-col items-start` : `w-full mt-32 lg:mt-44 px-2 lg:px-8 flex ${ showNumbers == true ? "flex-row items-end" : "flex-col items-start"}`}>
                <h2 className='text-black md:w-[14rem] md:max-w-[14rem] md:mr-14 lg:w-[16rem] lg:max-w-[16rem] lg:mr-16 2xl:w-[25rem] 2xl:max-w-[25rem] 2xl:mr-24 pb-6 lg:pb-12'>Gallery</h2>
                <div className=''>
                    { userFilters && showActiveFilters()}
                </div>
            </div>
           <div className={`w-full ${showNumbers == true ? "px-2" : "px-0" } lg:px-8`} >
                <div className="pb-20 md:px-0">
                    {/* The gallery elements */}
                    <div className='w-full flex flex-row'>

                        {/* Show filters in a different way on mobile */}
                        { !isMobile ? 
                            <div id="filterForm" className={`hidden ${showNumbers == true ? "lg:flex" : "lg:hidden"}`}>
                                { renderFilterList() }
                            </div>
                        :
                            <motion.div initial={{y: "100vh"}} animate={showMobileFilters ? "open" : "closed"} variants={filtersVariants} transition={{duration: 0.2, type: "spring", damping: 17}} id="filterForm" className={`${ showMobileFilters == true ? "flex" : "flex"} fixed top-0 left-0 h-screen bg-white w-scren z-[999] pt-2 overflow-scroll`}>
                                { renderMobileFilterList() }
                            </motion.div>
                        }
                        <div id="galleryDiv" className='mr-0 lg:mr-5'>
                            { elementaryRecords?.elementaries[0].id != 0 && galleryGrid() }
                            { loading && loader() }
                            { elementaryRecords?.elementaries[elementaryRecords.elementaries.length - 1].after == -1 &&
                                <div className='w-full mt-8'>
                                    <p className='text-black italic text-center'>No more results.</p>
                                </div>
                            }
                        </div>
                    </div>

                    <AnimatePresence >
                    { selectedToken && 
                        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opaicty: 0}} transition={{duration: 0.1}} className='w-screen h-screen fixed flex md:justify-center md:items-center top-0 lg:backdrop-blur-[1px] left-0 bg-black/30 z-[999]'
                         onClick={()=>{setSelectedToken(null); history(`/gallery`)}}
                        >
                         { renderAEInfoCard()}
                        </motion.div>
                    }
                    </AnimatePresence>
                </div>
            </div>
        </>);
}

function Accordian(props) {
    const [active, setActive] = useState(false)
    let pad = props.mobile ? "py-0" : "py-3";

    const accordianElem = (
    <div className='' key={props.index}>
        <div onClick={()=>{setActive(!active)}} className={`flex flex-row justify-between py-2 pl-2 pr-4 ${ props.mobile == true ? "bg-white hover-bg-off_white" : "hover:bg-[#F4EAEA] bg-off_white"} transition-colors duration-200 rounded-md hover:cursor-pointer`}>
            <h3 className='text-black font-semibold leading-0 text-[1.3rem] -mb-4'>{props.category}</h3>
            <h4 className='text-black leading-0'>{ active ? "-" : "+" }</h4>
        </div>
        <div id="content" className=''>
            <ul className={`${ active ? `${pad} bg-white h-auto rounded-md mb-4` : "h-0 overflow-hidden"}`}>
            { assetsByCategories[props.category].map((asset, idx) => {
                return(
                <li id={`category_data`} key={idx+asset} className={"flex flex-row px-3 py-0.5 hover:cursor-pointer transition-colors ease-in-out duration-75"}>
                    <input type={"checkbox"} name={asset} id={`${asset}_input`} className='w-5 bg-exiled_red accent-exiled_red focus:ring-exiled_red dark:focus:ring-exiled_red mr-2 hover:cursor-pointer'
                    onChange={(e) => {
                        if(e.target.checked) {
                            // console.log("👉 Add filter to prop")
                            props.addToFilters(e.target.checked, props.category, asset)
                        } else {
                            // console.log("👉 Remove filter from prop")
                            // console.log(props.category, asset)
                            props.removeFilter(props.category, asset)
                        }
                    }} />
                    <label htmlFor={`${asset}_input`} className={`text-black flex-grow hover:cursor-pointer ml-2 font-acumin font-[400] ${ !props.mobile ? "mt-1" : "my-auto" } uppercase tracking-wide text-[14px]`}>
                        { asset } 
                        {/* <span className='font-teko text-exiled_grey'>({ metadataNumbers.traits[props.category]?.[asset] })</span> */}
                    </label>     
                    
                </li>
                )
            })}
            </ul>
        </div>
    </div>)

    return accordianElem;
}