import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

//images 
import openSeaBlack from '../img/opensea.svg';
import raritySniper from '../img/v2_assets/rar-snip.svg';
import backImg from '../img/v2_assets/back.svg';
import downloadImg from '../img/v2_assets/download.svg';
import btnLoading from '../img/icons/loader.svg';
import linkImg from '../img/v2_assets/link.svg';


export default function Student(props) {
    const { id } = useParams();
    const [isOwner, setIsOwner] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [aeData, setAeData] = useState(null);
    
    useEffect(() => {
        setIsLoaded(true);
        if(props.isConnected) {
            props.getTokens()
        }
    }, [props.isConnected])

    useEffect(() => {
        if(aeData == null) {
            getMetadata();
            window.scrollTo(0,0);
        }
    }, [])

    useEffect(() => {
        if(props.tokenIDs) {
            setIsOwner(checkOwner())
            // console.log("checking owner")
        }
    }, [props.tokenIDs])

    function getMetadata() {
        let dataDump;
        fetch(`https://moetiqf369.execute-api.us-east-1.amazonaws.com/returnAEMetadata?id=${id}`,{method: "GET"})
            .then(res => res.json())
            .then((result) => {
                setAeData(result);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    function checkOwner() {
        if (props.tokenIDs) {
            for(let i = 0; i < props.tokenIDs.length; i++) {
                // console.log(props.tokenIDs[i]);
                if (id == props.tokenIDs[i]) {
                    return true;
                }
            }
        }
        return false;
    }

    return (
        <>
            <div className="w-full min-h-screen flex flex-row mt-[65px] md:mt-0 md:flex-col place-content-around" id="playground-bg" >
                <div className="mx-auto">
                    <div className={`bg-white md:rounded-xl px-3 py-3 flex flex-col md:flex-row relative drop-shadow-md delay-100 transition-all duration-400
                    ${ isLoaded ? "top-0 opacity-100" : "top-2 opacity-0" }
                    `}>

                        {/* Back + Copy Buttons */}
                        <div className='relative md:absolute top-0 md:top-[-28px] w-full place-content-between mb-3 '>
                            <div className='float-left'>
                                { props.userAddress != '' &&
                                <Link to={'/students/'} className="hover:no-underline text-black md:text-white flex flex-row  items-center transition-all hover:ml-[-4px] ">
                                    <img src={backImg} className="w-5 md:w-4 invert md:invert-0" />
                                    <p className='p-0 ml-2 text-lg md:text-base'>Back</p>
                                </Link> }
                            </div>
                            <div className='float-right mr-1 md:mr-6'>
                                <Link to="#" onClick={() => {
                                    navigator.clipboard.writeText(`https://www.alphaelementary.io/students/${id}`);
                                    props.notification("Link coppied to clipboard!", "success")
                                    }} className="hover:no-underline text-black md:text-white flex flex-row items-center justify-end transition-all hover:mt-[-2px]">
                                    <img src={linkImg} className="w-5 md:w-4 invert md:invert-0" />
                                    <p className='p-0 ml-2 text-lg md:text-base'>Copy Link</p>
                                </Link>
                            </div>
                        </div>

                        <div className='rounded-lg'>
                            <img src={ require(`../img/ae_students/${id}.png`)} className='w-fit rounded-lg' />
                        </div>

                        <div>
                            <div className='pt-3 px-2 md:pl-6 md:pr-3 flex flex-row place-content-between md:w-[380px]'>
                                <div className=''>
                                    <a
                                        className="ransition-all hover:no-underline hover:opacity-75" 
                                        href={`https://opensea.io/assets/ethereum/0x692038c37f56e1772ce1d61cd0ff2d4289028458/${id}`}
                                        target="_blank">
                                    </a>
                                        <h3 className='text-black'>AE #{id}</h3>
                                        { isOwner == true && <span className='text-black'> Owned by <span className='font-bold'>you</span></span> }
                                </div>
                                <div className='flex flex-row space-x-2 items-start'>
                                    <a
                                    className="block group relative transition-all" 
                                    href={`https://opensea.io/assets/ethereum/0x692038c37f56e1772ce1d61cd0ff2d4289028458/${id}`}
                                    target="_blank">
                                    <img src={openSeaBlack} alt="View on OpenSea" className="w-8 group-hover:opacity-75" />
                                    <p className='hidden group-hover:block border-none text-[11px] text-center font-semibold absolute mx-auto text-white leading-none bg-black py-1 px-2 mt-1 -left-4 rounded-lg transition-all'>OpenSea</p>
                                    </a>
                                    <a
                                    className="block group relative transition-all"
                                    href={`https://raritysniper.com/alpha-elementary/${id}`}
                                    target="_blank">
                                    <img src={raritySniper} alt="View on Rarity Sniper" className="h-8 group-hover:opacity-75 " />
                                    <p className='hidden group-hover:block border-none text-[11px] text-center font-semibold absolute mx-auto text-white leading-none bg-black py-1 px-2 mt-1 -left-2 rounded-lg'>Rarity Sniper</p>
                                    </a>
                                </div> 
                            </div>

                            <div className='pt-6 px-2 md:px-6 -mb-1'>
                                <h4 className='text-black'>Traits</h4>
                                <div className='grid grid-cols-2  gap-x-2 mt-4 w-full text-left'>
                                    { aeData &&Object.keys(aeData).map((key, index) => {
                                        if (key != "id") {
                                        return (

                                        <div key={index} className="w-full md:w-[96%]">
                                            <h4 className='text-[#AAAAAA] md:text-[22px]'>{key}</h4>
                                            <p className='text-black text-lg md:text-base pt-2 md:pt-0 pb-2'>{aeData[key]}</p>
                                        </div>
                                        )}})
                                    }
                                </div>
                                { !aeData && (<>
                                    <div className='flex flex-col mt-4 w-full h-24 items-center justify-center'>
                                        <img src={btnLoading} className='animate-spin w-8 invert' /> 
                                        <p className='text-black text-base italic mt-2 animate-pulse'>Fetching data...</p>
                                    </div>
                                    </>)
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
