import React from "react";

// logos
import metamask_logo from './img/metamask_logo.svg';
import wallet_connect_icon from './img/walletconnect-circle.svg';

// images
import neue_backpack from './img/show_and_tell/Backpack_Neue.jpg';


export class PrimaryButton extends React.Component {
    render() {
        return <a 
        onClick={this.props.click}
        href={this.props.link}
        className="tracking-tight uppercase text-xl font-bebas text-white px-12 py-2 rounded-lg 
        inline-block cursor-pointer transition-colors no-underline
        bg-salmon hover:bg-[#C33C3C] active:bg-[#C33C3C] hover:no-underline"
        >{this.props.children}</a>;
    }
}

export class PrimaryButtonNarrow extends React.Component {
    render() {
        return <a
        target={this.props.target}
        onClick={this.props.click}
        href={this.props.link}
        className="
        flex ml-3 tracking-tight px-7 py-[5px] leading-snug font-bebas
        uppercase text-sm bg-salmon text-white text-center rounded-lg cursor-pointer
        transition-colors
        hover:bg-primary_h
        active:bg-primary_a
        hover:no-underline
        ">
            {this.props.children}
            </a>;
    }
}

export class ButtonNarrowCol extends React.Component {
    render() {
        return <a
        target={this.props.target}
        onClick={this.props.click}
        href={this.props.link}
        className="
        flex ml-3 tracking-tight px-7 py-[5px] leading-snug 
        uppercase text-sm bg-primary_text text-white text-center rounded-lg cursor-pointer
        transition-colors
        hover:bg-tertiary
        active:bg-[#06212C]
        hover:no-underline
        " >
            {this.props.children}
            </a>;
    }
}

export class PrimaryButtonNarrowLoading extends React.Component {
    render() {
        return <a
        className="
        flex ml-3 tracking-tight px-7 py-[5px] leading-snug 
        uppercase text-sm bg-gray-400 text-white rounded-lg cursor-pointer
        transition-colors
        hover:bg-gray-500
        active:bg-gray-500
        hover:no-underline
        ">
            {this.props.children}
            </a>;
    }
}

export class ConnectWindowPopUp extends React.Component {
    btnStyle = "font-sans text-white border-2 border-white px-3 py-3 block w-[100%] hover:bg-white hover:text-black active:bg-gray-700 flex flex-col lg:flex-row items-center ";

    render() {
        return <>
            <div className="fixed top-0 left-0 w-screen h-screen z-[100] flex flex-col justify-center" >
                <div id="choose-wallet-provider" className="bg-black w-[90%] lg:w-[450px] px-12 py-12 text-center text-white mx-auto space-y-8 drop-shadow-3xl">
                    
                    {/* <span className=" hidden lg:block"> */}
                    { window.ethereum && <button onClick={this.props.conn_metamask} className={this.btnStyle} >
                        <img src={metamask_logo} className="w-14 lg:mr-6 py-4 mx-6" />
                        <p className="pb-0 text-center font-sans mx-auto "><span className="font-bebas pb-0 text-lg">MetaMask</span>
                        <br /><span className="text-[16px]">Connect with MetaMask (Desktop)</span></p>
                    </button> }
                    {/* </span> */}

                    <button onClick={this.props.conn_wallconnect} className={this.btnStyle}>
                        <img src={wallet_connect_icon} className="w-14 lg:mr-6 py-4 mx-6" />
                        <p className="pb-0 text-center font-sans mx-auto "><span className="font-bebas pb-0 text-lg">WalletConnect</span>
                        <br /><span className=" text-[16px]">Connect with a mobile wallet</span></p>
                    </button>
                    <button className="w-[100%] font-bebas text-[24px] tracking-tight px-7 py-[5px] leading-snug uppercase bg-black text-white border-2 border-white text-center
                     transition-colors hover:bg-white active:bg-white hover:border-white hover:text-black hover:no-underline"
                     onClick={this.props.toggle}
                     >
                    Close
                     </button>
                </div>
                
            </div>
        </>
    }
}

export class PageHeader extends React.Component {
    render() {
        return (
            <>
                <div id="page-banner" className="w-full  mt-[55px]">
                    <div className="text-center container px-10 mx-auto pt-20 pb-32 lg:pt-28 lg:pb-32 2xl:pt-40 2xl:pb-48" >
                        <h1 className="text-black
                        text-[4rem] leading-[3.5rem]
                        lg:text-[5rem] lg:leading-[4.8rem] lg:-mt-4
                        2xl:text-[7rem] 2xl:leading-[6.4rem]
                        ">{this.props.header}</h1>
                        <h5 className="text-black
                        mx-auto font-nanum text-[1.1rem] w-[85%]
                        sm:w-[40%]
                        lg:text-[1.3rem]
                        2xl:text-[1.5rem]">{this.props.subheader}</h5>
                       
                    </div>
                </div>
            </>
        );
    }
}

export class Connect extends React.Component {
    
    getNum(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    ae_num = this.getNum(1,3000);

    render() {
        return(
            <>
                <div className="text-center px-10 mt-6">
                    <h3 className="text-black mb-2">Please connect your wallet</h3>
                    <p className="text-black">Please connect with a wallet containing at least one Alpha Elementary kid.</p>

                    <button onClick={this.props.connect}
                        className="tracking-tight uppercase text-xl font-bebas text-white px-12 py-2 rounded-lg 
                        inline-block cursor-pointer transition-colors no-underline
                        bg-salmon hover:bg-[#C33C3C] active:bg-[#C33C3C] hover:no-underline"
                        >
                            Connect Wallet
                    </button> 

                    <div className="my-14 mx-auto block">
                        <a href={`https://opensea.io/assets/ethereum/0x692038c37f56e1772ce1d61cd0ff2d4289028458/${this.ae_num}`} alt="View on OpenSea" target="_blank">
                            <img src={ require(`./img/ae_students/${this.ae_num}.png`) } className="w-80 rounded-full inline-block" alt={`AE #${this.ae_num}`}/>
                        </a>
                        <p className="text-sm text-black py-1 hover:no-underline">AE {this.ae_num}</p>
                    </div>

                    <p className="text-black"><span className="font-bold">Don’t have an Alpha?</span><br />Check out the collection  
                    on <a className=""
                        href="https://opensea.io/collection/alphaelementary"
                        target="_blank">OpenSea</a>
                        , or come meet us in the <i>#School-yard</i> in <a className=""
                        href="https://discord.gg/WBsxBgRvdZ"
                        target="_blank">Discord</a>.</p>
                </div>
            </>
        );
    }
}