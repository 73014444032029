import React, {useState, useEffect} from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import { useMediaQuery } from 'react-responsive';

// import unrevealedSAT from '../img/show_and_tell/coming_soon.png';
// import neue_backpack from '../img/show_and_tell/Backpack_Neue.jpg';
// import btnLoading from '../img/icons/loader.svg';
import ae_to_sat_tokens from './show_and_tell/collection_metadata_consolidated.json';
import ae_allocation from './show_and_tell/allocation_list.json';
import ae_data from './show_and_tell/artefact_data.json';
import sat_ids from './show_and_tell/reveal_check/filenames.json';

let ae_id = []
let artefact_ids = []
let revealling_items = []

export default function MyCollection (props) {
    const isMobile = useMediaQuery({query: `(max-width: 600px)`});
    const [satArray, setSatArray] = useState();
    const [fetched, setFetched] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    
    const oneBus = "py-5 mx-8 mb-8 flex flex-row justify-end rounded-xl busloads pb-[250px] pt-5 pl-[80px] pr-[500px] w-[100%]";
    const moreBus = "py-5 mx-8 mb-8 flex flex-row justify-end rounded-xl busloads pb-[250px] pt-5 pr-[510px] w-[100%]"

    // automatically check tokens once wallet is connected
    
    useEffect(() => {
        if(props.isConnected) {
            checkOwner()
            checkMetadata()
        }
      }, [props.isConnected])
    

    function checkOwner() {
        if(String(props.userAddress).toLowerCase() == '0xaD04907941f84d7f63Bd51e8066Ac08847895A2E'.toLowerCase()) {
            console.log("you are on the list")
            setIsOwner(true);
        } else {
            console.log("Incorrect account")
        }
    }
    let count = 0;
    function checkMetadata() {
        // console.log(sat_ids[1]);
        for(let i = 0; i < sat_ids.length; i++) {
            for(let j = 0; j < ae_to_sat_tokens.length; j++) {
                if(ae_to_sat_tokens[j].sat_tokenIDs.indexOf(sat_ids[i]) != -1) {
                    let loc = ae_to_sat_tokens[j].sat_tokenIDs.indexOf(sat_ids[i]);
                    // console.log("FOUND")
                    ae_id.push(ae_to_sat_tokens[j].ae_tokenIDs[loc])
                    count++;
                }
            }
        }
        for(let k = 0; k < ae_id.length; k++) {
            artefact_ids.push(ae_allocation[ae_id[k]-1].artefactid)
            // console.log(`artefact id: ${ae_allocation[ae_id[k]-1].artefactid}`)
            revealling_items[k] = []
            revealling_items[k][0] = ae_data.showAllocations[ae_allocation[ae_id[k]-1].artefactid].show_and_tell_item;
            revealling_items[k][1] = ae_data.showAllocations[ae_allocation[ae_id[k]-1].artefactid].image_add;
            revealling_items[k][2] = ae_allocation[ae_id[k]-1].artefactid;
            revealling_items[k][3] = sat_ids[k];
            revealling_items[k][4] = ae_id[k];
            revealling_items[k][5] = String(ae_allocation[ae_id[k]-1].stolen);

            // console.log(revealling_items[k])
        } 
    }
    


    const connectBtn = (<>
      <button onClick={props.connectFunc}
          className="tracking-tight uppercase text-xl font-bebas text-white px-12 py-2 rounded-lg 
          inline-block cursor-pointer transition-colors no-underline
          bg-salmon hover:bg-[#C33C3C] active:bg-[#C33C3C] hover:no-underline">
              Connect Wallet
          </button>
      </>);

    function getGall() {
        let galleryList = (<><div className='text-black'>Please wait...</div></>);

        // if(props.satTokens) {
            galleryList = revealling_items.map(function(token) {
            return <>
                    <div id="card" className='mx-8 mb-8 flex flex-col items-center basis-1/5 space-y-2'>         
                    <div className='w-full relative'>

                            {/* Revealed */}
                            { token[1] &&
                            <img src={ `https://www.alphaelementary.io/artifacts/500/${token[1]}` } className='min-w-[260px] rounded-md ' /> }
                            { token[4] <= 3000 && <img src={ require(`./img/ae_students/${token[4]}.png`) } className='w-[26%] rounded-full absolute bottom-[-15px] right-[-15px]' /> }
 
                    </div>

                    <h4 className='text-black'>{token[0] && token[0]}</h4> 
                    <p className='text-xl text-black'>Artefact ID: {token[2]}</p>
                    <p className='text-xl text-black'>Show and tell file: {token[3]}</p>
                    <p className='text-xl text-black'>AE Number: {token[4]}</p>
                    <p className='text-xl text-black'>Stolen: {token[5]}</p>
                    </div>

                    </>
            });
        // }

        return (<>{galleryList}</>);

    }


    function getMintModal() {

// artworkRevealed

        const noTokens = (<>
            <div id="card" className='bg-white  py-12 flex flex-col items-center content-between rounded-xl'>
                <h3 className='text-black'>No artworks found.</h3>
                <p className='text-black'>Please ensure you are connected with the correct wallet on Ethereum Mainnet.</p>
            </div>
        </>);
        

        return(<>
            { isOwner ? getGall() : noTokens }
            {/* { gallery } */}
            </>
        );
    }

    
    return(<>
            <div id="show_and_tell_bg">
            <div className="relative overflow-x-hidden pt-10 min-h-screen max-h-auto flex flex-col place-content-around" >
                <div className="container mx-auto pt-14 pb-20">

                    <div className='flex flex-col w-full items-center' >
                        <h3 className='font-nanum text-black text-center font-lg lg:text-[2rem]'>Welcome to alpha elementary</h3>
                        <h1 className='text-black text-center mx-8 my-2 lg:mx-10 text-[80px] leading-[60px] lg:leading-[100px] lg:text-[120px]'>Show <span className="text-[#B7B7B7]">{isMobile ? "&" : "and" }</span> tell</h1>
                        <div className='w-full'><p className='text-lg text-black text-center mx-8 lg:mx-10 pb-8 lg:pb-20'>It's Show and Tell time.<br /> When your tokens turn into backpacks, you'll be able to unzip them and reveal what's inside.</p></div> 
                        { (!props.isConnected) && <div className='my-8'>
                              {/* <img src={neue_backpack} className="w-96" />  */}
                        </div> }
                        <div className='flex flex-row flex-wrap place-content-center' id="students">
                            { (!props.isConnected) &&  connectBtn}
                            
                            { isOwner == true && getGall() }
                            {/* { satArray.length > 0 && getGall() } */}
                           

                        </div>
                        {/* { props.isConnected && <p className='text-[18px] leading-4 max-w-xl text-center pb-0'><strong>Not seeing all your artworks?</strong><br /> If you recently minted one, please wait a moment then hit the refresh button below. Transactions can take a while to process.</p> } */}
                        {/* { props.isConnected && <PrimaryButtonNarrow click={props.fetchUserTokens} target="_blank" >Refresh</PrimaryButtonNarrow> } */}
                        {/* <h3 className='text-center text-black'>data: {satArray && satArray}</h3>
                        <h3 className='text-center text-black'>data: {satArray && satArray[0]}</h3>
                        <h3 className='text-center text-black'>data: {props.satTokens && props.satTokens}</h3> */}
                        

                    </div>

                </div>
            </div>
            </div>
        </>);
}