import React from "react";
import { PrimaryButtonNarrow } from  './components';

import RaritySniperImg from './img/logo3-white.png';


export default function Footer () {
    return <Modal />;
}

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {open: false};
    }

    get popup() {
        const pop = (<div className=" z-50 fixed top-0 left-0 w-screen lg:h-screen bg-black bg-opacity-90 flex justify-center items-center">
            <div className="bg-white text-black px-8 py-8 h-screen lg:h-fit lg:w-1/2 lg:rounded-lg overflow-auto pb-20 lg:pb-10">
                <p className="text-xs mb-3 pt-16 lg:pt-0"><i>January 27, 2022</i></p>
                <p className="mb-2">Except as permitted by the copyright law applicable to you, you may not reproduce or communicate any of the content on this website (this does not apply to the NFT minted via the Smart Contract), including files downloadable from this website, without the permission of the copyright owner.
                </p>
                <p className="mb-2">We will provide clear guidelines for minted NFTs prior to launch date (basically don't use them for evil).</p>
                <p className="mb-2">The Australian Copyright Act allows certain uses of content from the internet without the copyright owner&apos;s permission. This includes uses by educational institutions and by Commonwealth and State governments, provided fair compensation is paid. For more information, 
                see <a href="https://www.copyright.com.au" target="_blank">www.copyright.com.au</a> and <a href="https://www.copyright.org.au" target="_blank">www.copyright.org.au</a>.</p><p className="mb-2">
                The owners of copyright in the content on this website may receive compensation for the use of their content by educational institutions and governments, including from licensing schemes managed by Copyright Agency.
                </p><p className="mb-2">
            We may change these terms of use from time to time. Check before re-using any content from this website.</p>
            <span className="flex justify-center pt-6">
                <PrimaryButtonNarrow click={(e) => this.setState(({open}) => ({open: !open}))}>
                Close
                </PrimaryButtonNarrow>
            </span>
            </div>
            
    </div>);
        return pop;
    }

    render() {
        return <>
        <div className="bg-black py-12 px-10 flex flex-col items-center">
            <p className="mx-auto text-center" >Listed on Rarity Sniper</p>
            <a href="https://raritysniper.com/alpha-elementary" target="_blank"><img src={RaritySniperImg} className="h-24 mx-auto mb-12 inline-block" /></a>
            <p className="text-center mb-2">
                &#169; The Alpha Academy Project, 2022.
            </p>
            <p className="text-center text-xs">
                All images on this site belong to SaJo and HogChop and are subject to copyright laws. Images must not be used without consent.
                <br /><br /><a onClick={(e) => this.setState(({open}) => ({open: !open}))} className="text-primary hover:underline cursor-pointer">Click here</a> for further terms and conditions.
            </p>
            { this.state.open && this.popup }
        </div>
    </>;
    }
}
