import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

//images 
import MenuClose from '../img/v2_assets/close_btn.svg';
import openSeaBlack from '../img/opensea.svg';
import backImg from '../img/v2_assets/back.svg';
import linkImg from '../img/v2_assets/link.svg';
import fullsizeImg from '../img/v2_assets/full_size.svg';
import screenSM from '../img/v2_assets/screen_sm.svg';
import screenLG from '../img/v2_assets/screen_lg.svg';
import screenMB from '../img/v2_assets/screen_mb.svg';
import btnLoading from '../img/icons/loader.svg';
import unrevealedSAT from '../img/show_and_tell/coming_soon.png';

// elements svgs
import dopiumImg from '../img/v2_assets/dopium.svg';
import vibiumImg from '../img/v2_assets/vibium.svg';
import delinquiumImg from '../img/v2_assets/delinquium.svg';
import corruptiumImg from '../img/v2_assets/corruptium.svg';


export default function Artefacts(props) {
    const { id } = useParams();
    const [isOwner, setIsOwner] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [aeData, setAeData] = useState(null);
    const [modalData, setModalData] = useState('');
    
    useEffect(() => {
        setIsLoaded(true);
        if(props.isConnected) {
            props.getTokens();
        }
    }, [props.isConnected])

    useEffect(() => {
        if(props.satTokens) {
            ownerTools()
        }
    }, [props.satTokens])

    useEffect(() => {
        if(aeData == null) {
            getMetadata();
            window.scrollTo(0,0);
        }
    }, [])

    function getMetadata() {
        let dataDump;
        // for testing
        fetch(`https://ae-show-and-tell.s3.amazonaws.com/${id}?${Math.floor(Math.random()*99999)}`,{method: "GET"})
            .then(res => res.json())
            .then((result) => {
                let obj = {
                    id: result.id,
                    name: result.name,
                    filename: result.image,
                    elementName: [],
                    elements: []
                }

                if(result.animation_url) {
                    obj.animation_url = result.animation_url;
                }
                
                // get image
                if(obj.filename == "ipfs://QmXm9yBm6MFiPL7Y4g3HyYjDbUxE7gpE68zmiKmhnj1wGH") {
                    obj.filename = "https://www.alphaelementary.io/backpacks/Backpack_Neue.jpg"
                } else if (obj.filename == "ipfs://QmeLrcEnBuebFnNKo34JEur7BTfR9qK98BEYXM7fwF1ZfW") {
                    obj.filename = "https://www.alphaelementary.io/backpacks/Backpack_Psyche.jpg"
                } else if (obj.filename == "ipfs://Qma7RAYq3Vtr2YBP4ieK3FEuD8ux164F5V9EzzjsS9g1tL") {
                    obj.filename = "https://www.alphaelementary.io/backpacks/Backpack_Organica.jpg"
                } else if (obj.filename == "ipfs://QmZMpEwHZ31K1hETDbT6jgLhtkTzHYo9FnDNnHrWehg1ZM") {
                    obj.filename = "https://www.alphaelementary.io/backpacks/Backpack_Retro.jpg"
                } else if (obj.filename == "https://alphaelem.s3.amazonaws.com/backpack_fourhouse.png") {
                    obj.filename = "https://www.alphaelementary.io/backpacks/Backpack_fourhouse.png"
                } else if (obj.filename == "ipfs://QmR23xFPYT5K7PbteatEuJnHXHNurR6Aib1m8FbKZxzHqW") {
                    obj.filename = unrevealedSAT;
                }
                
                for(let i = 0; i < result.attributes.length; i++) {
                    // console.log(result.attributes[i])
                    if(result.attributes[i].trait_type == 'House') {
                        obj.house = result.attributes[i].value;
                    }
                    if(result.attributes[i].trait_type == 'Element') {
                        switch(result.attributes[i].value) {
                            case('Dopium'):
                                obj.elements.push(dopiumImg);
                                obj.elementName.push('Dopium');
                                break;
                            case('Delinquium'):
                                obj.elements.push(delinquiumImg);
                                obj.elementName.push('Delinquium');
                                break;
                            case('Corruptium'):
                                obj.elements.push(corruptiumImg);
                                obj.elementName.push('Corruptium');
                                break;
                            case('Vibium'):
                                obj.elements.push(vibiumImg);
                                obj.elementName.push('Vibium');
                                break;
                        } 
                    }
                }

                // console.log(obj)
                setAeData(obj);
            })
            .catch((error) => {
                // console.log(error)
            })
    }

    function checkElements() {

        if (aeData && aeData.elements.length > 0) {
            return (
                <>
                    <div className='pt-1 md:pt-0 px-2 md:px-6'>
                        <h4 className='text-black'>{ Object.keys(aeData.elements).length > 1 ? "Elements" : "Element"}</h4>
                        <div className='mt-2 grid grid-cols-3 gap-4 relative h-24'>
                            { Object.keys(aeData.elements).map(function(key, index) {
                                return(
                                    <Link key={index} to={`/elements#${aeData.elementName[index]}`}>
                                        <img src={aeData.elements[index]} className="w-24 transition-all hover:mt-[-3px]"/>
                                    </Link>
                            )})}
                        </div>
                    </div>
                </>
            )
        }
    }

    function checkHouse() {
        if (aeData && aeData.hasOwnProperty('house')) {
            return (
                <>
                    <div className='pt-6 px-2 md:px-6'>
                        <h4 className='text-black'>House</h4>
                        <p className='text-black text-lg md:text-base pt-2 md:pt-0'>{aeData.house}</p>
                    </div>
                </>
            )
        }
    }

    function ownerTools() {
        if (props.satTokens) {
            for(let i = 0; i < props.satTokens.length; i++) {
                // console.log(props.satTokens[i])
                if (id == props.satTokens[i]) {
                    setIsOwner(true)
                }
            }
        }
    }

    function ownerGoodies() {

        if (!isOwner) {
            return(<></>);
        }

        const mobFull = ['bobble_head_psychedelia.png','bull_skull_psychedelia.png','butterfly_psychedelia.png','captains_companion_neue.png','captains_companion_organica.png','captains_companion_psychedelia.png','captains_companion_retrogroove.png','cursed_apple_a_psychedelia.png','eye_psychedelia.png','golden_psilocybin_scyphozoa_organica.png','lava_blob_retrogroove.png','mind_bust_psychedelia.png','jester_psychedelia.png','penguin_neue.png','rubber_ducky_psychedelia.png','skull_psychedelia.png','skull_retrogroove.png',] // these ones should go full screen on mobile
        const mobZoom = ['rubber_ducky_retrogroove.png','rubber_ducky_organica.png','rubber_ducky_neue.png'] // zoom in a little on mobile
        const hideOnLandscape = ['golden_psilocybin_scyphozoa_organica.png','lava_blob_retrogroove.png']

        let fname = ''
        let dply = ''
        let hideOption = false
        if(aeData) {
            fname = aeData.filename.replace("https://alphaelem.s3.amazonaws.com/artefacts/", "")
            // fname = "captains_companion_psychedelia.png"
            if(mobFull.indexOf(fname) != -1) {
                dply = 'fh';
            }
            
            if(mobZoom.indexOf(fname) != -1) {
                dply = 'z';
            }

            if(hideOnLandscape.indexOf(fname) != -1) {
                hideOption = true;
            }
        }

        return(<>
        { isOwner &&
            <div className='pt-6 pb-4 md:pb-0 mx-2 md:mx-6 bg-slate-100 rounded-lg'>
                <h4 className='text-black pl-2'>Owner Extras <i className='text-[20px] text-slate-500'>beta feature</i></h4>
                <div className='my-2 grid grid-cols-2 md:grid-cols-4 gap-1 relative mx-auto'>
                    <a href={aeData?.filename} target="_blank" className="group justify-self-center px-1 py-1 hover:no-underline text-center block bg-black/0 hover:bg-black/10 rounded-lg transition-opacity">
                        <img src={fullsizeImg} alt="View full resolution" className='w-18 md:w-12 group-hover:opacity-100 opacity-75 transition-opacity mx-auto' />
                        <p className='text-[14px] md:text-[10px] text-black py-0 leading-tight'>Full Size</p>
                    </a>
                    { !hideOption && 
                    <a onClick={() => {setModalData(`/creator/wallpaper_maker/index.html?img=${fname}&p=${dply}&d=d1`)}} href="#" className="group justify-self-center px-1 py-1 hover:no-underline text-center block bg-black/0 hover:bg-black/10 rounded-lg transition-opacity">
                        <img src={screenSM} alt="View full resolution" className='w-12 md:w-8 py-2 group-hover:opacity-100 opacity-75 transition-opacity mx-auto' />
                        <p className='text-[14px] md:text-[10px] text-black py-0 leading-tight'>Wallpaper<br />1920 x 1080</p>
                    </a> }
                    { !hideOption &&
                    <a onClick={() => {setModalData(`/creator/wallpaper_maker/index.html?img=${fname}&p=${dply}&d=d2`)}} href="#" className="group justify-self-center px-1 py-1 hover:no-underline text-center block bg-black/0 hover:bg-black/10 rounded-lg transition-opacity">
                        <img src={screenLG} alt="View full resolution" className='w-12 md:w-8 py-2 group-hover:opacity-100 opacity-75 transition-opacity mx-auto' />
                        <p className='text-[14px] md:text-[10px] text-black py-0 leading-tight'>Wallpaper<br />3840 x 2160</p>
                    </a> }
                    <a onClick={() => {setModalData(`/creator/wallpaper_maker/index.html?img=${fname}&p=${dply}&d=`)}} href="#" className="group justify-self-center px-1 py-1 hover:no-underline text-center block bg-black/0 hover:bg-black/10 rounded-lg transition-opacity">
                        <img src={screenMB} alt="View full resolution" className='w-12 md:w-8 py-2 group-hover:opacity-100 opacity-75 transition-opacity mx-auto' />
                        <p className='text-[14px] md:text-[10px] text-black py-0 leading-tight'>Wallpaper<br />Auto size</p>
                    </a>
                </div>
            </div>
        }
        </>)
    }

    function modWin() {
        return(<>
        <div className='absolute top-0 left-0 w-full h-full bg-black/40 z-[99] flex flex-col place-content-around' onClick={() => {setModalData('')}}>
            <div className='mx-auto'>
                <div className='bg-white md:rounded-xl px-3 py-3 flex flex-col justify-items-stretch relative drop-shadow-md'>
                    <div className='ml-auto'>
                        <a href='#' onClick={() => {setModalData('')}}>
                            <img src={MenuClose} className="invert w-10 hover:opacity-75 hover:scale-95" alt="close window" />
                        </a>
                    </div>
                    <iframe src={modalData && modalData} width="100%" height="150" />
                    {/* <a className='text-black no-underline hover:bg-slate-200 px-3 py-2 rounded-lg' href={modalData}>View full page</a> */}
                </div>
            </div>
        </div>
        </>)
    }

    return (
        <>
            { modalData && modWin() }
            <div className="w-full md:min-h-screen flex flex-row mt-[65px] md:mt-0 md:flex-col place-content-around" id="playground-bg" >
                <div className="mx-auto">
                    <div className={`bg-white md:rounded-xl px-3 py-3 flex flex-col md:flex-row relative drop-shadow-md delay-100 transition-all duration-400
                    ${ isLoaded ? "top-0 opacity-100" : "top-2 opacity-0" }
                    `}>

                        {/* Back + Copy Buttons */}
                        <div className='relative md:absolute top-0 md:top-[-28px] w-full place-content-between mb-3 '>
                            <div className='float-left'>
                                { props.userAddress != '' &&
                                <Link to={'/students/'} className="hover:no-underline text-black md:text-white flex flex-row  items-center transition-all hover:ml-[-4px] ">
                                    <img src={backImg} className="w-5 md:w-4 invert md:invert-0" />
                                    <p className='p-0 ml-2 text-lg md:text-base'>Back</p>
                                </Link> }
                            </div>
                            <div className='float-right mr-1 md:mr-6'>
                                <Link to="#" onClick={() => {
                                    navigator.clipboard.writeText(`https://www.alphaelementary.io/show_and_tell/${id}`);
                                    props.notification("Link coppied to clipboard!", "success")
                                    }} className="hover:no-underline text-black md:text-white flex flex-row items-center justify-end transition-all hover:mt-[-2px]">
                                    <img src={linkImg} className="w-5 md:w-4 invert md:invert-0" />
                                    <p className='p-0 ml-2 text-lg md:text-base'>Copy Link</p>
                                </Link>
                            </div>
                        </div>

                        <div className='rounded-lg hidden md:block'>
                            { aeData?.animation_url && <iframe src={aeData.animation_url} className="md:w-[501px] md:h-[501px] bg-slate-100 rounded-lg"/> }
                            { aeData ? <img src={aeData.filename} className={`md:max-w-[500px] rounded-lg ${aeData.animation_url && 'hidden'}`} /> : <img src={unrevealedSAT} className={`md:max-w-[500px] rounded-lg animate-pulse`} /> }
                        </div>
                        <div className='md:hidden rounded-lg'>
                            { aeData ? <img src={aeData.filename} className={`md:max-w-[500px] rounded-lg`} /> : <img src={unrevealedSAT} className={`md:max-w-[500px] rounded-lg animate-pulse`} /> }
                        </div>

                        <div>
                            <div className='pt-3 px-2 md:pl-6 md:pr-3 flex flex-row place-content-between md:w-[380px]'>
                                <div className=''>
                                    { aeData &&
                                        <>
                                            <a
                                                className="ransition-all hover:no-underline hover:opacity-75" 
                                                href={`https://opensea.io/assets/ethereum/0xb6b4ca6fe80008c429b713c26d6dcebc4ac32893/${id}`}
                                                target="_blank">
                                                <h3 className='text-black'>{aeData.name}</h3>
                                            </a>
                                            <p className='text-black'>Artefact #{aeData.id}{ isOwner == true && <> | Owned by <span className='font-bold'>you</span></> }</p>
                                            
                                        </>
                                    }
                                    
                                </div>
                                <div className='flex flex-row space-x-2 items-start'>
                                    <a
                                    className="block group relative transition-all" 
                                    href={`https://opensea.io/assets/ethereum/0xb6b4ca6fe80008c429b713c26d6dcebc4ac32893/${id}`}
                                    target="_blank">
                                    <img src={openSeaBlack} alt="View on OpenSea" className="w-8 group-hover:opacity-75" />
                                    <p className='hidden group-hover:block border-none text-[11px] text-center font-semibold absolute mx-auto text-white leading-none bg-black py-1 px-2 mt-1 -left-4 rounded-lg transition-all'>OpenSea</p>
                                    </a>
                                </div> 
                            </div>

                            {checkElements()}
                            {checkHouse()}
                            
                            { !aeData && (<>
                                <div className='flex flex-col mt-4 w-full h-60 items-center justify-center'>
                                    <img src={btnLoading} className='animate-spin w-8 invert' /> 
                                    <p className='text-black text-base italic mt-2 animate-pulse'>Fetching data...</p>
                                </div>
                                </>)
                            }

                            {/* for later */}
                            { aeData && ownerGoodies() }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
