import React, { useEffect, useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { AnimatePresence, motion } from "framer-motion";
// import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
// import { useLazyQuery, gql } from '@apollo/client';
// import { saveToLocalStorage, loadFromLocalStorage } from './components/localStorageUtils';

// images
import AeLogoW from './img/AE_logo_V2.svg';
import StatusDot from './img/status_dot.svg';
import openSea from './img/opensea-w.svg';
import twitter from './img/exiled/twitter.svg';
import discord from './img/exiled/discord.svg';
import MenuClose from './img/v2_assets/close_btn.svg';
import MenuIcon from './img/v2_assets/burger_menu.svg';
import ExtLink from './img/v2_assets/external_link.svg';


export default function Navbar(props, {fixed}) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [userWidgetOpen, setUserWidgetOpen] = useState(false);
  // const [props.authedUser, setUserStatus] = useState(null);

  const widgetStates = {
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      // transition: { type: "spring", stiffness: 300, damping: 24, duration: 0.1 }
    },
    hidden: { opacity: 1, y: -10, scale: 1, transition: { duration: 0.1 }},
  }

  // IF new data is downloaded from DB, save it to local storage
  // useEffect(() => {
  //   if(userStatusResult) {
  //     // setUserStatus(userStatusResult) COMMENTING OUT TO SEE IF IT WORKS IN APP.JS
  //     props.setAuthedUser(userStatusResult)
  //     let tmpLocalStorage = {...userStatusResult, wallet: props.userAddress}
  //     // save to local storage
  //     saveToLocalStorage("getUser", tmpLocalStorage, 15);
  //   }
  // }, [userStatusResult])

  // useEffect(() => {
  //   if(userStatus) {
  //     console.log("userStatus")
  //     console.log(userStatus)
  //   }
  // }, [userStatus])


  function connectWalletMobile() {
    props.connectFunc()
    setNavbarOpen(!navbarOpen)
  }

  // function renderUserAccountWidget() {
  //   // render the widget with information based upon their status and WL status
  //   if(userStatusLoading) { // if waiting for data
  //     return (
  //       <div className="flex felx-row space-x-2 items-center">
  //         <p className="w-2/3 text-center pb-0 font-acumin italic">
  //           Loading...
  //         </p>
  //       </div>
  //     )
  //   } else if( props.authedUser?.getUser) { // if user account exists
  //     return (
  //       <>
  //         {/* AE Students */}
  //         { props.authedUser?.getUser.elementary_token_ids.length > 0 &&
  //         <>
  //             <h5>Your Alphas</h5>
  //             <div className={`grid py-2 ${userStatus.getUser.elementary_token_ids.length < 7 ? "grid-cols-4 gap-3" : "grid-cols-6 gap-2 max-h-36 overflow-y-scroll"}`}>
  //               { userStatus.getUser.elementary_token_ids.map((ae, idx) => {
  //                 return(
  //                   <Link to={`/students/${ae}`} key={idx}>
  //                     <img src={ require(`./img/ae_students/${ae}.png`) } className="w-full rounded-full hover:cursor-pointer hover:border-2 hover:border-white" alt={`Alpha ${ae}`} title={`AE #${ae}`} />
  //                   </Link>
  //                 )
  //               }) }
  //             </div>
  //           </>
  //           }

  //         <hr className="opacity-40" />
  //         {/* Exiled Status */}
  //         <div className="my-2">
  //           <h5 className="mb-1">Alpha Exiled Status</h5>
  //           <div className="flex felx-row space-x-2 items-center">
  //             <span className="w-1/3"><img src="https://placehold.co/160x160" className="w-full opacity-20" /></span>
  //             <p className="w-2/3 text-left pb-0 font-acumin">
  //               { props.authedUser?.getUser.status && <><strong>Status:</strong> { userStatus.getUser.status } <br /></> }
  //               { props.authedUser?.getUser.approved && <><strong>Application:</strong> { userStatus.getUser.approved ? "Approved" : "Declined" } <br /></> }
  //               { props.authedUser?.getUser.elementary_token_ids && <><strong>A.E.s Registered:</strong> { props.authedUser?.getUser.elementary_token_ids.length > 0 ? props.authedUser?.getUser.elementary_token_ids.length : 0 } <br /></>}
  //             </p>
  //           </div>
  //         </div>
  //       </>
  //     )
  //   } else if(!props.authedUser?.getUser) { // if user account doesn't exist
  //       if(props.isHolder) {
  //         return (
  //           <>
  //             <hr className="opacity-40" />
  //             {/* Exiled Status */}
    
  //             <hr className="opacity-40" />
  //             {/* Exiled Status */}
  //             <div className="my-2">
  //             <h5 className="mb-1">Alpha Exiled Status</h5>
  //               <div className="flex felx-row space-x-2 items-center">
  //                 <p className="w-2/3 text-center pb-0 font-acumin">
  //                   You are an A.E. holder and eligable to join the allowlist
  //                 </p>
  //               </div>
  //             </div>
  //           </>
  //         )
  //       } else if(true) { // update to "date" for public release
  //         return (
  //           <>
  //             <hr className="opacity-40" />
  //             {/* Exiled Status */}
    
  //             <hr className="opacity-40" />
  //             {/* Exiled Status */}
  //             <div className="my-2">
  //             <h5 className="mb-1">Alpha Exiled Status</h5>
  //               <div className="flex felx-row space-x-2 items-center">
  //                 <p className="w-2/3 text-center pb-0 font-acumin">
  //                   Allow list not open yet
  //                 </p>
  //               </div>
  //             </div>
  //           </>
  //         )
  //       }
  //   }
  // }

  return (
    <>
      <nav className="z-[60] fixed top-0 w-screen flex flex-col bg-black lg:py-1">
        <div className="mx-3 flex justify-between items-center lg:items-start">
            <div className="flex flex-row">
                <div
                className="logo min-w-[80px] lg:min-w-[240px] xl:min-w-[270px] 2xl:min-w-[285px] text-sm leading-relaxed display-inline mr-4 my-auto py-2 lg:pt-2 pl-2 pr-1.5 lg:pr-0 whitespace-nowrap uppercase no-underline text-white flex flex-row lg:flex-col"
                to="/#"
                >
                    <div>
                    <Link
                    className=""
                    to="elementary/#"
                    >
                    <img src={AeLogoW} alt="Alpha Elementary Logo" className="w-12 lg:px-1.5 pb-0 ml-0 mr-3 lg:mr-0"/>
                    </Link>
                    </div> 
            </div>
            </div>

            {/* DESKTOP NAV ELEMENTS */}
            {/* DESKTOP NAV ELEMENTS */}
            <div className="mr-1 lg:mr-5 lg:px-2 my-auto bg-black md:flex-row items-center space-x-6 xl:space-x-14 text-center hidden lg:flex">
                <Link onClick={() => {window.scrollTo(0,0);}} to="/gallery" className="nav-btn text-white hover:no-underline transition-all border-b-2 border-black hover:border-white hover:border-b-2 py-2">A.E. Gallery</Link>
                <Link onClick={() => {window.scrollTo(0,0);}} to="/houses" className="nav-btn text-white hover:no-underline transition-all border-b-2 border-black hover:border-white hover:border-b-2 py-2">The Houses</Link>
                <Link onClick={() => {window.scrollTo(0,0);}} to="/elements" className="nav-btn text-white hover:no-underline transition-all border-b-2 border-black hover:border-white hover:border-b-2 py-2">The Elements</Link>
            
            { props.isHolder ? (<>
                <Link onClick={() => {window.scrollTo(0,0);}} to="/students" className="nav-btn text-white hover:no-underline transition-all border-b-2 border-black hover:border-white hover:border-b-2 py-2">Your Alphas</Link>
                <Link onClick={() => {window.scrollTo(0,0);}} to="/experiments" className="nav-btn text-white hover:no-underline transition-all border-b-2 border-black hover:border-white hover:border-b-2 py-2">The Lab</Link>
                {/* <a href={'https://store.alphaelementary.io'} target={"_blank"} className="nav-btn text-white hover:no-underline transition-all border-b-2 border-black hover:border-white hover:border-b-2 py-2">Store <img src={ExtLink} alt="Visit Store" className="lg:inline-block w-3 -mt-0.5" /></a> */}
                </>)
                : (<>
                <div className="relative group">
                    <button disabled={true} className="nav-btn cursor-pointer text-white hover:no-underline opacity-50 transition-all border-b-0 py-3" href="#" >Your Alphas</button>
                    <span className="absolute left-0 right-0 mx-auto opacity-0 top-10 group-hover:top-12 transition-all group-hover:opacity-100 bg-salmon w-24 text-center py-1 text-[10px] rounded-full">Members only</span>
                </div>
                {/* <div className="relative group">
                    <button disabled={true} className="nav-btn cursor-pointer text-white hover:no-underline opacity-50 transition-all border-b-0 py-3" href="#" >🎨 Art Class</button>
                    <span className="absolute left-0 right-0 mx-auto opacity-0 top-10 group-hover:top-12 transition-all group-hover:opacity-100 bg-salmon w-24 text-center py-1 text-[10px] rounded-full">Members only</span>
                  </div> */}
                  <Link onClick={() => {window.scrollTo(0,0);}} to="/experiments" className="nav-btn text-white hover:no-underline transition-all border-b-2 border-black hover:border-white hover:border-b-2 py-2">Experiments</Link>
                {/* <a href={'https://store.alphaelementary.io'} target={"_blank"} className="nav-btn text-white hover:no-underline transition-all border-b-2 border-black hover:border-white hover:border-b-2 py-2">Store <img src={ExtLink} alt="Visit Store" className="lg:inline-block w-3 -mt-0.5" /></a> */}
                </>)
            }
            </div>

          <div className="lg:mr-5 lg:px-2 my-auto bg-black flex flex-row items-center space-x-3 mr-2 lg:space-x-4">
            <a
              className="w-[24px] hover:opacity-75"
              href="https://opensea.io/collection/alphaelementary"
              target="_blank">
              <img src={openSea} alt="Witness the collection" className="w-[24px]" />
            </a>
            <a
                  className="w-[24px] hover:opacity-75"
                  href="https://twitter.com/AlphaAcademyInc"
                  target="_blank"
                >
                  <img src={twitter} alt="Follow us on twitter to hear us shouting into the void" className="w-[24px]" />
                </a>
                <a
                  className=" w-[24px] hover:opacity-75"
                  href="https://discord.gg/WBsxBgRvdZ"
                  target="_blank"
                >
                  <img src={discord} alt="Join us in the playground" className="w-[24px]" />
                </a>

            <div id="connectWallet" className="relative">

            { props.userAddress != "" && <>
              <a onClick={()=>{setUserWidgetOpen(!userWidgetOpen)}} className="group lg:flex hidden flex-row place-content-between mx-auto mt-[1px] lg:ml-0 lg:mr-0 std_btn_no_y_pad h-10 items-center space-x-2 px-2 border-2 text-white rounded-lg cursor-pointer transition-colors no-underline hover:bg-white hover:bg-opacity-20 hover:no-underline">
                  {/* Render PFP if found in userStatus */}
                { (props?.authedUser && props.authedUser?.getUser && props.authedUser?.getUser.pfp != 0) ?
                // PFP found, render it!
                <>
                  {/* <span className="bg-green-500 w-2 h-2 rounded-full"></span> */}
                  {/* {<motion.img key={`${props.authedUser.getUser.pfp}_avatar`} initial={{x: 30}} animate={{x: 0, transform: {delay: 1}}} src={ require(`./img/ae_students/${props.authedUser.getUser.pfp}.png`) } alt="This dude just popped in to say hi" className="h-[30px] w-[30px] border border-gray-500 rounded-full -mt-[9px] -mb-2" />} */}
                  <div className="relative select-none pr-1">
                    { props.authedUser?.getUser?.discord_username ? `@${props.authedUser.getUser.discord_username}` : `0x...${props.userAddress.substring(37,42)}`}
                  </div>
                </>
                :
                <>
                  <span className="bg-green-500 w-2 h-2 rounded-full ml-1 mr-2"></span>
                  <div className="relative">
                    <span className="flex flex-row lowercase items-center select-none">
                      { props.authedUser?.getUser?.discord_username ? `@${props.authedUser.getUser.discord_username}` : `${props.userAddress.substring(0,5)}...${props.userAddress.substring(37,42)}`}
                    </span>
                    </div>
                  </>
                }
              </a>

              {/* ACCOUNT WIDGET */}
              <AnimatePresence exitBeforeEnter>
                { userWidgetOpen &&
                  <motion.div variants={widgetStates} initial="hidden" animate="visible" exit={{opacity: 0, y: -10}} className="bg-black px-3 pt-4 pb-2 absolute w-[300px] top-12 right-0">
                  <div className="text-left">
                      {/* { renderUserAccountWidget() } */}
                      {/* SHOW CONNECTED AS */}
                      <hr className="opacity-40 mb-2" /> 
                      <div className="text-center mb-2">
                        <p className="pb-0 leading-3 text-[0.6rem] opacity-40 uppercase">Connected as:</p>
                        <p className="pb-0 font-teko text-[1rem]">{props.userAddress}</p>
                      </div>
                    {/* DISCONNECT BUTTON */}
                    <a onClick={props.disconnect} className="lg:flex hidden flex-row items-center mx-auto pt-2 pb-1 border-2 text-white text-center rounded-lg cursor-pointer no-underline hover:bg-white hover:bg-opacity-20 hover:no-underline font-teko">
                      <div className="relative uppercase mx-auto text-[15pt] leading-5">
                          Disconnect Wallet
                      </div>
                    </a>
                  </div>
                </motion.div>
                }
              </AnimatePresence>
              </>
            }

            { props.userAddress == "" &&
              <button className="lg:flex hidden flex-row mx-auto px-5 py-1 std_btn border-2 text-white rounded-lg cursor-pointer transition-colors hover:bg-white hover:bg-opacity-20 hover:no-underline items-center" onClick={props.connectFunc}>
                  {/* <span className="bg-green-500 w-2 h-2 rounded-full mr-2"></span> */}
                  <span className="">Connect Wallet</span>
              </button>
            }
            </div>

            <button className="lg:hidden px-2 py-1 leading-snug border-2 text-white rounded-lg cursor-pointer transition-colors hover:bg-white hover:bg-opacity-20 hover:no-underline items-center flex" onClick={() => setNavbarOpen(!navbarOpen)}>
                  { props.userAddress != "" && <span className="bg-green-500 w-2 h-2 rounded-full mr-2"></span>}
                  <img src={ navbarOpen ? MenuClose : MenuIcon } alt="mobile menu" className="w-6" />
            </button>
            
            {/* MOBILE MENU (OPEN) */}
            {/* MOBILE MENU (OPEN) */}
            { navbarOpen &&
                <div className="absolute w-[300px] top-[66px] right-0 bg-black">
                    <div className="flex flex-col text-center">
                            <Link onClick={() => setNavbarOpen(!navbarOpen)} to="elementary/#about" className=" nav-btn text-white hover:no-underline transition-all border-b-0 py-5">About A.E.</Link>
                            {/* <Link onClick={() => setNavbarOpen(!navbarOpen)} to="/easter_hunt" className=" nav-btn text-white hover:no-underline transition-all border-b-0 py-5">Easter Hunt 🐰</Link> */}
                            <Link onClick={() => setNavbarOpen(!navbarOpen)} to="/gallery" className=" nav-btn text-white hover:no-underline transition-all border-b-0 py-5">A.E. Gallery</Link>
                            <Link onClick={() => {
                                    setNavbarOpen(!navbarOpen);
                                    window.scrollTo(0,0);
                            }} to="/houses" className=" nav-btn text-white hover:no-underline transition-all border-b-0 py-5">The Houses</Link>
                            <Link onClick={() => {
                                    setNavbarOpen(!navbarOpen);
                                    window.scrollTo(0,0);
                                  }} to="/elements" className="nav-btn text-white hover:no-underline transition-all border-b-0 py-5">The Elements</Link>
                                  { props.isHolder ? (<>
                            <Link onClick={() => {
                                    setNavbarOpen(!navbarOpen);
                                    window.scrollTo(0,0);
                            }} to="/students" className=" nav-btn text-white hover:no-underline transition-all border-b-0 py-5">Your Alphas</Link>
                            {/* <Link onClick={() => {
                                setNavbarOpen(!navbarOpen);
                                window.scrollTo(0,0);
                            }} to="/show_and_tell" className=" nav-btn text-white hover:no-underline transition-all border-b-0 py-5">Show &amp; Tell</Link> */}
                            <Link onClick={() => {
                                setNavbarOpen(!navbarOpen);
                                window.scrollTo(0,0);
                            }} to="/experiments" className=" nav-btn text-white hover:no-underline transition-all border-b-0 py-5">The Lab</Link>
                            <Link onClick={() => {
                                setNavbarOpen(!navbarOpen);
                                window.scrollTo(0,0);
                            }} to="/artclass" className=" nav-btn text-white hover:no-underline transition-all border-b-0 py-5">🎨 Art Class</Link>
                            {/* <a onClick={() => {
                                setNavbarOpen(!navbarOpen);
                            }} href={'https://store.alphaelementary.io'} target={"_blank"} className=" nav-btn text-white hover:no-underline transition-all border-b-0 py-5 ">
                              Store <img src={ExtLink} alt="Visit Store" className="inline-block w-3 -mt-0.5" /></a> */}
                            </>
                            )
                            : (<>
                            <div className="relative group">
                                <button disabled={true} className=" cursor-pointer nav-btn text-white hover:no-underline opacity-50 transition-all border-b-0 py-5" href="#" >Your Alphas</button>
                                <span className="absolute left-0 right-0 mx-auto opacity-0 top-8 group-hover:top-10 transition-all group-hover:opacity-100 bg-salmon w-24 text-center py-1 text-[10px] rounded-full">Members only</span>
                            </div>
                            <div className="relative group">
                                <button disabled={true} className=" cursor-pointer nav-btn text-white hover:no-underline opacity-50 transition-all border-b-0 py-5" href="#" >Show &amp; Tell</button>
                                <span className="absolute left-0 right-0 mx-auto opacity-0 top-8 group-hover:top-10 transition-all group-hover:opacity-100 bg-salmon w-24 text-center py-1 text-[10px] rounded-full">Members only</span>
                            </div>
                            {/* <a onClick={() => {
                                setNavbarOpen(!navbarOpen);
                            }} href={'https://store.alphaelementary.io'} target={"_blank"} className=" nav-btn text-white hover:no-underline transition-all border-b-0 py-5 ">
                              Store <img src={ExtLink} alt="Visit Store" className="inline-block w-3 -mt-0.5" /></a> */}
                            </>)
                        }

                        { props.userAddress != "" ? (<>
                        <a onClick={props.disconnect} className="text-center mx-auto w-full py-3 text-xs border-y-2 border-[#464646] text-white hover:bg-opacity-20 hover:no-underline items-center">
                        <div className="inline-block">
                            <span className="">Disconnect Wallet</span>
                            </div>
                        </a>
                        <div className="w-full text-center text-xs py-2">
                            <div className="text-center mx-auto w-full text-xs text-[#646464] items-center">Connected:</div>
                            <div>
                                <span className="bg-green-500 w-2 h-2 rounded-full mr-2 inline-block"></span>
                                <span className="group-hover:opacity-0 flex-row inline-block ">
                                    { props.userAddress.substring(0,5)}...
                                    { props.userAddress.substring(38,42)}
                                </span>
                            </div>
                        </div>
                        </>) : <>
                        <button className="text-center nav-btn mx-auto w-full py-3 lg:text-sx xl:text-xs border-y-2 border-[#464646] text-white hover:bg-opacity-20 hover:no-underline items-center" onClick={connectWalletMobile}>
                            <span className="bg-[#646464] w-2 h-2 rounded-full mr-2 inline-block"></span>Connect Wallet
                        </button>
                        </>}
                    </div>
                </div>
            }  
          </div>

        </div>
      </nav>
    </>
  );
}