import React, {useState, useEffect, useRef} from 'react';
import DatePicker from 'react-datepicker';
import { zonedTimeToUtc, format } from 'date-fns-tz';
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { HashLink as Link } from 'react-router-hash-link';
import { motion, AnimatePresence } from 'framer-motion';
import { ethers } from 'ethers';
import AddMumbaiButton from './add_mumbai';

import { Connect } from  '../components';
import { Leaderboard } from  './leaderboard';

import "react-datepicker/dist/react-datepicker.css";

import gridPaper from '../img/icons/gridpaper-thin.svg';
import mangaBackground from '../img/v2_assets/radial-bg-blue.jpg';
import CloseImg from '../img/v2_assets/close_btn.svg'
import arrowight from '../img/v2_assets/chevron-right-arrow.svg'
import polygonNetwork from '../img/v2_assets/polygon-network.png';

const PlayerClass = {
    0: {word: 'rock', symbol: '🪨'},
    1: {word: 'paper', symbol: '📄'},
    2: {word: 'scissors', symbol: '✂️'}
};


const GET_LEADERBOARD = gql`
  query TopRpsPlayers {
    topRpsPlayers {
      tokenId
      wallet
      username
      wins
      kills
      losses
      playerClass
    }
  }
`

// const GET_USERNAMES = gql`
//     query GetUsers($wallets: [String!]) {
//         getUsers(wallets: $wallets) {
//             discord_username
//             wallet
//         }
//     }
// `

// props.rpsTokens = [{tokenId: wins: tokenType: etc...}]
// props.rpsGames = [{matchId: matchOpen: etc...}]
// props.rpsGamePlayers = {matchId: [playerIds...]}
// props.rpsTokenIds = [,2,3,8...]

export default function RPSDashboard (props) {

    const [getLeaderboard, {loading: leaderboardLoading, error: leaderboardError, data: leaderboardData}] = useLazyQuery(GET_LEADERBOARD, {
        notifyOnNetworkStatusChange: true,
    });

    // const { loading: leaderboardLoading, error: leaderboardError, data: leaderboardData } = useQuery(GET_LEADERBOARD, {
    //     fetchPolicy: "network-only",
    // });

    const [value, setValue] = useState(1);
    const [numMatches, setNumMatches] = useState(0);
    const [joinMatchModal, setJoinMatchModal] = useState(null)
    const [selectedPlayer, setSelectedPlayer] = useState(0)
    const [moveDirection, setMoveDirection] = useState(1);
    const [matchestWithHolderTokens, setMatchestWithHolderTokens] = useState(null)
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    useEffect(()=>{
        props.resetMatchState()
    }, [])

    const handleMatchSubmit = (event) => {
      event.preventDefault();
      if (numMatches > 0) {
        props.createNewMatch(numMatches);
      } else {
        alert("numMatches cannot be 0");
      }
    };

    const handleMatchChange = (event) => {
        const inputValue = event.target.value;
        if (!isNaN(inputValue)) {
            setNumMatches(inputValue);
        }
      };

    const handleSubmit = (event) => {
      event.preventDefault();
      if (value > 0 && value < 4) {
        props.mintRPSToken(value);
      } else {
        alert("Input must be an integer between 1 and 3.");
      }
    };
  
    const handleInputChange = (event) => {
      const inputValue = event.target.value;
      if (!isNaN(inputValue)) {
        setValue(inputValue);
      }
    };

    useEffect(()=>{
        if(props?.rpsGamePlayers && props?.rpsTokenIds && props.rpsGames) {
            // console.log("CHECKING TOKENS")
            let tmp_data_array = []
            for(let match of props.rpsGames) {
                // console.log(Object.keys(props.rpsGamePlayers).includes(String(match.matchId)))
                if(Object.keys(props.rpsGamePlayers).includes(String(match.matchId)) && props.rpsGamePlayers[match.matchId].some(id => props.rpsTokenIds.includes(id))) {
                    console.log("match found")
                    let tmpTkn = props.rpsGamePlayers[match.matchId].find(id => props.rpsTokenIds.includes(id));
                     let tknSymbol = props.rpsTokens.find(token => token.tokenID == tmpTkn);
                    tmp_data_array.push([true, tmpTkn, PlayerClass[tknSymbol.tokenType].symbol])
                } else {
                    tmp_data_array.push([false, 0, "x"])
                }
            }
            setMatchestWithHolderTokens(tmp_data_array)
            // fetch leaderboard
            getLeaderboard()
        }
    }, [props.rpsGamePlayers, props.rpsTokenIds, props.rpsGames])

    useEffect(()=>{
        if(props.isConnected) {
            props.getRPSTokens()
            props.getActiveGames()
        }
    }, [props.isConnected])

    function openCreateGameModal() {
        setIsCreateModalOpen(true);
    }

    function closeCreateGameModal() {
        setIsCreateModalOpen(false);
    }

    function renderHolderTokens() {
        if(props.rpsTokens == null) return;

        function checkEntry(playerId) {
            let matches = []
            Object.entries(props.rpsGamePlayers).forEach(([matchId, playersArray]) => {
                if(playersArray.includes(playerId)) {
                    matches.push(matchId);
                }
            })
            if(matches.length > 0) {
                return String(matches.map(match => "#" + match).join(", "))
            } else {
                return "None"
            }
        }

        return(
            <>
               {props?.rpsTokens.length > 0 ? 
               <div className='grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 mx-auto place-content-center gap-4 mt-8'>
                {
                    props.rpsTokens.map((data) => {
                        return(
                            <div className='rounded-2xl border-[3px] border-white overflow-hidden shadow-md group' key={`token_card_${data.tokenID.toNumber()}`}>

                                <div className='flex flex-row'>
                                    <p className={`text-[8rem] px-5 pt-12 leading-[0rem] w-2/6 my-auto group-hover:scale-125 ${ PlayerClass[data.tokenType].word === 'scissors' && "hover:-rotate-45"} transition-transform duration-150`}>
                                        {PlayerClass[data.tokenType].symbol}
                                    </p>

                                    <div className='flex flex-col w-4/6 text-left uppercase border-l-[3px] border-white'>
                                        <div className='font-teko text-white border-b-[3px] border-white text-[2rem] pl-3'>Combatant #{data.tokenID.toNumber()}</div>
                                        <div className=''>
                                            <ul className="grid grid-cols-2 gap-2 px-3 py-4 text-[1.5rem] font-teko leading-7">
                                                <ul>class: <strong>{PlayerClass[data.tokenType].word}</strong></ul>
                                                <ul>max speed: <strong>{data.maxSpeed}</strong></ul>
                                                <ul>wins: <strong>{data.wins}</strong></ul>
                                                <ul>kills: <strong>{data.kills}</strong></ul>
                                                <ul>losses: <strong>{data.losses}</strong></ul>
                                                <ul>games played: <strong>{data.wins + data.losses}</strong></ul>
                                            </ul>
                                        </div>
                                        <div className='font-teko text-white border-t-[3px] border-white text-[2rem] pl-3'>Upcoming matches: 
                                            { props?.rpsGamePlayers && <strong>{" "}
                                                { checkEntry(data.tokenID.toNumber()) }
                                            </strong> }
                                        </div>
                                    </div>

                                </div>

                                <div className='bg-white pt-2 pb-1 text-blue-700 font-semibold'> 
                                    View on opensea | View on Blur
                                </div>    
                            </div>
                        )
                    })
                }
               </div>
                :   
               <>
                { String(props.rpsTokens) }
               </>
            }
            </>
        )
    }

    function renderMintWindow() {
        return(
            <>
               <form onSubmit={handleSubmit} className='flex flex-col space-y-6 place-content-center'>
                    <input
                        type="number"
                        disabled={props.rpsLoading}
                        value={value}
                        onChange={handleInputChange}
                        defaultValue={1}
                        max={3}
                        min={1}
                        onFocus={(e)=>{e.target.select()}}
                        className="px-3 py-0.5 font-teko text-[5rem] mx-auto bg-transparent text-center leading-3"
                    />
                    <button
                        type="submit"
                        disabled={props.rpsLoading}
                        className={`${props.rpsLoading && "animate-pulse"} rounded-md font-teko text-[1.1rem] tracking-wider mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-600 hover:bg-blue-700 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`}
                    >
                        <span className="mr-2">🎲</span>
                        {!props.rpsLoading ? "Mint Combatants" : "Processing..."}
                    </button>
                </form>
            </>
        )
    }

    function renderUpcomingMatches() {
        if (!props.rpsGames) return;

        let currentTime = new Date();

        let twoHoursAgo = new Date();

        twoHoursAgo.setMinutes(twoHoursAgo.getMinutes() + 120);

        return(<>
            <div className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 mx-auto place-content-center gap-4 mt-8">
            { props.rpsGames && props.rpsGames.map((game, index) => {
                let date = new Date(game.matchTime * 1000);

                if(date < twoHoursAgo) { return } // mach has passed

                return(
                    <div key={`${game.matchId}_upcoming`} className={`rounded-2xl border-[3px] border-white overflow-hidden shadow-md group ${!game.matchOpen && "opacity-70"}`}>
                        <div className='w-full flex flex-row py-12 place-content-center align-middle space-x-6'>
                            <p className={`text-[8rem] my-auto pb-0 mt-8`}>
                                ⚔️
                            </p>
                            <div className='flex flex-col space-y-5 -mt-4 -mb-6'>
                                <p className={`text-[5rem] font-teko pb-0 mt-6`}>
                                    {game.matchId.toString().padStart(3, '0')}
                                </p>
                                {game.rewardMatch && <span title="A humble amount, but nothing to sneeze at." className='text-[1.1rem] text-blue-800 leading-4 font-teko font-400 bg-yellow-400 rounded-md py-1.5 px-2 mb-2'>{`Prize: 💰 ${game.rewardAmount} MATIC`}</span>}
                            </div>
                        </div>
                        <div className='border-t-[3px] border-white pt-1 pb-0'>
                            <div className='font-light text-[2rem] text-white font-teko uppercase'>MATCH DATE: <span className='font-bold'>{ date.toLocaleDateString('en-GB',{ day: 'numeric', month: 'long', year: 'numeric' })}, { date.toLocaleTimeString([], {hour12: true, hour: "2-digit", minute: "2-digit"}) }</span></div>
                        </div>
                        <div className='border-t-[3px] border-white pt-1 pb-0'>
                            <div className='font-bold text-[1.5rem] text-white font-teko uppercase'>combatants</div>
                            <div className='font-light text-[1.5rem] text-white font-teko uppercase'>registered: <span className='font-bold'>
                                {props?.rpsGamePlayers && game?.matchId in props?.rpsGamePlayers ? String(props.rpsGamePlayers[game.matchId].length) : 0 }
                            </span></div>
                            <div className='font-light text-[1.5rem] text-white font-teko uppercase'>max: <span className='font-bold'>{ game.maxPlayers }</span></div>
                        </div>
                        <MatchActionButton game={game} setJoinMatchModal={setJoinMatchModal} hasPlayer={matchestWithHolderTokens ? matchestWithHolderTokens[index] : []} matchStatus="upcoming" />
                        { (String(props.userAddress).toLowerCase() == '0xA686e42124B746159552eA4868a5029C7a498eC1'.toLowerCase() && !game.matchSeedGenerated ) &&
                            <button onClick={() => { props.generateMatchSeed(game.matchId) }} className='pt-3 pb-2 text-white font-semibold bg-purple-400 hover:bg-purple-500 block w-full hover:no-underline'>
                                Generate Match Seed
                            </button>
                        }
                    </div>
                )
            })}
            </div>
        </>)
    }

    function renderHappeningNow() {
        if (!props.rpsGames) return;

        // let currentTime = new Date();
        let tenMinutesAgo = new Date();

        tenMinutesAgo.setMinutes(tenMinutesAgo.getMinutes() - 10);

        let twoHoursAgo = new Date();

        twoHoursAgo.setMinutes(twoHoursAgo.getMinutes() - 120);

        return(<>
            <div className="grid grid-cols-2 mx-auto place-content-center gap-4 mt-8">
            { props.rpsGames && props.rpsGames.map((game, index) => {
                let date = new Date(game.matchTime * 1000);

                if(date <= twoHoursAgo) { return }

                return(
                    <div key={`${game.matchId}_past`} className={`rounded-2xl border-[3px] overflow-hidden shadow-md group flex flex-row ${(date >= twoHoursAgo) ? "border-green-400" : "border-white"}`}>
                        <div className='flex flex-col py-12 place-content-center align-middle space-x-6 w-1/3 border-r-[3px] border-white'>
                            <p className={`text-[8rem] my-auto pb-0 mt-8`}>
                                {date >= tenMinutesAgo ? "🏁" : "🏆"}
                            </p>
                            <div className='flex flex-col space-y-5 -mt-4 -mb-6'>
                                <p className={`text-[5rem] font-teko pb-0 mt-6`}>
                                    {game.matchId.toString().padStart(3, '0')}
                                </p>
                                {game.rewardMatch && <span title="A humble amount, but nothing to sneeze at." className='text-[1.1rem] text-blue-800 leading-4 font-teko font-400 bg-yellow-400 rounded-md py-1.5 px-2 mb-2'>{`Prize: 💰 ${game.rewardAmount} MATIC`}</span>}
                            </div>
                        </div>

                        <div className='w-2/3'>
                            <div className=' pt-1 pb-0'>
                                <div className='font-light text-[2rem] text-white font-teko uppercase'>MATCH DATE: <span className='font-bold'>{ date.toLocaleDateString('en-GB',{ day: 'numeric', month: 'long', year: 'numeric' })}, { date.toLocaleTimeString([], {hour12: true, hour: "2-digit", minute: "2-digit"}) }</span></div>
                            </div>
                            <div className='border-t-[3px] border-white pt-1 pb-0'>
                                <div className='font-bold text-[1.5rem] text-white font-teko uppercase'>combatants</div>
                                <div className='font-light text-[1.5rem] text-white font-teko uppercase'>registered: <span className='font-bold'>
                                    {props?.rpsGamePlayers && game?.matchId in props?.rpsGamePlayers ? String(props.rpsGamePlayers[game.matchId].length) : 0 }
                                </span></div>
                                <div className='font-light text-[1.5rem] text-white font-teko uppercase'>max: <span className='font-bold'>{ game.maxPlayers }</span></div>
                            </div>
                            <MatchActionButton game={game} setJoinMatchModal={setJoinMatchModal} hasPlayer={matchestWithHolderTokens ? matchestWithHolderTokens[index] : []} matchStatus={date >= tenMinutesAgo ? "current" : "ended"} />
                        </div>

                    </div>
                )
            })}
            </div>
        </>)
    }

    function renderPastMatches() {
        if (!props.rpsGames) return;

        // let currentTime = new Date();
        let tenMinutesAgo = new Date();

        tenMinutesAgo.setMinutes(tenMinutesAgo.getMinutes() - 10);

        let twoHoursAgo = new Date();

        twoHoursAgo.setMinutes(twoHoursAgo.getMinutes() - 120);


        return(<>
            <div className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 mx-auto place-content-center gap-4 mt-8">
            { props.rpsGames && props.rpsGames.map((game, index) => {
                let date = new Date(game.matchTime * 1000);

                if(date >= twoHoursAgo) { return }

                return(
                    <div key={`${game.matchId}_past`} className={`rounded-2xl border-[3px] overflow-hidden shadow-md group ${(date >= tenMinutesAgo) ? "border-green-400" : "border-white"}`}>
                        <div className='w-full flex flex-row py-12 place-content-center align-middle space-x-6'>
                            <p className={`text-[8rem] my-auto pb-0 mt-8`}>
                                {date >= tenMinutesAgo ? "🏁" : "🏆"}
                            </p>
                            <div className='flex flex-col space-y-5 -mt-4 -mb-6'>
                                <p className={`text-[5rem] font-teko pb-0 mt-6`}>
                                    {game.matchId.toString().padStart(3, '0')}
                                </p>
                                {game.rewardMatch && <span title="A humble amount, but nothing to sneeze at." className='text-[1.1rem] text-blue-800 leading-4 font-teko font-400 bg-yellow-400 rounded-md py-1.5 px-2 mb-2'>{`💰 ${game.rewardAmount} MATIC`}</span>}
                            </div>
                        </div>
                        <div className='border-t-[3px] border-white pt-1 pb-0'>
                            <div className='font-light text-[2rem] text-white font-teko uppercase'>MATCH DATE: <span className='font-bold'>{ date.toLocaleDateString('en-GB',{ day: 'numeric', month: 'long', year: 'numeric' })}, { date.toLocaleTimeString([], {hour12: true, hour: "2-digit", minute: "2-digit"}) }</span></div>
                        </div>
                        <div className='border-t-[3px] border-white pt-1 pb-0'>
                            <div className='font-bold text-[1.5rem] text-white font-teko uppercase'>combatants</div>
                            <div className='font-light text-[1.5rem] text-white font-teko uppercase'>registered: <span className='font-bold'>
                                {props?.rpsGamePlayers && game?.matchId in props?.rpsGamePlayers ? String(props.rpsGamePlayers[game.matchId].length) : 0 }
                            </span></div>
                            <div className='font-light text-[1.5rem] text-white font-teko uppercase'>max: <span className='font-bold'>{ game.maxPlayers }</span></div>
                        </div>
                        <MatchActionButton game={game} setJoinMatchModal={setJoinMatchModal} hasPlayer={matchestWithHolderTokens ? matchestWithHolderTokens[index] : []} matchStatus={date >= tenMinutesAgo ? "current" : "ended"} />
                    </div>
                )
            })}
            </div>
        </>)
    }

    function joinMatchModalWindow() {
        let date = new Date(props.rpsGames[joinMatchModal-1].matchTime * 1000);

        return(
            <div key={`match_modal_match${joinMatchModal}`} className='w-full h-full fixed top-0 left-0 bg-black/30 flex place-content-center align-middle z-50'>
                <motion.div key={`${joinMatchModal}_popup`} className='bg-blue-700 border-[3px] border-white my-auto rounded-2xl overflow-hidden shadow-lg flex flex-row' initial={{y: 100, opacity: 0}} animate={{y: 0, opacity: 1}} exit={{scale: 0.95, transition: { duration: 0.05 } }}  transition={{type: "easeOut" , duration: 0.2, damping: 14}}>
                    <div className='flex flex-col pt-12 pb-6 place-content-center align-middle bg-cover bg-center px-6 text-center' style={{backgroundImage: `url(${mangaBackground})`}}>
                        <motion.p initial={{scale: 0.7}} animate={{scale: 1}} className={`text-[8rem] pb-0 mt-8 leading-[7rem]`}>
                            ⚔️
                        </motion.p>
                        <p className={`text-[5rem] font-teko pb-0 mt-6`}>
                            {joinMatchModal.toString().padStart(3, '0')}
                        </p>
                        <div className='font-light text-[2rem] text-white font-teko uppercase mt-12'>MATCH DATE: <br /><span className='font-bold'>{ date.toLocaleDateString('en-GB',{ day: 'numeric', month: 'long', year: 'numeric' })}, { date.toLocaleTimeString([], {hour12: true, hour: "2-digit", minute: "2-digit"}) }</span></div>
                    </div>

                    <div className='w-[360px] border-l-[3px] border-white relative text-center'>
                        <div className='font-bold text-[1.5rem] text-white font-teko uppercase text-center border-b-[3px] pt-1'>select combatant</div>
                        <button onClick={()=>{setJoinMatchModal(null)}}><img src={CloseImg} className='w-6 absolute top-2 right-4 hover:scale-105 transition-transform' /></button>

                    { props.rpsTokens === null ? 
                    <div className='flex flex-col mt-12'>
                        <p className='pb-0 my-auto'>You do not currently hold any combatants.</p>
                    </div>
                    : <>
                        <div className='flex flex-row place-content-center mb-3'>
                            <button className='disabled:hidden' disabled={props.rpsTokens.length <= 2} onClick={()=>{setSelectedPlayer((selectedPlayer - 1 + props.rpsTokens.length) % props.rpsTokens.length); setMoveDirection(-1)}} ><img src={arrowight} className='w-8 rotate-180' /></button>
                            <div className=''>
                                <motion.p key={`${selectedPlayer}_icon`} initial={{x: 40 * moveDirection, opacity: 0}} animate={{x: 0, opacity: 1}}
                                 className={`text-[8rem] px-5 pt-12 leading-[5rem] text-center`}>{props?.rpsTokens && PlayerClass[props.rpsTokens[selectedPlayer].tokenType].symbol}</motion.p>
                                <div key={`${selectedPlayer}_name`} className='font-bold text-[1.5rem] text-white font-teko uppercase text-center -mt-2'>combatant #{props.rpsTokens[selectedPlayer].tokenID.toNumber()}</div>
                            </div>
                            <button className='disabled:hidden' disabled={props.rpsTokens.length <= 1} onClick={()=>{setSelectedPlayer((selectedPlayer + 1) % props.rpsTokens.length); setMoveDirection(1); }}><img src={arrowight} className='w-8 hover:scale-105 active:scale-95 transition-transform' /></button>
                        </div>
                        <p className='pb-0 leading-5 text-center px-4 text-[0.9rem]'>When you join or leave a match, you are recording your entry on-chain. This will require a transaction which will incur a small gas fee.</p>
                        { (!props.rpsGamePlayers[joinMatchModal] || !props.rpsGamePlayers[joinMatchModal].includes(props.rpsTokens[selectedPlayer].tokenID.toNumber())) ?
                        <>
                            <button key="addplayer" disabled={props.enterMatchLoading} onClick={()=>{props.playerEnterMatch(props.rpsTokens[selectedPlayer].tokenID.toNumber(), joinMatchModal)}}
                            className={`${props.enterMatchLoading && "animate-pulse"} my-5 rounded-md font-teko text-[1.1rem] tracking-wider mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-700 hover:bg-blue-800 active:scale-95 active:bg-blue-900 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`} >
                                <span className="mr-2">🕹</span>
                                {!props.enterMatchLoading ? "Confirm Player Entry" : "Processing..."}
                            </button>
                        </>
                        :
                        <>
                            <button key="removeplayer" disabled={props.enterMatchLoading} onClick={()=>{props.playerLeaveMatch(props.rpsTokens[selectedPlayer].tokenID.toNumber(), joinMatchModal)}}
                            className={`${props.enterMatchLoading && "animate-pulse"} my-5 rounded-md font-teko text-[1.1rem] tracking-wider mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-700 hover:bg-blue-800 active:scale-95 active:bg-blue-900 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`} >
                                <span className="mr-2">🫡</span>
                                {!props.enterMatchLoading ? "Withdraw from Match" : "Processing..."}
                            </button>
                        </>}
                    </>}
                    </div>
                </motion.div>
            </div>

        )
    }

    return(<>
            <AnimatePresence>
                { joinMatchModal && joinMatchModalWindow() }
                <CreateGameModal 
                    isOpen={isCreateModalOpen} 
                    onClose={closeCreateGameModal}
                    onCreateGame={props.createNewMatch}
                />
                <div style={{backgroundImage: `url(${gridPaper})`, backgroundSize: '8px', backgroundRepeat: 'repeat'}} className='bg-blue-600 pt-16'>
                    <div className={`sm:flex lg:hidden bg-red-100 border-[1px] border-red-600 py-4 text-center w-full text-red-700`}>This experience is not yet optimised for small screens.</div>
                    <div className='py-12 container mx-auto text-center px-6 lg:px-0 '>
                        { (props.isConnected && props.currentNetwork != 80001) && 
                            <div className='flex flex-row space-x-6 align-middle'>
                                <img src={polygonNetwork} className='border-[3px] border-white w-[450px]' />
                                <p className='text-white my-auto text-left'><strong>Please switch to the Polygon Test Network</strong> <br />Your Metamask must be on the Polygon Test network in order to see this screen.</p>
                            </div>
                        }
                        { !props.isConnected &&
                            <Connect connect={props.connectFunc} />
                        }

                        { props.isConnected && <>
                            <div className=''>
                                <h3 className='text-white text-left mt-4'>Your Account</h3>
                                <hr className='border-2 border-white' />
                                <div className='flex flex-row space-x-6'>
                                    { props.authedUser && <PlayerCard user={props.authedUser.getUser?.discord_username} pfp={props.authedUser.getUser?.pfp ? props.authedUser.getUser.pfp : 0} tokens={props.userAETokens ? props.userAETokens : []} updatePfp={props.updatePfp} pfpLoading={props.pfpLoading} /> }
                                    <div className='text-center space-y-4'>
                                        <h5 className='pb-0 mt-3'>Need Test tokens?</h5>
                                        <a href="https://faucet.polygon.technology/" target="_blank" className={`rounded-md font-teko text-center text-[1.1rem] tracking-wider flex flex-row mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-600 hover:bg-blue-700 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`}>
                                            <span className="mr-2">🪙</span>
                                            get Test MATIC
                                        </a>
                                        <h5 className='pb-0 mt-3'>Add Testnet to Metamask</h5>
                                        <AddMumbaiButton />
                                    </div>
                                </div>
                            </div>

                            <div className=''>
                                <h3 className='text-white text-left mt-12'>Happening Now</h3>
                                <hr className='border-2 border-white' />
                                { renderHappeningNow() }
                            </div>

                            <div className=''>
                                <h3 className='text-white text-left mt-12'>Your combatants</h3>
                                <hr className='border-2 border-white' />
                                { renderHolderTokens() }
                            </div>

                            <div className=''>
                                <h3 className='text-white text-left mt-12'>Mint a combatant</h3>
                                <hr className='border-2 border-white' />
                                { renderMintWindow() }
                            </div>

                            <div className=''>
                                <div className='flex flex-row place-content-between mt-12 align-bottom'>
                                    <h3 className='text-white text-left'>Matches</h3>
                                    { String(props.userAddress).toLowerCase() == '0xA686e42124B746159552eA4868a5029C7a498eC1'.toLowerCase() &&
                                    <div className=''>
                                        <button
                                            onClick={openCreateGameModal}
                                            className={`rounded-md font-teko text-[1.1rem] tracking-wider mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-600 hover:bg-blue-700 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`}
                                        >
                                            <span className="mr-2">🕹</span>
                                            Create Match
                                        </button>
                                    </div>
                                    }
                                </div>
                                <hr className='border-2 border-white' />
                                { renderUpcomingMatches() }
                                <div className='flex flex-row place-content-between mt-12 align-bottom'>
                                    <h3 className='text-white text-left'>Past Matches</h3>
                                </div>
                                <hr className='border-2 border-white' />
                                { renderPastMatches() }
                            </div>
                            <div className=''>
                                <Leaderboard data={leaderboardData} loading={leaderboardLoading} playerClass={PlayerClass} contractAddress={props.rpsAddress} />
                            </div>
                        </>}

                    </div>
                </div>
            </AnimatePresence>
        </>);
}

function MatchActionButton({ game, setJoinMatchModal, hasPlayer, matchStatus }) {
    if(matchStatus == 'ended'  && game.matchSeedGenerated) {
        // if the match has already happened     
        return (<>
            <div className='border-t-[3px] border-white pt-1 pb-0'>
                <div className='font-light text-[1.5rem] text-white font-teko uppercase'>status: <span className='font-bold'>
                    ended
                </span></div>
            </div>
            <Link onClick={()=>{window.scrollTo(0,0)}} to={`/experiments/rps/game/${game.matchId}`} className=" pt-3 pb-2 text-blue-700 font-semibold bg-white hover:bg-blue-50 block w-full hover:no-underline">
                <span className="mr-2">🔴</span>
                Replay
            </Link>
        </>)
    } else if(matchStatus == 'ended' && !game.matchSeedGenerated) {
        return (<>
            <div className='border-t-[3px] border-white pt-1 pb-0'>
                <div className='font-light text-[1.5rem] text-yellow-300 font-teko uppercase'>status: <span className='font-bold'>
                    cancelled
                </span></div>
            </div>
            <Link disabled={true} className=" pt-3 pb-2 text-white font-semibold bg-yellow-400 hover:bg-yellow-500 block w-full hover:no-underline">
                <span className="mr-2">🚫</span>
                Match Cancelled
            </Link>
        </>)
    } else if(matchStatus == 'current' && game.matchSeedGenerated) {
        return (<>
            <div className='border-t-[3px] border-white pt-1 pb-0'>
                <div className='font-light text-[1.5rem] text-green-300 font-teko uppercase'>status: <span className='font-bold'>
                    Live
                </span></div>
            </div>
            <Link onClick={()=>{window.scrollTo(0,0)}} to={`/experiments/rps/game/${game.matchId}`} className=" pt-3 pb-2 text-white font-semibold bg-green-400 hover:bg-green-500 block w-full hover:no-underline">
                <span className="mr-2">🏁</span>
                Go to match
            </Link>
        </>)
    } else if(matchStatus == 'current' && !game.matchSeedGenerated) {
        // Game is open but for some reason seed has not yet been generated
        return (<>
            <div className='border-t-[3px] border-white pt-1 pb-0'>
                <div className='font-light text-[1.5rem] text-green-300 font-teko uppercase'>status: <span className='font-bold'>
                    Opening soon
                </span></div>
            </div>
            <div className='flex flex-row'>
                <Link onClick={()=>{window.scrollTo(0,0)}} to={`/experiments/rps/game/${game.matchId}`} className=" pt-3 pb-2 text-white font-semibold bg-green-400 hover:bg-green-500 block w-full hover:no-underline">
                    <span className="mr-2">🏁</span>
                    Go to match
                </Link>
                <button onClick={()=>{setJoinMatchModal(game.matchId)}} className=" pt-3 pb-2 text-white font-semibold bg-green-400 hover:bg-green-500 w-full">
                    <span className="mr-2">🥊</span>
                    Manage Participants
                </button>
            </div>
        </>)
    } else if(!game.matchOpen && !game.matchSeedGenerated) {
        // if the game is not open, and the seed isn't generated, it is pre-launch    
        return (<>
            <div className='border-t-[3px] border-white pt-1 pb-0'>
                <div className='font-light text-[1.5rem] text-white font-teko uppercase'>status: <span className='font-bold'>
                    Upcoming
                </span></div>
            </div>
            <button disabled={true} className=" pt-3 pb-2 text-blue-700 font-semibold bg-white hover:bg-blue-50 w-full">
                Coming Soon
            </button>
        </>)
    } else if (game.matchOpen && !game.matchSeedGenerated) {
        // if the game is open, but the match seed hasn't been generated, people can still enter
        return (<>
            <div className='border-t-[3px] border-white pt-1 pb-0'>
                <div className='font-light text-[1.5rem] text-white font-teko uppercase'>status: <span className='font-bold'>
                Open
                </span></div>
            </div>
            <button onClick={()=>{setJoinMatchModal(game.matchId)}} className=" pt-3 pb-2 text-blue-700 font-semibold bg-white hover:bg-blue-50 w-full">
                <span className="mr-2">🥊</span>
                Manage My Participants
            </button>
        </>)
    } else if (game.matchSeedGenerated) {
        // game is locked because match is imminent
        return (<>
            <div className='border-t-[3px] border-white pt-1 pb-0'>
                <div className='font-light text-[1.5rem] text-white font-teko uppercase'>status: <span className='font-bold'>
                    Locked 🔒
                </span></div>
            </div>
            
            { hasPlayer[0] == true ?
            <button disabled={true} className=" pt-3 pb-2 text-blue-700 font-semibold bg-white hover:bg-blue-50 w-full disabled:bg-blue-100">
                <span className="mr-2"></span>
                { hasPlayer[2] } #{ hasPlayer[1] } 
            </button>
            :
            <button disabled={true} className=" pt-3 pb-2 text-blue-700 font-semibold bg-white hover:bg-blue-50 w-full disabled:bg-blue-100">
                Combatants Locked
            </button>
            }
        </>)
    }
    return "error"
}

function CreateGameModal({ isOpen, onClose, onCreateGame }) {
    const [numGames, setNumGames] = useState(1);
    const [maxPlayers, setMaxPlayers] = useState(Array(numGames).fill(30));
    const [rewardMatch, setRewardMatch] = useState(Array(numGames).fill(false));
    const [rewardAmount, setRewardAmount] = useState(Array(numGames).fill('0'));
    const [dates, setDates] = useState(Array(numGames).fill(new Date()));

    // function handleNumGamesChange(e) {
    //     const newNumGames = Number(e.target.value);
    //     setNumGames(newNumGames);
    //     setMaxPlayers(oldMaxPlayers => [...oldMaxPlayers, ...Array(newNumGames - oldMaxPlayers.length).fill(1)]);
    //     setDates(oldDates => [...oldDates, ...Array(newNumGames - oldDates.length).fill(new Date())]);
    // }
    function handleNumGamesChange(e) {
        const num = parseInt(e.target.value);
        if (isNaN(num) || num < 1) {
            setNumGames(1);
            setMaxPlayers([1]);
            setDates([new Date()]);
        } else {
            setNumGames(num);
            setMaxPlayers(Array(num).fill(1));
            setDates(Array(num).fill(new Date()));
        }
    }

    function handleMaxPlayersChange(index, e) {
        const newMaxPlayers = [...maxPlayers];
        newMaxPlayers[index] = Number(e.target.value);
        setMaxPlayers(newMaxPlayers);
    }

    function handleRewardChange(index, e) {
        const newRewardMatch = [...rewardMatch];
        newRewardMatch[index] = e.target.checked;
        setRewardMatch(newRewardMatch);

        if (!e.target.checked) {
            const newRewardAmount = [...rewardAmount];
            newRewardAmount[index] = '0';
            setRewardAmount(newRewardAmount);
        }
    }

    function handleAmountChange(i, e) {
        const valueInMATIC = e.target.value;
        let valueInBaseUnit;
        // Check if the input is empty or not a number
        if (!valueInMATIC || isNaN(valueInMATIC)) {
            valueInBaseUnit = '0';
        } else {
            // Convert to Wei (or the base unit of MATIC)
            valueInBaseUnit = ethers.utils.parseUnits(valueInMATIC, 'ether').toString();
        }
        // Update state
        const newRewardAmount = [...rewardAmount];
        newRewardAmount[i] = valueInBaseUnit;
        // console.log(rewardAmount)
        setRewardAmount(newRewardAmount);
    }

    //  NEED INPUT BOX TO DEFINE REWARD AMOUNT IN MATIC

    function handleDateChange(index, date) {
        const newDates = [...dates];
        newDates[index] = date;
        setDates(newDates);
    }

    function handleSubmit() {
        const matchTimes = dates.map(date => Math.floor(date.getTime() / 1000)); // Convert Date to seconds
        onCreateGame(numGames, maxPlayers, matchTimes, rewardMatch, rewardAmount);
        // onClose();
    }

    function getDateTimeInZone(date, timeZone) {
        const utcDate = zonedTimeToUtc(date, timeZone);
        return format(utcDate, 'MMM d, yyyy H:mm:ss', { timeZone });
    }

    if(!isOpen) return null;

    return (
        <div key={"create_game_modal"} className='w-full h-full fixed top-0 left-0 bg-black/50 flex place-content-center align-middle z-[999]'>
            <motion.div key={"create_game_popup"} className='bg-blue-700 border-[3px] border-white my-auto rounded-2xl overflow-scroll max-h-[90vh] shadow-lg flex flex-col container' initial={{y: 100, opacity: 0}} animate={{y: 0, opacity: 1}} exit={{scale: 0.95, transition: { duration: 0.05 } }}  transition={{type: "easeOut" , duration: 0.2, damping: 14}}>
                <div className='flex flex-col pt-8 pb-6 place-content-center align-middle bg-cover bg-center px-6 text-center border-b-[3px] border-white relative' style={{backgroundImage: `url(${mangaBackground})`}}>
                    <button onClick={onClose}><img src={CloseImg} className='w-6 absolute top-2 right-2 hover:scale-105 transition-transform' /></button>
                    <p className={`text-[8rem] pb-0 mt-4 leading-[7rem]`}>
                        🎮
                    </p>
                    <div className='font-bold text-[1.9rem] text-white font-teko uppercase text-center pt-1'>create matches</div>
                </div>

                <div className='relative text-center'>
                    <div className='flex flex-col place-content-center mb-3 mt-4'>
                        <label className='border-b-[3px] border-white'>
                            <span className='font-teko text-[1.5rem] uppercase'>Number of Matches</span><br />
                            <input type="number" min="1" value={numGames} onChange={handleNumGamesChange} onFocus={(event) => event.target.select()} className='mb-5 mt-2 rounded-md font-teko text-[1.1rem] tracking-wider mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-600 hover:bg-blue-800 active:bg-blue-900 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50'/>
                        </label>
                        {Array(numGames).fill().map((_, i) => (
                            <div className='flex flex-col' key={`${i}_createGame`}>
                                <div className='flex flex-row space-x-6 mx-auto mt-4'>
                                    <label className='block'>
                                        <span className='font-teko text-[1.5rem] uppercase'>Max Players for Game {i+1}</span><br />
                                        <input type="number" min="5" value={maxPlayers[i]} onChange={e => handleMaxPlayersChange(i, e)} onFocus={(event) => event.target.select()} className='mb-5 mt-2 rounded-md font-teko text-[1.1rem] tracking-wider mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-600 hover:bg-blue-800 active:bg-blue-900 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50'/>
                                    </label>
                                    <label>
                                        <span className='font-teko text-[1.5rem] uppercase'>Date & Time for Game {i+1}</span><br />
                                        <DatePicker 
                                            selected={dates[i]}
                                            onChange={date => handleDateChange(i, date)}
                                            showTimeSelect
                                            dateFormat="Pp"
                                            className='mb-5 mt-2 rounded-md font-teko text-[1.1rem] tracking-wider mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-600 hover:bg-blue-800 active:bg-blue-900 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50'
                                        />
                                    </label>
                                    <div className='text-left my-auto'>
                                        <p className='pb-0'>🇬🇧 UK Time: <strong>{getDateTimeInZone(dates[i], 'Europe/London')}</strong></p>
                                        <p className='pb-0'>🇺🇸➡️ New York Time: <strong>{getDateTimeInZone(dates[i], 'America/New_York')}</strong></p>
                                        <p className='pb-0'>🇺🇸⬅️ California Time: <strong>{getDateTimeInZone(dates[i], 'America/Los_Angeles')}</strong></p>
                                    </div>
                                </div>
                                <div className=''>
                                    <input onChange={e => handleRewardChange(i, e)} type='checkbox' name={`awardMatch_${i}`} value={`isAwardMatch_${i}`} />
                                    <label className='ml-1' for={`awardMatch_${i}`}>Match pays a reward</label>
                                    { rewardMatch[i] &&
                                    <div className=''>
                                        <input type="number" name={`${i}_amount`} placeholder='amount' className='text-white font-bold px-2 py-1 text-center w-14 rounded-md border-2 border-white transition-colors bg-blue-600 hover:bg-blue-800 active:bg-blue-900 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50' onChange={e => handleAmountChange(i, e)} />
                                        <label className='ml-1' for={`${i}_amount`} >MATIC</label>
                                    </div> }
                                </div> 
                                <hr className='border-white/50 border-[1px] my-3' />
                            </div>
                        ))}
                    </div>
                    <button onClick={handleSubmit} className={`my-5 rounded-md font-teko text-[1.1rem] tracking-wider mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-700 hover:bg-blue-800 active:scale-95 active:bg-blue-900 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`} >
                        <span className="mr-2">🕹</span>
                        Create Matches
                    </button>
                </div>
            </motion.div>
        </div>
    )
}

// Helper function to deep compare objects
function deepEqual(object1, object2) {
    // Check if either argument is null or undefined
    if (object1 === null || object1 === undefined || object2 === null || object2 === undefined) {
      return object1 === object2;
    }
    
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
  
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    for (let key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        areObjects && !deepEqual(val1, val2) ||
        !areObjects && val1 !== val2
      ) {
        return false;
      }
    }
  
    return true;
  }
  
  function isObject(object) {
    return object != null && typeof object === 'object';
  }
  
  function PlayerCard({user, pfp, tokens, updatePfp, pfpLoading}) {
    const [showAvatarSelect, setShowAvatarSelect] = useState(false)
    const [selectedToken, setSelectedToken] = useState(pfp)

    return(<div key="player_card" className='flex flex-col space-y-4 bg-blue-700 rounded-2xl border-white border-[3px] w-96 mt-4'>
        <div onClick={()=>{setShowAvatarSelect(!showAvatarSelect)}} className='relative mx-auto mt-4 group hover:cursor-pointer'>
        { pfp !== 0 ?
            <img src={require(`../img/ae_students/${pfp}.png`)} className='w-40 rounded-full border-[3px] border-white mx-auto' alt="AE student" />
            :
            <img src={require(`../img/ae_students/${1}.png`)} className='w-1/4 saturate-0 rounded-full border-[3px] border-white mx-auto' alt="AE student" />
        }
        <h5 className='opacity-30 group-hover:opacity-100 transition-opacity absolute top-2 right-0'>✏️</h5>
        </div>

        { showAvatarSelect &&
        <div className=''>
            <h5>Update your PFP</h5>
            <div className='flex flex-row flex-wrap justify-center mx-auto mb-4 mt-2'>
                { tokens.map((tk) => {
                    return(<img onClick={()=>{setSelectedToken(tk)}} src={require(`../img/ae_students/${tk}.png`)} className={`w-14 rounded-full hover:cursor-pointer mx-1 ${selectedToken === tk ? "opacity-100 border-2 border-opacity-100 border-white" : "opacity-60 border-2 border-white/0"}`} alt="AE student" />)
                })}
            </div>

            <div className='flex flex-row space-x-4 mx-auto w-1/2 place-content-center'>
                <button onClick={()=>{setShowAvatarSelect(false)}} className={`rounded-md font-teko text-center text-[1.1rem] tracking-wider uppercase no-underline text-white border-2 border-white px-4 pt-1 pb-0.5 transition-colors bg-blue-600 hover:bg-blue-700 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`}>
                    close
                </button>
                <button disabled={selectedToken === pfp || pfpLoading} 
                onClick={()=>{updatePfp(selectedToken)}}
                className={`rounded-md font-teko text-center text-[1.1rem] tracking-wider uppercase no-underline text-white border-2 border-white px-4 pt-1 pb-0.5 transition-colors bg-blue-600 hover:bg-blue-700 hover:no-underline hover:text-white disabled:opacity-30`}>
                    {!pfpLoading ? "Save" : "Updating" }
                </button>
            </div>

        </div>
        }

        <h3>{user}</h3>
    </div>)
  }