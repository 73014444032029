import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useLazyQuery, gql } from '@apollo/client';
import { saveToLocalStorage, loadFromLocalStorage } from '../components/localStorageUtils';
import { HashLink as Link } from 'react-router-hash-link';
import { motion, AnimatePresence } from 'framer-motion';
import Sketch from 'react-p5';

import btnLoading from '../img/icons/loader.svg';
import { Connect } from  '../components';

import gridPaper from '../img/icons/gridpaper-thin.svg';
import mangaBackground from '../img/v2_assets/radial-bg-blue.jpg';
import CloseImg from '../img/v2_assets/close_btn.svg'

// SOUNDS
import rockAttack from '../vid/rock-smash.mp3'
import polygonNetwork from '../img/v2_assets/polygon-network.png';

// matchPlayers state = [{tokenId: X, lives: X, wins: X, losses: X, maxSpeed: X, type: X, kills: X}]
// holdersWallets = {tokenId: {wallet: "", username: "", pfp: n}}

const GET_USERS = gql`
    query GetUsers($wallets: [String!]) {
        getUsers(wallets: $wallets) {
            discord_username
            wallet
            pfp
        }
    }
`

const UPDATE_TOKENS = gql`
  mutation UpdateRpsTokens($input: [RpsTokenData]) {
    updateRpsTokens(input: $input)
  }
`

// P5 vars
let game = {
    state: 'pre', // pre, start, end
    margin: 10,
    zoom: 1,
    participants: {
        rock: 0,
        paper: 0,
        scissors: 0,
        all: 0
    }
}

let maxSight = 900; //maximum sight an agent can have
let minSight = 25;
let combatDist = 20; // range to trigger combat
let respawnCooldownTime = 20;

let combatants = [];
let commentary = []; // for updates on the action
let defeatedPlayers = [] // move defeated players here
let deathAnim = [];
let winnerAnim = []; // for falling animation
let setWinner = false;

const fauxPlayerLength = 40;

// let margin = 10; // margin around the arean

// testing
let visualizeSight = false; // for seeing how far the combatant is seeing

const PlayerClass = {
    0: {word: 'rock', symbol: '🪨'},
    1: {word: 'paper', symbol: '📄'},
    2: {word: 'scissors', symbol: '✂️'}
};

export default function RPSGame (props) {
    const { id } = useParams();
    const selectedTokenId = useRef(null);

    // const [userData, setUserData] = useState(null)
    const [matchObject, setMatchObject] = useState(null)
    const [combatantsFetched, setCombatantsFetched] = useState(false)
    const [matchPlayers, setMatchPlayers] = useState([])
    const [combatantsTest, setCombatantsTest] = useState([])
    const [liveMatchData, setLiveMatchData] = useState({})
    const [showPlayerLost, setShowPlayerLost] = useState(null);
    const [gameOverData, setGameOverData] = useState(null);
    const [gameOverWindow, setGameOverWindow] = useState(false);
    const [holdersWallets, setHoldersWallets] = useState({});
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [matchLive, setMatchLive] = useState(false);
    const [matchTime, setMatchTime] = useState(null);
    const [matchReady, setMatchReady] = useState(false);
    const [matchCommentary, setMatchCommentary] = useState([])

    const [getDiscordNames, {loading: userStatusLoading, error: userStatusError, data: userDiscordNames}] = useLazyQuery(GET_USERS, {
        notifyOnNetworkStatusChange:true,
        onCompleted: data => {
          // setMatchReady(true)
          console.log(data)
        }
    });

    const [updateTokenData, {loading: dbLoading, error: dbError, data: dbResponse}] = useMutation(UPDATE_TOKENS, {
      notifyOnNetworkStatusChange:true,
      onCompleted: data => {
        console.log("Data updated successfully")
      }
    })

    const highlightPlayerToken = (id) => {
        selectedTokenId.current = id;
    }

    const setShowPlayerLostModal = (player) => {
        setShowPlayerLost(player)
    }

    const hidePlayerLostModal = () => {
        setShowPlayerLost(null)
    }

    const createGameOverData = (data) => {
        console.log("winner selected")
        setGameOverData(data);
        setGameOverWindow(true)
    }

    const clearGameOverData = () => {
      setGameOverWindow(false)
    }

    useEffect(() => {
        // if preview match, don't get real players
        if(id === 'preview') { 
          console.log("PREVIEW MATCH")
          setMatchPlayers(new Array(40).fill(0))
      }
    }, [])

    const updateTokensDB = () => {
      console.log("attempting to update DB")
      let updatedTokens = matchPlayers.map(player => {
        let index = gameOverData.matchSummary.playerIDs.indexOf(player.tokenId);
        if(index !== -1) {
          return {
            tokenId: player.tokenId,
            playerClass: player.type,
            wins: player.wins + gameOverData.matchSummary.wins[index],
            kills: player.kills + gameOverData.matchSummary.kills[index],
            losses: player.losses + gameOverData.matchSummary.losses[index],
            maxSpeed: player.maxSpeed + gameOverData.matchSummary.maxSpeed[index],
            wallet: holdersWallets[player.tokenId].wallet,
            username: holdersWallets[player.tokenId].username
          };
        }
        return player;
      });
      console.log(updatedTokens)
      updateTokenData({variables: {input: updatedTokens}})
    }

    useEffect(()=> {
        if(id === 'preview') { return }
        if(!combatantsFetched && props.isConnected) {
          console.log("FETCHING NEW DATA")
            setCombatantsFetched(true);
            props.getCurrentMatchPlayerData(Number(id));
            if(props.rpsTokenIds == null) {
                // console.log("tokens are null, fetching tokens")
                props.getRPSTokens()
              }
              props.getSeedForMatch(id)
        }

        // if no match data, get match data
        if(props.rpsGames == null) {
          props.getActiveGames()
        } else {
          if(props?.rpsGames && props.rpsGames.find(game => game.matchId == id)) {
            setMatchDates(id)
          } else if(!props.rpsGames.find(game => game.matchId == id)) {
            alert("Match does not exist")
          }
        }
    }, [props.isConnected, id])

    useEffect(() => {
      if(id === 'preview') { return }
      if(props?.rpsGames && props.rpsGames.find(game => game.matchId == id)) {
        setMatchDates(id)
      }
    }, [props.rpsGames])

    function setMatchDates(matchId) {
        if(id === 'preview') { return }
        let currentTime = new Date();
        // console.log(props.rpsGames)
        let matchTime = new Date(props.rpsGames.find(game => game.matchId == id).matchTime * 1000);
        setMatchTime(matchTime)
        setMatchObject(props.rpsGames.find(game => game.matchId == id))

        if(matchTime < currentTime) {
          setMatchLive(true)
        }
  
    }

    useEffect(() => {
        if(id === 'preview') { return }
        // build the players object
        let tempPlayersArr = [];
        let tmpWalletObj = {};
        let storeTokenIDs = []
        // console.log("🏁")
        if(props.rpsMatchPlayerData) {
            for(let player of props.rpsMatchPlayerData) {
                if(storeTokenIDs.includes(Number(player.tokenID))) {
                  //skill
                } else {
                  let tmpObj = {}
                  tmpObj.tokenId = Number(player.tokenID);
                  tmpObj.lives = 3;
                  tmpObj.wins = player.wins;
                  tmpObj.losses = player.losses;
                  tmpObj.kills = player.kills;
                  tmpObj.maxSpeed = player.maxSpeed;
                  tmpObj.type = player.tokenType;
                  if(props.rpsTokenIds) {
                      tmpObj.isOwner = props.rpsTokenIds.some(id => (id === Number(player.tokenID)));
                  } else {
                      tmpObj.isOwner = false;
                  }
                  storeTokenIDs.push(tmpObj.tokenId);
                  tempPlayersArr.push(tmpObj)
                  // for fetching wallets and usernames
                  tmpWalletObj[Number(player.tokenID)] = {}
                }
            }
            setMatchPlayers(tempPlayersArr);
            if(Object.keys(tmpWalletObj).length != 0) {
                getWallets(tmpWalletObj);
            }
        }
    }, [props.rpsMatchPlayerData])

    async function getWallets(wallets) {
        let response = await props.getWalletsOfPlayers(Object.keys(wallets));
        let tmpPlayerData = {... wallets};
        for(let [index, wallet] of response.entries()) {
            tmpPlayerData[Object.keys(tmpPlayerData)[index]] = {wallet}
        }
        // console.log("Finished adding wallets")
        // console.log(tmpPlayerData)
        setHoldersWallets(tmpPlayerData);
        getDiscordNames({variables: { wallets: [...new Set(response)] }})
    }


    useEffect(() => {
        if(userDiscordNames) {
            let tmpPlayerData = {... holdersWallets} 
            for (let [id, data] of Object.entries(tmpPlayerData)) {
                // Find the matching user in userDiscordNames.getUsers
                let matchingUser = userDiscordNames.getUsers.find(user => user.wallet === data.wallet);
            
                // If a matching user is found, add the username property to the current entry
                if (matchingUser && matchingUser.discord_username !== null) {
                    tmpPlayerData[id].username = matchingUser.discord_username;
                } else {
                    tmpPlayerData[id].username = `${tmpPlayerData[id].wallet.substring(0,5)}...${tmpPlayerData[id].wallet.substring(37,42)}`
                }
                // console.log(matchingUser)
                if(matchingUser.pfp !== null && matchingUser.pfp !== 0) {
                  tmpPlayerData[id].pfp = matchingUser.pfp
                } else {
                  tmpPlayerData[id].pfp = 0; // no PFP found or set
                }
            }
            setHoldersWallets({...tmpPlayerData})
            setMatchReady(true)
        }
    }, [userDiscordNames])

// if user's wallet is connected, check to see if they've registered for experiments
  // useEffect(()=> {
  //   // get local user (if exists)
  //   const localUser = loadFromLocalStorage("getUser");
  //   if(props.userAddress.length > 0 && localUser && localUser.wallet === props.userAddress) {
  //     setUserData(localUser)
  //   } 
  // }, [props.userAddress])

    function loader() {

        const loaderAnimation = (<>
            <span className='mx-auto'>
                <img src={btnLoading} className="w-12 animate-spin mx-auto" />
            </span>
        </>)

        return loaderAnimation
    }

    function renderGame() {
        // gameTestData

        const preload = (p5) => {
          // p5.loadSound(rockAttack)
        }

        const setup = (p5, canvasParentRef) => {
            var canvasDiv = document.getElementById('myCanvas');
            let sketchCanvas = p5.createCanvas(1000, 1000).parent(canvasParentRef);
            var masterCnv = document.getElementById('defaultCanvas0');
            masterCnv.classList.add("mx-auto")

            game = {
              state: 'pre', // pre, start, end
              margin: 10,
              zoom: 1,
              participants: {
                  rock: 0,
                  paper: 0,
                  scissors: 0,
                  all: 0
              }
            }
            
            // settings
            p5.frameRate(45);
            if(id !== 'preview') {
              p5.randomSeed(props.rpsMatchSeed * 0.8);
              // console.log(`Using match seed: ${props.rpsMatchSeed}`)
            }
            p5.angleMode(p5.DEGREES);
            p5.rectMode(p5.CENTER);
            p5.noStroke();
            p5.textSize(30);
            game.participants.all = matchPlayers.length;
            // game.participants.all = gameTestData[0].length;

            // when game starts, start timer
            game.timer = p5.millis();
            
            // CREATE THE PLAYERS
            let crown_id = [0];
            let highestScore = 0;
            combatants = [] // prevent error where old data remains
            if(id !== 'preview') { // preview matches skip adding real dta
              for(let [index, player] of matchPlayers.entries()) {
                  let should_crown = false;
                  combatants.push(new Agent(p5, p5.random(game.margin, p5.width - game.margin), p5.random(game.margin, p5.height - game.margin), player.lives, player.tokenId, player.wins, should_crown, player.maxSpeed, player.type, player.isOwner, holdersWallets[player.tokenId].username));
                  // increment participant type counter:
                  game.participants[PlayerClass[player.type].word]++;

                  // check to see if crown should be awarded
                  if(player.wins > highestScore) {
                      highestScore = player.wins;
                      combatants[index].ruler = true;
                      if(combatants.length !== 1) {
                          for(let i = 0; i < crown_id.length; i++) {
                          combatants[crown_id[i]].ruler = false; // remove the crown from the previous holder 
                          }
                      }
                      crown_id = [index];
                  } else if (player.wins == highestScore && highestScore > 0) {
                      // two winners
                      highestScore = player.wins;
                      combatants[index].ruler = true;

                      crown_id.push(index);
                  }
                  setCombatantsTest(combatants)
                  setLiveMatchData(game)
              }
            } else {
              // fill with dummy data
              for(let i = 0; i < fauxPlayerLength; i++) {
                combatants.push(new Agent(p5, p5.random(game.margin, p5.width - game.margin), p5.random(game.margin, p5.height - game.margin), 3, i, 0, false, 4, i % 3, false, "BOT"));
                    // increment participant type counter:
                game.participants[PlayerClass[i % 3].word]++;
              } 
              setCombatantsTest(combatants)
              setLiveMatchData(game)
            }

        }
    
        const draw = (p5) => {
          let translateDist = p5.map(game.zoom, 1, 2, 0, p5.width/2);
          p5.translate(-translateDist, -translateDist)
          p5.scale(game.zoom)
          if(p5.frameCount % 30 === 29) {
            setCombatantsTest([...combatants]);
            setLiveMatchData(game)
            setMatchCommentary([...commentary])
          }
          p5.background(85,127,239);
          
          // draw boundry
          p5.fill(155, 171, 215);
          p5.rect(p5.width/2, p5.height/2, p5.width-(game.margin*2), p5.height-(game.margin)*2);
            p5.push();
              p5.strokeWeight(1);
              p5.stroke(255, 122);
              p5.line(0, game.margin, p5.width, game.margin);
              p5.line(0, p5.height-game.margin, p5.width, p5.height-game.margin);
              p5.line(game.margin, 0, game.margin, p5.height);
              p5.line(p5.width-game.margin, 0, p5.width-game.margin, p5.height);
            p5.pop();

            // display pre game without moving
            if(game.state == 'pre') {
                for(let i in combatants) {
                    combatants[i].display();
                }
            }
            
            // check types
            if(game.state == 'start') {
              p5.fill(0);

              // clear out any dead combatants
              for(let i in combatants) {
                if (combatants[i].toDestroy) {
                  let tmp_player = combatants.splice(i, 1)[0];
                  game.participants[tmp_player.type]--; // sub 1 from player counter
                  tmp_player.time = (p5.millis() - game.timer)  / 1000; // time in second
                  if(tmp_player.isOwner) {
                    setShowPlayerLostModal({playerId: tmp_player.index, type: tmp_player.type, lifespan: tmp_player.time.toFixed(2), kills: tmp_player.kills})
                    }
                  defeatedPlayers.push(tmp_player);
                }
              }
          
              
              for(let i in combatants) {
                combatants[i].flock(combatants, p5);
                combatants[i].update();
                combatants[i].display(selectedTokenId.current);
              }
          
              // when players die show death animation
              for(let d of deathAnim) {
                d.show();
                d.update();
                if(d.shouldDestroy == true) {
                  deathAnim.splice(deathAnim.indexOf(d), 1);
                }
              }
          
              // increase margins over time, stop at 80px from centre
              if(game.margin < ((p5.width+p5.height)/4)-80 && game.participants.all > 2) {
                game.margin += p5.map(combatants.length, 2, game.participants.all, 0.1, 0.001);
                let zoomTo = p5.map(game.margin, 10, ((p5.width+p5.height)/4)-80, 1, 2.1);
                // console.log(`zoom to: ${zoomTo}`)
                game.zoom = p5.lerp(game.zoom, zoomTo, 0.01);
              }

              checkWinner(p5);
            
              p5.push();
                p5.fill(0);
                p5.textSize(14);
                p5.textAlign(p5.CENTER);
                if (commentary.length > 4) {
                  commentary.shift() // remove comment from start of array
                }
              p5.pop();
            }
            
            if(game.state == 'end') {
              // show winners
              for(let i in combatants) {
                combatants[i].display();
                combatants[i].update();
              }

              // when players die
              for(let d of deathAnim) {
                d.show();
                d.update();
                if(d.shouldDestroy == true) {
                  deathAnim.splice(deathAnim.indexOf(d), 1);
                }
              }

              if(!setWinner) {
                setWinner = true;
                game.matchSummary = {}
                game.matchSummary.playerIDs = []
                game.matchSummary.wins = []
                game.matchSummary.kills = []
                game.matchSummary.losses = []
                game.matchSummary.maxSpeed = []

                // add winner record and increase games won
                // NOTE: these are not accumulative numbers, eg not adding wins to previous wins, this is done in the contract
                for(let c of combatants) {
                  game.matchSummary.playerIDs.push(c.index)
                  game.matchSummary.wins.push(1)
                  game.matchSummary.kills.push(c.kills)
                  game.matchSummary.losses.push(0)
                  game.matchSummary.maxSpeed.push(0)
                }
                
                for(let d of defeatedPlayers) {
                  if(d.index != game.winner.index) {
                    game.matchSummary.playerIDs.push(d.index)
                    game.matchSummary.wins.push(0)
                    game.matchSummary.kills.push(d.kills)
                    game.matchSummary.losses.push(1)
                    game.matchSummary.maxSpeed.push(0)
                  }
                }
                
                createGameOverData(game);
              }
              winState(p5);
            }
          }

          const keyPressed = (p5) => {
            if (p5.key == 'h' || p5.key == 'H') {
              visualizeSight = !visualizeSight;
            }
            // if (p5.key == 'p' || p5.key == 'P') {
            // //   console.log(defeatedPlayers)
            // }
          }
          
    
        return (<>
          <Sketch preload={preload} setup={setup} draw={draw} keyPressed={keyPressed} />
        </>)
        

    }

    function renderGameUpdateWindow() {
      if(props.userAddress == '' || String(props.userAddress).toLowerCase() != '0xA686e42124B746159552eA4868a5029C7a498eC1'.toLowerCase()) {
        return;
      }

      // const [showUpdateModal, setShowUpdateModal] = useState(false);
      const showModal = () => {
        setShowUpdateModal(true);
      }

      const hideModal = () => {
        setShowUpdateModal(false);
      }

      return(<>
        { showUpdateModal && <UpdateGameDataModal gamedata={gameOverData} close={hideModal} updateDB={updateTokensDB} updatePlayers={props.updatePlayers} awardWinner={props.awardWinner} matchId={id} /> }
        { gameOverData && <button
          onClick={showModal}
          className={`absolute right-3 top-1 rounded-md font-teko text-[1.1rem] tracking-wider mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-600 hover:bg-blue-700 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`}
        >
            <span className="mr-2">🚀</span>
            Record Game Data
        </button>}
      </>)
    }

    if(props.isConnected === false) {
      return (<>
          <div className='flex flex-row'>
            <div className='py-12 mx-auto text-center px-6 lg:px-0 relative min-w-[1040px]' id="myCanvas">
              <Connect connect={props.connectFunc} />
            </div>
          </div>
      </>)
    }


    return(<>
            <AnimatePresence>
              {/* COMMENTARY */}
              <div className='fixed bottom-3 left-5 text-white z-[99]'>
                <div>
                  { matchCommentary.map((comment) => {
                    return(<motion.p key={comment} initial={{opacity: 0, y: 20}} animate={{opacity: 1, y: 0}} exit={{opacity: 0}} className='pb-2 font-semibold'>{comment}</motion.p>)
                  }) }
                </div>
              </div>

                <div style={{backgroundImage: `url(${gridPaper})`, backgroundSize: '8px', backgroundRepeat: 'repeat'}} className='bg-blue-600 pt-16 min-h-[85vh]'>
                    <motion.div initial={{y: -50}} animate={{y:0, transition: {delay: 0.5, duration: 0.7}}} className='flex w-full bg-blue-600 border-b-[3px] border-white text-center relative'>
                        <Link to="/experiments/rps/dashboard/" className={`absolute left-3 top-1 rounded-md font-teko text-[1.1rem] tracking-wider mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-600 hover:bg-blue-700 active:scale-95 active:bg-blue-800 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`} >
                            <span className="mr-2">⚔️</span>
                            Back to Dashboard
                        </Link>

                        <span className='text-white mt-3 mx-auto flex flex-row space-x-6'>
                          <h3>⚔️ Match { id.toString().padStart(3, '0') }</h3>
                          {(matchObject && matchObject.rewardMatch) && <motion.span initial={{opacity: 0, y: 10}} animate={{opacity: 1, y: 0, transition: {delay: 1}}} title="A humble amount, but nothing to sneeze at." className='text-[1.1rem] my-auto text-blue-800 leading-4 font-teko font-400 bg-yellow-400 rounded-md py-1.5 px-2 mb-2'>{`💰 ${matchObject.rewardAmount} MATIC`}</motion.span>}
                        </span>

                        {renderGameUpdateWindow()}
                    </motion.div>

                    <div className='flex flex-row'>
                        <div className='py-12 mx-auto text-center px-6 lg:px-0 relative min-w-[1040px]' id="myCanvas">
                          {/* SHOW IF NOT ON CORRECT CHAIN */}
                          { (props.isConnected && props.currentNetwork != 80001) && 
                            <div className='flex flex-row space-x-6 align-middle'>
                                <img src={polygonNetwork} className='border-[3px] border-white w-[450px]' />
                                <p className='text-white my-auto text-left'><strong>Please switch to the Polygon Test Network</strong> <br />Your Metamask must be on the Polygon Test network in order to see this screen.</p>
                            </div>
                        }
                          {/* SHOW IF NOT ON CORRECT CHAIN */}

                            { gameOverWindow && <ShowWinner player={gameOverData.winner} playerName={ id === 'preview' ? "Bot" : holdersWallets[gameOverData.winner.index].username} hide={clearGameOverData} pfps={holdersWallets} />}
                            { showPlayerLost && <PlayerLost player={showPlayerLost} show={setShowPlayerLostModal} hide={hidePlayerLostModal} /> }
                            { (props.isConnected && userDiscordNames != null && matchLive && game.state === 'pre') &&
                            // time for match to go live
                             <button 
                                onClick={(e)=>{game.state = 'start'; e.target.classList.add('hidden')}} className="absolute w-36 -ml-[72px] rounded-md mb-4 mt-[480px] font-teko text-[1.1rem] tracking-wider uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-600 hover:bg-blue-700 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">
                                <span className='mr-2'>🏁</span>
                                start match
                            </button>
                            }

                            {/* SHOW START BUTTON FOR PREVIEW MATCH */}
                            { id === 'preview' &&
                             <button 
                                onClick={(e)=>{game.state = 'start'; e.target.classList.add('hidden')}} className="absolute w-36 -ml-[72px] rounded-md mb-4 mt-[480px] font-teko text-[1.1rem] tracking-wider uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-600 hover:bg-blue-700 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50">
                                <span className='mr-2'>🏁</span>
                                start match
                            </button>
                            }

                            { (props.isConnected && userDiscordNames != null && !matchLive) &&
                            // not yet time for match
                             <div className='absolute w-full h-full z-[999]'>
                              <div className='mx-auto bg-blue-600 border-white border-[3px] rounded-2xl pt-8 pb-4 mt-0 w-[400px] '>
                                <h4>Match has not begun</h4>
                                <p>This match is due to begin { matchTime?.toLocaleDateString()}, { matchTime?.toLocaleTimeString([], {hour12: true, hour: "2-digit", minute: "2-digit"})}</p>
                              </div>
                            </div>
                            }
                            
                            {/* Render test match if vpreview mode */}
                            {/* { id === 'preview' && renderGame() } */}

                            { (matchReady && props.rpsMatchSeed != null || id === 'preview') ? renderGame() :
                             <div className='flex w-full h-full'>
                              { matchPlayers.length == 0 ?
                              <> 
                              {/* If there are 0 players, discord will never not be null */}
                                <div className='my-auto mx-auto'>
                                    <p className='italic mt-4' >This match contains no combatants</p>
                                    <Link to="/experiments/rps/dashboard/" className={`my-5 rounded-md font-teko text-[1.1rem] tracking-wider mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-800 hover:bg-blue-900 active:scale-95 active:bg-blue-600 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`} >
                                        <span className="mr-2">⚔️</span>
                                        Back to Dashboard
                                    </Link>
                                </div>
                              </>
                              :
                              <div className='my-auto mx-auto'>
                                    {loader()}
                                    <span className='animate-pulse italic mt-4 block'>Fetching data...</span>
                                </div>
                              }
                            </div>
                             }
                        </div>
                        { userDiscordNames != null &&
                        <motion.div key="playerBoard" initial={{x: 100, opacity: 0}} animate={{x: 0, opacity: 1}} className='bg-blue-800 border-l-[3px] border-white' id="participants">
                            <div className={`border-b-[3px] border-white px-2 pt-3 flex flex-row place-content-around align-middle transition-colors bg-blue-600 space-x-4`}>
                                { liveMatchData?.participants?.all && <h3 className=''>🪨 {liveMatchData.participants.rock}</h3> }
                                { liveMatchData?.participants?.all && <h3 className=''>📄 {liveMatchData.participants.paper}</h3> }
                                { liveMatchData?.participants?.all && <h3 className=''>✂️ {liveMatchData.participants.scissors}</h3> }
                            </div>  
                            <div className={`border-b-[3px] border-white px-2 pt-3 flex flex-row place-content-around align-middle transition-colors bg-blue-600`}>
                                <h4>Combatants</h4>
                            </div>  

                            { combatantsTest.length > 0 && combatantsTest.map((player) => {
                                return(
                                    <motion.div 
                                        onHoverStart={()=>{highlightPlayerToken(player.index)}}
                                        onHoverEnd={()=>{highlightPlayerToken(null)}}
                                        key={`${player.index}_div`} initial={{opacity: 0.4}} animate={{opacity: 1}} exit={{opacity: 0, y: 30}}
                                        className={`border-b-[3px] border-white px-2 pt-3 flex flex-row place-content-around align-middle transition-colors hover:cursor-pointer ${ player.isOwner ? "bg-teal-400 hover:bg-teal-300" : "bg-blue-600 hover:bg-blue-500"}`}>
                                        <div className='flex flex-row space-x-3 mr-8'>
                                            <h4>{PlayerClass[player.typeId].symbol} #{player.index}</h4>
                                            
                                            {/* ADD PFP - show if pfp != 0 otherwise ignore */}
                                            { (holdersWallets[player.index].hasOwnProperty("pfp") && holdersWallets[player.index].pfp != 0) && <img src={require(`../img/ae_students/${holdersWallets[player.index].pfp}.png`)} className='border-2 border-white rounded-full w-10 my-auto -mt-1' alt="AE token" /> }
                                            
                                            { id !== 'preview' && holdersWallets[player.index] && <h4 className='font-light uppercase text-[1.5rem]'>
                                                { holdersWallets[player.index].username ?  
                                                    holdersWallets[player.index].username
                                                    :`${holdersWallets[player.index].wallet.substring(0,5)}...${holdersWallets[player.index].wallet.substring(37,42)}`
                                                }
                                            </h4> }
                                            <motion.h4 initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} className='text-[1rem]'>{ "❤️".repeat(player.lives) }</motion.h4>
                                        </div>
                                        <div className='flex flex-row'>
                                            <h4 className='text-[1.3rem] mb-2'>🔪</h4>
                                            <h4 className='ml-2'>{player.kills}</h4>
                                        </div>
                                    </motion.div>   
                                )
                            }) 
                            }
                        </motion.div> }
                    </div>

                </div>
            </AnimatePresence>
        </>);
}

function PlayerLost({player, show, hide}) {
    const getPlayerIcon = {
        "rock": "🪨",
        "paper": "📄",
        "scissors": "✂️"
    }

    // Trigger hide function after 4 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            hide();
        }, 4000);
        return () => {
            clearTimeout(timer)
        }
    }, [player]);

    return (
        <motion.div key={`${player.playerId}_lost_modal`}
        initial={{y: 400}} animate={{y: 0}} exit={{scale: 0.8, opacity: 0}}
        className='w-full fixed bottom-5 left-0 flex z-[999]'>
            <div onClick={hide} className='bg-blue-900 border-[3px] border-white rounded-2xl flex flex-col mx-auto text-center py-6 px-6 bg-cover bg-center drop-shadow-2xl hover:cursor-pointer'  style={{backgroundImage: `url(${mangaBackground})`}}>
                <h4 className='-mt-3 mb-4 text-[10rem]'>{ getPlayerIcon[player.type] }</h4>
                <h4 className='mt-12 mb-4 text-[4rem]'>Player defeated!</h4>
                <h5 className='my-1 text-[1.8rem]'>{player.type} #{player.playerId} was defeated in ⏱ {player.lifespan} seconds with 🔪 {player.kills} kills.</h5>
            </div>
        </motion.div>
    )
}

function ShowWinner({player, playerName, hide, pfps}) {
    const getPlayerIcon = {
        "rock": "🪨",
        "paper": "📄",
        "scissors": "✂️"
    }

    return (
        <motion.div key={`${1}_lost_modal`}
        initial={{y: 0, opacity: 0}} animate={{y: 0, opacity: 1, transition: {delay: 2}}} exit={{scale: 0.8, opacity: 0}}
        className='w-screen h-screen bg-black/50 fixed top-0 left-0 flex flex-col z-[900]'>
            <motion.div initial={{y: 80}} animate={{y: 0}} className='bg-blue-900 border-[3px] border-white rounded-2xl flex flex-col mx-auto text-center pt-6 pb-2 px-6 bg-cover bg-center drop-shadow-2xl my-auto -pt-6 relative'  style={{backgroundImage: `url(${mangaBackground})`}}>
                <button onClick={hide} className='z-[999]'><img src={CloseImg} className='w-6 absolute top-2 right-4 hover:scale-105 transition-transform' /></button>
                <motion.div drag dragElastic={0.5} dragSnapToOrigin={true}>
                    <motion.h4 initial={{y: "-50vh"}} animate={{y: 0, transition: {duration: 3, type: "ease out", delay: 1}}} className='-mt-6 mb-8 text-[6rem] z-10 select-none'>👑</motion.h4>
                </motion.div>
                <motion.h4 initial={{opacity: 0}} animate={{opacity: 1, transition: {delay: 4}}} className='-mt-14 mb-6 text-[3rem] select-none'>🙇</motion.h4>
                <h4 className='mt-6 mb-4 text-[10rem] leading-[4rem]'>{getPlayerIcon[player.type]}</h4>
                <h4 className='mt-8 mb-4 text-[4rem]'>{player.type} Wins!</h4>
                { (pfps[player.index].hasOwnProperty("pfp") && pfps[player.index].pfp != 0) && <img src={require(`../img/ae_students/${pfps[player.index].pfp}.png`)} className='border-2 border-white rounded-full w-32 mx-auto mb-4' alt="AE token" /> }
                <h5 className='my-1 text-[1.8rem]'>{playerName}{playerName[playerName.length -1] == "s" ? "\'" : "\'s"} {player.type} #{player.index} kicked ass with {player.kills} kills</h5>
                <div className='flex flex-row spce-x-4'>
                    <Link to="/experiments/rps/dashboard/" className={`my-5 rounded-md font-teko text-[1.1rem] tracking-wider mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-800 hover:bg-blue-900 active:scale-95 active:bg-blue-600 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`} >
                        <span className="mr-2">⚔️</span>
                        Back to Dashboard
                    </Link>
                    {/* <button className={`my-5 rounded-md font-teko text-[1.1rem] tracking-wider mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-800 hover:bg-blue-900 active:scale-95 active:bg-blue-600 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`} >
                        <span className="mr-2">🏆</span>
                        View Replay & Results
                    </button> */}
                </div>
            </motion.div>
        </motion.div>
    )
}

class Agent {
    constructor(p5, posX, posY, lives, idx, wins, crown, maxSpeed, playerType, owner, username) {
      this.p5 = p5;
      this.index = idx; // tokenId
      this.type = PlayerClass[playerType].word;
      this.typeId = playerType;
      this.pos = this.p5.createVector(posX, posY);
      this.velocity = this.p5.createVector(this.p5.random(-maxSpeed, maxSpeed), this.p5.random(-maxSpeed, maxSpeed));
      this.acceleration = this.p5.createVector();
      this.maxSpeed = maxSpeed;
      this.maxForce = 1;
      this.lives = lives;
      this.sight = minSight;
      this.tmpsightcol = this.p5.color(0, 255, 60, 30);
      this.kills = 0;
      this.fighting = false;
      this.toDestroy = false;
      this.respawnCooldown = 0;
      this.respawnAnim = 0;
      this.gamesWon = wins;
      this.ruler = crown;
      this.isOwner = owner;
      this.uname = username
      this.respawnPos = this.p5.createVector();
    }
  
    display(selectedTokenId) {
      if(visualizeSight) {
        this.p5.push();
        this.p5.fill(this.tmpsightcol);
        this.p5.circle(this.pos.x, this.pos.y, this.sight);
        this.p5.pop();
      }
      
      this.p5.push();
      this.p5.translate(this.pos.x, this.pos.y);

      if(this.index === selectedTokenId) {
        this.p5.push();
        this.p5.fill(this.p5.color(233, 178, 28));
        this.p5.circle(0, 6, 80);
        this.p5.pop();
      } else if(this.isOwner) {
        this.p5.fill(this.p5.color(62, 212, 191, 120));
        this.p5.circle(0, 6, 80);
        this.p5.scale(1.5);
      }
  
      if(this.respawnCooldown > 0) {
        this.p5.push();
        // if(this.index === 1) {
        //   console.log(animProgress)
        // }
        this.respawnAnim = this.p5.lerp(this.respawnAnim, 50, 0.2)
        let rippleAlpha = this.p5.map(this.respawnAnim, 0, 50, 255, 0);
        let rippleThickness = this.p5.map(this.respawnAnim, 0, 50, 10, 0.5);

        this.p5.strokeWeight(rippleThickness);
        this.p5.stroke(255, 247, 186, rippleAlpha);
        this.p5.noFill();
        this.p5.circle(this.respawnPos.x, this.respawnPos.y + 6, this.respawnAnim);
        this.p5.pop();
      } else {
        this.respawnAnim = 0;
      }

      // fade in on respawn
      let tr = this.p5.map(this.respawnCooldown, 0, respawnCooldownTime, 255, 30);
      this.p5.fill(0, tr);
  
      // draw the combatant
      switch(this.type) {
        case "rock":
          this.p5.text("🪨", -15, 15);
          break;
        case "paper":
          this.p5.text("📄", -15, 15);
          break;
        case "scissors":
          this.p5.text("✂️", -15, 15);
          break;
        default:
          console.log("error assigning type");
      }

      // display player name if less than 10 players
      if(combatants.length <= 25) {
        this.p5.push()
        this.p5.textSize(10)
        this.p5.fill(0, 150)
        this.p5.text(`${this.uname}`, -15, 35);
        this.p5.pop()
      }


      if(this.ruler) {
        this.p5.text("👑", -15, -7);
      }
  
      this.p5.pop();
      
      // draw lives
      // for(let i = 0; i < this.lives; i++) {
      //   this.p5.fill(255,0,0);
      //   this.p5.circle(this.pos.x + (10 * i), this.pos.y + 15, 5);
      // }
  
      // // show kills
      // for(let i = 0; i < this.kills; i++) {
      //   this.p5.push();
      //       let posX = this.p5.sin(this.p5.map(i, 0, this.kills-1, 0, 90)) * 25;
      //       let posY = this.p5.cos(this.p5.map(i, 0, this.kills-1, 0, 90)) * 25;
      //       this.p5.translate(this.pos.x, this.pos.y);
      //       this.p5.fill(0,80,0);
      //       this.p5.circle(posX, posY, 5)
      //   this.p5.pop();
      // }
    }
  
    update() {
        // check lives
        if(this.lives < 1) {
          this.toDestroy = true;
        }
        // cooldown after respawn
        if(this.respawnCooldown > 0) {
          this.respawnCooldown--;
        }
        this.velocity.add(this.acceleration);
        this.velocity.limit(this.maxSpeed)
        this.pos.add(this.velocity);
        this.acceleration.mult(0);
      
        if(this.pos.x > this.p5.width - (game.margin + 5) || this.pos.x < 0 + (game.margin + 5)) {
          this.acceleration.mult(-1, -1);
          this.velocity.x = this.velocity.x * -10;
        }
      
        if(this.pos.y > this.p5.height - (game.margin + 5) || this.pos.y < 0 + (game.margin + 5)) {
          this.acceleration.mult(-1, -1);
          this.velocity.y = this.velocity.y * -10;
        }
      
        // respawn if out of bounds
        if(this.pos.x > this.p5.width - game.margin || this.pos.x < game.margin || this.pos.y > this.p5.height - game.margin || this.pos.y < game.margin) {
            this.respawn()
        }

    }
      
      // Boids style alignment
    align(agents) {
        let steering = this.p5.createVector();
        let totalNeighbours = 0;
        for (let other of agents) {
          let outcome = ruleset(this, other);
          let proximity = this.p5.dist(this.pos.x, this.pos.y, other.pos.x, other.pos.y);
          if (other != this && proximity < this.sight && outcome.winner === this) {
            steering.add(other.velocity);
            totalNeighbours++;
          }
          // if enemy, decide who lives and who dies
          if(proximity < combatDist && this.type !== other.type && !this.fighting && !other.fighting && this.respawnCooldown == 0) {
            mortalCombat(this, other, this.p5);
          }
        }
        if (totalNeighbours > 0) {
          steering.div(totalNeighbours);
          steering.setMag(this.maxSpeed);
          steering.sub(this.velocity);
          steering.limit(this.maxForce);
        }
      
        // if no neighbours close by, look further
        if(totalNeighbours == 0) {
          // increase sight
          this.sight = this.p5.lerp(this.sight, maxSight, 0.001);
          this.tmpsightcol = this.p5.color(0, 255, 60, 30) // increasing sight
        } else if(this.sight > minSight && totalNeighbours != 0) {
          // decrease sight
          this.sight = this.p5.lerp(this.sight, minSight, 0.001);
          this.tmpsightcol = this.p5.color(255, 0, 0, 30) // decreasing sight
        }
      
        return steering;
      }
      
      separation(agents, p5) {
        let steering = p5.createVector();
        let total = 0;
        
        for (let other of agents) {
          let proximity = p5.dist(this.pos.x, this.pos.y, other.pos.x, other.pos.y);
          let outcome = ruleset(this, other);
          if (other != this && proximity < this.sight *0.8 && outcome.winner !== this) {
            let diff = this.pos.copy().sub(other.pos);
            diff.div(proximity * proximity);
            steering.add(diff);
            total++;
          }
          if (other != this && proximity < 10 && this.type === other.type) {
            let diff = this.pos.copy().sub(other.pos);
            diff.div(proximity * proximity);
            steering.add(diff);
            total++;
          }
        }
        
        if (total > 0) {
          steering.div(total);
          steering.setMag(this.maxSpeed);
          steering.sub(this.velocity);
          steering.limit(this.maxForce);
        }
        return steering;
      }
      
      cohesion(agents) {
        let steeringPrey = this.p5.createVector();
        let totalPrey = 0;
        let steeringFriend = this.p5.createVector();
        let totalFriend = 0;
      
        for (let other of agents) {
          let proximity = this.p5.dist(this.pos.x, this.pos.y, other.pos.x, other.pos.y);
          let outcome = ruleset(this, other);
          if (other != this && proximity < this.sight * 2 && outcome.winner === this) {
            steeringPrey.add(other.pos);
            totalPrey++;
            break;
          } else if(other != this && proximity < this.sight && this.type == other.type) {
            steeringFriend.add(other.pos);
            totalFriend++;
          }
        }
      
        if (totalPrey > 0) {
          steeringPrey.div(totalPrey);
          steeringPrey.sub(this.pos);
          steeringPrey.setMag(this.maxSpeed);
          steeringPrey.sub(this.velocity);
          return steeringPrey;
        } else if(totalFriend > 0) {
          steeringFriend.div(totalFriend);
          steeringFriend.sub(this.pos);
          steeringFriend.setMag(this.maxSpeed);
          steeringFriend.sub(this.velocity);
          steeringFriend.limit(this.maxForce);
          return steeringFriend;
        }
      
        return this.p5.createVector(); // Return an empty vector if none of the above conditions are met
      }
        
      flock(agents, p5) {
        let alignment = this.align(agents, p5);
        let cohesion = this.cohesion(agents, p5);
        let separation = this.separation(agents, p5);
      
        this.acceleration.add(alignment);
        this.acceleration.add(cohesion);
        this.acceleration.add(separation);
      }
      
      respawn() {
        this.respawnCooldown = respawnCooldownTime;
        this.pos = this.p5.createVector(this.p5.random(game.margin, this.p5.width-game.margin), this.p5.random(game.margin, this.p5.height-game.margin))
        this.velocity = this.p5.createVector(this.p5.random(-this.maxSpeed, this.maxSpeed), this.p5.random(-this.maxSpeed, this.maxSpeed));
        this.acceleration = this.p5.createVector();
      }
    } 

    function mortalCombat(player1, player2, p5) {
        player1.fighting = true;
        player2.fighting = true;
        let {loser, winner} = ruleset(player1, player2);
        if(loser.lives > 0) {
          loser.lives--;
          loser.fighting = false;
          deathAnim.push(new DeathParticle(loser.pos.x, loser.pos.y, "🔥", p5));
          if(loser.lives !== 0) {
            loser.respawn();
          }
        } else if (loser.lives === 0) {
          deathAnim.push(new DeathParticle(loser.pos.x, loser.pos.y, "💀", p5));
        }

        // add commentary        
        if(winner.kills == 0) {
          let msg = `${winner.uname} got their first kill, taking down ${loser.uname}.`
          if(commentary.indexOf(msg) == -1) {
            commentary.push(`${winner.uname} got their first kill, taking down ${loser.uname}.`)
          }
        } else if(loser.lives === 0 && loser.kills === 0) {
          let msg = `${loser.uname} was knocked out of the game by ${winner.uname} without a single kill!`
          if(commentary.indexOf(msg) == -1) {
            commentary.push(`${loser.uname} was knocked out of the game by ${winner.uname} without a single kill!`)
          }
        } else if(winner.kills > 7) {
          let msg = `${winner.uname} is becoming unstoppable! ${loser.uname} learned that the hard way.`
          if(commentary.indexOf(msg) == -1) {
            commentary.push(`${winner.uname} is becoming unstoppable! ${loser.uname} learned that the hard way.`)
          }
        } else if(winner.kills > 5) {
          let msg = `${winner.uname} has become bloodthirsty, claiming another kill!`
          if(commentary.indexOf(msg) == -1) {
            commentary.push(`${winner.uname} has become bloodthirsty, claiming another kill!`)
          }
        } else if(winner.kills > 3) {
          let msg = `${winner.uname} is starting to rampage and ${loser.uname} was in the way!`
          if(commentary.indexOf(msg) == -1) {
            commentary.push(`${winner.uname} is starting to rampage and ${loser.uname} was in the way!`)
          }
        }

        winner.kills++;
        winner.fighting = false;
        game.winner = winner;
  
      }
      
    function ruleset(player1, player2) {
        if (player1.type === 'rock') {
          return player2.type === 'scissors' ? {loser: player2, winner: player1} : {loser: player1, winner: player2};
        }
      
        if (player1.type === 'paper') {
          return player2.type === 'rock' ? {loser: player2, winner: player1}  : {loser: player1, winner: player2};
        }
      
        // At this point, player1.type must be 'scissors'
        return player2.type === 'paper' ? {loser: player2, winner: player1}  : {loser: player1, winner: player2};
      }
      
    function winState(p5) {
        if(winnerAnim.length < 100 && p5.frameCount % 4 === 0) {
          winnerAnim.push(new Waterfall(game.winner_type, p5))
        }
        
        for(let particle of winnerAnim) {
          particle.show();
        }
        
      }
      
      function checkWinner(p5) {
        let hasRock = false;
        let hasPaper = false;
        let hasScissors = false;
      
        for (let agent of combatants) {
          switch (agent.type) {
            case 'rock':
              hasRock = true;
              break;
            case 'paper':
              hasPaper = true;
              break;
            case 'scissors':
              hasScissors = true;
              break;
          }
        }
      
        if (hasRock && !hasPaper && !hasScissors) {
          console.log("Rock is the winner!");
          game.state = 'end';
          game.winner_type = '🪨';
          processWinners(p5);
        } else if (hasPaper && !hasRock && !hasScissors) {
          console.log("Paper is the winner!");
          game.state = 'end';
          game.winner_type = '📄';
          processWinners(p5);
        } else if (hasScissors && !hasRock && !hasPaper) {
          console.log("Scissors is the winner!");
          game.state = 'end';
          game.winner_type = '✂️';
          processWinners(p5);
        }
      }
      
      function processWinners(p5) {
        for(let i in combatants) {
          let tmp_player = combatants[i];
          tmp_player.time = (p5.millis() - game.timer)  / 1000; // time in second
          tmp_player.gamesWon++;
          defeatedPlayers.push(tmp_player)
        }
      }
      
      class Waterfall {
        constructor(icon, p5) {
          this.p5 = p5;
          this.pos = p5.createVector(p5.random(0,p5.width), p5.random(-10, 10));
          this.velocity = p5.createVector(0,3);
          this.icon = icon;
          this.angle = p5.random(100)
        }
        
        show() {
          this.pos.add(this.velocity);
          this.p5.push();
          this.p5.translate(this.pos.x, this.pos.y);
          if(this.icon === "📄") {
            // flowy anim
            this.pos.x += this.p5.sin(this.angle) * 3;
            this.angle+=3;
          } else if(this.icon === "✂️") {
            this.p5.rotate(this.angle);
            this.angle+=3;
          }
          this.p5.textSize(20);
          this.p5.text(this.icon, 0, 0);
          this.p5.pop();
          
          if(this.pos.y > this.p5.height) {
            this.pos.y = -10;
          }
        }
      }
      
      class DeathParticle {
        constructor(x, y, iconType, p5) {
          this.icon = iconType;
          this.p5 = p5;
          this.pos = p5.createVector(x, y);
          this.lifespan = 120;
          this.shouldDestroy = false;
        }
        
        show() {
          this.p5.push();
          this.p5.translate(this.pos.x, this.pos.y);
          this.p5.scale(this.p5.map(this.lifespan, 120, 70, 1.8, 1, false));
          this.p5.textSize(20);
          this.p5.text(this.icon, 0, 0);
          this.p5.pop();
        }
        
        update() {
          if(this.lifespan > 0) {
            this.show();
            this.pos.x += this.p5.random(-2, 2);
            this.pos.y += this.p5.random(-2, 2);
            
            // if skull, make it float away
            if(this.icon == "💀") {
                this.pos.y -= 1.5;
            }
            this.lifespan--;
          } else {
            this.shouldDestroy = true;
          }
        }
      }

function UpdateGameDataModal({gamedata, close, updateDB, updatePlayers, awardWinner, matchId}) {

  return(<>
    <div key={"game_over_admin_modal"} className='w-full h-full fixed top-0 left-0 bg-black/50 flex place-content-center align-middle z-50'>
        <motion.div key={"update_game_popup"} className='bg-blue-700 border-[3px] border-white my-auto rounded-2xl overflow-hidden shadow-lg flex flex-col container' initial={{y: 100, opacity: 0}} animate={{y: 0, opacity: 1}} exit={{scale: 0.95, transition: { duration: 0.05 } }}  transition={{type: "easeOut" , duration: 0.2, damping: 14}}>
            <div className='flex flex-col pt-8 pb-6 place-content-center align-middle bg-cover bg-center px-6 text-center relative' style={{backgroundImage: `url(${mangaBackground})`}}>
                <button onClick={close}><img src={CloseImg} className='w-6 absolute top-2 right-2 hover:scale-105 transition-transform' /></button>
                <p className={`text-[8rem] pb-0 mt-4 leading-[7rem]`}>
                    🎮
                </p>
                <div className='font-bold text-[1.9rem] text-white font-teko uppercase text-center pt-1 mb-6'>Update on-chain data</div>
                <div className='flex flex-row space-x-4 w-[400px] mx-auto'>
                  <button
                    onClick={()=>{updatePlayers(matchId, gamedata.matchSummary)}}
                    className={`rounded-md font-teko text-[1.1rem] tracking-wider mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-600 hover:bg-blue-700 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`}
                  >
                    <span className="mr-2">💎</span>
                      Write to Blockchain
                  </button>
                  <button
                    onClick={()=>{updateDB()}}
                    className={`rounded-md font-teko text-[1.1rem] tracking-wider mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-600 hover:bg-blue-700 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`}
                  >
                    <span className="mr-2">💎</span>
                      Update Database
                  </button>
                    <button
                      onClick={()=>{awardWinner(matchId, gamedata.winner.index)}}
                      className={`rounded-md font-teko text-[1.1rem] tracking-wider mx-auto uppercase no-underline text-white border-2 border-white px-3 pt-2 py-1 transition-colors bg-blue-600 hover:bg-blue-700 hover:no-underline hover:text-white disabled:border-slate-300 disabled:text-slate-300 disabled:hover:bg-slate-50`}
                    >
                      <span className="mr-2">🪙</span>
                        Award Winner
                  </button>
                </div>
            </div>
          </motion.div>
    </div>
  </>)
}
