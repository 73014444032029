// import twitterIcon from "../public/twitter.svg";
// import Image from "next/image";

const TWITTER_CLIENT_ID = "NVNLX2lOWUROWFVLTzcwTWNuTFE6MTpjaQ" // give your twitter client id here

// twitter oauth Url constructor


// the component
export function TwitterOauthButton() {
  function getTwitterOauthUrl() {
  const rootUrl = "https://twitter.com/i/oauth2/authorize";
  const options = {
    redirect_uri: "http://192.168.0.150:3000/auth/twitter/", // client url cannot be http://localhost:3000/ or http://127.0.0.1:3000/
    // redirect_uri: "http://172.17.130.9:3000/auth/twitter/", // client url cannot be http://localhost:3000/ or http://127.0.0.1:3000/
    client_id: TWITTER_CLIENT_ID,
    state: "state",
    response_type: "code",
    code_challenge: "challenge",
    code_challenge_method: "plain",
    scope: ["users.read", "tweet.read", "follows.read", "follows.write"].join(" "), // add/remove scopes as needed
  };
  const qs = new URLSearchParams(options).toString();
  return `${rootUrl}?${qs}`;
}

  return (
    <a className="a-button row-container" href={getTwitterOauthUrl()}>
      <p className="text-black">Log in with Twitter</p>
      <p>{" twitter"}</p>
    </a>
  );
}

// https://twitter.com/i/oauth2/authorize?response_type=code&client_id=NVNLX2lOWUROWFVLTzcwTWNuTFE6MTpjaQ&redirect_uri=http://172.20.10.2:3000/auth/twitter/&scope=users.read%20follows.read&state=state&code_challenge=NWRdEFACKWU%3D&code_challenge_method=plain